import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BrandProfileService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'CUSTOM';
    }
    // <--------------------------------------------------ProductCategory stats Apis-------------------------------------------------->
    GetBrandProductCategoryFollowerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandProductCategoryProfileViewerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandProductCategoryClickThroughMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/Click_through_view_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandProductCategoryRecommendationsMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/recommendation_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandProductCategoryBreakdownMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/product_categories_breakdown/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    // <--------------------------------------------------vibes Causes stats Apis-------------------------------------------------->
    GetBrandVibeFollowerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/vibe_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandVibeProfileViewerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/vibe_profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandVibeClickThroughMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/vibe_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandVibeRecommendationsMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/vibe_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandVibeBreakdownMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/vibes_breakdown_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    // <--------------------------------------------------values stats Apis-------------------------------------------------->
    GetBrandValueFollowerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/value_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandValueProfileViewerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/value_profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandValueClickThroughMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/value_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandValueRecommendationsMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/value_recommendation_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandValueBreakdownMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/value_breakdown_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    // <--------------------------------------------------Scoial Causes stats Apis-------------------------------------------------->
    GetBrandSocialCausesFollowerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/social_cause_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandSocialCausesProfileViewerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/social_cause_profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandSocialCausesClickThroughMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/social_cause_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandSocialCausesRecommendationsMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/social_cause_recommendation_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandSocialCausesBreakdownMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/reports/social_cause_breakdown_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
}
BrandProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandProfileService_Factory() { return new BrandProfileService(i0.ɵɵinject(i1.HttpClient)); }, token: BrandProfileService, providedIn: "root" });
