<div class="content-wrapper">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row position-relative">

        <div class="side-img-wrap align-items-center">
            <img alt="dashboard-banner-image" class="side-img"
                 src="{{cdnUrl}}/assets/images/brand-account-images/PersonalBrand_Girl-and-Mirror-Trimmed.png">
        </div>

        <div>
            <h1>Brand Journey Videos</h1>
            <p class="desk-p">
                Share the timeless stories from your journey that are core to your<br> brand’s DNA. We recommend videos
                to be 60 seconds or less.
            </p>
            <p class="mobile-p" style="padding-right: 23px">
                Share the timeless stories from your journey that are core to your brand’s DNA. We recommend videos
                to be 60 seconds or less.
            </p>
        </div>

        <div class="need-help-btn ml-auto">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf2hA0OtD4NlRvhfIOgPJcFNBGqyKIRIYLi7cL8QTKqTj9WAw/viewform"
               target="_blank">Need help uploading your video?</a>
        </div>

    </div>

    <!-- Main content -->
    <section *ngIf="showStories" class="content">
        <div class="container-fluid pr-0">
            <div class="row mx-md-5 pr-0 ml-0">
                <section class="col-12 pr-0">
                    <nav>
                        <div class="nav nav-tabs " id="nav-tab" role="tablist">
                            <a #poplink1 (click)="showTabContent(pop1,poplink1)"
                               class="nav-item nav-link bs-tab-link active" href="javascript:void(0)">All</a>
                            <a #poplink2 (click)="showTabContent(pop2, poplink2)" class="nav-item nav-link bs-tab-link"
                               href="javascript:void(0)">Completed</a>
                            <a #poplink3 (click)="showTabContent(pop3, poplink3)" class="nav-item nav-link bs-tab-link"
                               href="javascript:void(0)">Not Completed</a>
                        </div>
                    </nav>

                    <p *ngIf="uploadlink" class="uploadlink">
                        We’re happy to help you add your video to Tailorie if needed!
                        <a href="https://docs.google.com/forms/d/1kFLzQEhlRRPGm4O4KSJuRG-c3d4g4FqkZKGbA1Gxu58"
                           target="_blank">Let us know!</a>
                    </p>


                    <div class="tab-content pr-0 pr-md-5" id="nav-tabContent">

                        <!-- -------------------- ALL ------------------- -->
                        <div #pop1 aria-labelledby="pop1-tab" class="tab-pane fade bs-tab-content show active" id="pop1"
                             role="tabpanel">
                            <div class="pt-3"></div>

                            <div class="row mr-5 mt-5 mb-5">
                                <div *ngFor="let brandStoryType of allBrandStoryTypes; let j=index"
                                     class="col-xs-12 col-md-5 col-lg-5 col-xl-4 mb-3 px-0 px-md-3">
                                    <div class="card-deck">
                                        <div class="card">
                                            <form enctype="multipart/form-data">
                                                <div class="form-group">
                                                    <fieldset class="form-group">
                                                        <div class="custom-file fileInputProfileWrap stories-video">
                                                            <ng-container
                                                                    *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0; else elseTemplate">
                                                                <div class="stories-video">
                                                                    <video
                                                                            (webkitfullscreenchange)="adjVideo(brandStoryType.id)"
                                                                            [attr.data-postId]="brandStoryType.fStories[0].id"
                                                                            [poster]="brandStoryType.fStories[0].video_thumbnail"
                                                                            controls id="videosrc_{{brandStoryType.id}}"
                                                                            preload="metadata">
                                                                        <source
                                                                                src="{{brandStoryType.fStories[0].video_url}}"
                                                                                type="video/mp4">
                                                                    </video>
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #elseTemplate>
                                                                <div class="img-space"
                                                                     style="cursor: pointer; position: relative">
                                                                    <!--                                                                    <input-->
                                                                    <!--                                                                        (change)="uploadBrandStoryTUSWay($event,brandStoryType); showVDModel();"-->
                                                                    <!--                                                                        accept="video/mp4,video/mov, video/3gpp, video/m4v',-->
                                                                    <!--                                                                            application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"-->
                                                                    <!--                                                                        class="fileInputProfile" style="cursor: pointer"-->
                                                                    <!--                                                                        type="file">-->
                                                                    <!--                                                                    <i class="fas fa-cloud-upload-alt" style=""></i>-->
                                                                    <!--                                                                    <h5>Upload Video</h5>-->
                                                                    <img (click)="showVModal(brandStoryType)"
                                                                         alt="cloud"
                                                                         src="assets/images/icons/cloudIcon.png"
                                                                         style="display: block;width: 85px;height: auto !important ;cursor: pointer;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </form>
                                            <div class="custom-scrollbar-css">
                                                <div class="card-body">
                                                    <p class="card-title">
                                                        {{brandStoryType.brand_post_category_short_text}}
                                                    </p>
                                                    <ng-container
                                                            *ngIf="brandStoryType.brand_post_category_long_text || brandStoryType.brand_post_category_long_text !== ''">
                                                        <br>
                                                        <p [innerHTML]="brandStoryType.brand_post_category_long_text">
                                                        </p>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div class="card-footer">
                                                <div class="video-upload">
                                                    <div appDropdown class="dropdown dropup">
                                                        <button [class.dropdown-hover]="brandStoryType.uploaded"
                                                                [disabled]="!brandStoryType.uploaded"
                                                                class="btn dropdown-toggle p-0" data-toggle="dropdown"
                                                                type="button">
                                                            <img alt="ellipsis" class="ellipsis"
                                                                 src="../../assets/images/icons/ellipses.png">
                                                        </button>
                                                        <div *ngIf="brandStoryType.uploaded" class="dropdown-menu"
                                                             role="menu">
                                                            <a (click)="openTModal(brandStoryType)"
                                                               class="dropdown-item" href="javascript:void(0)"><i
                                                                    aria-hidden="true" class="fa fa-image"
                                                                    style="margin-right: 8px; font-size: 12px"></i>
                                                                Edit Thumbnail</a>
                                                            <a (click)="removeStory(brandStoryType)"
                                                               class="dropdown-item" href="javascript:void(0)"><i
                                                                    aria-hidden="true" class="fa fa-trash"
                                                                    style="margin-right: 10px"></i>
                                                                Delete Video</a>
                                                        </div>
                                                    </div>
                                                    <ng-container
                                                            *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0; else elseFooterTemplate">
                                                        <ng-container>
                                                            <a (click)="loadVideo('',brandStoryType, brandStoryType?.fStories[0], 'all-thumb', 'all-post')"
                                                               style="cursor: pointer; display: inline-block">
                                                                <div [ngClass]="{selected: 0 === 0}"
                                                                     class="thumbnail all-thumb-{{brandStoryType.id}}"
                                                                     id="all-post-{{brandStoryType.fStories[0].id}}">
                                                                    <img alt="video-thumbnail" loading="lazy"
                                                                         src="{{brandStoryType?.fStories[0]?.video_thumbnail ||
                                                                         'https://webcdn.tailorie.com/assets/images/image-not-available.jpeg'}}">
                                                                </div>
                                                            </a>
                                                        </ng-container>

                                                    </ng-container>
                                                    <ng-template #elseFooterTemplate>
                                                        <a *ngFor="let loop of arrayCount(1)"
                                                           class="add-video-placeholder"
                                                           id="show">
                                                            <!--                                                                    <input-->
                                                            <!--                                                                            (change)="uploadBrandStoryTUSWay($event,brandStoryType)"-->
                                                            <!--                                                                            accept="video/mp4,video/mov, video/3gpp, video/m4v',-->
                                                            <!--                                                                            application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"-->
                                                            <!--                                                                            class="tinyFileInput"-->
                                                            <!--                                                                            style="cursor: pointer;"-->
                                                            <!--                                                                            type="file">-->
                                                            <img (click)="showVModal(brandStoryType)" alt="plus"
                                                                 src="../../assets/images/icons/plus.png">
                                                        </a>
                                                    </ng-template>
                                                    <!-- 
                                                    <ng-container *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0; else elseFooterTemplate">
                                                        <ng-container *ngFor="let post of brandStoryType.fStories; let i = index">
                                                            <a (click)="loadVideo('comp',brandStoryType, post, 'comp-thumb', 'comp-post')" style="cursor: pointer; display: inline-block">
                                                                <div [ngClass]="{selected: i === 0}" class="thumbnail comp-thumb-{{brandStoryType.id}}" id="comp-post-{{post.id}}">
                                                                    <img alt="video-thumbnail" src="{{post.video_thumbnail}}">
                                                                </div>
                                                            </a>
                                                        </ng-container>
                                                        <ng-container *ngFor="let rand of arrayCount(3-brandStoryType.fStories.length)">
                                                            <a class="add-video-placeholder">
                                                                                                                                       <input
                                                                                                                                               (change)="uploadBrandStoryTUSWay($event,brandStoryType)"
                                                                                                                                               accept="video/mp4,video/mov, video/3gpp, video/m4v',
                                                                                                                                           application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"
                                                                                                                                               class="tinyFileInput"
                                                                                                                                               style="cursor: pointer;"
                                                                                                                                               type="file">
                                                                <img (click)="showVModal(brandStoryType)" alt="plus" src="../../assets/images/icons/plus.png">
                                                            </a>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-template #elseFooterTemplate>
                                                        <a *ngFor="let loop of arrayCount(1)" class="add-video-placeholder">
                                                                                                                               <input
                                                                                                                                       (change)="uploadBrandStoryTUSWay($event,brandStoryType)"
                                                                                                                                       accept="video/mp4,video/mov, video/3gpp, video/m4v',
                                                                                                                                       application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"
                                                                                                                                       class="tinyFileInput"
                                                                                                                                       style="cursor: pointer;"
                                                                                                                                       type="file">
                                                            <img (click)="showVModal(brandStoryType)" alt="plus" src="../../assets/images/icons/plus.png">
                                                        </a>
                                                    </ng-template> -->
                                                </div>

                                                <div class="video-description">
                                                    <button (click)="showDModal(brandStoryType)"
                                                            *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0"
                                                            class="edit" type="button">Edit Description
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="progress" style="height: 4px;">
                                                <div *ngIf="!brandStoryType.uploaded" aria-valuemax="100"
                                                     aria-valuemin="0" aria-valuenow="25" class="progress-bar"
                                                     role="progressbar" style="width: 100%;"></div>
                                                <div *ngIf="brandStoryType.uploaded" aria-valuemax="100"
                                                     aria-valuemin="0" aria-valuenow="25" class="progress-bar1"
                                                     role="progressbar" style="width: 100%;"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- Propeller Card -->

                            </div>

                        </div>
                        <!-- -------------------- Completed ------------------- -->
                        <div #pop2 aria-labelledby="pop2-tab" class="tab-pane fade bs-tab-content" id="pop2"
                             role="tabpanel">
                            <div class="pt-3"></div>

                            <div class="row mr-5 mt-5 mb-5">
                                <ng-container *ngFor="let brandStoryType of allBrandStoryTypes">
                                    <ng-container *ngIf="brandStoryType.uploaded">
                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 px-0 px-md-3">
                                            <div class="card-deck">
                                                <div class="card">
                                                    <form enctype="multipart/form-data">
                                                        <div class="form-group">
                                                            <fieldset class="form-group">
                                                                <div
                                                                        class="custom-file fileInputProfileWrap stories-video">
                                                                    <ng-container
                                                                            *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0; else elseTemplate">
                                                                        <div class="stories-video">
                                                                            <video
                                                                                    (webkitfullscreenchange)="adjVideo(brandStoryType.id)"
                                                                                    [attr.data-postId]="brandStoryType.fStories[0].id"
                                                                                    [poster]="brandStoryType.fStories[0].video_thumbnail"
                                                                                    controls
                                                                                    id="comp_videosrc_{{brandStoryType.id}}"
                                                                                    preload="metadata"
                                                                                    src="{{brandStoryType.fStories[0].video_url}}">
                                                                            </video>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplate>
                                                                        <div class="img-space" style="cursor: pointer">
                                                                            <!--                                                                            <input-->
                                                                            <!--                                                                                (change)="uploadBrandStoryTUSWay($event,brandStoryType); showVDModel()"-->
                                                                            <!--                                                                                accept="video/mp4,video/mov, video/3gpp, video/m4v',-->
                                                                            <!--                                                                            application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"-->
                                                                            <!--                                                                                class="tinyFileInput"-->
                                                                            <!--                                                                                style="cursor: pointer" type="file">-->
                                                                            <i (click)="showVModal(brandStoryType)"
                                                                               class="fas fa-cloud-upload-alt"
                                                                               style=""></i>
                                                                            <h5>Upload Video</h5>
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                    </form>
                                                    <div class="custom-scrollbar-css">
                                                        <div class="card-body">
                                                            <h5 class="card-title">
                                                                {{brandStoryType.brand_post_category_short_text}}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer">
                                                        <div class="video-upload">
                                                            <div appDropdown class="dropdown dropup">
                                                                <button [class.dropdown-hover]="brandStoryType.uploaded"
                                                                        [disabled]="!brandStoryType.uploaded"
                                                                        class="btn dropdown-toggle"
                                                                        data-toggle="dropdown"
                                                                        style="font-size:24px; padding: 0px 10px 0 0 !important;"
                                                                        type="button">
                                                                    <img alt="ellipsis" class="ellipsis"
                                                                         src="../../assets/images/icons/ellipses.png">
                                                                </button>
                                                                <div class="dropdown-menu" role="menu">
                                                                    <a (click)="openTModal(brandStoryType)"
                                                                       class="dropdown-item" href="javascript:void(0)"><i
                                                                            aria-hidden="true" class="fa fa-image"
                                                                            style="margin-right: 8px; font-size: 12px"></i>
                                                                        Edit Thumbnail</a>
                                                                    <a (click)="removeCompStory(brandStoryType)"
                                                                       *ngIf="brandStoryType.uploaded"
                                                                       class="dropdown-item" href="javascript:void(0)">
                                                                        <i aria-hidden="true" class="fa fa-trash"
                                                                           style="margin-right: 10px;"></i> Delete
                                                                        Video
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <ng-container
                                                                    *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0; else elseFooterTemplate">
                                                                <ng-container>
                                                                    <a (click)="loadVideo('',brandStoryType, brandStoryType?.fStories[0], 'all-thumb', 'all-post')"
                                                                       style="cursor: pointer; display: inline-block">
                                                                        <div [ngClass]="{selected: 0 === 0}"
                                                                             class="thumbnail all-thumb-{{brandStoryType.id}}"
                                                                             id="all-post-{{brandStoryType.fStories[0].id}}">
                                                                            <img alt="video-thumbnail"
                                                                                 src="{{brandStoryType?.fStories[0]?.video_thumbnail ||
                                                                                 'https://webcdn.tailorie.com/assets/images/image-not-available.jpeg' }}">
                                                                        </div>
                                                                    </a>
                                                                </ng-container>

                                                            </ng-container>
                                                            <ng-template #elseFooterTemplate>
                                                                <a *ngFor="let loop of arrayCount(1)"
                                                                   class="add-video-placeholder">
                                                                    <!--                                                                    <input-->
                                                                    <!--                                                                            (change)="uploadBrandStoryTUSWay($event,brandStoryType)"-->
                                                                    <!--                                                                            accept="video/mp4,video/mov, video/3gpp, video/m4v',-->
                                                                    <!--                                                                            application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"-->
                                                                    <!--                                                                            class="tinyFileInput"-->
                                                                    <!--                                                                            style="cursor: pointer;"-->
                                                                    <!--                                                                            type="file">-->
                                                                    <img (click)="showVModal(brandStoryType)" alt="plus"
                                                                         src="../../assets/images/icons/plus.png">
                                                                </a>
                                                            </ng-template>


                                                        </div>

                                                        <div class="video-description">
                                                            <button (click)="showDModal(brandStoryType)" class="edit"
                                                                    type="button">Edit
                                                                Description
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="progress" style="height: 4px;">
                                                        <div *ngIf="!brandStoryType.uploaded" aria-valuemax="100"
                                                             aria-valuemin="0" aria-valuenow="25" class="progress-bar"
                                                             role="progressbar" style="width: 100%;"></div>
                                                        <div *ngIf="brandStoryType.uploaded" aria-valuemax="100"
                                                             aria-valuemin="0" aria-valuenow="25" class="progress-bar1"
                                                             role="progressbar" style="width: 100%;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </div>
                            <!-- Propeller Card -->

                        </div>
                        <!-- -------------------- Not Completed ------------------- -->
                        <div #pop3 aria-labelledby="pop3-tab" class="tab-pane fade bs-tab-content" id="pop3"
                             role="tabpanel">
                            <div class="pt-3"></div>
                            <div class="row mr-5 mt-5 mb-5">
                                <ng-container *ngFor="let brandStoryType of allBrandStoryTypes">
                                    <ng-container *ngIf="!brandStoryType.uploaded">
                                        <div class="col-lg-4 mb-3">
                                            <div class="card-deck">
                                                <div class="card">
                                                    <form enctype="multipart/form-data">
                                                        <div class="form-group">
                                                            <fieldset class="form-group">
                                                                {{imageError}}
                                                                <div class="custom-file fileInputProfileWrap">

                                                                    <ng-container
                                                                            *ngIf="brandStoryType.uploaded; else elseTemplate">
                                                                        <video [poster]="brandStoryType.fStories[0].video_thumbnail" controls
                                                                               preload="metadata"
                                                                               src="{{brandStoryType.brandStory.video_url}}"
                                                                               style="height: 410px; width: 100%"></video>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplate>

                                                                        <div class="img-space"
                                                                             style="cursor: pointer; position: relative">
                                                                            <!--                                                                            <input-->
                                                                            <!--                                                                                (change)="uploadBrandStoryTUSWay($event,brandStoryType); showVDModel()"-->
                                                                            <!--                                                                                accept="video/mp4,video/mov, video/3gpp, video/m4v',-->
                                                                            <!--               application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"-->
                                                                            <!--                                                                                class="fileInputProfile" type="file">-->

                                                                            <img (click)="showVModal(brandStoryType)"
                                                                                 alt="cloud"
                                                                                 src="assets/images/icons/cloudIcon.png"
                                                                                 style="display: block;width: 85px;height: auto !important ;cursor: pointer;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">

                                                                        </div>
                                                                    </ng-template>


                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                    </form>
                                                    <div class="custom-scrollbar-css">
                                                        <div class="card-body">
                                                            <p class="card-title">
                                                                {{brandStoryType.brand_post_category_short_text}}
                                                            </p>
                                                            <ng-container
                                                                    *ngIf="brandStoryType.brand_post_category_long_text || brandStoryType.brand_post_category_long_text !== ''">
                                                                <br>
                                                                <p
                                                                        [innerHTML]="brandStoryType.brand_post_category_long_text">
                                                                </p>

                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="card-footer">
                                                        <div class="video-upload">

                                                            <div appDropdown class="dropdown dropup">
                                                                <button [class.dropdown-hover]="brandStoryType.uploaded"
                                                                        [disabled]="!brandStoryType.uploaded"
                                                                        class="btn dropdown-toggle p-0"
                                                                        data-toggle="dropdown" type="button">
                                                                    <img alt="ellipsis" class="ellipsis"
                                                                         src="../../assets/images/icons/ellipses.png">
                                                                </button>
                                                                <div *ngIf="brandStoryType.uploaded"
                                                                     class="dropdown-menu" role="menu">
                                                                    <a (click)="removeStory(brandStoryType)"
                                                                       class="dropdown-item"
                                                                       href="javascript:void(0)"><i aria-hidden="true"
                                                                                                    class="fa fa-trash"
                                                                                                    style="margin-right: 10px"></i>
                                                                        Delete Video</a>
                                                                </div>
                                                            </div>
                                                            <ng-container
                                                                    *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0; else elseFooterTemplate">
                                                                <ng-container>
                                                                    <a (click)="loadVideo('',brandStoryType, brandStoryType?.fStories[0], 'all-thumb', 'all-post')"
                                                                       style="cursor: pointer; display: inline-block">
                                                                        <div [ngClass]="{selected: 0 === 0}"
                                                                             class="thumbnail all-thumb-{{brandStoryType.id}}"
                                                                             id="all-post-{{brandStoryType.fStories[0].id}}">
                                                                            <img alt="video-thumbnail"
                                                                                 src="{{brandStoryType?.fStories[0]?.video_thumbnail}}">
                                                                        </div>
                                                                    </a>
                                                                </ng-container>

                                                            </ng-container>
                                                            <ng-template #elseFooterTemplate>
                                                                <a *ngFor="let loop of arrayCount(1)"
                                                                   class="add-video-placeholder">
                                                                    <!--                                                                    <input-->
                                                                    <!--                                                                            (change)="uploadBrandStoryTUSWay($event,brandStoryType)"-->
                                                                    <!--                                                                            accept="video/mp4,video/mov, video/3gpp, video/m4v',-->
                                                                    <!--                                                                            application/x-mpegURL, video/MP2T, video/quicktime, video/x-msvideo, video/x-flv, video/x-m4v, video/wmv"-->
                                                                    <!--                                                                            class="tinyFileInput"-->
                                                                    <!--                                                                            style="cursor: pointer;"-->
                                                                    <!--                                                                            type="file">-->
                                                                    <img (click)="showVModal(brandStoryType)" alt="plus"
                                                                         src="../../assets/images/icons/plus.png">
                                                                </a>
                                                            </ng-template>

                                                        </div>

                                                        <div class="video-description">
                                                            <button (click)="showDModal(brandStoryType)"
                                                                    *ngIf="brandStoryType.fStories && brandStoryType.fStories.length > 0"
                                                                    class="edit" type="button">Edit Description
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="progress" style="height: 4px;">
                                                        <div *ngIf="!brandStoryType.uploaded" aria-valuemax="100"
                                                             aria-valuemin="0" aria-valuenow="25" class="progress-bar"
                                                             role="progressbar" style="width: 100%;"></div>
                                                        <div *ngIf="brandStoryType.uploaded" aria-valuemax="100"
                                                             aria-valuemin="0" aria-valuenow="25" class="progress-bar1"
                                                             role="progressbar" style="width: 100%;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </div>
                            <!-- Propeller Card -->


                        </div>
                        <div *ngIf="showSaveBar" [@slideInOut] class="saveBar" style="padding: 30px;">
                            <div class="d-flex flex-nowrap align-items-center">
                                         <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                         </span>
                                <span *ngIf="showAlertMessage" class="alertSuccessMessage">
                                            <img alt="success" src="assets/images/checkMark.png"
                                                 style="width: 19px;"> {{alertMessage}}
                                        </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>


                </section>
            </div>
        </div>
    </section>

    <!-- /.content -->

    <!--  POP UPS  -->
    <!-- Upload Video Modal -->
    <div *ngIf="showVideoModal" [style.display]="showVideoModal ? 'flex' : 'none'" class="modal upload-modal"
         id="myModal">
        <div class="modal-dialog modal-lg modal-padding-adj">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 *ngIf="tempBrandStoryType" class="modal-title">Add
                        a {{tempBrandStoryType.header}} Video</h4>
                    <button (click)="hideVModal()" class="close" data-dismiss="modal" type="button">
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container pl-0">
                        <div class="row main-form">
                            <div class="col-lg-7">
                                <div class="row">
                                    <div class="col-lg-12 pl-0">
                                        <h4 *ngIf="tempBrandStoryType"
                                            class="story-type-title">{{tempBrandStoryType.brand_post_category_short_text}}</h4>
                                        <p class="">
                                            Videos should be 60 seconds or less.
                                        </p>
                                    </div>
                                </div>

                                <div *ngIf="tempBrandStoryType" class="row">
                                    <!--                                                        <div class="col-lg-12 side-text pl-0" *ngIf="tempBrandStoryType.brand_post_category_long_text">-->
                                    <!--                                                            <p class="pb-0 mb-1">{{tempBrandStoryType.brand_post_category_long_text}}-->
                                    <!--                                                            </p>-->
                                    <!--                                                        </div>-->
                                    <ng-container *ngIf="arrayEle" class="side-text">
                                        <ng-container
                                                *ngFor="let text of arrayEle; let index = index">
                                            <p class="pb-0 mb-1 d-block w-100"><span
                                                    style="display: none">{{ index + 1}}</span>
                                                •<span [innerHTML]="text"></span>
                                            </p>
                                        </ng-container>
                                    </ng-container>

                                    <div *ngIf="!tempBrandStoryType.brand_post_category_long_text"
                                         class="col-lg-12 side-text pl-0">
                                        <p class="pb-0 mb-1 desk-p">• Here at Tailorie, we are
                                            inspired by
                                            diverse brands like you! Where do you
                                            draw your inspiration?
                                        </p>
                                        <p class="pb-0 mb-1 mobile-p">• Here at Tailorie, we are
                                            inspired by
                                            diverse brands like you!<br> Where do you
                                            draw your inspiration?
                                        </p>
                                        <p class="pb-0 mb-1"> • Do you have a process of getting
                                            yourself into the creative zone?</p>
                                        <p class="pb-0 mb-0"> • Tell us a personal story about how
                                            you became inspired.</p>
                                    </div>
                                </div>
                                <!--                                                    <div class="row">-->
                                <!--                                                        <div class="question col-lg-12">-->
                                <!--                                                            <a style="cursor: pointer;">-->
                                <!--                                                                <h1>&nbsp;</h1>-->
                                <!--                                                            </a>-->

                                <!--                                                        </div>-->
                                <!--                                                    </div>-->
                                <div class="row">
                                    <div class="col-lg-8 pl-0">

                                        <!--                                        <label *ngIf="tempBrandStoryType"-->
                                        <!--                                               class="btn7 text-capitalize text-center show-on-desktop">-->
                                        <!--                                            <span>-->
                                        <!--                                                Upload your {{tempBrandStoryType.header}} video-->
                                        <!--                                            </span>-->

                                        <!--                                            <input #addVideo-->
                                        <!--                                                   (change)="add($event)"-->
                                        <!--                                                   *ngIf="!thumbnailData"-->
                                        <!--                                                   accept="video/*"-->
                                        <!--                                                   hidden-->
                                        <!--                                                   id="select-video" type="file">-->

                                        <!--                                            <input (click)="uploadMoreVideosTUSWay(); showVDModel()"-->
                                        <!--                                                   *ngIf="thumbnailData"-->
                                        <!--                                                   accept="video/*" hidden-->
                                        <!--                                                   id="introVideoUpload">-->
                                        <!--                                        </label>-->

                                        <!-- Start video uploading and move to next description popup | For Mobile-->
                                        <label
                                                class="btn7 text-capitalize text-center">
                                            <span>
                                                Upload your {{tempBrandStoryType?.header}} video
                                            </span>

                                            <!-- Start video uploading and move to next description popup | For Mobile-->
                                            <input #addJourneyVideo
                                                   (change)="directUpload($event)"
                                                   *ngIf="!thumbnailData"
                                                   accept="video/*" hidden
                                                   id="select-video-mobile" type="file">

                                            <!--                                            <input (click)="uploadMoreVideosTUSWay(); showVDModel()"-->
                                            <!--                                                   *ngIf="thumbnailData"-->
                                            <!--                                                   accept="video/*" hidden-->
                                            <!--                                                   id="introVideoUploadMob">-->

                                        </label>


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 offset-1 px-5 px-md-1 video-preview">
                                <input
                                    #selectjourneyvideo
                                    (change)="directUpload($event)" accept="video/*"
                                    hidden
                                    id="select-video" type="file">
                                <div (click)="selectjourneyvideo.click()" class="mobile position-relative" style="z-index: 55">
                                    <div class="desk-view">
                                        <img [ngClass]="{'toggleIndex': thumbnailData}" alt="iPhone" loading="lazy"
                                             src="../../assets/images/iphone-white.svg"
                                             style="position: absolute;margin-left: -66px;z-index: 15;margin-top: -64px;">
                                        <img *ngIf="thumbnailData" [src]="thumbnailData" alt="thumbnail-image"
                                             loading="lazy"
                                             style="position: absolute;left: 11px;top: 48%;z-index: 99;height: auto;width: 213px;transform: translateY(-50%);border-radius: 10px;"/>
                                    </div>
                                    <div (click)="add($event)" class="video-preview">
                                        <img *ngIf="thumbnailData" [src]="thumbnailData" alt="thumbnail-image"
                                             loading="lazy"
                                             style="filter: blur(8px); webkit-filter: blur(8px); width: 100%;height: 100%;object-fit: cover;object-position: center; z-index: 30"/>
                                        <div *ngIf="!thumbnailData">
                                            <img alt="cloud" loading="lazy" src="assets/images/icons/cloudIcon.png"
                                                 style="display: block;width: 85px;height: auto !important ;cursor: pointer;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Description Into Brand Story Model -->
    <div *ngIf="showDescModal" [style.display]="showDescModal ? 'block' : 'none'" class="modal description-modal"
         id="myModal2">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Description
                    </h4>
                    <button (click)="hideDModal()" [disabled]="isLoading" class="close" data-dismiss="modal" type="button">
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body py-0"
                     style="border-bottom-left-radius: 20px; border-bottom-right-radius: 20px">
                    <div class="container pl-0 mb-5">
                        <div class="row main-brand">
                            <div class="col-lg-7 px-0">
                                <div class="row">
                                    <div class="col-lg-12 pl-0">
                                        <h4 *ngIf="tempBrandStoryType" class="mb-0">
                                            {{tempBrandStoryType.brand_post_category_short_text}}
                                        </h4>
                                        <h4 *ngIf="!tempBrandStoryType" class="mb-0">
                                            Introduce Your Brand
                                        </h4>
                                        <p class="mb-0"
                                           style="opacity: 1; font-size: 18px; line-height: 1.3;">
                                            Start the conversation and engage your audience with an
                                            <br> authentic description of your video.
                                        </p>
                                    </div>
                                </div>

                                <div class="row no-gutters">
                                    <div class="col-lg-9">
                                        <div class="text-erea">
                                            <div class="form-group mb-0">
                                                                    <textarea
                                                                            *ngIf="tempBrandStoryType && tempPostforDesc"
                                                                            [(ngModel)]="tempPostforDesc.description"
                                                                            [disabled]="isLoading"
                                                                            class="form-control" id="branddescriptioncomment"
                                                                            maxlength="250"
                                                                            onkeyup="countChar250(this)" placeholder="Write here..."
                                                                            rows="5"
                                                                            style="resize: none">
                                                                    </textarea>
                                                <label class="labelclass" id="charNum250">
                                                    {{0}}/250 Characters
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="tempPostforDesc">
                                    <div class="row">
                                        <p class="p-0 m-0 mb-2">Select your video</p>
                                        <ng-container *ngIf="tempBrandStoryType">
                                            <div class="col-lg-12 p-0">
                                                <ng-container
                                                        *ngFor="let post of tempBrandStoryType.fStories; let i = index">
                                                    <a (click)="loadVideoDescription(post)"
                                                       [ngClass]="{selected: post == tempPostforDesc}"
                                                       style="cursor: pointer; display: inline-block; margin-right: 10px">
                                                        <div class="thumbnail"
                                                             style="border-radius: 4px">
                                                            <img alt="video-thumbnail" loading="lazy"
                                                                 src="{{post.video_thumbnail || 'https://webcdn.tailorie.com/assets/images/image-not-available.jpeg'}}">
                                                        </div>
                                                    </a>
                                                </ng-container>

                                            </div>
                                        </ng-container>

                                    </div>
                                </ng-container>
                                <div class="col-lg-8 pl-lg-0 mt-4">
                                    <button (click)="updateCurrentPost()" [disabled]="isLoading" class="btn7 border-0">
                                        <span *ngIf="!isLoading"> Save Description</span>
                                        <span *ngIf="isLoading" class="tiny-loader"></span>
                                    </button>
                                    <div *ngIf="flashMessage"
                                         class="flex items-center mr-4">
                                        <ng-container *ngIf="flashMessage === 'success'">
                                            <small class="ml-2">
                                                <i class="fa fa-check"
                                                   style="color: #006aa5"></i>
                                                Description Updated Successfully</small>
                                        </ng-container>
                                        <ng-container *ngIf="flashMessage === 'error'">
                                            <small class="ml-2"> <i class="fa fa-times"
                                                                    style="color: #e4430a"></i>
                                                An error occurred, try again!</small>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 offset-1 px-5 px-md-1 description-thumbnail">
                                <div *ngIf="tempPostforDesc" class="mobile position-relative"
                                     style="z-index: 55">
                                    <img [ngClass]="{'toggleIndex': tempPostforDesc.video_thumbnail}" alt="iphone-frame"
                                         loading="lazy"
                                         src="../../assets/images/iphone-white.svg"
                                         style="position: absolute;width:312px; margin-left: -68px;margin-top: -44px; z-index: 15">
                                    <div class="video-preview">
                                        <img #pic (load)="onLoad()"
                                             [src]="tempPostforDesc.video_thumbnail"
                                             alt="image for test"
                                             loading="lazy"
                                             style="visibility: hidden; position: absolute; z-index: -1">

                                        <img *ngIf="tempPostforDesc.video_thumbnail"
                                             [ngStyle]="popUpImagePreview === 'h' ? {'filter': 'blur(8px)', 'webkit-filter': 'blur(8px)'} : {}"
                                             [src]="tempPostforDesc.video_thumbnail"
                                             alt="thumbnail" loading="lazy"
                                             style="width: 100%;height: 100%;object-fit: cover; object-position: center;"/>


                                        <img *ngIf="tempPostforDesc.video_thumbnail && popUpImagePreview === 'h'"
                                             [src]="tempPostforDesc.video_thumbnail" alt="thumbnail" loading="lazy"
                                             style="position: absolute;left: 0;top: 48%;z-index: 99;height: auto;width: 210px;transform: translateY(-50%);border-radius: 10px;"/>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- video upload Description Model -->
    <div *ngIf="showVideoDescModal" [style.display]="showVideoDescModal ? 'block' : 'none'" class="modal description-modal"
         id="myModal3">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Description
                    </h4>
                    <button (click)="hideVDModal()" class="close" data-dismiss="modal"
                            type="button">
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body py-0"
                     style="border-bottom-left-radius: 20px; border-bottom-right-radius: 20px">
                    <div class="container pl-0 mb-5">
                        <div class="row main-brand">
                            <div class="col-lg-7 pl-0">
                                <div class="row">
                                    <div class="col-lg-12 pl-0">
                                        <h4 class="mb-0">
                                            {{shortText}}
                                            <!-- Introduce Your Brand-->
                                        </h4>
                                        <p class="mb-0"
                                           style="opacity: 1; font-size: 18px; line-height: 1.3;">
                                            Start the conversation and engage your audience with an
                                            <br> authentic description of your video.
                                        </p>
                                    </div>
                                </div>

                                <div class="row no-gutters">
                                    <div class="col-md-9">
                                        <div class="text-erea">
                                            <div class="form-group mb-0">
                                                <textarea [(ngModel)]="brandVideoDescription"
                                                          class="form-control"
                                                          id="branddescription" maxlength="250"
                                                          onkeyup="countChar250b(this)"
                                                          placeholder="Write here..." rows="5"
                                                          style="resize: none">
                                                </textarea>
                                                <label class="labelclass" id="charNum250b">
                                                    {{0}}/250 Characters
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 pl-0 mt-4">
                                    <label (click)="saveModalDescription()"
                                           class="btn7">
                                        Save Description
                                    </label>
                                </div>

                            </div>
                            <div class="col-md-4 offset-1 px-5 px-md-1 description-thumbnail">
                                <div class="mobile position-relative"
                                     style="z-index: 55">
                                    <img alt="iphone-frame"
                                         loading="eager"
                                         src="../../assets/images/iphone-white.svg"
                                         style="position: absolute;width:312px; margin-left: -68px;margin-top: -44px; z-index: 15">
                                    <div class="video-Dec-preview">
                                        <div>
                                            <img alt="cloud" loading="eager" src="assets/images/icons/clound.png"
                                                 style="display: block;width:48px;height: auto !important ;cursor: pointer;position: absolute; top: 43%; left: 50%; transform: translate(-50%, -50%)">
                                            <p style="display: block;font-family: TTCommons-Regular; color: #c78891;font-size: 15px; width: 192px;height: auto !important ;cursor: pointer;position: absolute; top: 55%; left: 63%; transform: translate(-50%, -50%)">
                                                Your video is uploading!</p>
                                            <p style="display: block;font-family: TTCommons-Regular; color: #c78891;font-size: 13px; width: 130px;height: auto !important ;cursor: pointer;position: absolute; top: 62%; left: 56%; transform: translate(-50%, -50%)">
                                                A preview will display</p>
                                            <p style="display: block;font-family: TTCommons-Regular; color: #c78891;font-size: 13px; width: 130px;height: auto !important ;cursor: pointer;position: absolute; top: 66%; left: 57%; transform: translate(-50%, -50%)">
                                                here once it’s ready.</p>
                                            <p style="display: block; color: #c78891;font-family: TTCommons-Regular; font-size: 13px;width: 140px;height: auto !important ;cursor: pointer;position: absolute; top: 74%; left: 55%; transform: translate(-50%, -50%)">
                                                In the meantime, add a</p>
                                            <p style="display: block; color: #c78891;font-family: TTCommons-Regular; font-size: 13px;width: 140px;height: auto !important ;cursor: pointer;position: absolute; top: 78%; left: 52%; transform: translate(-50%, -50%)">
                                                description of your video.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--  -----------  THUMBNAIL EDIT MODAL  ----------- -->

    <div *ngIf="showThumbnailModal" [style.display]="showThumbnailModal ? 'flex' : 'none'" class="modal upload-modal"
         id="thumbnailModal">
        <div class="modal-dialog modal-lg modal-padding-adj">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 *ngIf="tempBrandStoryType" class="modal-title">
                        Update {{tempBrandStoryType.header}} Video Thumbnail</h4>
                    <button (click)="closeTModal()" class="close" data-dismiss="modal" type="button">
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div *ngIf="tempBrandStoryType" class="modal-body">
                    <div class="container pl-0">
                        <div class="row main-form">
                            <div class="col-lg-7">
                                <h4 class="story-type-title">
                                    Select your video preview thumbnail
                                </h4>

                                <div *ngIf="tempBrandStoryType" class="video-thumb-preview">
                                    <!--                                    <video [muted]="true" [src]="tempBrandStoryType.fStories[0].video_url"-->
                                    <!--                                           controlsList="nodownload nofullscreen"-->
                                    <!--                                           id="videoPreview_{{tempBrandStoryType.id}}"></video>-->

                                    <video *ngIf="tempBrandStoryType && tempBrandStoryType.fStories" [muted]="true" [poster]="tempBrandStoryType.fStories[0].video_thumbnail"
                                           controls controlsList="nodownload nofullscreen"
                                           id="videoPreview_{{tempBrandStoryType.id}}" playsinline preload="metadata">
                                        <source
                                                src="{{tempBrandStoryType.fStories[0].video_url}}"
                                                type="video/mp4">
                                    </video>


                                </div>

                                <!--                                <div class="capture-button">-->
                                <!--                                    <button  class="btn btn-outline-secondary"><i class="fa fa-camera"></i> Capture</button>-->
                                <!--                                </div>-->

                                <button (click)="captureThumbnail(tempBrandStoryType)"
                                        [disabled]="isLoading"
                                        class="btn7 capture-button show-on-desktop">
                                    Capture Thumbnail
                                </button>


                                <!--                                <div style="border: 1px solid grey; width: 300px; height: 250px;">-->
                                <!--                                    <canvas id="sampleCanvas" style="overflow: hidden; height: 100%; width: 100%; object-fit: contain"></canvas>-->
                                <!--                                </div>-->

                            </div>
                            <div class="col-lg-4 offset-1 px-5 px-md-1 video-preview">

                                <div class="mobile position-relative" style="z-index: 55">
                                    <div class="desk-view">
                                        <img [ngClass]="{'toggleIndex': thumbnailData}" alt="iPhone" loading="lazy"
                                             src="../../assets/images/iphone-white.svg"
                                             style="position: absolute;margin-left: -66px;z-index: 15;margin-top: -64px;">


                                    </div>
                                    <div class="video-preview">
                                        <img *ngIf="thumbnailData" [src]="thumbnailData" alt="thumbnail-image"
                                             loading="lazy"
                                             style="filter: blur(8px); webkit-filter: blur(8px); width: 100%;height: 100%;object-fit: cover;object-position: center; z-index: 30"/>
                                        <div *ngIf="!thumbnailData">
                                            <img alt="cloud" loading="lazy" src="{{cdnUrl}}/assets/images/no-preview-available-pink.png"
                                                 style="display: block;width: 100px;height: auto !important ;cursor: pointer;position: absolute; top: 50%; left: 50%; transform:
                                                 translate(-50%, -50%)">
                                        </div>
                                        <img *ngIf="videoThumbnailPreview" [src]="videoThumbnailPreview" alt="thumbnail-image"
                                             loading="lazy"
                                             style="position: absolute;left: -12px;top: 50%;z-index: 95;height: auto;width: 258px;transform: translateY(-50%);"/>
                                        <div class="canvas-wrapper">
                                            <canvas id="prevCanvas"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 mt-2 mt-lg-5">
                                <button (click)="updateThumbnail()"
                                        [disabled]="isLoading"
                                        class="btn7 update-button">
                                    <span *ngIf="!isLoading">
                                        <span *ngIf="!tempBrandStoryType.fStories[0].video_thumbnail">
                                            Add Thumbnail
                                        </span>
                                        <span *ngIf="tempBrandStoryType.fStories[0].video_thumbnail">
                                            Update Thumbnail
                                        </span>

                                    </span>
                                    <span *ngIf="isLoading" class="tiny-loader blue-cutoff"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
