import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {AuthenticatedUser} from '../../services/auth/authenticateduser';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-footer-homepage',
    templateUrl: './footer-homepage.component.html',
    styleUrls: ['./footer-homepage.component.scss']
})
export class FooterHomepageComponent implements OnInit {
    version = '1.0.0';
    currentUser: AuthenticatedUser;
    isHomepage = false;
    cdnUrl = environment.webStaticResources;

    constructor(private authService: AuthService, private router: Router) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    ngOnInit() {
        this.isHomepage = (this.router.url).toString() === '/';
    }

}
