export let single = [
    {
        name: 'Profile Views',
        value: 7200
    },
    {
        name: 'Website Click-Through',
        value: 3150
    },
    {
        name: 'Brand Mentions',
        value: 2940
    },
    {
        name: 'Recommendations',
        value: 2940
    }
];

