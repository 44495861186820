import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Interests } from './interests';

@Injectable({
  providedIn: 'root'
})
export class InterestsService extends ApiService {
  selected = {
    startDate: null,
    endDate: null,
};
currentFilter: String = 'WEEK';
  constructor(private http: HttpClient) {
      super();
  }

  GetAllInterests(): Observable<Interests>  {
    return this.http.get<Interests>(`${this.apiUrl}/interests/`).pipe();
}
FetchFollowerData(id,startDate, endDate){
  return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/interest_follower/?date_from=${startDate}&date_to=${endDate}&interest_id=${id}`)
  .pipe();
}

FetchRecommendersData(id,startDate, endDate){
  return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/interest_recommendation/?date_from=${startDate}&date_to=${endDate}&interest_id=${id}`)
  .pipe();
}
FetchJourneyData(id,startDate, endDate){
  return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/interest_viewer/?date_from=${startDate}&date_to=${endDate}&interest_id=${id}`)
  .pipe();
}
}
