import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {BrandStory} from '../brand_stories/brand-stories';
import {ContactUs} from './Contact_Us';

@Injectable({
    providedIn: 'root',
})
export class ContactUsService extends ApiService {
    constructor(private http: HttpClient) {
        super();
    }

    // POST
    ContactSubmissions(data): Observable<ContactUs> {
        return this.http.post<ContactUs>(`${this.apiUrl}/contact_submissions/`, data).pipe();
    }
}
