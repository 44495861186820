import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BrandService} from '../shared/services/brand/brand.service';
import {AuthService} from '../shared/services/auth/auth.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {BrandSignupChecklist} from '../shared/services/brand/brand';
import {VideoUploadServiceService} from '../shared/services/video-upload-service.service';

@Component({
    selector: 'app-dash-layout',
    templateUrl: './dash-layout.component.html',
    styleUrls: ['./dash-layout.component.scss']
})

export class DashLayoutComponent implements OnInit {
    brandsSignUpChecklist: any;
    loadDashboard = false;
    private currentUser: AuthenticatedUser;

    constructor(private brandService: BrandService, private authService: AuthService, private router: Router,
                public videoUpload: VideoUploadServiceService) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {
        if (this.currentUser && this.currentUser.user.branduserprofile) {
            this.brandService.GetBrandSignupChecklistResolve(+this.currentUser.user.branduserprofile.brand.id).toPromise().then(myData => {
                const brandData = myData as BrandSignupChecklist;
                if (brandData) {
                    if (brandData.provided_brand_description && brandData.provided_business_details && brandData.provided_category_details
                        && brandData.selected_brand_values && brandData.selected_brand_vibes && brandData.selected_product_categories &&
                        brandData.selected_social_values && brandData.shared_customer_profile && brandData.uploaded_intro_video) {
                        console.log('OnBoarding Complete!');
                        this.loadDashboard = true;

                        // this.videoUpload.UploadIntroVideo();
                        this.videoUpload.UploadIntroVideoTUSWay();
                    } else {
                        console.log('Complete Onboarding Process First!');
                        this.loadDashboard = false;
                        this.router.navigateByUrl('/brandaccount');
                        return false;
                    }

                } else {
                    console.log('Could Not resolve data!');
                    this.loadDashboard = false;
                    this.router.navigateByUrl('/brandaccount');
                    return false;
                }
            });
        } else {
            console.log('Brand User Profile Does not Exists.');
            this.router.navigateByUrl('/brandaccount');
            return false;
        }
    }

}
