<main class="login">
    <div class="container">
        <div class="row justify-content-center align-content-center">
            <div class="col-md-6">

                <div class="login-box">
                    <div class="login-logo">
                        <a href="https://tailorie.com">
                            <img alt="tailorie" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">
                            <span>Brands</span>
                        </a>
                    </div>
                    <!-- /.login-logo -->
                    <div class="card-body login-card-body">
                        <div *ngIf="isLoginError" class="alert alert-danger text-sm">{{error}}</div>
                        <form (ngSubmit)="onLoginSubmit()" [formGroup]="loginForm">
                            <div class="form-group">
                                <input [ngClass]="{ 'is-invalid': (f.email.dirty || loginFormSubmitted) && f.email.errors }" class="form-control" formControlName="email" id="email"
                                       name="email" placeholder="Email" required tabindex="1" type="email">

                                <div *ngIf="(f.email.dirty || loginForm) && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email is invalid!</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input (keyup.enter)="onLoginSubmit()" [ngClass]="{ 'is-invalid': (f.password.dirty || loginFormSubmitted) && f.password.errors }"
                                       class="form-control" formControlName="password" id="password" name="Password" placeholder="Password" required tabindex="2"
                                       type="password">
                                <div *ngIf="(f.password.dirty || loginForm) && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">
                                        Password can not be less than 6 characters
                                    </div>
                                    <div *ngIf="f.password.errors.mustMatch">Password is invalid!</div>
                                </div>
                            </div>
                        </form>

                        <div class="login-btns">
                            <button (click)="onLoginSubmit()" class="btn btn-block btn-primary loginBtn" type="submit">
                                <span *ngIf="!loginFormSubmitted">Login</span>
                                <span *ngIf="loginFormSubmitted"> <img src="assets/images/icons/loding-white.gif"
                                                                       width="20px"> </span>
                            </button>
                            <p class="signUpLink">
                                Don’t have an account?
                                <a routerLink="/signup">
                                    Sign Up
                                </a>
                            </p>
                            <a (click)="openModal('forgotPasswordModal')" class="forgotLink" href="javascript:void(0)">
                                Forgot Password?
                            </a>
                        </div>
                    </div>
                    <!-- /.login-card-body -->
                </div>
            </div>
        </div>
    </div>

    <!-- FORGOT PASSWORD MODAL -->
    <div class="modal text-center open" id="forgotPasswordModal">

        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-body">
                    <span (click)="closeModal('forgotPasswordModal')" data-dismiss="modal" style="display: block; text-align: right">
                        <img class="cross-icon" src="../../assets/images/cancel.png">
                    </span>
                    <h3 class="mb-0">Oops! Forgot Your Password?</h3>
                    <h4 class="pb-1 pt-3 text-muted">
                        We have your back. Enter the email address associated with this account to reset it.
                    </h4>
                    <form (keydown.enter)="onResetFormSubmit()">
                        <div class="input-group mb-3 mx-auto">
                            <input aria-label="user email" aria-required="true" class="form-control" id="forgot-email" name="forgot-email" placeholder="myemail@email.com" required
                                   type="email">
                            <div class="input-group-append">
                                <button (click)="onResetFormSubmit()" class="btn btn-primary" id="button-addon2" type="submit">
                                    <img alt="send-button" src="../../assets/images/paper-plane.svg" style="width: 18px; margin-top: 3px">
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-11 col-md-8">
                                <div *ngIf="forgotPasswordSuccessMessageText" style="color: #1E2A40; font-size: 18px">
                                    {{forgotPasswordSuccessMessageText}}
                                </div>
                                <div *ngIf="forgotPasswordErrorMessage" style="color: #DC6B00; font-size: 18px">
                                    <!--                                    !-->
                                    {{forgotPasswordErrorMessageText}}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</main>
