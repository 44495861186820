import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SocialCauseService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    CreateSocialCause(data) {
        return this.http
            .post(`${this.apiUrl}/social_causes/`, JSON.stringify(data), this.httpOptions)
            .pipe();
    }
    GetAllSocialCauses() {
        return this.http.get(`${this.apiUrl}/social_causes/`).pipe();
    }
    FetchFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/Social_causes_followers/?date_from=${startDate}&date_to=${endDate}&social_causes_id=${id}`)
            .pipe();
    }
    FetchRecommendersData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/social_causes_recommenders/?date_from=${startDate}&date_to=${endDate}&social_causes_id=${id}`)
            .pipe();
    }
    FetchJourneyData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/social_causes_viewers/?date_from=${startDate}&date_to=${endDate}&social_causes_id=${id}`)
            .pipe();
    }
}
SocialCauseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocialCauseService_Factory() { return new SocialCauseService(i0.ɵɵinject(i1.HttpClient)); }, token: SocialCauseService, providedIn: "root" });
