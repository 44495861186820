import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-tailorie',
    templateUrl: './tailorie.component.html',
    styleUrls: ['./tailorie.component.scss', '../../assets/css/mainPages.min.css'],
})
export class TailorieComponent implements OnInit {
    environmentText: string;
    imageTag: string;
    imageTagRef: string;
    cdnUrl = environment.webStaticResources;

    currentUser: AuthenticatedUser;

    constructor(private router: Router, private authService: AuthService) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    ngOnInit() {
        this.environmentText = null;
        if (environment.env !== 'Prod') {
            this.environmentText = `Current Environment: ${environment.env}`;
        }
        this.imageTag = environment.imageTag;
        this.imageTagRef = environment.imageTagRef;
        this.authService.isAuthenticated().then((user: AuthenticatedUser) => {
            this.authService.validateAuthToken().subscribe(
                (user) => {
                },
                (error) => {
                    if (error.error.non_field_errors[0] === 'User doesn\'t exist.') {
                        this.authService.logout();
                    }
                }
            );
        });
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/']);
    }


}
