<main *ngIf="signupState == 0" class="preCodeVerification">

    <section class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-between align-items-center pt-2">
                <div class="col-12 d-flex justify-content-between">
                    <a class="navbar-brand" href="https://tailorie.com/brands-home">
                        <img alt="" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">
                        <span>Brands</span>
                    </a>
                    <!--                    <a [routerLink]="['/']" class="head" href="#">-->
                    <!--                        tailorie-->
                    <!--                        <span class="title-brand">Brand Account</span>-->
                    <!--                    </a>-->
                    <h5>
                        <a class="nav-link mt-2" href="https://tailorie.com/contact-us/">Get Help</a>
                    </h5>
                </div>
            </div>
        </div>
    </section>

    <!-- SIGNUP CODE VERIFICATION -->
    <div class="verficiationTab">
        <span [ngClass]="{'overlay': verCodeSent}" class="overlay"></span>
        <div *ngIf="verCodeSent" id="thankyouModal">
            <div class="customModal">
                <div class="thankYou">
                    <h1 class="d-block text-">Thank You!</h1>
                    <h5 class="text-center">One of our team members will be in touch soon.</h5>
                </div>
                <button (click)="verCodeSent=false" class="btn btn-primary mx-0" type="button">Close</button>
            </div>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6 order-1 order-md-0 text-center text-md-right">
                <img alt="Join Now" class="pt-4 hi-img" src="{{cdnUrl}}/assets/images/brand-account-images/02-FrankieHello.png">
            </div>
            <div class="col-9 col-sm-8 col-md-6 text-center offset-lg-right-1 order-0 order-md-1">
                <h4 class="yah pt-lg-0">
                    Yay!
                </h4>
                <p class="brand-p">We are so glad you are here.</p>
                <div class="form-group mx-auto d-flex justify-content-center justify-content-md-start">
                    <input #precode (keydown.enter)="verifyPreCode(precode.value)" class="form-control px-3" placeholder="Enter your invite code" required type="text">
                    <button (click)="verifyPreCode(precode.value)" class="button3 ml-2">Submit</button>
                </div>
                <small [ngClass]="!message.success ? 'text-danger': ''" class="validation-message">
                    <span>
                         <img *ngIf="message.msg && !message.success" alt="error" src="assets/images/ErrorAlert.png"
                              style="width: 11px">
                        <img *ngIf="message.msg && message.success" alt="success" src="assets/images/checkMark.png"
                             style="width: 11px">
                        {{message.msg}}&nbsp;
                    </span>
                </small>

                <div class="hr-line">
                    <hr>
                </div>

                <p class="mid-label">Or request an invite to join the party</p>
                <form [formGroup]="requestVerficationCodeForm" class="form-group mx-auto d-flex justify-content-center justify-content-md-start">
                    <input [ngClass]="{ 'is-invalid': (vrf.email.dirty || requestVerficationCodeFormSubmitted) && vrf.email.errors }" aria-describedby="button-addon2"
                           aria-label="user email" aria-required="true" class="form-control px-3 mb-0" formControlName="email" id="ver_email"
                           placeholder="myemail@email.com" required type="email">

                    <button (click)="requestPreVerificationCode()" [disabled]="" class="btn button4 ml-2">
                        <span *ngIf="!requestVerficationCodeFormSubmitted">Request</span>
                        <span *ngIf="requestVerficationCodeFormSubmitted">
                            <img src="{{cdnUrl}}/assets/images/icons/loader.svg" width="20px">
                        </span>
                    </button>
                </form>

                <small *ngIf="requestVerficationCodeForm.get('email').hasError('required') && requestVerficationCodeFormSubmitted" class="text-danger validation-message">
                    Please enter email
                </small>
                <small *ngIf="requestVerficationCodeForm.get('email').hasError('email') && requestVerficationCodeFormSubmitted" class="text-danger validation-message">
                    Please enter a valid email
                </small>
                <small [ngClass]="!emailmessage.success ? 'text-danger': ''" class="validation-message">
                    <span>
                         <img *ngIf="emailmessage.msg && !emailmessage.success" alt="error"
                              src="assets/images/ErrorAlert.png"
                              style="width: 11px; margin-bottom: 4px">
                        <img *ngIf="emailmessage.msg && emailmessage.success" alt="success"
                             src="assets/images/checkMark.png"
                             style="width: 11px; margin-bottom: 4px">
                        {{emailmessage.msg}}&nbsp;
                    </span>
                </small>
            </div>
        </div>

        <app-footer-homepage></app-footer-homepage>

        <!--        <footer style="margin-bottom: -20px">-->
        <!--            <div class="footer-wrap">-->
        <!--                <div class="container py-5">-->
        <!--                    <div class="row justify-content-between" style="height: auto">-->
        <!--                        <div class="col-lg-2">-->
        <!--                            <a class="navbar-brand" routerLink="/brandsHome">-->
        <!--                                tailorie <span>Brands</span>-->
        <!--                            </a>-->
        <!--                        </div>-->
        <!--                        <div class="col-lg-5 offset-lg-right-1 pt-3">-->
        <!--                            <h5>-->
        <!--                                Join the growing community of hundreds of brands connecting with users like never-->
        <!--                                before.-->
        <!--                            </h5>-->
        <!--                            <div class="link-wrap">-->
        <!--                                <a class="d-block text-center d-md-inline-block mobapp-btn" href="#">-->
        <!--                                    <img class="img-fluid" src="{{cdnUrl}}/assets/images/icons/getfromappstoreCS.png"-->
        <!--                                         style="width: 180px;">-->
        <!--                                </a>-->
        <!--                                <a class="d-block text-center d-md-inline-block ml-md-2 mobapp-btn" href="#">-->
        <!--                                    <img class="img-fluid"-->
        <!--                                         src="{{cdnUrl}}/assets/images/icons/getitifrom-PlayStoreCS.png"-->
        <!--                                         style="width: 180px;">-->
        <!--                                </a>-->
        <!--                                <div class="social-icons">-->
        <!--                                    <a href="https://www.facebook.com/thetailorie" target="_blank">-->
        <!--                                        <img alt="facbook" src="{{cdnUrl}}/assets/images/icons/facebook.png">-->
        <!--                                    </a>-->
        <!--                                    <a href="https://www.instagram.com/tailorie/" target="_blank">-->
        <!--                                        <img alt="instgram" src="{{cdnUrl}}/assets/images/icons/instagram.png">-->
        <!--                                    </a>-->
        <!--                                    <a href="https://twitter.com/TailorieApp" target="_blank">-->
        <!--                                        <img alt="twitter" src="{{cdnUrl}}/assets/images/icons/twitter.png">-->
        <!--                                    </a>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                -->
        <!--            </div>-->
        <!--        </footer>-->
    </div>

</main>

<main *ngIf="signupState !== 0" class="signup">
    <img *ngIf="signupState === 2" class="signUp-image" src="{{cdnUrl}}/assets/images/brand-account-images/typewritter.png">

    <!--  SIGNUP PROCESS  -->
    <div *ngIf="signupState !== 0" class="container">
        <div class="row justify-content-center align-content-center">
            <div [ngClass]="{'col-lg-4': signupState === 1, 'col-lg-7':signupState === 2 }" class="col-md-6">
                <div class="signup-box">
                    <div class="signup-logo">
                        <a href="https://tailorie.com">
                            <img alt="tailorie" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">
                            <span>Brands</span>
                        </a>
                    </div>
                    <div>
                        <p *ngIf="signupState === 1" class="signup-box-msg">
                            Join a growing community of <br> hundreds of brands connecting <br> with users like never before.
                        </p>
                    </div>
                    <div class="card-body signup-card-body">

                        <!-- Sign Up State 1 -->

                        <div *ngIf="signupState === 1">
                            <div *ngIf="isSignupError" class="alert alert-danger text-sm">{{error}}</div>
                            <form (ngSubmit)="onUserSignup()" [formGroup]="signupForm">
                                <!-- Email -->
                                <div class="form-group">
                                    <input [ngClass]="{ 'is-invalid': (f.email.dirty || signupFormSubmitted) && f.email.errors }" class="form-control" formControlName="email"
                                           id="email" name="email" placeholder="Email" required tabindex="1" type="email">
                                    <div *ngIf="(f.email.dirty || signupFormSubmitted) && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">
                                            We don’t have our team of carrier pigeons trained yet, we need your email!
                                        </div>
                                        <div *ngIf="f.email.errors.email">Email is invalid!</div>
                                        <div *ngIf="f.email.errors.notUniqueEmail">Email is already registered.
                                        </div>
                                    </div>
                                </div>
                                <!-- Password-->
                                <div class="form-group">
                                    <input (keydown.enter)="onUserSignup()" [ngClass]="{ 'is-invalid': (f.password.dirty || signupFormSubmitted) && f.password.errors }"
                                           class="form-control"
                                           formControlName="password" id="password" name="Password" placeholder="Choose Password" required tabindex="2" type="password">
                                    <div *ngIf="(f.password.dirty || signupFormSubmitted) && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f?.password?.errors?.required">Whoops, we need a password!</div>
                                        <div *ngIf="f?.password?.errors?.pattern">Password must contain at least 8 characters, including one uppercase,one lowercase and one
                                            number!
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="signup-btns">
                                <button (click)="onUserSignup()" [disabled]="signupFormSubmitted" class="btn btn-block btn-primary create-account">
                                    <span *ngIf="!signupFormSubmitted">Create Account</span>
                                    <span *ngIf="signupFormSubmitted"><img
                                            src="{{cdnUrl}}/assets/images/icons/loding-white.gif"
                                            width="20px"></span>
                                </button>
                                <a class="alreadyregistered" routerLink="/login">
                                    Already have an account?
                                </a>
                                <a (click)="openModal('forgotPasswordModal')" class="forgotLink" data-target="#forgotPasswordModal" data-toggle="modal" href="javascript:void(0)">Forgot
                                    Password?
                                </a>
                            </div>
                        </div>

                        <!-- Sign Up State 2 -->

                        <div *ngIf="signupState === 2" class="codeVerification">
                            <span *ngIf="!isVerifyFailed">
                                <h1>Check Your Email!</h1>
                                <p>We sent you a six-digit code to <b>{{currentUser ? currentUser.email : userData.email}}</b>
                                    confirm your email address.</p>
                            </span>
                            <span *ngIf="isVerifyFailed">
                                <h1>Oops.</h1>
                                <p>It looks like the code you entered is incorrect. Try it again.</p>
                            </span>
                            <div class="code-inputs">
                                <input #firstInput (input)="verificationCodeChangeFocus($event)" (paste)="onVerificationCodePaste($event)" [disabled]="isVerifying" autofocus
                                       max="1" maxlength="1" min="1" name="code" type="number">
                                <input (input)="verificationCodeChangeFocus($event)" (keydown.backspace)="focusBack($event)" (paste)="onVerificationCodePaste($event)"
                                       [disabled]="isVerifying"
                                       max="1" maxlength="1"
                                       min="1" name="code" type="number">
                                <input (input)="verificationCodeChangeFocus($event)" (keydown.backspace)="focusBack($event)" [disabled]="isVerifying" max="1" maxlength="1" min="1"
                                       name="code" type="number"> -
                                <input (input)="verificationCodeChangeFocus($event)" (keydown.backspace)="focusBack($event)" (paste)="onVerificationCodePaste($event)"
                                       [disabled]="isVerifying" max="1" maxlength="1"
                                       min="1" name="code" type="number">
                                <input (input)="verificationCodeChangeFocus($event)" (keydown.backspace)="focusBack($event)" (paste)="onVerificationCodePaste($event)"
                                       [disabled]="isVerifying" max="1" maxlength="1"
                                       min="1" name="code" type="number">
                                <input (input)="verificationCodeChangeFocus($event)" (keydown.backspace)="focusBack($event)" (paste)="onVerificationCodePaste($event)"
                                       [disabled]="isVerifying" id="lastInput" max="1" maxlength="1"
                                       min="1" name="code" type="number">

                                <div class="position-relative">
                                    <span *ngIf="isVerifying" class="spinner">
                                        <img src="{{cdnUrl}}/assets/images/icons/loader.svg">
                                    </span>
                                    <span *ngIf="!isVerifying && isVerified" class="spinner">
                                        <img src="{{cdnUrl}}/assets/images/icons/check.svg">
                                    </span>
                                </div>
                            </div>
                            <a (click)="resendCode()" *ngIf="!isResendCode">Send me a new code.</a>
                            <a *ngIf="isResendCode">Thanks! A new code is on it’s way to your inbox.</a>

                        </div>

                        <p class="signup-bottom-box">
                            By creating an account you agree to our
                            <a href="https://tailorie.com/privacy-policy">Privacy Policy</a> and
                            <a href="https://tailorie.com/terms-conditions">Terms & Conditions</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- FORGOT PASSWORD MODAL -->
    <div class="modal text-center open" id="forgotPasswordModal">

        <div class="modal-dialog modal-dialog-centered modal-lg">

            <div class="modal-content">

                <div class="modal-body">

                    <span (click)="closeModal('forgotPasswordModal')" data-dismiss="modal" style="display: block; text-align: right">
                        <img class="cross-icon" src="../../assets/images/cancel.png">
                    </span>

                    <h3 class="mb-0">Oops! Forgot Your Password?</h3>
                    <p class="pb-1 text-muted">We have your back. We'll reset it for you.</p>

                    <form (keydown.enter)="onResetFormSubmit()">
                        <div class="input-group mb-3 mx-auto">
                            <input aria-label="user email" aria-required="true" class="form-control" id="forgot-email" name="forgot-email" placeholder="myemail@email.com" required
                                   type="email">
                            <div class="input-group-append">
                                <button (click)="onResetFormSubmit()" class="btn btn-primary" id="button-addon2" type="submit">
                                    <img alt="send-button" src="../../assets/images/paper-plane.svg" style="width: 18px; margin-top: 3px">
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-11 col-md-6">
                                <div *ngIf="forgotPasswordSuccessMessage" class="alert alert-success">
                                    {{forgotPasswordSuccessMessageText}}
                                </div>
                                <div *ngIf="forgotPasswordErrorMessage" class="alert alert-danger">
                                    {{forgotPasswordErrorMessageText}}
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    </div>
</main>

<!-- Footer -->
<app-footer-homepage *ngIf="signupState === 2"></app-footer-homepage>
