import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
export class ApiService {
    constructor() {
        // Http Headers
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        this.httpOptionsFormData = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            }),
        };
        this.apiUrl = `${environment.apiBaseUrl}/api/${environment.apiVersion}`;
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(); }, token: ApiService, providedIn: "root" });
