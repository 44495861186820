import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {ActivatedRoute, Router, RouterEvent} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';

@Component({
    selector: 'app-customer-profile',
    templateUrl: './community-profile.component.html',
    styleUrls: ['./community-profile.component.scss']
})
export class CommunityProfileComponent implements OnInit {
    currentUser: AuthenticatedUser;
    cdnUrl = environment.webStaticResources;
    selectedTab = null;
    mobileView = false;
    public navLinks: any;
    selectedMenu: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
    ) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    ngOnInit() {
        this.setTabs();

        // GET current Activated Router URL
        this.setSelectedMenu(this.router.routerState.snapshot.url);
        // If Activated route has Project Dashboard then show Projects List
        this.router.events.subscribe(event => {
            this.setSelectedMenu(!(event instanceof RouterEvent) || event.url);
        });
    }

    // setTabs() {
    //     this.navLinks = [
    //         {path: 'overview', label: 'Overview', fragment: 'one'},
    //         {path: 'product-category', label: 'Product Categories', fragment: 'two'},
    //         {path: 'vibe', label: 'Vibes', fragment: 'three'},
    //         {path: 'value', label: 'Values', fragment: 'four'},
    //         {path: 'interest', label: 'Interests', fragment: 'five'},
    //         {path: 'social-cause', label: 'Social Causes', fragment: 'six'},
    //     ];
    // }

    navigateTo(value) {
        this.selectedMenu = value;
        if (value) {
            this.router.navigate(['/community-profile/' + value]);
        }
        return false;
    }

    setTabs() {
        this.navLinks = [
            {path: 'overview', label: 'Overview'},
            {path: 'product-category', label: 'Product Categories'},
            {path: 'vibe', label: 'Vibes'},
            {path: 'value', label: 'Values'},
            {path: 'interest', label: 'Interests'},
            {path: 'social-cause', label: 'Social Causes'},
        ];
    }

    private setSelectedMenu(url: any) {
        url = url.toString();
        if (url.includes('overview')) {
            this.selectedMenu = 'overview';
        } else if (url.includes('product-category')) {
            this.selectedMenu = 'product-category';
        } else if (url.includes('vibe')) {
            this.selectedMenu = 'vibe';
        } else if (url.includes('value')) {
            this.selectedMenu = 'value';
        } else if (url.includes('interest')) {
            this.selectedMenu = 'interest';
        } else if (url.includes('social-cause')) {
            this.selectedMenu = 'social-cause';
        } else {
            this.selectedMenu = 'overview';
        }
    }
}
