import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../_helpers/validators/must-match.validator';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    resetToken: string;
    loading = false;
    resetDone = false;

    resetPasswordForm: FormGroup;
    resetPasswordFormSubmitted: boolean;

    forgotPasswordSuccessMessage = false;
    forgotPasswordSuccessMessageText = '';
    forgotPasswordErrorMessage = false;
    forgotPasswordErrorMessageText = '';

    tokenAvailable = false;
    tokenValid = false;
    cdnUrl = environment.webStaticResources;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public fb: FormBuilder,
        private router: Router,
    ) {

        this.activatedRoute.queryParams.subscribe(params => {
            this.resetToken = params.token;

            if (!this.resetToken) {
                window.location.href = '/login';
            } else {
                this.authService.ResetPasswordValidateToken(this.resetToken).subscribe(obj => {
                    this.tokenValid = true;
                    this.tokenAvailable = true;

                    this.resetPasswordForm = this.fb.group({
                        password: ['', [Validators.required, Validators.minLength(8)]],
                        confirm_password: ['', Validators.required]
                    }, {
                        validator: MustMatch('password', 'confirm_password'),
                    });

                }, error => {
                    this.tokenValid = false;
                    this.tokenAvailable = true;
                });
            }
        });
    }

    /**
     * convenience getter for easy access to form fields of Reset Password Form
     */

    get rpf() {
        return this.resetPasswordForm.controls;
    }

    ngOnInit() {
    }

    validateAllFormFields(formGroup: FormGroup) {         // {1}
        Object.keys(formGroup.controls).forEach(field => {  // {2}
            const control = formGroup.get(field);             // {3}
            if (control instanceof FormControl) {             // {4}
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {        // {5}
                this.validateAllFormFields(control);            // {6}
            }
        });
    }

    passwordResetFormOnSubmit() {
        this.loading = true;
        this.resetDone = false;

        if (this.resetPasswordFormSubmitted) {
            return false;
        } else {

            this.forgotPasswordSuccessMessage = false;
            this.forgotPasswordErrorMessage = false;
            this.forgotPasswordErrorMessageText = '';
            this.forgotPasswordSuccessMessageText = '';

            this.resetPasswordFormSubmitted = true;
            if (this.resetPasswordForm.valid) {

                const password = this.resetPasswordForm.get('password').value;

                this.authService.ResetPasswordConfirm(this.resetToken, password).subscribe(obj => {
                    // @ts-ignore
                    if (obj.status === 'OK') {
                        this.forgotPasswordSuccessMessage = true;
                        this.loading = false;
                        this.resetDone = true;
                        // this.forgotPasswordSuccessMessageText = 'Password updated successfully.<br>' +
                        //     'You will be redirected to the login page in a few seconds.<br>' +
                        //     '<a href="/login">Click here</a> if you are not redirected automatically.';
                        this.forgotPasswordSuccessMessageText = 'Your password has been reset';

                        setTimeout(() => {
                            this.router.navigate(['/login']);
                        }, 3000);
                    } else {
                        this.forgotPasswordErrorMessage = true;
                        this.forgotPasswordErrorMessageText = 'Something unexpected happened';
                    }

                    this.resetPasswordFormSubmitted = false;

                }, error => {
                    this.forgotPasswordErrorMessage = true;
                    this.forgotPasswordErrorMessageText = error.error.errors[0].password[0];
                    this.loading = false;
                    this.resetDone = false;

                    this.resetPasswordFormSubmitted = false;
                });
            } else {
                this.resetPasswordFormSubmitted = false;
                this.validateAllFormFields(this.resetPasswordForm);
                return false;
            }

        }

    }
}
