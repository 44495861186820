import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContactUsService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    // POST
    ContactSubmissions(data) {
        return this.http.post(`${this.apiUrl}/contact_submissions/`, data).pipe();
    }
}
ContactUsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactUsService_Factory() { return new ContactUsService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactUsService, providedIn: "root" });
