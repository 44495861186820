<div class="content-wrapper tab-content" id="nav-tabContent">
    <div class="main-section">
        <div class="product-heading position-relative">
            <form class="form-inline" id="cntnr">
                <span class="arrow-horizontal-right" id="rtBtn">
                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                </span>
                <span class="arrow-horizontal-left" id="ltBtn">
                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                </span>
                <div class="col-lg-4 mobile-view pl-0 pr-0" style="margin-bottom: 32px !important;">
                    <div class="form-group">

                        <ng-select (change)="onChangeValue($event)" [(ngModel)]="proSelected" [clearable]="false"
                                   [searchable]="false" class="w-100" name="productLabel">
                            <ng-container *ngFor="let vibe of allVibes;let index=index">
                                <ng-container *ngIf="vibe?.is_predefined">
                                    <ng-option [value]="vibe.title">
                                        <label>
                                            <img alt="{{vibe?.title}}" src="{{vibe?.image}}"
                                                 style="width: 29px; padding-right: 10px">
                                            {{vibe?.title}}
                                        </label>
                                    </ng-option>
                                </ng-container>
                            </ng-container>
                        </ng-select>

                    </div>
                </div>
                <ng-container *ngFor="let vibe of allVibes;let index=index">
                    <div *ngIf="vibe.is_predefined" class="text-center">
                        <div class="select-box">
                            <input (click)="onChange(vibe);" [checked]="index=='0'?true:false" hidden id="pc-{{vibe.id}}" name="vibe"
                                   type="radio"/>
                            <label for="pc-{{vibe.id}}">
                                <img alt="{{vibe.title}}" src="{{vibe.image}}">
                                {{(vibe.title === 'Minimal' ? 'Minimalist' : vibe.title)}}
                            </label>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
        <div aria-labelledby="nav-home-tab" class="tab-pane fade show active" id="nav-home" role="tabpanel">
            <div class="row">
                <div class="col-lg-12">
                    <div class="date-period">
                        <div class="fc-center">
                            <h5 *ngIf="currday">{{startDate | date: 'EEEE, MMMM d, yyyy'}}
                            </h5>
                            <h5 *ngIf="currweek">
                                {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                            </h5>
                            <h5 *ngIf="currmonth">
                                {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                            </h5>
                            <h5 *ngIf="curryear">
                                {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                            </h5>
                            <div class="btn-group btn-group-toggle" data-toggle="buttons">

                                <label (click)="setActive('dayBtn', 'pcfbtn'); ctShowDay()" class="btn pcfbtn active"
                                       id="dayBtn">
                                    <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                           [autoApply]="options.autoApply"
                                           [closeOnAutoApply]="options.closeOnAutoApply"
                                           [customRangeDirection]="options.customRangeDirection"
                                           [linkedCalendars]="options.linkedCalendars"
                                           [lockStartDate]="options.lockStartDate"
                                           [maxDate]="maxDate"
                                           [showCancel]="options.showCancel"
                                           [showClearButton]="options.showClearButton"
                                           [showISOWeekNumbers]="options.showISOWeekNumbers"
                                           [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                           autocomplete="off"
                                           emptyWeekRowClass="empty-week"
                                           firstDayOfNextMonthClass="first-next-day"
                                           firstMonthDayClass="first-day"
                                           id="daypicker"
                                           lastDayOfPreviousMonthClass="last-previous-day"
                                           lastMonthDayClass="last-day" matInput
                                           name="options"
                                           ngxDaterangepickerMd opens="left"
                                           singleDatePicker="true" type="radio"> Day
                                </label>
                                <label (click)="setActive('weekBtn', 'pcfbtn'); ctShoWeek()" class="btn pcfbtn"
                                       id="weekBtn">
                                    <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                           [autoApply]="options.autoApply"
                                           [closeOnAutoApply]="options.closeOnAutoApply"
                                           [customRangeDirection]="options.customRangeDirection"
                                           [linkedCalendars]="options.linkedCalendars"
                                           [lockStartDate]="options.lockStartDate"
                                           [maxDate]="maxDate"
                                           [showCancel]="options.showCancel"
                                           [showClearButton]="options.showClearButton"
                                           [showISOWeekNumbers]="options.showISOWeekNumbers"
                                           [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                           autocomplete="off"
                                           dateLimit="6"
                                           emptyWeekRowClass="empty-week"
                                           firstDayOfNextMonthClass="first-next-day"
                                           firstMonthDayClass="first-day" id="weekpicker"
                                           lastDayOfPreviousMonthClass="last-previous-day"
                                           lastMonthDayClass="last-day" matInput name="options"
                                           ngxDaterangepickerMd
                                           opens="left" type="radio">
                                    Week
                                </label>
                                <label (click)="setActive('monthBtn', 'pcfbtn'); ctShowMonth()" class="btn pcfbtn"
                                       id="monthBtn">
                                    <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                           [autoApply]="options.autoApply"
                                           [closeOnAutoApply]="options.closeOnAutoApply"
                                           [customRangeDirection]="options.customRangeDirection"
                                           [linkedCalendars]="options.linkedCalendars"
                                           [lockStartDate]="options.lockStartDate"
                                           [maxDate]="maxDate"
                                           [showCancel]="options.showCancel"
                                           [showClearButton]="options.showClearButton"
                                           [showISOWeekNumbers]="options.showISOWeekNumbers"
                                           [showWeekNumbers]="options.showWeekNumbers"
                                           applyLabel="Okay"
                                           autocomplete="off" dateLimit="30"
                                           emptyWeekRowClass="empty-week"
                                           firstDayOfNextMonthClass="first-next-day"
                                           firstMonthDayClass="first-day"
                                           lastDayOfPreviousMonthClass="last-previous-day"
                                           lastMonthDayClass="last-day"
                                           matInput
                                           name="options" ngxDaterangepickerMd
                                           opens="center" type="radio">
                                    Month
                                </label>
                                <label (click)="setActive('yearBtn', 'pcfbtn');myProduct.open()" class="btn pcfbtn"
                                       id="yearBtn" style="line-height: 28px">
                                    <mat-select #myProduct style="visibility:hidden;width: 0">
                                        <mat-option (click)="selectYear(year,'pc')"
                                                    *ngFor="let year of years"
                                                    [value]="year">{{ year }}
                                        </mat-option>
                                    </mat-select>
                                    Year
                                </label>
                                <label (click)="setActive('customBtn', 'pcfbtn');ctCustomDate()" class="ngx-daterangepicker-action btn pcfbtn" id="customBtn" style="width:60px">
                                    <input #customDate (datesUpdated)="selectedChange($event)"
                                           [(ngModel)]="selected"
                                           [autoApply]="options.autoApply"
                                           [closeOnAutoApply]="options.closeOnAutoApply"
                                           [customRangeDirection]="options.customRangeDirection"
                                           [linkedCalendars]="false"
                                           [lockStartDate]="options.lockStartDate"
                                           [maxDate]="maxDate"
                                           [showCancel]="options.showCancel"
                                           [showClearButton]="options.showClearButton"
                                           [showISOWeekNumbers]="options.showISOWeekNumbers"
                                           [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                           autocomplete="off"
                                           emptyWeekRowClass="empty-week"
                                           firstDayOfNextMonthClass="first-next-day"
                                           firstMonthDayClass="first-day" id="customDate"
                                           lastDayOfPreviousMonthClass="last-previous-day"
                                           lastMonthDayClass="last-day" matInput name="options"
                                           ngxDaterangepickerMd
                                           opens="right"
                                           type="radio"
                                    />
                                    Custom
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- cards -->
            <div class="row no-gutters metric-containers">
                <div class="col-sm-4 col-md-4 col-xl-4 col-12 card-wrap">
                    <div class="card mb-5">
                        <div class="card-head">
                            <h3 class="card-title">Followers</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="cd-body">
                            <div class="card-data">
                                <h1>{{ followersData?.count_per | number: '0.0-0'}}%</h1>
                                <h5 *ngIf="journeyData?.count_per !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : followersData?.count_per>= 0,
                                    'negative' : followersData?.count_per<0}" class="percent">
                                    <!--                                    <p style="color: #3F9194" *ngIf="followersData?.count_per===0">+/-</p>-->
                                    {{ journeyData?.count | numberConversion:1 }}
                                </h5>
                                <h5 *ngIf="journeyData?.count_per === 0 && currentFilter !== 'CUSTOM'" class="percent positive">No Change</h5>
                            </div>
                            <p>Total # of followers who have selected {{ interestData?.title}} as an interest.</p>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-xl-4 col-12 card-wrap">
                    <div class="card mb-5">
                        <div class="card-head">
                            <h3 class="card-title">Recommenders</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="cd-body">
                            <div class="card-data">
                                <h1>{{ recommendData?.count_per | number: '0.0-0' }}%</h1>
                                <h5 *ngIf="recommendData?.count_per !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : recommendData?.count_per>= 0,
                                    'negative' : recommendData?.count_per<0}" class="percent">
                                    <!--                                    <p style="color: #3F9194" *ngIf="recommendData?.count_per===0">+/-</p>-->
                                    {{ recommendData?.count | numberConversion:1 }}
                                </h5>
                                <h5 *ngIf="recommendData?.count_per === 0 && currentFilter !== 'CUSTOM'" class="percent positive">No Change</h5>
                            </div>
                            <p>Total # of users with {{ interestData?.title}} icon who recommend your brand.</p>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-xl-4 col-12 card-wrap">
                    <div class="card mb-5">
                        <div class="card-head">
                            <h3 class="card-title">Viewers</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="cd-body">
                            <div class="card-data">
                                <h1>{{ journeyData?.count_per | number: '0.0-0' }}%</h1>
                                <h5 *ngIf="journeyData?.count_per !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : journeyData?.count_per>= 0,
                                    'negative' : journeyData?.count_per<0}" class="percent">
                                    <!--                                    <p style="color: #3F9194" *ngIf="journeyData?.count_per===0">+/-</p>-->

                                    {{ journeyData?.count | numberConversion:1}}
                                </h5>
                                <h5 *ngIf="journeyData?.count_per === 0 && currentFilter !== 'CUSTOM'" class="percent positive">No Change</h5>
                            </div>
                            <p>Total # of viewers with {{ interestData?.title}} icon who visited your brand profile.</p>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
            <!--cards-end-->

        </div>
    </div>
</div>
