import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BrandTopUsers} from './brandTopUsers';

@Injectable({
  providedIn: 'root'
})
export class BrandTopUsersService extends ApiService {

  constructor(
      private http: HttpClient
  ) {
    super();
  }

  GetBrandTopUsers(): Observable<BrandTopUsers> {
    return this.http.get<BrandTopUsers>(`${this.apiUrl}/get_brand_admin_top_users`)
        .pipe();
  }
}
