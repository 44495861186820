import { Component, OnInit } from '@angular/core';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {Router} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-error404-page',
  templateUrl: './error404-page.component.html',
  styleUrls: ['./error404-page.component.scss']
})
export class Error404PageComponent implements OnInit {

  currentUser: AuthenticatedUser;
  constructor(private router: Router,
              private authService: AuthService,
              private titleService: Title, ) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.authService.validateAuthToken().subscribe(user => {
          },
          error => {
            if (error.error.non_field_errors[0] === 'User doesn\'t exist.') {
              this.authService.logout();
            }
          });
    }
  }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
