import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';

// ----------- Helpers
import {JwtInterceptor} from './_helpers/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/interceptors/error.interceptor';
import {DatePipe} from '@angular/common';
// ----------- Services
import {ApiService} from './shared/services/api.service';
import {UserService} from './shared/services/user/user.service';
import {BrandService} from './shared/services/brand/brand.service';
import {VideoProcessingService} from './video-processing-service';

// ----------- Components
import {AppComponent} from './app.component';
import {TailorieComponent} from './tailorie/tailorie.component';
import {BrandAccountComponent, MutiplesOfFiveDirective, NumbersOnlyDirective} from './brand-account/brand-account.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PerformanceComponent} from './performance/performance.component';
import {BrandProfileComponent} from './brand-profile/brand-profile.component';
import {CommunityProfileComponent} from './community-profile/community-profile.component';
import {BrandJourneyComponent} from './brand-journey/brand-journey.component';
import {DashHeaderComponent} from './dash-header/dash-header.component';
import {DashSideMenuComponent} from './dash-side-menu/dash-side-menu.component';
import {DashLayoutComponent} from './dash-layout/dash-layout.component';
import {EditBrandProfileComponent} from './edit-brand-profile/edit-brand-profile.component';
import {EditCommunityProfileComponent} from './edit-community-profile/edit-community-profile.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {CompanyPageComponent} from './company-page/company-page.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {UserSignupComponent} from './user-signup/user-signup.component';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {Error404PageComponent} from './error404-page/error404-page.component';
import {FooterHomepageComponent} from './shared/components/footer-homepage/footer-homepage.component';
import {HeaderHomepageComponent} from './shared/components/header-homepage/header-homepage.component';
import {CanvasCrateComponent} from './canvas-crate/canvas-crate.component';
import { DragDirective } from './canvas-crate/dragDrop.directive';
import {AccountInfoComponent} from './account-info/account-info.component';
import {BrandResourcesComponent} from './brand-resources/brand-resources.component';

// ----------- Directives
import {DropdownDirective} from './shared/directives/dropdown.directive';
// ----------- Required 3rd Party Packages
import {ChartsModule, ThemeService} from 'ng2-charts';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {NgxMaskModule} from 'ngx-mask';
import {LineChartModule, NgxChartsModule} from '@swimlane/ngx-charts';
import {ToastrModule} from 'ngx-toastr';
import {Ng5SliderModule} from 'ng5-slider';
import {SimplebarAngularModule} from 'simplebar-angular';
// import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {NgSelectModule} from '@ng-select/ng-select';
// import { NgxDaterangepicker } from 'ngx-datepicker-material';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {ShortNumberPipe} from './shared/filter-pipe/currency-format-pipe';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// import {NgxMatDrpModule} from 'ngx-mat-daterange-picker';
import {MatCheckboxModule, MatDialogModule, MatIconModule, MatSelectModule} from '@angular/material';
import {CountDownPipe} from './shared/filter-pipe/count-down.pipe';
// import { HighchartService } from './dashboard/service/highchart-service/highchart.service';
import {PostMetricsPopupComponent} from './shared/components/post-metrics-popup/post-metrics-popup.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {CommunityMobileViewComponent} from './shared/components/community-mobile-view/community-mobile-view.component';
import {MatTabsModule} from '@angular/material/tabs';
import {OverviewComponent} from './community-profile/overview/overview.component';
import {ProductcategoryComponent} from './community-profile/productcategory/productcategory.component';
import {VibeComponent} from './community-profile/vibe/vibe.component';
import {ValueComponent} from './community-profile/value/value.component';
import {SocialcauseComponent} from './community-profile/socialcause/socialcause.component';
import {InterestsComponent} from './community-profile/interests/interests.component';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {NumberConversionPipe} from './shared/pipe/number-conversion/number-conversion.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


// --------- SENTRY Integration Imports --------
import {environment} from '../environments/environment';
import * as Sentry from '@sentry/angular';
import {Integrations} from '@sentry/tracing';


// SwiperOptions from 'swiper' could also be used here instead of SwiperConfigInterface
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 9,
    centeredSlides: true,
};


// ----------- SENTRY Integration -----------
const env = environment.env;

if (env !== 'local') {
    Sentry.init({
        dsn: 'https://68447f73f1804785a9fc53342db3aa93@o361225.ingest.sentry.io/3807924',
        environment: env,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Integrations.BrowserTracing({
                tracingOrigins: ['localhost', 'https://staging.tailorie.com/', 'https://tailorie.com/'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

@NgModule({
    declarations: [
        AppComponent,
        TailorieComponent,
        BrandAccountComponent,
        DashboardComponent,
        PerformanceComponent,
        BrandProfileComponent,
        CommunityProfileComponent,
        BrandJourneyComponent,
        DashHeaderComponent,
        DashSideMenuComponent,
        DashLayoutComponent,
        LoginComponent,
        EditBrandProfileComponent,
        EditCommunityProfileComponent,
        ResetPasswordComponent,
        ContactUsComponent,
        NumbersOnlyDirective,
        MutiplesOfFiveDirective,
        CompanyPageComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        Error404PageComponent,
        UserSignupComponent,
        LogoutComponent,
        FooterHomepageComponent,
        HeaderHomepageComponent,
        DropdownDirective,
        ShortNumberPipe,
        AccountInfoComponent,
        BrandResourcesComponent,
        CountDownPipe,
        PostMetricsPopupComponent,
        CommunityMobileViewComponent,
        OverviewComponent,
        ProductcategoryComponent,
        VibeComponent,
        ValueComponent,
        SocialcauseComponent,
        InterestsComponent,
        NumberConversionPipe,
        CanvasCrateComponent,
        DragDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        Ng5SliderModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        NgxDaterangepickerMd.forRoot(),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        ChartsModule,
        LoadingBarModule,
        NgxMaskModule.forRoot(),
        NgSelectModule,
        LineChartModule,
        NgxChartsModule,
        SimplebarAngularModule,
        MatSelectModule,
        MatDialogModule,
        NgxPaginationModule,
        MatTabsModule,
        MatIconModule,
        MatCheckboxModule,
        SwiperModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        ApiService,
        UserService,
        BrandService,
        DatePipe,
        VideoProcessingService,
        ThemeService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    ],
    bootstrap: [AppComponent],
    entryComponents: [PostMetricsPopupComponent],
})
export class AppModule {
}
