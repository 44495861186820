import {ProductCategory} from '../product_category/product-category';
import {Vibe} from '../vibe/vibe';
import {Value} from '../value/value';
import {SocialCause} from '../social_cause/social_cause';
import {Interests} from '../interests/interests';

export class BrandSignupChecklist {
    provided_business_details: boolean;
    selected_product_categories: boolean;
    provided_category_details: boolean;
    selected_brand_vibes: boolean;
    selected_brand_values: boolean;
    selected_social_values: boolean;
    provided_brand_description: boolean;
    shared_customer_profile: boolean;
    uploaded_intro_video: boolean;
    greeted_on_dashboard: boolean;

    brand: number;
}

export class BrandCustomerProfile {
    persona_description: string;
    min_age: number;
    max_age: number;
    male_to_female_ratio: number;
    customer_interests: Array<string>;
    customer_life_stages: Array<string>;
    brand: number;
}


// export class BrandProductCategory {
//     brand: Brand;
//     product_category: ProductCategory;
//     percentage: number;
// }


export class BrandProductCategory {

    public product_category_id ?: number;

    constructor(
        private _id: number,
        private _min_price ?: number,
        private _max_price ?: number,
        private _avg_price ?: number,
        private _tags ?: Array<string>,
        private _percentage ?: number,
        private _placeholder ?: Array<string>,
    ) {
    }

    get id() {
        if (this._id !== undefined) {
            return this._id;
        } else {
            return this.product_category_id;
        }
    }

    get min_price() {
        return this._min_price;
    }

    set min_price(value: number) {
        this._min_price = value;
    }

    get max_price() {
        return this._max_price;
    }

    set max_price(value: number) {
        this._max_price = value;
    }

    get avg_price() {
        return this._avg_price;
    }

    set avg_price(value: number) {
        this._avg_price = value;
    }

    get tags() {
        return this._tags;
    }

    set tags(value: Array<string>) {
        this._tags = value;
    }

    get percentage() {
        if (!this._percentage) {
            return 0;
        }
        return this._percentage;
    }

    set percentage(value: number) {
        this._percentage = value;
    }

    get tagplaceholder() {
        return this._placeholder;
    }

    _obj() {
        return {
            id: this.id,
            min_price: this._min_price,
            max_price: this._max_price,
            avg_price: this._avg_price,
            tags: this._tags,
            percentage: this._percentage,
        };
    }
}

export class BrandVibe {

    public vibe_id ?: number;

    constructor(
        private _id: number,
        private _percentage ?: number,
        private _tags ?: Array<string>,
    ) {
    }

    get id() {
        if (this._id !== undefined) {
            return this._id;
        } else {
            return this.vibe_id;
        }
    }

    get tags() {
        return this._tags;
    }

    get percentage() {
        if (!this._percentage) {
            return 0;
        }
        return this._percentage;
    }

    set percentage(value: number) {
        this._percentage = value;
    }

    _obj() {
        return {
            id: this.id,
            tags: this._tags,
            percentage: this._percentage
        };
    }
}

export class BrandValue {

    public value_id ?: number;

    constructor(
        private _id: number,
        private _percentage ?: number,
        private _tags ?: Array<String>,
    ) {
    }

    get id() {
        if (this._id !== undefined) {
            return this._id;
        } else {
            return this.value_id;
        }
    }

    get tags() {
        return this._tags;
    }

    get percentage() {
        if (!this._percentage) {
            return 0;
        }
        return this._percentage;
    }

    set percentage(value: number) {
        this._percentage = value;
    }

    _obj() {
        return {
            id: this.id,
            tags: this._tags,
            percentage: this.percentage
        };
    }
}

// export class BrandInterest {

//     public value_id ?: number;

//     constructor(
//         private _id: number,
//         private _percentage ?: number,
//         private _tags ?: Array<String>,
//     ) {
//     }

//     get id() {
//         if (this._id !== undefined) {
//             return this._id;
//         } else {
//             return this.value_id;
//         }
//     }

//     get tags() {
//         return this._tags;
//     }

//     get percentage() {
//         if (!this._percentage) {
//             return 0;
//         }
//         return this._percentage;
//     }

//     set percentage(value: number) {
//         this._percentage = value;
//     }

//     _obj() {
//         return {
//             id: this.id,
//             tags: this._tags,
//             percentage: this.percentage
//         };
//     }
// }

export class BrandSocialCause {

    public social_cause_id ?: number;

    constructor(
        private _id: number,
        private _tags ?: Array<String>
    ) {
    }

    get id() {
        if (this._id !== undefined) {
            return this._id;
        } else {
            return this.social_cause_id;
        }
    }

    get tags() {
        return this._tags;
    }

    _obj() {
        return {
            id: this.id,
            tags: this._tags
        };
    }
}


export class Brand {
    constructor(
        public id: number,
        public business_name: string,
        public description: string,
        public tags: Array<string>,
        public brandsignupchecklist: BrandSignupChecklist,
        public brandcustomerprofile: BrandCustomerProfile,
        public product_categories: Array<BrandProductCategory>,
        public vibes: Array<BrandVibe>,
        public values: Array<BrandValue>,
        public social_causes: Array<BrandSocialCause>,
        public sales_channel ?: string,
        public website ?: string,
        public headquarter_location ?: string,
        public tax_id ?: string,
        public years_in_business ?: number,
        public year_founded ?: number,
        public number_of_locations ?: number,
        public business_percent_online ?: number,
        public business_percent_instore ?: number,
        public brand_username?: string,
        public logo_url?: string,
        public minimum_age_required ?: number,
        public created_at ?: string
    ) {
    }

    getProductCategoryCount(allProductCategories: Array<ProductCategory>, ispredefined = true) {
        let count = 0;
        if (ispredefined && allProductCategories && this.product_categories) {
            this.product_categories.forEach((pc, index) => {
                allProductCategories.forEach((apc, index2) => {
                    if (pc.id === apc.id) {
                        if (apc.is_predefined) {
                            count += 1;
                        }
                    }
                });
            });
        } else {
            return this.product_categories.length;
        }

        return count;
    }

    getVibesCount(allVibes: Array<Vibe>, ispredefined = true) {
        let count = 0;

        if (ispredefined && allVibes && this.vibes) {
            this.vibes.forEach((vibe, index) => {
                allVibes.forEach((avibe, index2) => {
                    if (vibe.id === avibe.id) {
                        if (avibe.is_predefined) {
                            count += 1;
                        }
                    }
                });
            });
        } else {
            return this.vibes.length;
        }

        return count;
    }

    getValuesCount(allValues: Array<Value>, ispredefined = true) {
        let count = 0;

        if (ispredefined && allValues && this.values) {
            this.values.forEach((value, index) => {
                allValues.forEach((avalue, index2) => {
                    if (value.id === avalue.id) {
                        if (avalue.is_predefined) {
                            count += 1;
                        }
                    }
                });
            });
        } else {
            return this.values.length;
        }

        return count;
    }

    getInterestsCount(allInterests: Array<Interests>, ispredefined = true) {
        let count = 0;

        // if (ispredefined) {
        //     this.interests.forEach((value, index) => {
        //         allInterests.forEach((avalue, index2) => {
        //             if (value.id === avalue.id) {
        //                 if (avalue.is_predefined) {
        //                     count += 1;
        //                 }
        //             }
        //         });
        //     });
        // } else {
        //     return this.values.length;
        // }

        return count;
    }

    getSocialCausesCount(allSocialCauses: Array<SocialCause>, ispredefined = true) {
        let count = 0;

        if (ispredefined) {
            this.social_causes.forEach((socialCause, index) => {
                allSocialCauses.forEach((asocialCause, index2) => {
                    if (socialCause.id === asocialCause.id) {
                        if (asocialCause.is_predefined) {
                            count += 1;
                        }
                    }
                });
            });
        } else {
            return this.social_causes.length;
        }

        return count;
    }

    initBrandInfo() {
        const updatedProductCategories = [];
        this.product_categories.forEach((productCategory, index) => {
            let skip = false;

            const pc = new BrandProductCategory(productCategory.product_category_id, productCategory.min_price,
                productCategory.max_price, productCategory.avg_price, productCategory.tags, productCategory.percentage);
            updatedProductCategories.forEach((uc: ProductCategory, index2) => {
                if (uc.id === pc.id) {
                    skip = true;
                }
            });
            if (!skip) {
                updatedProductCategories.push(pc);
            }
        });

        this.product_categories = updatedProductCategories;

        const updatedVibes = [];

        this.vibes.forEach((vibe, index) => {
            let skip = false;

            const v = new BrandVibe(vibe.vibe_id, vibe.percentage);

            updatedVibes.forEach((uv: Vibe, index2) => {
                if (uv.id === v.id) {
                    skip = true;
                }
            });
            if (!skip) {
                updatedVibes.push(v);
            }
        });

        this.vibes = updatedVibes;

        const updatedValues = [];

        this.values.forEach((value, index) => {
            let skip = false;

            const v = new BrandValue(value.value_id, value.percentage);

            updatedValues.forEach((uv: Value, index2) => {
                if (uv.id === v.id) {
                    skip = true;
                }
            });
            if (!skip) {
                updatedValues.push(v);
            }
        });

        this.values = updatedValues;

        const updatedSocialCause = [];

        this.social_causes.forEach((socialCause, index) => {
            const sc = new BrandSocialCause(socialCause.social_cause_id);
            updatedSocialCause.push(sc);
        });

        this.social_causes = updatedSocialCause;
    }
}

export class BrandContactDetails {
    contact_name: string;
    title: string;
    phone: number;
}

export class BrandBillingAddress {
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    country: string;
    zip_code: number;
}
