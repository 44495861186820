import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Options} from 'ng5-slider';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {Brand} from '../shared/services/brand/brand';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../shared/services/auth/auth.service';
import {BrandService} from '../shared/services/brand/brand.service';
import {VibeService} from '../shared/services/vibe/vibe.service';
import {ValueService} from '../shared/services/value/value.service';
import {ProductCategoryService} from '../shared/services/product_category/product-category.service';
import {ProductCategory} from '../shared/services/product_category/product-category';
import {Vibe} from '../shared/services/vibe/vibe';
import {Value} from '../shared/services/value/value';
import {SocialCause} from '../shared/services/social_cause/social_cause';
import {SocialCauseService} from '../shared/services/social_cause/social-cause.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavEditcommunityModel} from '../shared/services/Brand Profile/brand-profile';
import Timer = NodeJS.Timer;


@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-community-profile.component.html',
    styleUrls: ['./edit-community-profile.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({opacity: 1, transform: 'translateY(0)'})),
            transition('void => *', [
                style({opacity: 0, transform: 'translateY(100%)'}),
                animate(400)
            ]),
            transition('* => void', [
                animate(400, style({opacity: 0, transform: 'translateY(100%)'}))
            ])
        ])
    ]
})
export class EditCommunityProfileComponent implements OnInit, AfterViewInit {
    cdnUrl = environment.webStaticResources;
    value1 = 50;
    singleSliderRange: Options = {
        floor: 0,
        ceil: 100,
        animate: false,
    };
    value = 20;
    highValue = 60;
    ageRangeSliderRange: Options = {
        floor: 0,
        ceil: 100,
        animate: false,
    };
    currentUser: AuthenticatedUser;
    dateToday: Date = new Date();

    currentBrandInfo: Brand;
    // For storing all available data for these entities
    productCategories: Array<ProductCategory>;
    productCategoriesTags: Array<string>;
    vibes: Array<Vibe>;
    values: Array<Value>;
    socialCauses: Array<SocialCause>;
    fitButtons = [
        {text: 'Petite', isClicked: false},
        {text: 'Regular', isClicked: false},
        {text: 'Plus', isClicked: false},
        {text: 'Tall', isClicked: false},
        {text: 'Maternity', isClicked: false},
    ];
    priceButtons = [
        {text: '$', isClicked: false},
        {text: '$$', isClicked: false},
        {text: '$$$', isClicked: false},
        {text: '$$$$', isClicked: false},
        {text: '$$$$$', isClicked: false},
    ];
    styleVibeButtons = [
        {text: 'Preppy', isClicked: false},
        {text: 'Blam', isClicked: false},
        {text: 'Boho', isClicked: false},
        {text: 'Western', isClicked: false},
        {text: 'Hipster', isClicked: false},
        {text: 'Vintage', isClicked: false},
        {text: 'Athleisure', isClicked: false},
        {text: 'Professional', isClicked: false},
        {text: 'Classic', isClicked: false},
    ];
    genderButtons = [
        {text: 'Male', isClicked: false},
        {text: 'Female', isClicked: false},
    ];
    dressButtons = [
        {text: '20 s', isClicked: false},
        {text: '30 s', isClicked: false},
        {text: '40 s', isClicked: false},
        {text: '50 Plus', isClicked: false},
    ];

    selectedTab = null;
    showSaveBar = false;
    updateProfilePicFlag = false;
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    alertTimeout: Timer;
    alertErrorTimeout: Timer;
    csLifeTxt = '';
    cusTxt = '';
    navMenuBindingList = [];
    selectedMenu: { id: string, label: string, fragment: string };

    constructor(
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private authService: AuthService,
        private brandService: BrandService,
        private vibeService: VibeService,
        private valueService: ValueService,
        private productCategoryService: ProductCategoryService,
        private socialCauseService: SocialCauseService
    ) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    /**
     * Update entity lists with brand specific information
     * e.g
     * updating product categories with data like if they're selected or not etc
     */
    initBrandInfo() {
        this.currentBrandInfo.brandcustomerprofile = this.authService.currentUserValue.user.branduserprofile.brand.brandcustomerprofile;

        if (!this.currentBrandInfo.brandcustomerprofile.male_to_female_ratio) {
            this.currentBrandInfo.brandcustomerprofile.male_to_female_ratio = 50;
        }
        if (!this.currentBrandInfo.brandcustomerprofile.min_age) {
            this.currentBrandInfo.brandcustomerprofile.min_age = 20;
        }
        if (!this.currentBrandInfo.brandcustomerprofile.max_age) {
            this.currentBrandInfo.brandcustomerprofile.max_age = 60;
        }
        this.productCategoryService.GetAllProductCategories().subscribe(
            (productCategories) => {
                this.productCategories = productCategories.results;
                this.productCategories.forEach((productCategory, index) => {
                    productCategory.selected = false;
                    this.currentBrandInfo.product_categories.forEach((brandProductCategory, index2) => {
                        if (brandProductCategory.id === productCategory.id) {
                            productCategory.selected = true;
                            productCategory.min_price = brandProductCategory.min_price;
                            productCategory.max_price = brandProductCategory.max_price;
                            productCategory.tags = brandProductCategory.tags;
                        }
                    });
                });
                this.vibeService.GetAllVibes().subscribe((vibes) => {
                    this.vibes = vibes.results;
                    this.vibes.forEach((vibe, index) => {
                        vibe.selected = false;
                        this.currentBrandInfo.vibes.forEach((brandVibe, index2) => {
                            if (brandVibe.id === vibe.id) {
                                vibe.selected = true;
                            }
                        });
                    });
                    this.valueService.GetAllValues().subscribe((values) => {
                        this.values = values.results;
                        this.values.forEach((value, index) => {
                            value.selected = false;
                            this.currentBrandInfo.values.forEach((brandValue, index2) => {
                                if (brandValue.id === value.id) {
                                    value.selected = true;
                                }
                            });
                        });
                        this.socialCauseService.GetAllSocialCauses().subscribe((socialCauses) => {
                            this.socialCauses = socialCauses.results;
                            this.socialCauses.forEach((socialCause, index) => {
                                socialCause.selected = false;
                                this.currentBrandInfo.social_causes.forEach((brandSocialCause, index2) => {
                                    if (brandSocialCause.id === socialCause.id) {
                                        socialCause.selected = true;
                                    }
                                });
                            });
                        });
                    });
                });
            },
            (err) => {
            }
        );
    }

    ngOnInit() {
        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;


        this.currentBrandInfo = new Brand(
            currentBrand.id,
            currentBrand.business_name,
            currentBrand.description,
            currentBrand.tags,
            currentBrand.brandsignupchecklist,
            currentBrand.brandcustomerprofile,
            currentBrand.product_categories,
            currentBrand.vibes,
            currentBrand.values,
            currentBrand.social_causes,
            currentBrand.sales_channel,
            currentBrand.website,
            currentBrand.headquarter_location,
            currentBrand.tax_id,
            currentBrand.years_in_business,
            currentBrand.number_of_locations,
            currentBrand.business_percent_online,
            currentBrand.business_percent_instore
        );
        this.currentBrandInfo.initBrandInfo();
        this.initBrandInfo();
        this.navMenuBindingList = new NavEditcommunityModel().getNavEditMenu();
        this.selectedMenu = this.navMenuBindingList[0].label;
    }

    ngAfterViewInit() {
        this.changeDetector();
    }

    loadMenu(selectedItem) {
        console.log(selectedItem);
        this.showTabContentDropdown(selectedItem.id, selectedItem.fragment);
    }

    changeDetector() {
        const allInputs = document.querySelectorAll('input');
        const allTextareas = document.querySelectorAll('textarea');

        allInputs.forEach((value, key) => {
            value.addEventListener('keyup', () => {
                this.showSaveBar = true;
            });
        });

        allTextareas.forEach((value, key) => {
            value.addEventListener('keyup', () => {
                this.showSaveBar = true;
            });
        });
    }

    // <===================================================Customer Profile================================================================>

    submitCustomerProfile() {
        const customerPersonaDescription = document.getElementById(`branddescriptioncomment`) as HTMLInputElement;
        this.currentBrandInfo.brandcustomerprofile.persona_description = customerPersonaDescription.value;

        const customerProfile = {
            brandsignupchecklist: {
                shared_customer_profile: true,
            },
            brandcustomerprofile: this.currentBrandInfo.brandcustomerprofile,
        };

        this.brandService.UpdateBrandById(this.currentBrandInfo.id, customerProfile).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
                this.currentBrandInfo = brand;
                this.showAlert('Brand Customer Profile Updated!');
                setTimeout(() => {
                    this.showSaveBar = false;
                    console.log(this.showSaveBar);
                }, 2000);
                // this.showSaveBar = false;
                // window.location.reload();
                return false;
            },
            (err) => {
                this.showAlertError('Something went wrong while updating the customer Profile!');

            }
        );
    }

    addCustomerInterestsTags() {
        this.cusTxt = '';
        const customerInterestsTags = document.getElementById(`customerinterest`) as HTMLInputElement;
        const customerInterestsTagsArray: Array<string> = customerInterestsTags.value.split(',');

        if (customerInterestsTags.value === null || customerInterestsTags.value === '') {
            this.showAlertError('Customer Interest Tags Empty!');
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customerInterestsTagsArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            if (this.currentBrandInfo.brandcustomerprofile.customer_interests != null) {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.currentBrandInfo.brandcustomerprofile.customer_interests.length; i++) {
                    uniqueTags.push(this.currentBrandInfo.brandcustomerprofile.customer_interests[i]);
                }
            }
            this.currentBrandInfo.brandcustomerprofile.customer_interests = uniqueTags.filter(
                (elem, index, self) => index === self.indexOf(elem)
            );
            customerInterestsTags.value = '';
        }
    }

    removeCustomerInterestTag(tagWord) {
        const index: number = this.currentBrandInfo.brandcustomerprofile.customer_interests.indexOf(tagWord);
        if (index !== -1) {
            this.currentBrandInfo.brandcustomerprofile.customer_interests.splice(index, 1);
        }
    }

    addCustomerDescriptionTags() {
        this.csLifeTxt = '';
        const customerDescriptionTags = document.getElementById(`customerDescription`) as HTMLInputElement;
        const customerDescriptionTagsArray: Array<string> = customerDescriptionTags.value.split(',');
        if (customerDescriptionTags.value === null || customerDescriptionTags.value === '') {
            this.showAlertError('Customer Description Tags Empty!');
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customerDescriptionTagsArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            if (this.currentBrandInfo.brandcustomerprofile.customer_life_stages != null) {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.currentBrandInfo.brandcustomerprofile.customer_life_stages.length; i++) {
                    uniqueTags.push(this.currentBrandInfo.brandcustomerprofile.customer_life_stages[i]);
                }
            }
            this.currentBrandInfo.brandcustomerprofile.customer_life_stages = uniqueTags.filter(
                (elem, index, self) => index === self.indexOf(elem)
            );
            customerDescriptionTags.value = '';
        }
    }

    removeCustomerDescriptionTag(tagWord) {
        const index: number = this.currentBrandInfo.brandcustomerprofile.customer_life_stages.indexOf(tagWord);
        if (index !== -1) {
            this.currentBrandInfo.brandcustomerprofile.customer_life_stages.splice(index, 1);
        }
    }

    showTabContentDropdown(tabContentID, fragment) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }

        document.getElementById(tabContentID).classList.add('show', 'active');
    }

    showTabContent(pop: HTMLDivElement, link: HTMLAnchorElement) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }
        pop.classList.add('show', 'active');
        link.classList.add('active');
    }

    toggleSaveBar() {
        this.showSaveBar = true;
    }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 5000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }

    onKeyUpInterest(event) {
        this.cusTxt = event.target.value;
        if (event.key === ',') {
            let customerInterestsTagsArray: Array<string> = event.target.value.split(',');
            customerInterestsTagsArray.forEach((tag, index) => {
                customerInterestsTagsArray[index] = tag.trim();
            });
            customerInterestsTagsArray = customerInterestsTagsArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Customer Interest Tags Empty!');
            } else {

                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customerInterestsTagsArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });

                if (this.currentBrandInfo.brandcustomerprofile.customer_interests != null) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.currentBrandInfo.brandcustomerprofile.customer_interests.length; i++) {
                        uniqueTags.push(this.currentBrandInfo.brandcustomerprofile.customer_interests[i]);
                    }
                }

                this.currentBrandInfo.brandcustomerprofile.customer_interests = uniqueTags.filter(
                    (elem, index, self) => index === self.indexOf(elem)
                );
                event.target.value = '';
                this.cusTxt = '';
            }

        }
    }

    onKeyUpDescriptionTags(event) {
        this.csLifeTxt = event.target.value;
        if (event.key === ',') {
            let customerDescriptionTagsArray: Array<string> = event.target.value.split(',');
            customerDescriptionTagsArray.forEach((tag, index) => {
                customerDescriptionTagsArray[index] = tag.trim();
            });
            customerDescriptionTagsArray = customerDescriptionTagsArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Customer Description Tags Empty!');
            } else {

                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customerDescriptionTagsArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });

                if (this.currentBrandInfo.brandcustomerprofile.customer_life_stages != null) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.currentBrandInfo.brandcustomerprofile.customer_life_stages.length; i++) {
                        uniqueTags.push(this.currentBrandInfo.brandcustomerprofile.customer_life_stages[i]);
                    }
                }
                this.currentBrandInfo.brandcustomerprofile.customer_life_stages = uniqueTags.filter(
                    (elem, index, self) => index === self.indexOf(elem)
                );

                event.target.value = '';
                this.csLifeTxt = '';
            }
        }
    }
}
