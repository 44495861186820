/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-homepage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header-homepage.component";
import * as i5 from "../../services/auth/auth.service";
var styles_HeaderHomepageComponent = [i0.styles];
var RenderType_HeaderHomepageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderHomepageComponent, data: {} });
export { RenderType_HeaderHomepageComponent as RenderType_HeaderHomepageComponent };
function View_HeaderHomepageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Brands"]))], null, null); }
function View_HeaderHomepageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link"], ["href", "#"], ["routerLink", "/login"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Log In"]))], function (_ck, _v) { var currVal_2 = "/login"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HeaderHomepageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link join-btn"], ["href", "#"], ["routerLink", "/signup"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Join Now"]))], function (_ck, _v) { var currVal_2 = "/signup"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HeaderHomepageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "nav-item ml-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "nav-link join-btn"], ["href", "/brand-journey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to Dashboard"]))], null, null); }
export function View_HeaderHomepageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "header", [["class", "mainHeader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "nav", [["class", "navbar navbar-expand-md navbar-light fixed-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "container px-4 px-md-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["class", "navbar-brand"], ["href", "https://tailorie.com/brands-home/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderHomepageComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["aria-controls", "topNav"], ["aria-expanded", "false"], ["aria-label", "Toggle navigation"], ["class", "navbar-toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 10, "div", [["class", "collapse navbar-collapse"], ["id", "topNav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "ul", [["class", "navbar-nav ml-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "nav-link px-2"], ["href", "https://tailorie.com/contact-us"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderHomepageComponent_2)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderHomepageComponent_3)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderHomepageComponent_4)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.hideText; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.currentUser; _ck(_v, 15, 0, currVal_2); var currVal_3 = !_co.currentUser; _ck(_v, 17, 0, currVal_3); var currVal_4 = _co.currentUser; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cdnUrl, "/assets/images/Tailorie-logo_dark.svg"); _ck(_v, 4, 0, currVal_0); }); }
export function View_HeaderHomepageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-homepage", [], null, null, null, View_HeaderHomepageComponent_0, RenderType_HeaderHomepageComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderHomepageComponent, [i2.Router, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderHomepageComponentNgFactory = i1.ɵccf("app-header-homepage", i4.HeaderHomepageComponent, View_HeaderHomepageComponent_Host_0, {}, {}, []);
export { HeaderHomepageComponentNgFactory as HeaderHomepageComponentNgFactory };
