import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {AuthService} from '../shared/services/auth/auth.service';
import {BrandStories, BrandStoryType, FilterBrandStories, Post, StoryItem} from '../shared/services/brand_stories/brand-stories';
import {BrandStoriesService} from '../shared/services/brand_stories/brand-stories.service';
import {Brand} from '../shared/services/brand/brand';
import {ProductCategory} from '../shared/services/product_category/product-category';
import {Vibe} from '../shared/services/vibe/vibe';
import {Value} from '../shared/services/value/value';
import {SocialCause} from '../shared/services/social_cause/social_cause';
import {ProductCategoryService} from '../shared/services/product_category/product-category.service';
import {VibeService} from '../shared/services/vibe/vibe.service';
import {ValueService} from '../shared/services/value/value.service';
import {SocialCauseService} from '../shared/services/social_cause/social-cause.service';
import {ToastrService} from 'ngx-toastr';
import {VideoProcessingService} from '../video-processing-service';
import {environment} from '../../environments/environment';
import * as tus from 'tus-js-client';
import {VideoUploadServiceService} from '../shared/services/video-upload-service.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
// @ts-ignore
import Timer = NodeJS.Timer;

@Component({
    selector: 'app-brand-profile',
    templateUrl: './brand-journey.component.html',
    styleUrls: ['./brand-journey.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({opacity: 1, transform: 'translateY(0)'})),
            transition('void => *', [
                style({opacity: 0, transform: 'translateY(100%)'}),
                animate(400),
            ]),
            transition('* => void', [
                animate(400, style({opacity: 0, transform: 'translateY(100%)'})),
            ]),
        ]),
    ],
})
export class BrandJourneyComponent implements OnInit {
    scrWidth: any;
    @ViewChild('pic', {static: false}) pic: ElementRef;
    cdnUrl = environment.webStaticResources;
    public thumbnailData: string | void;
    currentUser: AuthenticatedUser;
    imageError: string;
    tempBrandStoryType: BrandStoryType;
    tempPostforDesc: StoryItem;
    tempSelectedStory: StoryItem;
    allBrandStoryTypes: Array<BrandStoryType>;
    allBrandStories: BrandStories;
    allBrandsFiltered: FilterBrandStories;
    singelPost: Post;
    showThumbnailModal = false;
    popUpImagePreview = 'h';
    uploadlink = false;
    showVideoDescModal: boolean;
    showDescModal: boolean;
    showVideoModal: boolean;
    content: string;
    title: string;
    shortText: string;
    longData: string;
    dataCount: any;
    countLeft: number;
    brandVideoDescription: string;
    public showStories = false;
    showMsg = {
        type: 'success',
        msg: 'Success! Your changes have been saved.',
        show: false,
    };
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    alertTimeout: Timer;
    alertErrorTimeout: Timer;
    showSaveBar = false;
    strArray: any [];
    arrayEle: any [];
    isLoading = false;
    flashMessage: 'success' | 'error' | null;
    videoThumbnailPreview: any;
    timeFrame: number;
    private selectedIntroVideo;
    private currentBrandInfo: Brand;
    private allProductCategories: Array<ProductCategory>;
    private allVibes: Array<Vibe>;
    private allValues: Array<Value>;
    private allSocialCauses: Array<SocialCause>;

    constructor(
        private videoService: VideoProcessingService,
        private authService: AuthService,
        private brandStoriesService: BrandStoriesService,
        private productCategoryService: ProductCategoryService,
        private vibeService: VibeService,
        private valueService: ValueService,
        private socialCauseService: SocialCauseService,
        private toastr: ToastrService,
        private videoUploadingService: VideoUploadServiceService,
        private changeDetection: ChangeDetectorRef
    ) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
        this.flashMessage = null;
        this.getScreenSize();
    }

    private static showBodyScroll(): void {
        document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    }

    private static hideBodyScroll(): void {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    ngOnInit() {

        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;
        this.currentBrandInfo = new Brand(currentBrand.id, currentBrand.business_name, currentBrand.description, currentBrand.tags,
            currentBrand.brandsignupchecklist, currentBrand.brandcustomerprofile,
            currentBrand.product_categories, currentBrand.vibes, currentBrand.values, currentBrand.social_causes,
            currentBrand.sales_channel, currentBrand.website, currentBrand.headquarter_location, currentBrand.tax_id,
            currentBrand.years_in_business, currentBrand.number_of_locations, currentBrand.business_percent_online,
            currentBrand.business_percent_instore);
        this.currentBrandInfo.initBrandInfo();

        this.initBrandInfo();

        let currentElemId = '';

        document.addEventListener('fullscreenchange', () => {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If there isn't one,
            // the value of the property is null.
            console.log('Common Fullscreen');
            currentElemId = document.fullscreenElement ? document.fullscreenElement.id : currentElemId;
            if (document.fullscreenElement) {
                document.getElementById(currentElemId).classList.add('adj-video');
            } else {
                document.getElementById(currentElemId).classList.remove('adj-video');
            }
        }, false);

        document.addEventListener('mozfullscreenchange', () => {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If there isn't one,
            // the value of the property is null.
            console.log('Moz Fullscreen');
            currentElemId = document.fullscreenElement ? document.fullscreenElement.id : currentElemId;
            if (document.fullscreenElement) {
                document.getElementById(currentElemId).classList.add('adj-video');
            } else {
                document.getElementById(currentElemId).classList.remove('adj-video');
            }
        }, false);

        document.addEventListener('webkitfullscreenchange', () => {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If there isn't one,
            // the value of the property is null.
            console.log('Webkit Fullscreen');
            currentElemId = document.fullscreenElement ? document.fullscreenElement.id : currentElemId;
            if (document.fullscreenElement) {
                document.getElementById(currentElemId).classList.add('adj-video');
            } else {
                document.getElementById(currentElemId).classList.remove('adj-video');
            }
        }, false);

        document.addEventListener('msfullscreenchange', () => {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If there isn't one,
            // the value of the property is null.
            console.log('Ms Fullscreen');
            currentElemId = document.fullscreenElement ? document.fullscreenElement.id : currentElemId;
            if (document.fullscreenElement) {
                document.getElementById(currentElemId).classList.add('adj-video');
            } else {
                document.getElementById(currentElemId).classList.remove('adj-video');
            }
        }, false);

        const pageLoad = localStorage.getItem('pageLoad');
        // const pageLoad = 'fast';
        if (pageLoad === 'slow') {
            setTimeout(() => {
                this.initBrandStory();
            }, 5000);
        } else {
            this.initBrandStory();
        }
        this.brandStoriesService.brandStoryUploaded.subscribe(value => {
            if (value) {
                this.initBrandStory();
                this.brandStoriesService.setBrandStoryUploaded(false);
            }
        });
    }

    initBrandInfo() {
        this.productCategoryService.GetAllProductCategories().subscribe(productCategories => {
                this.allProductCategories = productCategories.results;
                this.allProductCategories.forEach((productCategory, index) => {
                    productCategory.selected = false;
                    this.currentBrandInfo.product_categories.forEach((brandProductCategory, index2) => {
                        if (brandProductCategory.id === productCategory.id) {
                            productCategory.selected = true;
                            productCategory.min_price = brandProductCategory.min_price;
                            productCategory.max_price = brandProductCategory.max_price;
                            productCategory.percentage = brandProductCategory.percentage;
                        }
                    });
                });

                this.vibeService.GetAllVibes().subscribe(vibes => {
                    this.allVibes = vibes.results;
                    this.allVibes.forEach((vibe, index) => {
                        vibe.selected = false;
                        this.currentBrandInfo.vibes.forEach((brandVibe, index2) => {
                            if (brandVibe.id === vibe.id) {
                                vibe.selected = true;
                                vibe.percentage = brandVibe.percentage;
                            }
                        });
                    });
                    this.valueService.GetAllValues().subscribe(values => {
                        this.allValues = values.results;
                        this.allValues.forEach((value, index) => {
                            value.selected = false;
                            this.currentBrandInfo.values.forEach((brandValue, index2) => {
                                if (brandValue.id === value.id) {
                                    value.selected = true;
                                    value.percentage = brandValue.percentage;
                                }
                            });
                        });

                        this.socialCauseService.GetAllSocialCauses().subscribe((socialCauses) => {
                            this.allSocialCauses = socialCauses.results;
                            this.allSocialCauses.forEach((socialCause, index) => {
                                socialCause.selected = false;
                                this.currentBrandInfo.social_causes.forEach((brandSocialCause, index2) => {
                                    if (brandSocialCause.id === socialCause.id) {
                                        socialCause.selected = true;
                                    }
                                });
                            });
                        });
                    });
                });
            },
            err => {

            });
    }

// <==============================get stories====================================================>


    public add($event?: Event): void {
        this.thumbnailData = null;
        this.selectedIntroVideo = null;
        // @ts-ignore
        const videoFile = $event.target.files[0];

        if (videoFile) {
            // const mbSize = videoFile.size / 1024 / 1024;
            const fileIsMp4 =
                videoFile.type === 'video/mp4' ||
                'video/3gpp' ||
                'video/m4v' ||
                'application/x-mpegURL' ||
                'video/MP2T' ||
                'video/quicktime' ||
                // || 'video/x-msvideo' || 'video/x-flv'
                'video/x-m4v' ||
                'video/wmv' ||
                'video/mov';

            if (!fileIsMp4) {
                this.thumbnailData = 'assets/images/invalidFile.png';
            } else {
                this.generateThumbnail(videoFile)
                    .then(resp => {
                        this.thumbnailData = resp;
                        this.selectedIntroVideo = videoFile;
                    });
            }

        }


        // this.videoService
        //     .promptForVideo()
        //     .then((videoFile) => {
        //         console.log('in then');
        //         if (videoFile) {
        //             // const mbSize = videoFile.size / 1024 / 1024;
        //             const fileIsMp4 =
        //                 videoFile.type === 'video/mp4' ||
        //                 'video/3gpp' ||
        //                 'video/m4v' ||
        //                 'application/x-mpegURL' ||
        //                 'video/MP2T' ||
        //                 'video/quicktime' ||
        //                 // || 'video/x-msvideo' || 'video/x-flv'
        //                 'video/x-m4v' ||
        //                 'video/wmv' ||
        //                 'video/mov';
        //
        //             if (!fileIsMp4) {
        //                 return 'assets/images/invalidFile.png';
        //             } else {
        //                 this.selectedIntroVideo = videoFile;
        //                 return this.videoService.generateThumbnail(videoFile).catch((err) => {
        //                     console.log(err);
        //                 });
        //             }
        //         }
        //     })
        //     .then((thumbnailData) => {
        //         this.thumbnailData = thumbnailData;
        //     });

        // this.generateThumbnail()
    }

    initBrandStory() {
        this.uploadlink = false;
        this.brandVideoDescription = '';
        this.brandStoriesService.GetAllBrandStoryTypes('journey').subscribe(brandStoryTypes => {
            this.allBrandStoryTypes = brandStoryTypes.results;

            this.brandStoriesService.GetBrandFilteredStory(this.currentBrandInfo.id, 'journey').subscribe(stories => {

                this.allBrandsFiltered = stories;

                this.allBrandStoryTypes.forEach((brandStoryType, index) => {
                    brandStoryType.uploaded = false;

                    this.allBrandsFiltered.posts.forEach((brandStory, index2) => {
                        if (brandStory.title === brandStoryType.post_type) {
                            brandStoryType.uploaded = true;
                            brandStoryType.fStories = brandStory.items;
                        }
                    });

                });

                this.showStories = true;
                this.changeDetection.markForCheck();
            });

        });
        localStorage.setItem('pageLoad', 'fast');
    }

    showTabContent(pop: HTMLDivElement, link: HTMLAnchorElement) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }
        pop.classList.add('show', 'active');
        link.classList.add('active');
    }

    // Methods For ALL Tab

    arrayCount(n: number) {
        return Array(n);
    }

    // tslint:disable-next-line:max-line-length
    loadVideo(videoTagIdPreSelector: string, brandStoryType: BrandStoryType, post: StoryItem, thumbnailClass: string, elemIdPreSelector: string) {

        this.tempSelectedStory = post;

        let elemRef = 'videosrc_' + brandStoryType.id;

        if (videoTagIdPreSelector === 'comp') {
            elemRef = 'comp_videosrc_' + brandStoryType.id;
        }

        const activeThumb = elemIdPreSelector + '-' + post.id;
        const allthumb = thumbnailClass + '-' + brandStoryType.id;

        document.querySelectorAll('.' + allthumb).forEach((elem, index) => {
            elem.classList.remove('selected');
        });

        document.getElementById(activeThumb).classList.add('selected');

        (document.getElementById(elemRef) as HTMLVideoElement).src = post.video_url;
        (document.getElementById(elemRef) as HTMLVideoElement).setAttribute('data-postId', (post.id).toString());
    }

    // Methods for COMPLETED Tab

    removeStory(brandStoryType: BrandStoryType) {
        const elemRef = 'videosrc_' + brandStoryType.id;
        const postId = (document.getElementById(elemRef) as HTMLVideoElement).getAttribute('data-postId');
        this.removeCurrentBrandStory(postId);
    }

    loadCompVideo(brandStoryType: BrandStoryType, post: StoryItem) {
        const elemRef = 'comp_videosrc_' + brandStoryType.id;
        (document.getElementById(elemRef) as HTMLVideoElement).src = post.video_url;
        (document.getElementById(elemRef) as HTMLVideoElement).setAttribute('data-comp-postId', (post.id).toString());
    }

    // Common REMOVE STORY method

    removeCompStory(brandStoryType: BrandStoryType) {
        const elemRef = 'comp_videosrc_' + brandStoryType.id;
        const postId = (document.getElementById(elemRef) as HTMLVideoElement).getAttribute('data-comp-postId');
        this.removeCurrentBrandStory(postId);
    }

    // Update STORY DESCRIPTION method

    removeCurrentBrandStory(postId) {
        this.showSaveBar = true;
        // this.spinnerService.show();
        this.brandStoriesService.RemoveBrandStory(this.currentBrandInfo.id, postId).subscribe(obj => {
            this.showAlert('Story Deleted Successfully!');
            setTimeout(() => {
                this.initBrandStory();
                this.showSaveBar = false;
            }, 5000);


        }, error => {
            this.showAlertError('Oops! Something Went Wrong!');
            // this.toastr.error('Oops! Something Went Wrong!');
        });
    }

    //   Modals Show Hide Methods

    updateCurrentPost() {
        this.isLoading = true;
        const data = {
            description: this.tempPostforDesc.description
        };
        this.brandStoriesService.updateBrandStory(this.currentBrandInfo.id, this.tempPostforDesc.id, data).subscribe(
            obj => {
                this.initBrandStory();

                setTimeout(() => {
                    this.showFlashMessage('success');
                    this.isLoading = false;
                }, 1500);
            }, error => {
                // this.toastr.error('Something went wrong while updating description.');
            }
        );
    }

    showVModal(brandStoryType) {
        this.tempBrandStoryType = null;
        this.thumbnailData = null;
        this.tempBrandStoryType = brandStoryType;
        this.longData = this.tempBrandStoryType.brand_post_category_long_text;
        this.strArray = this.longData.split('\n');
        this.strArray = this.strArray.filter((item) => {
            return item !== '';
        });
        this.arrayEle = this.strArray.filter((element) => {
            return /\S/.test(element);
        });
        this.showVideoModal = true; // Show-Hide Modal Check
        BrandJourneyComponent.hideBodyScroll();
    }

    hideVModal() {
        this.showVideoModal = false;
        this.tempPostforDesc = null;
        this.tempBrandStoryType = null;
        this.thumbnailData = null;
        BrandJourneyComponent.showBodyScroll();
    }

    showDModal(brandStoryType) {
        this.tempBrandStoryType = null;
        this.thumbnailData = null;
        this.tempBrandStoryType = brandStoryType;

        this.tempPostforDesc = this.tempBrandStoryType.fStories[0];
        if (this.tempPostforDesc.description) {
            this.dataCount = this.tempPostforDesc.description.length;
        } else {
            this.dataCount = 0;
        }
        this.showDescModal = true;
        BrandJourneyComponent.hideBodyScroll();

    }

    onLoad() {
        const width = (this.pic.nativeElement as HTMLImageElement).naturalWidth;
        const height = (this.pic.nativeElement as HTMLImageElement).naturalHeight;
        if (width > height) {
            this.popUpImagePreview = 'h';
        } else {
            this.popUpImagePreview = 'v';
        }
    }

    hideDModal() {
        this.showDescModal = false;
        this.flashMessage = null;
        this.tempPostforDesc = null;
        this.tempBrandStoryType = null;
        this.thumbnailData = null;
        BrandJourneyComponent.showBodyScroll();
    }

    showVDModel() {
        this.showVideoDescModal = true;
        localStorage.setItem('uploadedPostType', JSON.stringify(this.tempBrandStoryType));
        const retrievedObject = JSON.parse(localStorage.getItem('uploadedPostType'));
        this.shortText = retrievedObject.brand_post_category_short_text;
        BrandJourneyComponent.hideBodyScroll();
    }

    // Temp video upload pormpt
    // tempUploader(brandStoryType) {
    //     this.selectedIntroVideo = null;
    //     this.tempBrandStoryType = null;
    //     this.thumbnailData = null;
    //     this.tempBrandStoryType = brandStoryType;
    //     this.add($event);
    //     setTimeout(() => {
    //         this.uploadMoreVideos();
    //     }, 1000);
    // }

    hideVDModal() {
        localStorage.setItem('pageReload', 'true');
        // descritopn update

        const videoUploaded = localStorage.getItem('videoUploaded');
        const postId = localStorage.getItem('post_id');
        this.showVideoDescModal = false;
        const videodata = {
            description: this.brandVideoDescription
        };
        if (videoUploaded === 'true') {
            this.brandStoriesService.updateBrandStory(this.currentBrandInfo.id, postId, videodata).subscribe(
                obj => {
                    // this.hideVDModal();
                    setTimeout(() => {
                        window.localStorage.removeItem('videoDescription');
                        window.localStorage.removeItem('videoUploaded');
                        window.localStorage.removeItem('post_id');
                        window.localStorage.removeItem('pageReload');
                        this.brandStoriesService.setBrandStoryUploaded(false);
                        this.initBrandStory();
                        this.tempPostforDesc = null;
                        this.tempBrandStoryType = null;
                    }, 1000);

                }
            );

        } else {
            localStorage.setItem('videoDescription', this.brandVideoDescription || '');
            localStorage.setItem('pageReload', 'true');
        }
        BrandJourneyComponent.showBodyScroll();
    }

    loadVideoDescription(post: StoryItem) {
        this.tempPostforDesc = post;
    }

    adjVideo(id: number) {
        const elem = document.getElementById('videosrc_' + id) as HTMLVideoElement;
        if (elem.webkitDisplayingFullscreen) {
            console.log('Displaying in Fullscreen Webkit');
            elem.classList.add('adj-video');
        } else {
            console.log('Exiting Fullscreen Webkit');
            elem.classList.remove('adj-video');
        }
    }

    // uploadBrandStory(e, brandStoryType) {
    //     const data = new FormData();
    //     this.uploadlink = false;
    //     data.append('post_content_type', 'video_post');
    //     // data.append('brand', String(this.currentBrandInfo.id));
    //     data.append('post_type', brandStoryType.id);
    //     data.append('video', e.target.files[0]);
    //     if (e.target.files[0]) {
    //         // const mbSize = videoFile.size / 1024 / 1024;
    //         const fileIsMp4 = (e.target.files[0].type === 'video/mp4' || 'video/3gpp' || 'video/m4v'
    //             || 'application/x-mpegURL' || 'video/MP2T' || 'video/quicktime'
    //             // || 'video/x-msvideo' || 'video/x-flv'
    //             || 'video/x-m4v' || 'video/wmv');
    //
    //         if (!fileIsMp4) {
    //             this.toastr.error('Videos Files supported Only');
    //             this.uploadlink = false;
    //             return false;
    //         } else {
    //             this.spinnerService.show();
    //             setTimeout(() => {
    //                 this.uploadlink = true;
    //             }, 1000);
    //             this.brandStoriesService.CreateBrandStory(this.currentBrandInfo.id, data).subscribe(brandStory => {
    //                 this.toastr.success('Video uploaded successfully');
    //
    //                 setTimeout(() => {
    //                     this.uploadlink = false;
    //                     this.selectedIntroVideo = null;
    //                     this.spinnerService.hide();
    //                     // window.location.reload();
    //                     this.initBrandStory();
    //
    //                 }, 5000);
    //
    //             }, err => {
    //                 this.uploadlink = false;
    //                 this.spinnerService.hide();
    //                 this.toastr.error('Something went wrong!');
    //                 this.initBrandStory();
    //             });
    //         }
    //     }
    // }

    // uploadMoreVideos() {
    //     if (this.selectedIntroVideo) {
    //         this.spinnerService.show();
    //         const data = new FormData();
    //         data.append('post_content_type', 'video_post');
    //         // data.append('brand', String(this.brandInfo.id));
    //         data.append('post_type', this.tempBrandStoryType.post_type);
    //         data.append('video', this.selectedIntroVideo);
    //         setTimeout(() => {
    //             this.uploadlink = true;
    //         }, 1000);
    //         this.brandStoriesService.CreateBrandStory(this.currentBrandInfo.id, data).subscribe(brandStory => {
    //
    //             this.toastr.success('Video uploaded successfully');
    //             this.hideVModal();
    //
    //             setTimeout(() => {
    //                 this.spinnerService.hide();
    //                 this.uploadlink = false;
    //                 this.selectedIntroVideo = null;
    //                 this.thumbnailData = null;
    //                 // window.location.reload();
    //                 this.initBrandStory();
    //             }, 6000);
    //
    //         }, err => {
    //             this.uploadlink = false;
    //             this.spinnerService.hide();
    //             // window.location.reload();
    //             this.initBrandStory();
    //             this.toastr.error('Something went wrong!');
    //         });
    //     } else {
    //         this.toastr.error('Videos Files supported Only');
    //     }
    // }

    uploadMoreVideosTUSWay() {
        if (this.selectedIntroVideo) {
            // const mbSize = videoFile.size / 1024 / 1024;
            const fileIsMp4 = (this.selectedIntroVideo.type === 'video/mp4' || 'video/3gpp' || 'video/m4v'
                || 'application/x-mpegURL' || 'video/MP2T' || 'video/quicktime'
                // || 'video/x-msvideo' || 'video/x-flv'
                || 'video/x-m4v' || 'video/wmv');

            if (!fileIsMp4) {
                this.toastr.error('Videos Files supported Only');
                this.uploadlink = false;
                return false;
            } else {

                // -----------------------------   TUS UPLOADER

                this.videoUploadingService.showUploadBar();

                // Get the selected file from the input element
                const file = this.selectedIntroVideo;
                let currPer = 0;
                const brandId = this.currentBrandInfo.id;

                const serv = this.brandStoriesService;
                const toastrSer = this.toastr;
                const brandSer = this.brandStoriesService;
                const VDescription = localStorage.getItem('videoDescription');
                let postDataObj;

                if (VDescription === null || VDescription === '') {
                    postDataObj = {
                        post_content_type: 'video_post',
                        post_type: this.tempBrandStoryType.id,
                        guid: '0',
                        description: VDescription,
                    };
                } else {
                    postDataObj = {
                        post_content_type: 'video_post',
                        post_type: this.tempBrandStoryType.id,
                        guid: '0'
                    };
                }
                // const postDataObj = {
                //     post_content_type: 'video_post',
                //     post_type: this.tempBrandStoryType.id,
                //     guid: '0'
                // };

                const envUrl = environment.apiBaseUrl || 'http://192.168.100.113:8000';
                const uploadAPIUrl = envUrl + '/api/v1/files/';
                localStorage.setItem('pageReload', 'false');
                localStorage.setItem('videoUploaded', 'false');
                const videoUploaded = localStorage.getItem('videoUploaded');

                // Create a new tus upload
                const upload = new tus.Upload(file, {
                    endpoint: uploadAPIUrl,
                    retryDelays: [0, 3000, 5000, 10000, 20000],
                    metadata: {
                        filename: file.name,
                        filetype: file.type
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.currentUser.token
                    },
                    onError(error) {
                        console.log('Failed because: ' + error);
                    },
                    onProgress(bytesUploaded, bytesTotal) {

                        document.getElementById('video-upload-wrapper').classList.add('showBar');

                        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                        console.log(bytesUploaded, bytesTotal, percentage + '%');
                        currPer = Math.round(+percentage);
                        if (+percentage > 70 && +percentage < 90) {
                            document.getElementById('progress-bar').style.width = +currPer + '%';
                        } else if (+percentage > 95 && +percentage <= 100) {
                            document.getElementById('progress-bar').style.width = '95%';
                        } else {
                            document.getElementById('progress-bar').style.width = +percentage + '%';
                        }

                    },
                    onSuccess() {
                        if ('name' in upload.file) {
                            console.log('Download %s from %s', upload.file.name, upload.url);
                        } else {
                            console.log('Download %s from %s', upload.file, upload.url);
                        }
                        if (videoUploaded === 'false') {
                            if (currPer === 100) {
                                const upUrl = upload.url.split('/');
                                const guid = upUrl[upUrl.length - 2];
                                postDataObj.guid = guid;
                                postDataObj.description = localStorage.getItem('videoDescription');

                                serv.CreateBrandStory(brandId, postDataObj).subscribe(res => {
                                    localStorage.setItem('post_id', res[`post`].id);
                                    localStorage.setItem('videoUploaded', 'true');
                                    document.getElementById('uploading-text').innerText =
                                        'Uploading Video';
                                    // const elem = document.createElement('span');
                                    // elem.innerHTML = '<br> Reloading Page, Please Wait!';
                                    // document.getElementById('uploading-text').appendChild(elem);

                                    // setTimeout(() => {
                                    //     document.getElementById('uploading-text').innerText =
                                    //         'Finalizing Video. Please Wait!';
                                    // }, 3000);

                                    setTimeout(() => {
                                        document.getElementById('uploading-text').innerText =
                                            'Success! Your video has been uploaded.';
                                        document.getElementById('progress-bar').style.width = '100%';
                                        localStorage.setItem('pageLoad', 'slow');

                                        setTimeout(() => {
                                            document.getElementById('video-upload-wrapper').classList.remove('showBar');
                                            const toReload = localStorage.getItem('pageReload');


                                            if (toReload === 'true') {
                                                window.localStorage.removeItem('videoDescription');
                                                window.localStorage.removeItem('videoUploaded');
                                                window.localStorage.removeItem('post_id');
                                                window.localStorage.removeItem('pageReload');
                                                window.localStorage.removeItem('uploadedPostType');
                                                serv.setBrandStoryUploaded(true);
                                                // window.location.reload();
                                            }
                                        }, 5000);
                                    }, 10000);

                                }, error => {
                                    // document.getElementById('uploading-text').innerText =
                                    //     'Something went wrong!';
                                    toastrSer.error('Something went wrong!');
                                });
                            }
                        } else {
                            if (currPer === 100) {
                                const upUrl = upload.url.split('/');
                                const guid = upUrl[upUrl.length - 2];
                                postDataObj.guid = guid;

                                serv.CreateBrandStory(brandId, postDataObj).subscribe(res => {
                                    localStorage.setItem('post_id', res[`post`].id);
                                    localStorage.setItem('videoUploaded', 'true');
                                    document.getElementById('uploading-text').innerText =
                                        'Uploading Video';
                                    setTimeout(() => {
                                        document.getElementById('uploading-text').innerText =
                                            'Success! Your video has been uploaded.';
                                        localStorage.setItem('pageLoad', 'slow');

                                        setTimeout(() => {
                                            document.getElementById('video-upload-wrapper').classList.remove('showBar');
                                            const toReload = localStorage.getItem('pageReload');

                                            if (toReload === 'true') {

                                                window.localStorage.removeItem('videoDescription');
                                                window.localStorage.removeItem('videoUploaded');
                                                window.localStorage.removeItem('post_id');
                                                window.localStorage.removeItem('pageReload');
                                                window.localStorage.removeItem('uploadedPostType');
                                                serv.setBrandStoryUploaded(true);
                                                // window.location.reload();
                                            }
                                        }, 5000);
                                    }, 10000);

                                }, error => {
                                    // document.getElementById('uploading-text').innerText =
                                    //     'Something went wrong!';
                                    toastrSer.error('Something went wrong!');
                                });
                            }
                        }

                    },


                });

                // Start the upload
                upload.start();
                this.hideVModal();

                // -----------------------------   .end TUS UPLOADER

            }
        }
    }

    uploadBrandStoryTUSWay(e, brandStoryType) {
        if (e.target.files[0]) {
            // const mbSize = videoFile.size / 1024 / 1024;
            const fileIsMp4 = (e.target.files[0].type === 'video/mp4' || 'video/3gpp' || 'video/m4v'
                || 'application/x-mpegURL' || 'video/MP2T' || 'video/quicktime'
                // || 'video/x-msvideo' || 'video/x-flv'
                || 'video/x-m4v' || 'video/wmv');

            if (!fileIsMp4) {
                this.toastr.error('Videos Files supported Only');
                this.uploadlink = false;
                return false;
            } else {

                // -----------------------------   TUS UPLOADER

                this.videoUploadingService.showUploadBar();

                // Get the selected file from the input element
                const file = e.target.files[0];
                let currPer = 0;
                const brandId = this.currentBrandInfo.id;

                const serv = this.brandStoriesService;
                const toastrSer = this.toastr;
                const brandSer = this.brandStoriesService;
                const VDescription = localStorage.getItem('videoDescription');


                let postDataObj;

                if (VDescription === null || VDescription === '') {
                    postDataObj = {
                        post_content_type: 'video_post',
                        post_type: brandStoryType.id,
                        guid: '0',
                        description: VDescription,
                    };
                } else {
                    postDataObj = {
                        post_content_type: 'video_post',
                        post_type: brandStoryType.id,
                        guid: '0'
                    };
                }


                const envUrl = environment.apiBaseUrl || 'http://192.168.100.100:8000';
                const uploadAPIUrl = envUrl + '/api/v1/files/';
                localStorage.setItem('pageReload', 'false');
                localStorage.setItem('videoUploaded', 'false');
                const videoUploaded = localStorage.getItem('videoUploaded');
                // Create a new tus upload
                const upload = new tus.Upload(file, {
                    endpoint: uploadAPIUrl,
                    retryDelays: [0, 3000, 5000, 10000, 20000],
                    metadata: {
                        filename: file.name,
                        filetype: file.type
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.currentUser.token
                    },
                    onError(error) {
                        console.log('Failed because: ' + error);
                    },
                    onProgress(bytesUploaded, bytesTotal) {

                        document.getElementById('video-upload-wrapper').classList.add('showBar');

                        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                        console.log(bytesUploaded, bytesTotal, percentage + '%');
                        currPer = Math.round(+percentage);
                        document.getElementById('progress-bar').style.width = +percentage + '%';
                    },
                    onSuccess() {
                        if ('name' in upload.file) {
                            console.log('Download %s from %s', upload.file.name, upload.url);
                        } else {
                            console.log('Download %s from %s', upload.file, upload.url);
                        }
                        if (videoUploaded === 'false') {
                            if (currPer === 100) {
                                const upUrl = upload.url.split('/');
                                const guid = upUrl[upUrl.length - 2];
                                postDataObj.guid = guid;
                                postDataObj.description = localStorage.getItem('videoDescription');


                                serv.CreateBrandStory(brandId, postDataObj).subscribe(res => {
                                    localStorage.setItem('post_id', res[`post`].id);
                                    localStorage.setItem('videoUploaded', 'true');
                                    document.getElementById('uploading-text').innerText =
                                        'Uploading Video';
                                    // const elem = document.createElement('span');
                                    // elem.innerHTML = '<br> Reloading Page, Please Wait!';
                                    // document.getElementById('uploading-text').appendChild(elem);

                                    // setTimeout(() => {
                                    //     document.getElementById('uploading-text').innerText =
                                    //         'Uploading Video!. Please Wait!';
                                    // }, 3000);

                                    setTimeout(() => {
                                        document.getElementById('uploading-text').innerText =
                                            'Success! Your video has been uploaded.';
                                        localStorage.setItem('pageLoad', 'slow');

                                        setTimeout(() => {
                                            document.getElementById('video-upload-wrapper').classList.remove('showBar');

                                            const toReload = localStorage.getItem('pageReload');

                                            if (toReload === 'true') {
                                                window.localStorage.removeItem('videoDescription');
                                                window.localStorage.removeItem('videoUploaded');
                                                window.localStorage.removeItem('post_id');
                                                window.localStorage.removeItem('pageReload');
                                                window.localStorage.removeItem('uploadedPostType');
                                                serv.setBrandStoryUploaded(true);
                                                // window.location.reload();
                                            }

                                        }, 5000);
                                    }, 10000);

                                }, error => {
                                    toastrSer.error('Something went wrong!');
                                });
                            }
                        } else {
                            if (currPer === 100) {
                                const upUrl = upload.url.split('/');
                                const guid = upUrl[upUrl.length - 2];
                                postDataObj.guid = guid;
                                serv.CreateBrandStory(brandId, postDataObj).subscribe(res => {
                                    localStorage.setItem('post_id', res[`post`].id);
                                    localStorage.setItem('videoUploaded', 'true');
                                    document.getElementById('uploading-text').innerText =
                                        'Uploading Video';
                                    // const elem = document.createElement('span');
                                    // elem.innerHTML = '<br> Reloading Page, Please Wait!';
                                    // document.getElementById('uploading-text').appendChild(elem);

                                    // setTimeout(() => {
                                    //     document.getElementById('uploading-text').innerText =
                                    //         'Uploading Video!. Please Wait!';
                                    // }, 3000);

                                    setTimeout(() => {
                                        document.getElementById('uploading-text').innerText =
                                            'Success! Your video has been uploaded.';
                                        localStorage.setItem('pageLoad', 'slow');

                                        setTimeout(() => {
                                            document.getElementById('video-upload-wrapper').classList.remove('showBar');

                                            const toReload = localStorage.getItem('pageReload');

                                            if (toReload === 'true') {
                                                window.localStorage.removeItem('videoDescription');
                                                window.localStorage.removeItem('videoUploaded');
                                                window.localStorage.removeItem('post_id');
                                                window.localStorage.removeItem('pageReload');
                                                window.localStorage.removeItem('uploadedPostType');
                                                serv.setBrandStoryUploaded(true);
                                                // window.location.reload();
                                            }

                                        }, 5000);
                                    }, 10000);

                                }, error => {
                                    toastrSer.error('Something went wrong!');
                                });
                            }
                        }
                    },
                });

                // Start the upload
                upload.start();

                // -----------------------------   .end TUS UPLOADER

            }
        }
    }

    saveModalDescription(): void {
        localStorage.setItem('pageReload', 'true');
        localStorage.setItem('videoDescription', this.brandVideoDescription);
        this.showVideoDescModal = false;

        // description update

        const videoUploaded = localStorage.getItem('videoUploaded');
        const postId = localStorage.getItem('post_id');
        const data = localStorage.getItem('videoDescription');
        const videodata = {
            description: this.brandVideoDescription
        };
        if (videoUploaded === 'true') {
            this.brandStoriesService.updateBrandStory(this.currentBrandInfo.id, postId, videodata).subscribe(
                obj => {
                    // this.hideVDModal();
                    setTimeout(() => {
                        window.localStorage.removeItem('videoDescription');
                        window.localStorage.removeItem('videoUploaded');
                        window.localStorage.removeItem('post_id');
                        window.localStorage.removeItem('pageReload');
                        // location.reload();
                        this.initBrandStory();
                    }, 1000);

                }
            );

        } else {
            localStorage.setItem('videoDescription', this.brandVideoDescription);
            localStorage.setItem('pageReload', 'true');
        }

    }

    toggleSaveBar() {
        this.showSaveBar = true;
    }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 6000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 6000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }

    showselected($event: Event): void {
        console.log($event);
    }

    directUpload($event: Event) {
        this.selectedIntroVideo = null;
        // this.spinnerService.show();
        // this.add($event);
        // @ts-ignore
        this.selectedIntroVideo = $event.target.files[0];
        setTimeout(() => {
            this.showVDModel();
            this.uploadMoreVideosTUSWay();
        }, 500);
    }

    closeTModal() {
        this.showThumbnailModal = false;
        this.tempBrandStoryType = null;
        BrandJourneyComponent.showBodyScroll();
    }

    openTModal(brandStoryType: BrandStoryType) {
        this.tempBrandStoryType = null;
        this.thumbnailData = null;
        this.tempBrandStoryType = brandStoryType;
        this.thumbnailData = brandStoryType.fStories[0].video_thumbnail;
        this.videoThumbnailPreview = this.thumbnailData;
        this.showThumbnailModal = true;
        BrandJourneyComponent.hideBodyScroll();
        this.changeDetection.markForCheck();
    }

    captureThumbnail(tempBrandStoryType: BrandStoryType) {
        // this.thumbnailData = null;
        this.videoThumbnailPreview = null;

        const canvasId = 'prevCanvas';
        const id = `videoPreview_${tempBrandStoryType.id}`;
        const video = document.getElementById(id) as HTMLVideoElement;

        const curTime = video.currentTime;
        this.timeFrame = Math.round(curTime * (tempBrandStoryType.fStories[0].video_frame_rate || 29));

        if (this.scrWidth > 992) {

            const canvas = document.getElementById(canvasId) as HTMLCanvasElement;

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            canvas.toBlob((blob) => {
                try {
                    const img = new Image();
                    img.src = window.URL.createObjectURL(blob);
                    console.clear();
                } catch (e) {
                    console.log();
                }

            });
            console.clear();

        }

    }

    updateThumbnail(): void {
        this.isLoading = true;

        if (this.scrWidth < 992 || !this.timeFrame) {
            const id = `videoPreview_${this.tempBrandStoryType.id}`;
            const video = document.getElementById(id) as HTMLVideoElement;

            const curTime = video.currentTime;
            this.timeFrame = Math.round(curTime * (this.tempBrandStoryType.fStories[0].video_frame_rate || 29));
        }

        // tslint:disable-next-line:max-line-length
        this.brandStoriesService.updateThumbnail(this.timeFrame || 1, this.currentBrandInfo.brandcustomerprofile.brand,
            this.tempBrandStoryType.fStories[0].id)
            .subscribe(resp => {
                this.closeTModal();
                this.showSaveBar = true;
                this.showAlert('Thumbnail updated successfully');
                this.initBrandStory();
                this.isLoading = false;
                setTimeout(() => {
                    this.showSaveBar = false;
                }, 4000);

            }, error => {
                this.showSaveBar = true;
                this.showAlertError('Oops! Something Went Wrong!');
                this.isLoading = false;
                setTimeout(() => {
                    this.showSaveBar = false;
                }, 4000);
            });
    }

    private generateThumbnail(videoFile: Blob): Promise<string> {
        const video: HTMLVideoElement = document.createElement('video');
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        return new Promise<string>((resolve, reject) => {
            canvas.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                resolve(canvas.toDataURL());
            });
            if (videoFile.type) {
                video.setAttribute('type', videoFile.type);
            }
            video.preload = 'auto';
            video.src = window.URL.createObjectURL(videoFile);
            video.load();
        });
    }

    private showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Hide it after 3 seconds
        setTimeout(() => {
            this.flashMessage = null;
        }, 6000);
    }

}
