<span class="d-none">Image Tag: {{imageTag}}</span>
<span class="d-none">Image Tag Ref: {{imageTagRef}}</span>

<app-header-homepage></app-header-homepage>

<main>
    <!-- Hero Area -->
    <section class="heroArea">
        <div class="container">
            <div class="row">
                <!-- Left Text Area -->
                <div class="col-lg-6 text-center text-lg-left">
                    <h1>
                        Tell your story like never before.
                    </h1>
                    <p class="mx-auto mx-lg-0">
                        Create authentic and meaningful relationships between your brand and the people you serve.
                    </p>
                    <div class="d-block">
                        <a *ngIf="!currentUser" class="nav-link join-btn" href="#" routerLink="/signup">Join Now</a>
                        <!-- TODO: replace below href link to dashboard once production screens got approved -->
                        <a *ngIf="currentUser" class="nav-link join-btn" href="/brand-journey">Dashboard</a>
                        <p *ngIf="!currentUser" class="w-100" style="font-size: 18px">Already Registered? <a class="btn-link" href="#" routerLink="/login" style="font-size: 18px">Sign
                            In</a></p>
                    </div>
                </div>
                <!-- Right Vector Area -->
                <div class="col-lg-6 mt-5 mt-lg-2">
                    <img alt="ladies" class="img-fluid" src="{{cdnUrl}}/assets/images/PersonalBrand_2.png">
                </div>
            </div>
        </div>
    </section>

    <!-- Reach Section -->
    <section class="reach">
        <div class="container-fluid p-0">
            <div class="row no-gutters">
                <!-- Right Vector Area -->
                <div class="col-lg-7 px-0 order-1 order-lg-0">
                    <div class="right-area pt-0">
                        <img alt="wellness" src="{{cdnUrl}}/assets/images/Wellness.png">
                    </div>
                </div>
                <!-- Left Text Area -->
                <div class="col-lg-4 px-3 px-lg-0 order-0 order-lg-1">
                    <div class="left-area text-center text-lg-left">
                        <h2 class="mx-auto mx-lg-0">
                            Truly tailored to reach the right people.
                        </h2>

                        <h5>
                            Boost Exposure
                        </h5>
                        <p class="mx-auto mx-lg-0">
                            Connect with people whose vibe, values, and interests align with your story.
                        </p>
                        <h5 class="mt-5">
                            Thoughtful Insights & Analytics
                        </h5>
                        <p class="mx-auto mx-lg-0">
                            Celebrate your customers! Self-expression driven data allows you to genuinely understand your community.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Mid Page Text Banner -->
    <div class="midTextBanner">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <h1>
                        We’re here to connect your<br> brand with people who care about your story as much as you do.
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <!-- Tools Section -->
    <section class="tools overflow-hidden">
        <header>
            <h1>
                Customer expectations are shifting...<br> Shift with them.
            </h1>
        </header>
        <div class="container">
            <div class="row justify-content-around">
                <!-- Tool Item -->
                <div class="col-lg-3">
                    <div class="tool-item">
                        <img alt="statue" src="{{cdnUrl}}/assets/images/GrowUp.png">
                        <h5>
                            Grow Trust
                        </h5>
                        <p>
                            It’s time to be an open book & lead with your story. Erase consumer skepticism by embracing transparency.
                        </p>
                    </div>
                </div>
                <!-- Tool Item -->
                <div class="col-lg-3">
                    <div class="tool-item">
                        <img alt="arrow" src="{{cdnUrl}}/assets/images/brand-account-images/image1.png">
                        <h5>
                            Dig Deep
                        </h5>
                        <p>
                            Because everyone’s tired of small talk.
                        </p>
                    </div>
                </div>
                <!-- Tool Item -->
                <div class="col-lg-3">
                    <div class="tool-item">
                        <img alt="chair" src="{{cdnUrl}}/assets/images/AddFree.png">
                        <h5>
                            Ad Free
                        </h5>
                        <p>
                            Stop tooting your own horn - a good story is always worth sharing. Authentic recommendations are more powerful than ads.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-around">
                <!-- Tool Item -->
                <div class="col-lg-3 offset-lg-2">
                    <div class="tool-item">
                        <img alt="coat" src="{{cdnUrl}}/assets/images/SparkImpact.png">
                        <h5>
                            Spark Impact
                        </h5>
                        <p>
                            Encourage conscious consumerism. Share how you give back to match with consumers who care.
                        </p>
                    </div>
                </div>
                <!-- Tool Item -->
                <div class="col-lg-3 offset-lg-right-2">
                    <div class="tool-item">
                        <img alt="cactus" src="{{cdnUrl}}/assets/images/CuratedIntroductions.png">
                        <h5>
                            Curated Introductions
                        </h5>
                        <p>
                            Meet new customers when customers are ready to meet you. Increase conversion with a motivated audience.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Small Image on Bottom Right -->
        <!--        <img class="smallPic" src="{{cdnUrl}}/assets/images/Wellness.png"-->
        <!--            alt="small ladies">-->

    </section>

    <!-- Analytics Section -->
    <!--    <section class="analytics overflow-hidden">-->
    <!--            <div class="row no-gutters justify-content-md-center">-->
    <!--                <div class="col-lg-5">-->
    <!--                    <div class="left-area">-->
    <!--                        <h1>-->
    <!--                            Finding your customers is one thing, understanding them is a game changer.-->
    <!--                        </h1>-->
    <!--                        <p>-->
    <!--                            Tailorie democratizes access to actionable data analytics for brands of-->
    <!--                            all sizes allowing brands to discover and adapt to new market opportunities.-->
    <!--                        </p>-->

    <!--                    </div>-->
    <!--                </div>-->
    <!--                <div class="col-lg-6 position-relative">-->
    <!--&lt;!&ndash;                    <img class="img-fluid mac-vector"&ndash;&gt;-->
    <!--&lt;!&ndash;                        src="{{cdnUrl}}/assets/images/Macbook.svg" alt="Mac Vector">&ndash;&gt;-->
    <!--                </div>-->
    <!--            </div>-->
    <!--    </section>-->
    <section class="analytics">
        <div class="container position-relative">
            <div class="row no-gutters justify-content-md-center">
                <div class="col-lg-5">
                    <div class="left-area">
                        <h1>
                            Finding your customers is one thing, understanding them is a game changer.
                        </h1>
                        <p>
                            Tailorie democratizes access to actionable data analytics for brands of all sizes allowing brands to discover and adapt to new market opportunities.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 position-relative">
                    <img alt="Mac Vector" class="img-fluid mac-vector" src="{{cdnUrl}}/assets/images/Dashboard@2x.png">
                </div>
            </div>
        </div>
    </section>
    <section class="tool overflow-hidden" style="background-color: #ffffff;">
        <div class="container">
            <div class="row justify-content-around">
                <!-- Tool Item -->
                <div class="col-lg-3">
                    <div class="pic-item">
                        <img alt="statue" src="{{cdnUrl}}/assets/images/MENTAL HEALTH1.png">
                        <h5>
                            Breathe Easy
                        </h5>
                        <p>
                            We provide an ethical approach<br> to handling consumer data.
                        </p>
                    </div>
                </div>
                <!-- Tool Item -->
                <div class="col-lg-3">
                    <div class="pic-item">
                        <img alt="arrow" src="{{cdnUrl}}/assets/images/KNOWLEDGE1.png">
                        <!--                        <img src={{cdn}}"assets/images/KNOWLEDGE1.png" alt="arrow">-->
                        <h5>
                            Make Informed Decisions
                        </h5>
                        <p>
                            Data-driven solutions that <br>verify your customer personas.
                        </p>
                    </div>
                </div>
                <!-- Tool Item -->
                <div class="col-lg-3">
                    <div class="pic-item">
                        <img alt="chair" src="{{cdnUrl}}/assets/images/LOCAL COMMUNITY.png">
                        <h5>
                            Find Your Perfect Match
                        </h5>
                        <p>We’ll help guide you to the right<br> audience for your brand.</p>
                    </div>
                </div>
            </div>
        </div>

    </section>

    <!-- Testimonial Section -->
    <div class="tesitmonials">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-7">
                    <div class="left-area">
                        <h1>
                            Stories connect people.
                            <br class="add-lg"> Share Yours.
                        </h1>


                        <a *ngIf="!currentUser" class="nav-link join-btn" routerLink="/signup" style="background-color: #006aa5; text-decoration: none;border-radius: 3px">Join
                            Now</a>
                        <a *ngIf="currentUser" class="nav-link join-btn" href="/dashboard" style="background-color: #006aa5; text-decoration: none;border-radius: 3px">Dashboard</a>
                    </div>
                </div>
                <!--                <div class="col-lg-5">-->
                <!--                    <div class="left-area">-->
                <!--                        <h5>-->
                <!--                            Ne disruptive and immersive engagments for mom-and-pop shops to major brands.-->
                <!--                        </h5>-->
                <!--                        <h1>-->
                <!--                            "As a startup, we need tools that help us reach the right customer, be flexible, and-->
                <!--                            scrappy while also being strategic. We love how easy it is to tell people our story-->
                <!--                            that-->
                <!--                            also moves our business froward."-->
                <!--                        </h1>-->
                <!--                        <h5 class="name">-->
                <!--                            brianne oskowski-->
                <!--                        </h5>-->
                <!--                        <h5 class="title">-->
                <!--                            owner of curious-->
                <!--                        </h5>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="col-lg-4 offset-lg-1 d-flex flex-column justify-content-end">-->
                <!--                    <div class="right-area">-->
                <!--                        <img class="img-fluid" src="{{cdnUrl}}/assets/images/lady_picture.png" alt="lady">-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>

    <!-- Clients bar -->
    <!--    <div class="clients" style="background-color: #ffefe8; padding: 40px 0;">-->
    <!--        <div class="container">-->
    <!--            <div class="row justify-content-center">-->
    <!--                <div class="col-lg-9">-->
    <!--                    <img class="img-fluid" src="{{cdnUrl}}/assets/images/logos.png" alt="clients">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
</main>


<!-- Footer -->
<footer>
    <!--    <div class="footer-wrap">-->
    <!--        <div class="container py-5">-->
    <!--            <div class="row justify-content-between">-->
    <!--                <div class="col-lg-2">-->
    <!--                    <a routerLink="/brandsHome" class="navbar-brand">-->
    <!--                        <img src="{{cdnUrl}}/assets/images/Tailorie-logo-dark.png" alt=""> <span>Brands</span>-->
    <!--                    </a>-->
    <!--                </div>-->
    <!--                <div class="col-lg-5 offset-lg-right-1 pt-3">-->
    <!--                    <h5>-->
    <!--                        Join the growing community of hundreds of brands connecting with users like never before.-->
    <!--                    </h5>-->
    <!--                    <div class="link-wrap">-->
    <!--                        <a href="#" class="d-block text-center d-md-inline-block mobapp-btn">-->
    <!--                            <img class="img-fluid" style="width: 180px;" src="{{cdnUrl}}/assets/images/icons/Badge_GooglePlay.png">-->
    <!--                        </a>-->
    <!--                        <a href="#" class="d-block text-center d-md-inline-block ml-md-2 mobapp-btn">-->
    <!--                            <img class="img-fluid" style="width: 180px;" src="{{cdnUrl}}/assets/images/icons/Badge-AppStore.png">-->
    <!--                        </a>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <app-footer-homepage></app-footer-homepage>
</footer>
