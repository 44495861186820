import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BrandStoriesService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        // Private Observable to store and update boolean status of video uploaded status
        this.storyUploaded = new BehaviorSubject(false);
        // Public property of observable type which return storyUpload observable value as a observable
        this.brandStoryUploaded = this.storyUploaded.asObservable();
    }
    // Function to set video uploaded status (boolean) in storyUpload observable
    setBrandStoryUploaded(value) {
        this.storyUploaded.next(value);
    }
    GetAllBrandStoryTypes(filter) {
        return this.http
            .get(`${this.apiUrl}/post_types/brand/?brand_post_category=${filter}`, this.httpOptions)
            .pipe();
    }
    // POST
    CreateBrandStory(brandId, data) {
        return this.http.post(`${this.apiUrl}/brands/${brandId}/posts/`, data).pipe();
    }
    GetAllBrandStory(brandId, filter) {
        return this.http
            .get(`${this.apiUrl}/brands/${brandId}/posts/?brand_post_category=${filter}`, this.httpOptions)
            .pipe();
    }
    GetBrandFilteredStory(brandId, filter) {
        return this.http
            .get(`${this.apiUrl}/brands/${brandId}/posts_filtered/?brand_post_category=${filter}`, this.httpOptions)
            .pipe();
    }
    RemoveBrandStory(Id, brandStoryId) {
        return this.http
            .delete(`${this.apiUrl}/brands/${Id}/posts/${brandStoryId}/`, this.httpOptions)
            .pipe();
    }
    // Update Brand Story
    updateBrandStory(brandId, postId, data) {
        return this.http.patch(`${this.apiUrl}/brands/${brandId}/posts/${postId}/`, data).pipe();
    }
    // Update Video Thumbnail
    updateThumbnail(timeFrame, brandId, postId) {
        const params = new HttpParams({
            fromObject: { frame_number: timeFrame.toString() },
        });
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        };
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${this.apiUrl}/brands/${brandId}/posts/${postId}/select_video_thumbnail/`, params.toString(), httpOptions).pipe();
    }
}
BrandStoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandStoriesService_Factory() { return new BrandStoriesService(i0.ɵɵinject(i1.HttpClient)); }, token: BrandStoriesService, providedIn: "root" });
