import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../shared/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.currentUserValue) {
            if (['company-page', 'login', 'brandaccount'].includes(route.routeConfig.path)) {
                // this.router.navigate(['/dashboard']);
                // TODO: replace redirect to dashboard once production screens are approved
                window.location.href = '/brand-journey';
                return false;
            }
            // logged in so return true
            return true;
        } else {
            if (!['login', 'brandaccount'].includes(route.routeConfig.path)) {
                // not logged in so redirect to login page with the return url
                sessionStorage.setItem('login_page_error', 'not_logged_in');
                sessionStorage.setItem('login_redirect_url', state.url);
                // this.router.navigate(['/login']);
                window.location.href = '/login';
                return false;
            }
            return true;
        }
    }

}
