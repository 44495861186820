import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class InterestsService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    GetAllInterests() {
        return this.http.get(`${this.apiUrl}/interests/`).pipe();
    }
    FetchFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/interest_follower/?date_from=${startDate}&date_to=${endDate}&interest_id=${id}`)
            .pipe();
    }
    FetchRecommendersData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/interest_recommendation/?date_from=${startDate}&date_to=${endDate}&interest_id=${id}`)
            .pipe();
    }
    FetchJourneyData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/interest_viewer/?date_from=${startDate}&date_to=${endDate}&interest_id=${id}`)
            .pipe();
    }
}
InterestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterestsService_Factory() { return new InterestsService(i0.ɵɵinject(i1.HttpClient)); }, token: InterestsService, providedIn: "root" });
