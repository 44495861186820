import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {AuthenticatedUser} from '../../services/auth/authenticateduser';

@Component({
    selector: 'app-header-homepage',
    templateUrl: './header-homepage.component.html',
    styleUrls: ['./header-homepage.component.scss']
})
export class HeaderHomepageComponent implements OnInit {
    cdnUrl = environment.webStaticResources;
    currentUser: AuthenticatedUser;
    hideText = false;

    constructor(private router: Router, private authService: AuthService) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    ngOnInit() {
        this.hideText = (this.router.url).toString() === '/contact-us' ||
            (this.router.url).toString() === '/privacy-policy' || (this.router.url).toString() === '/terms-and-condition';
    }

    openDropdown() {
        document.getElementById('topNav').classList.toggle('show');
    }
}
