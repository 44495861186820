import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {User} from './user';
import {Brand} from '../brand/brand';

@Injectable({
    providedIn: 'root',
})
export class UserService extends ApiService {
    constructor(private http: HttpClient) {
        super();
    }

    // POST
    CreateUser(data): Observable<User> {
        return this.http.post<User>(`${this.apiUrl}/users/`, JSON.stringify(data), this.httpOptions).pipe();
    }

    // POST (New Sign Up)
    RegisterUser(userData): Observable<User> {
        // const formdata: FormData = userData;
        return this.http
            .post<User>(`${this.apiUrl}/users/signup/`, {
                email: userData.email,
                password: userData.password,
                user_type: userData.user_type,
            })
            .pipe();
    }

    // Email Verification
    VerifyEmail(code): Observable<any> {
        return this.http
            .post(
                `${this.apiUrl}/users/email-verification/`,
                {
                    action: 'verify_code',
                    verification_code: code,
                },
                this.httpOptions
            )
            .pipe();
    }

    // Resend Code for Email Verification
    ResendCode(): Observable<any> {
        return this.http
            .post(
                `${this.apiUrl}/users/email-verification/`,
                {
                    action: 'resend_code',
                },
                this.httpOptions
            )
            .pipe();
    }

    GetUserByEmail(email): Observable<User> {
        return this.http
            .get<User>(`${this.apiUrl}/users/email/${email}/`)
            .pipe
            // retryWhen(e => e.pipe(scan((errorCount, error) => {
            //         if (errorCount >= 2) {
            //             throw error;
            //         }
            //         return errorCount + 1;
            //     }, 0),
            //     delay(1000)
            // )),
            ();
    }

    GetUserEmailAvailability(email): Observable<User> {
        return this.http
            .get<User>(`${this.apiUrl}/users/check_availability/email/${email}/`)
            .pipe();
    }

    updateUserProfileById(id, data): Observable<User> {
        return this.http.patch<User>(`${this.apiUrl}/users/${id}/`, data).pipe();
    }

    updateBrandUserProfile(data): Observable<User> {
        return this.http.patch<User>(`${this.apiUrl}/users/profile/`, data).pipe();
    }
}
