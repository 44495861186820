import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountsCountriesService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    getStates(countryName) {
        return this.http.get(`${this.apiUrl}/misc/states/${countryName}/`).pipe(map((resp) => {
            return resp.data.map(state => {
                return { value: state.state_name, label: state.state_name };
            });
        }));
    }
}
AccountsCountriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountsCountriesService_Factory() { return new AccountsCountriesService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountsCountriesService, providedIn: "root" });
