/* tslint:disable:no-string-literal */
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProductCategoryService} from '../shared/services/product_category/product-category.service';
import {ProductCategory} from '../shared/services/product_category/product-category';
import {Brand} from '../shared/services/brand/brand';
import {AuthService} from '../shared/services/auth/auth.service';
import {Vibe} from '../shared/services/vibe/vibe';
import {VibeService} from '../shared/services/vibe/vibe.service';
import {Value} from '../shared/services/value/value';
import {SocialCause} from '../shared/services/social_cause/social_cause';
import {SocialCauseService} from '../shared/services/social_cause/social-cause.service';
import {ValueService} from '../shared/services/value/value.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {environment} from '../../environments/environment';
import {BrandProfileService} from '../shared/services/Brand Profile/brand-profile.service';
import {BrandProfile, NavMenuModel} from '../shared/services/Brand Profile/brand-profile';
import {DatePipe} from '@angular/common';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {ActivatedRoute, Router} from '@angular/router';

import * as moment from 'moment';

@Component({
    selector: 'app-brand-persona',
    templateUrl: './brand-profile.component.html',
    styleUrls: ['./brand-profile.component.scss']
})
export class BrandProfileComponent implements OnInit {
    @ViewChild(DaterangepickerDirective, {static: false}) pickerDirective: DaterangepickerDirective;
    public responsiveWidth: any;
    cdnUrl = environment.webStaticResources;
    currentUser: AuthenticatedUser;
    dateToday: Date = new Date();
    currentBrandInfo: Brand;
    allProductCategories: Array<ProductCategory>;
    allVibes: Array<Vibe>;
    allValues: Array<Value>;
    allSocialCauses: Array<SocialCause>;
    pcBrandProfile: BrandProfile;
    vibBrandProfile: BrandProfile;
    valBrandProfile: BrandProfile;
    scBrandProfile: BrandProfile;
    pcTableData: Array<any> = [];
    vibTableData: Array<any> = [];
    valTableData: Array<any> = [];
    scTableData: Array<any> = [];
    currentFilter = 'CUSTOM';
    currentDate = this.datepipe.transform(this.dateToday, 'yyyy-MM-dd');
    startDate: any;
    endDate: any;
    dateLimit = '7';
    selectedDay: { startDate, endDate };
    selected = {startDate: null, endDate: null};
    monthselected: { startDate, endDate };
    vibeselectedday: { startDate, endDate };
    valueselectedday: { startDate, endDate };
    socialselectedday: { startDate, endDate };
    isShown = false; // hidden by default
    currday = false;
    currweek = true;
    currmonth = false;
    curryear = false;
    currCustom = false;

    vibcurrday = false;
    vibcurrweek = true;
    vibcurrmonth = false;
    vibcurryear = false;
    vibCurrCustom = false;

    valcurrday = false;
    valcurrweek = true;
    valcurrmonth = false;
    valcurryear = false;
    valCurrCustom = false;

    sccurrday = false;
    sccurrweek = true;
    sccurrmonth = false;
    sccurryear = false;
    scCurrCustom = false;

    bottomBorder = false;
    options: any = {
        autoApply: false,
        alwaysShowCalendars: false,
        showCancel: false,
        showClearButton: false,
        linkedCalendars: true,
        singleDatePicker: true,
        showWeekNumbers: false,
        showISOWeekNumbers: false,
        customRangeDirection: false,
        lockStartDate: false,
        closeOnAutoApply: true
    };
    maxDate = moment().max(moment().add(1, 'd'));
    minDate = moment().startOf('year');
    years = [];
    selectedYear: any;
    selectedTab = null;
    public today = new Date();
    public yearLimit: any;
    navMenuBindingList = [];
    selectedMenu: { id: string, label: string, fragment: string };
    searchKey: { id: string; label: string; fragment: string };
    public proSelected: any;
    public vibeSelected: any;
    public valueSelected: any;
    public socailSelected: any;
    socialCausesCount = 0;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private brandprofileService: BrandProfileService,
        private productCategoryService: ProductCategoryService,
        private vibeService: VibeService,
        private valueService: ValueService,
        public datepipe: DatePipe,
        private socialCauseService: SocialCauseService,
        private router: Router
    ) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    initObjects() {
        this.pcBrandProfile = {
            followerMatch: 0,
            followerMatchprev: 0,
            profileViewerMatch: 0,
            profileViewerMatchprev: 0,
            clickThroughMatch: 0,
            clickThroughMatchprev: 0,
            recommendations: 0,
            recommendationsprev: 0,
            breakdown: [],
        };
        this.vibBrandProfile = {
            followerMatch: 0,
            followerMatchprev: 0,
            profileViewerMatch: 0,
            profileViewerMatchprev: 0,
            clickThroughMatch: 0,
            clickThroughMatchprev: 0,
            recommendations: 0,
            recommendationsprev: 0,
            breakdown: [],
        };
        this.valBrandProfile = {
            followerMatch: 0,
            followerMatchprev: 0,
            profileViewerMatch: 0,
            profileViewerMatchprev: 0,
            clickThroughMatch: 0,
            clickThroughMatchprev: 0,
            recommendations: 0,
            recommendationsprev: 0,
            breakdown: [],
        };
        this.scBrandProfile = {
            followerMatch: 0,
            followerMatchprev: 0,
            profileViewerMatch: 0,
            profileViewerMatchprev: 0,
            clickThroughMatch: 0,
            clickThroughMatchprev: 0,
            recommendations: 0,
            recommendationsprev: 0,
            breakdown: [],
        };

        this.pcTableData = [];
        this.vibTableData = [];
        this.valTableData = [];
        this.scTableData = [];
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.responsiveWidth = event.target.innerWidth;
        this.initHorizontalScroll();
    }

    initBrandInfo() {
        this.productCategoryService.GetAllProductCategories().subscribe(
            (productCategories) => {
                this.allProductCategories = productCategories.results;

                this.allProductCategories.forEach((productCategory, index) => {
                    productCategory.selected = false;
                    this.currentBrandInfo.product_categories.forEach((brandProductCategory, index2) => {
                        if (brandProductCategory.id === productCategory.id) {
                            productCategory.selected = true;
                            productCategory.min_price = brandProductCategory.min_price;
                            productCategory.max_price = brandProductCategory.max_price;
                            productCategory.percentage = brandProductCategory.percentage;
                            if (productCategory.is_predefined) {
                                this.proSelected = productCategory.title;
                            }

                        }
                    });
                });

                this.vibeService.GetAllVibes().subscribe((vibes) => {
                    this.allVibes = vibes.results;
                    this.allVibes.forEach((vibe, index) => {
                        vibe.selected = false;
                        this.currentBrandInfo.vibes.forEach((brandVibe, index2) => {
                            if (brandVibe.id === vibe.id) {
                                vibe.selected = true;
                                vibe.percentage = brandVibe.percentage;
                                if (vibe.is_predefined) {
                                    this.vibeSelected = vibe.title;
                                }
                            }
                        });
                    });
                    this.valueService.GetAllValues().subscribe((values) => {
                        this.allValues = values.results;
                        this.allValues.forEach((value, index) => {
                            value.selected = false;
                            this.currentBrandInfo.values.forEach((brandValue, index2) => {
                                if (brandValue.id === value.id) {
                                    value.selected = true;
                                    value.percentage = brandValue.percentage;
                                    if (value.is_predefined) {
                                        this.valueSelected = value.title;
                                    }
                                }
                            });
                        });

                        this.socialCauseService.GetAllSocialCauses().subscribe((socialCauses) => {
                            this.allSocialCauses = socialCauses.results;
                            this.allSocialCauses.forEach((socialCause, index) => {
                                socialCause.selected = false;
                                this.currentBrandInfo.social_causes.forEach((brandSocialCause, index2) => {
                                    if (brandSocialCause.id === socialCause.id) {
                                        socialCause.selected = true;
                                        if (socialCause.selected && socialCause.is_predefined) {
                                            this.socialCausesCount = socialCause.id;
                                        }

                                        if (socialCause.is_predefined) {
                                            this.socailSelected = socialCause.title;
                                        }
                                    }
                                });
                                setTimeout(() => {
                                    this.initHorizontalScroll();
                                }, 100);
                            });
                        });
                    });
                });
            },
            (err) => {
            }
        );
    }

    ngOnInit() {
        this.yearLimit = this.today.getFullYear();
        this.generateYears();
        this.dateSelector();
        if (this.route.snapshot.fragment) {
            this.selectedTab = this.route.snapshot.fragment;
        } else {
            this.selectedTab = 'pc';
        }
        this.initObjects();
        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;
        this.currentBrandInfo = new Brand(
            currentBrand.id,
            currentBrand.business_name,
            currentBrand.description,
            currentBrand.tags,
            currentBrand.brandsignupchecklist,
            currentBrand.brandcustomerprofile,
            currentBrand.product_categories,
            currentBrand.vibes,
            currentBrand.values,
            currentBrand.social_causes,
            currentBrand.sales_channel,
            currentBrand.website,
            currentBrand.headquarter_location,
            currentBrand.tax_id,
            currentBrand.years_in_business,
            currentBrand.number_of_locations,
            currentBrand.business_percent_online,
            currentBrand.business_percent_instore
        );
        this.currentBrandInfo.initBrandInfo();
        this.initBrandInfo();
        this.initProductCategoriesStats();
        this.initBrandVibesStats();
        this.initBrandValuesStats();
        this.initBrandSocialCausesStats();
        this.navMenuBindingList = new NavMenuModel().getNavMenu();
        const selectedData = this.navMenuBindingList[this.navMenuBindingList.map((item) => {
            return item.fragment;
        }).indexOf(this.selectedTab)];
        this.selectedMenu = selectedData ? selectedData.label : null;
        const width = this.responsiveWidth < 768;
        setTimeout(() => {
            this.initHorizontalScroll();
        }, 500);
    }

    generateYears() {
        const count = this.yearLimit - 2020;
        for (let i = 0; i <= count; i++) {
            this.years.push(2020 + i);
        }
    }

    dateSelector() {
        if (this.brandprofileService.selected.startDate === null && this.brandprofileService.currentFilter === 'CUSTOM') {
            this.selected = {startDate: null, endDate: null};
            const weekdate = new Date();
            const weekend = new Date(weekdate);
            weekend.setDate(weekdate.getDate());
            const weekstart = new Date(weekdate);
            weekstart.setDate(weekdate.getDate() - 6);

            const createdDate = this.datepipe.transform(this.currentUser.user.branduserprofile.brand.created_at, 'yyyy-MM-dd');
            this.startDate = this.datepipe.transform(createdDate, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
        } else {
            this.startDate = this.brandprofileService.selected.startDate;
            this.endDate = this.brandprofileService.selected.endDate;
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
        }
        switch (this.brandprofileService.currentFilter) {
            case 'WEEK':
                this.ctShoWeek();
                this.vibShoWeek();
                this.valShoWeek();
                this.scShowWeek();
                this.setActive('weekBtn', 'pcfbtn');
                this.setActive('vibeweekBtn', 'vfbtn');
                this.setActive('valueweekBtn', 'vafbtn');
                this.setActive('scweekBtn', 'dcfbtn');
                break;

            case 'DAY':
                this.ctShowDay();
                this.vibShowDay();
                this.valShowDay();
                this.scShowDay();
                this.setActive('dayBtn', 'pcfbtn');
                this.setActive('vibedayBtn', 'vfbtn');
                this.setActive('valuedayBtn', 'vafbtn');
                this.setActive('scdayBtn', 'dcfbtn');
                break;
            case 'MONTH':
                this.ctShowMonth();
                this.vibShowMonth();
                this.valShowMonth();
                this.scShowMonth();
                this.setActive('monthBtn', 'pcfbtn');
                this.setActive('vibmonthBtn', 'vfbtn');
                this.setActive('valuemonthBtn', 'vafbtn');
                this.setActive('scmonthBtn', 'dcfbtn');
                break;
            case 'CUSTOM':
                this.ctShowCustom();
                this.setActive('customBtn', 'pcfbtn');
                this.setActive('vibCustomBtn', 'vfbtn');
                this.setActive('valCustomBtn', 'vafbtn');
                this.setActive('scCustomBtn', 'dcfbtn');
                break;

            default:
                break;
        }

    }

    loadMenu(selectedItem) {
        this.router.navigate([], {fragment: selectedItem.fragment});
        this.showTabContentDropdown(selectedItem.id, selectedItem.fragment);
    }

    selectedChange(event) {
        if (event.startDate === null) {
            return;
        }
        if (this.currentFilter === 'WEEK') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.productLoadWeekDatePicker();
            this.vibeLoadWeekDatePicker();
            this.valueLoadWeekDatePicker();
            this.socialLoadWeekDatePicker();
        }
        if (this.currentFilter === 'DAY') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.productLoadDayDatePicker();
            this.vibeLoadDayDatePicker();
            this.valueLoadDayDatePicker();
            this.socialLoadDayDatePicker();
        }
        if (this.currentFilter === 'MONTH') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.productLoadMonthDatePicker();
            this.vibeLoadMonthDatePicker();
            this.valueLoadMonthDatePicker();
            this.socialLoadMonthDatePicker();

        }
        if (this.currentFilter === 'MONTH') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.productLoadMonthDatePicker();
            this.vibeLoadMonthDatePicker();
            this.valueLoadMonthDatePicker();
            this.socialLoadMonthDatePicker();

        }
        if (this.currentFilter === 'CUSTOM') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.productLoadMonthDatePicker();
            this.vibeLoadMonthDatePicker();
            this.valueLoadMonthDatePicker();
            this.socialLoadMonthDatePicker();
        }

    }

    selectYear(year, category) {
        this.brandprofileService.currentFilter = this.currentFilter;
        this.startDate = year + '-01-01';
        this.endDate = year + '-12-31';
        switch (category) {
            case 'pc':
                this.productLoadYearDatePicker();
                break;
            case 'vib':
                this.valueLoadYearDatePicker();
                break;
            case 'val':
                this.vibeLoadYearDatePicker();
                break;
            case 'sc':
                this.socialLoadYearDatePicker();
                break;
            default:
                return;
        }

    }

    getSelectedCount(arr: Array<object>) {
        let count = 0;
        arr.forEach((a) => {
            // @ts-ignore
            if (a.selected) {
                count++;
            }
        });
        return count;
    }

    // <--------------------------------------------------Product stats--------------------------------->
    initProductCategoriesStats() {
        this.initObjects();
        this.brandprofileService.GetBrandProductCategoryFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                this.pcBrandProfile.followerMatch = +res[`match_percentage`];
                this.pcBrandProfile.followerMatchprev = +res[`match_percentage_previous`];
                this.brandprofileService
                    .GetBrandProductCategoryProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vm) => {
                        this.pcBrandProfile.profileViewerMatch = +vm['match_percentage'];
                        this.pcBrandProfile.profileViewerMatchprev = +vm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandProductCategoryClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((ctm) => {
                                this.pcBrandProfile.clickThroughMatch = +ctm['match_percentage'];
                                this.pcBrandProfile.clickThroughMatchprev = +ctm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandProductCategoryRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((rm) => {
                                        this.pcBrandProfile.recommendations = +rm['match_percentage'];
                                        this.pcBrandProfile.recommendationsprev = +rm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandProductCategoryBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((bm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.pcBrandProfile.breakdown = bm['response'] as Array<any>;
                                                this.pcTableData = [];
                                                this.allProductCategories.forEach((pc) => {
                                                    this.pcBrandProfile.breakdown.forEach((pcb) => {
                                                        if (pcb.category_id === pc.id) {
                                                            const newObj = {
                                                                image: pc.image,
                                                                title: pc.title,
                                                                allocation: pc.percentage,
                                                                avgAge: pcb.avg_age,
                                                                followers: pcb.total_followers,
                                                                perOfFollwers: pcb.percentage_followers,
                                                                viewers: pcb.viewer,
                                                                perOfViewers: pcb.viewer_percentage,
                                                                per: pc.is_predefined,
                                                                prosel: pc.selected
                                                            };
                                                            this.pcTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    // <--------------------------------------------------Vibes stats-------------------------------->
    initBrandVibesStats() {
        this.initObjects();
        this.brandprofileService.GetBrandVibeFollowerMatch(this.startDate, this.endDate).subscribe((res) => {
            // tslint:disable-next-line:no-string-literal
            this.vibBrandProfile.followerMatch = +res['match_percentage'];
            this.vibBrandProfile.followerMatchprev = +res['match_percentage_previous'];

            this.brandprofileService
                .GetBrandVibeProfileViewerMatch(this.startDate, this.endDate)
                .subscribe((vpm) => {
                    this.vibBrandProfile.profileViewerMatch = +vpm['match_percentage'];
                    this.vibBrandProfile.profileViewerMatchprev = +vpm['match_percentage_previous'];

                    this.brandprofileService
                        .GetBrandVibeClickThroughMatch(this.startDate, this.endDate)
                        .subscribe((vcm) => {
                            this.vibBrandProfile.clickThroughMatch = +vcm['match_percentage'];
                            this.vibBrandProfile.clickThroughMatchprev = +vcm['match_percentage_previous'];

                            this.brandprofileService
                                .GetBrandVibeRecommendationsMatch(this.startDate, this.endDate)
                                .subscribe((vrm) => {
                                    this.vibBrandProfile.recommendations = +vrm['match_percentage'];
                                    this.vibBrandProfile.recommendationsprev = +vrm['match_percentage_previous'];

                                    this.brandprofileService
                                        .GetBrandVibeBreakdownMatch(this.startDate, this.endDate)
                                        .subscribe((vbm) => {
                                            // tslint:disable-next-line:no-string-literal
                                            this.vibBrandProfile.breakdown = vbm['response'] as Array<any>;
                                            this.vibTableData = [];

                                            this.allVibes.forEach((vibe) => {
                                                this.vibBrandProfile.breakdown.forEach((vib) => {
                                                    if (vib.vibe_id === vibe.id) {
                                                        const newObj = {
                                                            image: vibe.image,
                                                            title: vibe.title,
                                                            allocation: vibe.percentage,
                                                            avgAge: vib.avg_age,
                                                            followers: vib.total_followers,
                                                            perOfFollwers: vib.percentage_followers,
                                                            viewers: vib.viewer,
                                                            perOfViewers: vib.viewer_percentage,
                                                            vibper: vibe.is_predefined,
                                                            vibesel: vibe.selected
                                                        };
                                                        this.vibTableData.push(newObj);
                                                    }
                                                });
                                            });
                                        });
                                });
                        });
                });
        });
    }

    // <--------------------------------------------------values stats------------------------------>
    initBrandValuesStats() {
        this.initObjects();
        this.brandprofileService.GetBrandValueFollowerMatch(this.startDate, this.endDate).subscribe((res) => {
            // tslint:disable-next-line:no-string-literal
            this.valBrandProfile.followerMatch = +res['match_percentage'];
            this.valBrandProfile.followerMatchprev = +res['match_percentage_previous'];

            this.brandprofileService
                .GetBrandValueProfileViewerMatch(this.startDate, this.endDate)
                .subscribe((vlpm) => {
                    this.valBrandProfile.profileViewerMatch = +vlpm['match_percentage'];
                    this.valBrandProfile.profileViewerMatchprev = +vlpm['match_percentage_previous'];

                    this.brandprofileService
                        .GetBrandValueClickThroughMatch(this.startDate, this.endDate)
                        .subscribe((vlcm) => {
                            this.valBrandProfile.clickThroughMatch = +vlcm['match_percentage'];
                            this.valBrandProfile.clickThroughMatchprev = +vlcm['match_percentage_previous'];

                            this.brandprofileService
                                .GetBrandValueRecommendationsMatch(this.startDate, this.endDate)
                                .subscribe((vlrm) => {
                                    this.valBrandProfile.recommendations = +vlrm['match_percentage'];
                                    this.valBrandProfile.recommendationsprev = +vlrm['match_percentage_previous'];

                                    this.brandprofileService
                                        .GetBrandValueBreakdownMatch(this.startDate, this.endDate)
                                        .subscribe((vlbm) => {
                                            // tslint:disable-next-line:no-string-literal
                                            this.valBrandProfile.breakdown = vlbm['response'] as Array<any>;
                                            this.valTableData = [];

                                            this.allValues.forEach((value) => {
                                                this.valBrandProfile.breakdown.forEach((val) => {
                                                    if (val.value_id === value.id) {
                                                        const newObj = {
                                                            image: value.image,
                                                            title: value.title,
                                                            allocation: value.percentage,
                                                            avgAge: val.avg_age,
                                                            followers: val.total_followers,
                                                            perOfFollwers: val.percentage_followers,
                                                            viewers: val.viewer,
                                                            perOfViewers: val.viewer_percentage,
                                                            valper: value.is_predefined,
                                                            valuesel: value.selected
                                                        };
                                                        this.valTableData.push(newObj);
                                                    }
                                                });
                                            });
                                        });
                                });
                        });
                });
        });
    }

    // <--------------------------------------------------Scoial Causes stats----------------------->
    initBrandSocialCausesStats() {
        this.initObjects();
        this.brandprofileService
            .GetBrandSocialCausesFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.scBrandProfile.followerMatch = +res['match_percentage'];
                this.scBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandSocialCausesProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((scpm) => {
                        this.scBrandProfile.profileViewerMatch = +scpm['match_percentage'];
                        this.scBrandProfile.profileViewerMatchprev = +scpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandSocialCausesClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((sccm) => {
                                this.scBrandProfile.clickThroughMatch = +sccm['match_percentage'];
                                this.scBrandProfile.clickThroughMatchprev = +sccm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandSocialCausesRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((scrm) => {
                                        this.scBrandProfile.recommendations = +scrm['match_percentage'];
                                        this.scBrandProfile.recommendationsprev = +scrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandSocialCausesBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((scbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.scBrandProfile.breakdown = scbm['response'] as Array<any>;
                                                this.scTableData = [];
                                                this.allSocialCauses.forEach((socialcauses) => {
                                                    this.scBrandProfile.breakdown.forEach((sc) => {
                                                        if (sc.social_cause_id === socialcauses.id) {
                                                            const newObj = {
                                                                image: socialcauses.image,
                                                                title: socialcauses.title,
                                                                avgAge: sc.avg_age,
                                                                followers: sc.total_followers,
                                                                perOfFollwers: sc.percentage_followers,
                                                                viewers: sc.viewer,
                                                                perOfViewers: sc.viewer_percentage,
                                                                scper: socialcauses.is_predefined,
                                                                scsel: socialcauses.selected
                                                            };
                                                            this.scTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    // <-------------------------------------------------- calender product stats----------------------->
    productLoadDayDatePicker() {
        this.brandprofileService
            .GetBrandProductCategoryFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.pcBrandProfile.followerMatch = +res['match_percentage'];
                this.pcBrandProfile.followerMatchprev = +res['match_percentage_previous'];
                this.brandprofileService
                    .GetBrandProductCategoryProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vm) => {
                        this.pcBrandProfile.profileViewerMatch = +vm['match_percentage'];
                        this.pcBrandProfile.profileViewerMatchprev = +vm['match_percentage_previous'];
                        this.brandprofileService
                            .GetBrandProductCategoryClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((ctm) => {
                                this.pcBrandProfile.clickThroughMatch = +ctm['match_percentage'];
                                this.pcBrandProfile.clickThroughMatchprev = +ctm['match_percentage_previous'];
                                this.brandprofileService
                                    .GetBrandProductCategoryRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((rm) => {
                                        this.pcBrandProfile.recommendations = +rm['match_percentage'];
                                        this.pcBrandProfile.recommendationsprev = +rm['match_percentage_previous'];
                                        this.brandprofileService
                                            .GetBrandProductCategoryBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((bm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.pcBrandProfile.breakdown = bm['response'] as Array<any>;
                                                this.pcTableData = [];
                                                this.allProductCategories.forEach((pc) => {
                                                    this.pcBrandProfile.breakdown.forEach((pcb) => {
                                                        if (pcb.category_id === pc.id) {
                                                            const newObj = {
                                                                image: pc.image,
                                                                title: pc.title,
                                                                allocation: pc.percentage,
                                                                avgAge: pcb.avg_age,
                                                                followers: pcb.total_followers,
                                                                perOfFollwers: pcb.percentage_followers,
                                                                viewers: pcb.viewer,
                                                                perOfViewers: pcb.viewer_percentage,
                                                                per: pc.is_predefined,
                                                                prosel: pc.selected

                                                            };
                                                            this.pcTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    productLoadWeekDatePicker() {

        this.brandprofileService
            .GetBrandProductCategoryFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.pcBrandProfile.followerMatch = +res['match_percentage'];
                this.pcBrandProfile.followerMatchprev = +res['match_percentage_previous'];
                this.brandprofileService
                    .GetBrandProductCategoryProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vm) => {
                        this.pcBrandProfile.profileViewerMatch = +vm['match_percentage'];
                        this.pcBrandProfile.profileViewerMatchprev = +vm['match_percentage_previous'];
                        this.brandprofileService
                            .GetBrandProductCategoryClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((ctm) => {
                                this.pcBrandProfile.clickThroughMatch = +ctm['match_percentage'];
                                this.pcBrandProfile.clickThroughMatchprev = +ctm['match_percentage_previous'];
                                this.brandprofileService
                                    .GetBrandProductCategoryRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((rm) => {
                                        this.pcBrandProfile.recommendations = +rm['match_percentage'];
                                        this.pcBrandProfile.recommendationsprev = +rm['match_percentage_previous'];
                                        this.brandprofileService
                                            .GetBrandProductCategoryBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((bm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.pcBrandProfile.breakdown = bm['response'] as Array<any>;
                                                this.pcTableData = [];
                                                this.allProductCategories.forEach((pc) => {
                                                    this.pcBrandProfile.breakdown.forEach((pcb) => {
                                                        if (pcb.category_id === pc.id) {
                                                            const newObj = {
                                                                image: pc.image,
                                                                title: pc.title,
                                                                allocation: pc.percentage,
                                                                avgAge: pcb.avg_age,
                                                                followers: pcb.total_followers,
                                                                perOfFollwers: pcb.percentage_followers,
                                                                viewers: pcb.viewer,
                                                                perOfViewers: pcb.viewer_percentage,
                                                                per: pc.is_predefined,
                                                                prosel: pc.selected
                                                            };
                                                            this.pcTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    productLoadMonthDatePicker() {

        this.brandprofileService
            .GetBrandProductCategoryFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.pcBrandProfile.followerMatch = +res['match_percentage'];
                this.pcBrandProfile.followerMatchprev = +res['match_percentage_previous'];
                this.brandprofileService
                    .GetBrandProductCategoryProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vm) => {
                        this.pcBrandProfile.profileViewerMatch = +vm['match_percentage'];
                        this.pcBrandProfile.profileViewerMatchprev = +vm['match_percentage_previous'];
                        this.brandprofileService
                            .GetBrandProductCategoryClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((ctm) => {
                                this.pcBrandProfile.clickThroughMatch = +ctm['match_percentage'];
                                this.pcBrandProfile.clickThroughMatchprev = +ctm['match_percentage_previous'];
                                this.brandprofileService
                                    .GetBrandProductCategoryRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((rm) => {
                                        this.pcBrandProfile.recommendations = +rm['match_percentage'];
                                        this.pcBrandProfile.recommendationsprev = +rm['match_percentage_previous'];
                                        this.brandprofileService
                                            .GetBrandProductCategoryBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((bm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.pcBrandProfile.breakdown = bm['response'] as Array<any>;
                                                this.pcTableData = [];
                                                this.allProductCategories.forEach((pc) => {
                                                    this.pcBrandProfile.breakdown.forEach((pcb) => {
                                                        if (pcb.category_id === pc.id) {
                                                            const newObj = {
                                                                image: pc.image,
                                                                title: pc.title,
                                                                allocation: pc.percentage,
                                                                avgAge: pcb.avg_age,
                                                                followers: pcb.total_followers,
                                                                perOfFollwers: pcb.percentage_followers,
                                                                viewers: pcb.viewer,
                                                                perOfViewers: pcb.viewer_percentage,
                                                                per: pc.is_predefined,
                                                                prosel: pc.selected
                                                            };
                                                            this.pcTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    productLoadYearDatePicker() {
        this.brandprofileService
            .GetBrandProductCategoryFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.pcBrandProfile.followerMatch = +res['match_percentage'];
                this.pcBrandProfile.followerMatchprev = +res['match_percentage_previous'];
                this.brandprofileService
                    .GetBrandProductCategoryProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vm) => {
                        this.pcBrandProfile.profileViewerMatch = +vm['match_percentage'];
                        this.pcBrandProfile.profileViewerMatchprev = +vm['match_percentage_previous'];
                        this.brandprofileService
                            .GetBrandProductCategoryClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((ctm) => {
                                this.pcBrandProfile.clickThroughMatch = +ctm['match_percentage'];
                                this.pcBrandProfile.clickThroughMatchprev = +ctm['match_percentage_previous'];
                                this.brandprofileService
                                    .GetBrandProductCategoryRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((rm) => {
                                        this.pcBrandProfile.recommendations = +rm['match_percentage'];
                                        this.pcBrandProfile.recommendationsprev = +rm['match_percentage_previous'];
                                        this.brandprofileService
                                            .GetBrandProductCategoryBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((bm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.pcBrandProfile.breakdown = bm['response'] as Array<any>;
                                                this.pcTableData = [];
                                                this.allProductCategories.forEach((pc) => {
                                                    this.pcBrandProfile.breakdown.forEach((pcb) => {
                                                        if (pcb.category_id === pc.id) {
                                                            const newObj = {
                                                                image: pc.image,
                                                                title: pc.title,
                                                                allocation: pc.percentage,
                                                                avgAge: pcb.avg_age,
                                                                followers: pcb.total_followers,
                                                                perOfFollwers: pcb.percentage_followers,
                                                                viewers: pcb.viewer,
                                                                perOfViewers: pcb.viewer_percentage,
                                                                per: pc.is_predefined,
                                                                prosel: pc.selected
                                                            };
                                                            this.pcTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    // <-------------------------------------------------- calender Vibe stats----------------------->
    vibeLoadDayDatePicker() {

        this.brandprofileService
            .GetBrandVibeFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.vibBrandProfile.followerMatch = +res['match_percentage'];
                this.vibBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandVibeProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vpm) => {
                        this.vibBrandProfile.profileViewerMatch = +vpm['match_percentage'];
                        this.vibBrandProfile.profileViewerMatchprev = +vpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandVibeClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vcm) => {
                                this.vibBrandProfile.clickThroughMatch = +vcm['match_percentage'];
                                this.vibBrandProfile.clickThroughMatchprev = +vcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandVibeRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vrm) => {
                                        this.vibBrandProfile.recommendations = +vrm['match_percentage'];
                                        this.vibBrandProfile.recommendationsprev = +vrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandVibeBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.vibBrandProfile.breakdown = vbm['response'] as Array<any>;
                                                this.vibTableData = [];

                                                this.allVibes.forEach((vibe) => {
                                                    this.vibBrandProfile.breakdown.forEach((vib) => {
                                                        if (vib.vibe_id === vibe.id) {
                                                            const newObj = {
                                                                image: vibe.image,
                                                                title: vibe.title,
                                                                allocation: vibe.percentage,
                                                                avgAge: vib.avg_age,
                                                                followers: vib.total_followers,
                                                                perOfFollwers: vib.percentage_followers,
                                                                viewers: vib.viewer,
                                                                perOfViewers: vib.viewer_percentage,
                                                                vibper: vibe.is_predefined,
                                                                vibesel: vibe.selected
                                                            };
                                                            this.vibTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    vibeLoadWeekDatePicker() {

        this.brandprofileService
            .GetBrandVibeFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.vibBrandProfile.followerMatch = +res['match_percentage'];
                this.vibBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandVibeProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vpm) => {
                        this.vibBrandProfile.profileViewerMatch = +vpm['match_percentage'];
                        this.vibBrandProfile.profileViewerMatchprev = +vpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandVibeClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vcm) => {
                                this.vibBrandProfile.clickThroughMatch = +vcm['match_percentage'];
                                this.vibBrandProfile.clickThroughMatchprev = +vcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandVibeRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vrm) => {
                                        this.vibBrandProfile.recommendations = +vrm['match_percentage'];
                                        this.vibBrandProfile.recommendationsprev = +vrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandVibeBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.vibBrandProfile.breakdown = vbm['response'] as Array<any>;
                                                this.vibTableData = [];

                                                this.allVibes.forEach((vibe) => {
                                                    this.vibBrandProfile.breakdown.forEach((vib) => {
                                                        if (vib.vibe_id === vibe.id) {
                                                            const newObj = {
                                                                image: vibe.image,
                                                                title: vibe.title,
                                                                allocation: vibe.percentage,
                                                                avgAge: vib.avg_age,
                                                                followers: vib.total_followers,
                                                                perOfFollwers: vib.percentage_followers,
                                                                viewers: vib.viewer,
                                                                perOfViewers: vib.viewer_percentage,
                                                                vibper: vibe.is_predefined,
                                                                vibesel: vibe.selected
                                                            };
                                                            this.vibTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    vibeLoadMonthDatePicker() {

        this.brandprofileService
            .GetBrandVibeFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.vibBrandProfile.followerMatch = +res['match_percentage'];
                this.vibBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandVibeProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vpm) => {
                        this.vibBrandProfile.profileViewerMatch = +vpm['match_percentage'];
                        this.vibBrandProfile.profileViewerMatchprev = +vpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandVibeClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vcm) => {
                                this.vibBrandProfile.clickThroughMatch = +vcm['match_percentage'];
                                this.vibBrandProfile.clickThroughMatchprev = +vcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandVibeRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vrm) => {
                                        this.vibBrandProfile.recommendations = +vrm['match_percentage'];
                                        this.vibBrandProfile.recommendationsprev = +vrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandVibeBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.vibBrandProfile.breakdown = vbm['response'] as Array<any>;
                                                this.vibTableData = [];

                                                this.allVibes.forEach((vibe) => {
                                                    this.vibBrandProfile.breakdown.forEach((vib) => {
                                                        if (vib.vibe_id === vibe.id) {
                                                            const newObj = {
                                                                image: vibe.image,
                                                                title: vibe.title,
                                                                allocation: vibe.percentage,
                                                                avgAge: vib.avg_age,
                                                                followers: vib.total_followers,
                                                                perOfFollwers: vib.percentage_followers,
                                                                viewers: vib.viewer,
                                                                perOfViewers: vib.viewer_percentage,
                                                                vibper: vibe.is_predefined,
                                                                vibesel: vibe.selected
                                                            };
                                                            this.vibTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    vibeLoadYearDatePicker() {

        this.brandprofileService
            .GetBrandVibeFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.vibBrandProfile.followerMatch = +res['match_percentage'];
                this.vibBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandVibeProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vpm) => {
                        this.vibBrandProfile.profileViewerMatch = +vpm['match_percentage'];
                        this.vibBrandProfile.profileViewerMatchprev = +vpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandVibeClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vcm) => {
                                this.vibBrandProfile.clickThroughMatch = +vcm['match_percentage'];
                                this.vibBrandProfile.clickThroughMatchprev = +vcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandVibeRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vrm) => {
                                        this.vibBrandProfile.recommendations = +vrm['match_percentage'];
                                        this.vibBrandProfile.recommendationsprev = +vrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandVibeBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.vibBrandProfile.breakdown = vbm['response'] as Array<any>;
                                                this.vibTableData = [];

                                                this.allVibes.forEach((vibe) => {
                                                    this.vibBrandProfile.breakdown.forEach((vib) => {
                                                        if (vib.vibe_id === vibe.id) {
                                                            const newObj = {
                                                                image: vibe.image,
                                                                title: vibe.title,
                                                                allocation: vibe.percentage,
                                                                avgAge: vib.avg_age,
                                                                followers: vib.total_followers,
                                                                perOfFollwers: vib.percentage_followers,
                                                                viewers: vib.viewer,
                                                                perOfViewers: vib.viewer_percentage,
                                                                vibper: vibe.is_predefined,
                                                                vibesel: vibe.selected
                                                            };
                                                            this.vibTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    // <-------------------------------------------------- calender Value stats----------------------->
    valueLoadDayDatePicker() {

        this.brandprofileService
            .GetBrandValueFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.valBrandProfile.followerMatch = +res['match_percentage'];
                this.valBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandValueProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vlpm) => {
                        this.valBrandProfile.profileViewerMatch = +vlpm['match_percentage'];
                        this.valBrandProfile.profileViewerMatchprev = +vlpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandValueClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vlcm) => {
                                this.valBrandProfile.clickThroughMatch = +vlcm['match_percentage'];
                                this.valBrandProfile.clickThroughMatchprev = +vlcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandValueRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vlrm) => {
                                        this.valBrandProfile.recommendations = +vlrm['match_percentage'];
                                        this.valBrandProfile.recommendationsprev = +vlrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandValueBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vlbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.valBrandProfile.breakdown = vlbm['response'] as Array<any>;
                                                this.valTableData = [];

                                                this.allValues.forEach((value) => {
                                                    this.valBrandProfile.breakdown.forEach((val) => {
                                                        if (val.value_id === value.id) {
                                                            const newObj = {
                                                                image: value.image,
                                                                title: value.title,
                                                                allocation: value.percentage,
                                                                avgAge: val.avg_age,
                                                                followers: val.total_followers,
                                                                perOfFollwers: val.percentage_followers,
                                                                viewers: val.viewer,
                                                                perOfViewers: val.viewer_percentage,
                                                                valper: value.is_predefined,
                                                                valuesel: value.selected
                                                            };
                                                            this.valTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    valueLoadWeekDatePicker() {

        this.brandprofileService
            .GetBrandValueFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.valBrandProfile.followerMatch = +res['match_percentage'];
                this.valBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandValueProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vlpm) => {
                        this.valBrandProfile.profileViewerMatch = +vlpm['match_percentage'];
                        this.valBrandProfile.profileViewerMatchprev = +vlpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandValueClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vlcm) => {
                                this.valBrandProfile.clickThroughMatch = +vlcm['match_percentage'];
                                this.valBrandProfile.clickThroughMatchprev = +vlcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandValueRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vlrm) => {
                                        this.valBrandProfile.recommendations = +vlrm['match_percentage'];
                                        this.valBrandProfile.recommendationsprev = +vlrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandValueBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vlbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.valBrandProfile.breakdown = vlbm['response'] as Array<any>;
                                                this.valTableData = [];

                                                this.allValues.forEach((value) => {
                                                    this.valBrandProfile.breakdown.forEach((val) => {
                                                        if (val.value_id === value.id) {
                                                            const newObj = {
                                                                image: value.image,
                                                                title: value.title,
                                                                allocation: value.percentage,
                                                                avgAge: val.avg_age,
                                                                followers: val.total_followers,
                                                                perOfFollwers: val.percentage_followers,
                                                                viewers: val.viewer,
                                                                perOfViewers: val.viewer_percentage,
                                                                valper: value.is_predefined,
                                                                valuesel: value.selected
                                                            };
                                                            this.valTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    valueLoadMonthDatePicker() {

        this.brandprofileService
            .GetBrandValueFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.valBrandProfile.followerMatch = +res['match_percentage'];
                this.valBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandValueProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vlpm) => {
                        this.valBrandProfile.profileViewerMatch = +vlpm['match_percentage'];
                        this.valBrandProfile.profileViewerMatchprev = +vlpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandValueClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vlcm) => {
                                this.valBrandProfile.clickThroughMatch = +vlcm['match_percentage'];
                                this.valBrandProfile.clickThroughMatchprev = +vlcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandValueRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vlrm) => {
                                        this.valBrandProfile.recommendations = +vlrm['match_percentage'];
                                        this.valBrandProfile.recommendationsprev = +vlrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandValueBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vlbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.valBrandProfile.breakdown = vlbm['response'] as Array<any>;
                                                this.valTableData = [];

                                                this.allValues.forEach((value) => {
                                                    this.valBrandProfile.breakdown.forEach((val) => {
                                                        if (val.value_id === value.id) {
                                                            const newObj = {
                                                                image: value.image,
                                                                title: value.title,
                                                                allocation: value.percentage,
                                                                avgAge: val.avg_age,
                                                                followers: val.total_followers,
                                                                perOfFollwers: val.percentage_followers,
                                                                viewers: val.viewer,
                                                                perOfViewers: val.viewer_percentage,
                                                                valper: value.is_predefined,
                                                                valuesel: value.selected
                                                            };
                                                            this.valTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    valueLoadYearDatePicker() {

        this.brandprofileService
            .GetBrandValueFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.valBrandProfile.followerMatch = +res['match_percentage'];
                this.valBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandValueProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((vlpm) => {
                        this.valBrandProfile.profileViewerMatch = +vlpm['match_percentage'];
                        this.valBrandProfile.profileViewerMatchprev = +vlpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandValueClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((vlcm) => {
                                this.valBrandProfile.clickThroughMatch = +vlcm['match_percentage'];
                                this.valBrandProfile.clickThroughMatchprev = +vlcm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandValueRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((vlrm) => {
                                        this.valBrandProfile.recommendations = +vlrm['match_percentage'];
                                        this.valBrandProfile.recommendationsprev = +vlrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandValueBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((vlbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.valBrandProfile.breakdown = vlbm['response'] as Array<any>;
                                                this.valTableData = [];

                                                this.allValues.forEach((value) => {
                                                    this.valBrandProfile.breakdown.forEach((val) => {
                                                        if (val.value_id === value.id) {
                                                            const newObj = {
                                                                image: value.image,
                                                                title: value.title,
                                                                allocation: value.percentage,
                                                                avgAge: val.avg_age,
                                                                followers: val.total_followers,
                                                                perOfFollwers: val.percentage_followers,
                                                                viewers: val.viewer,
                                                                perOfViewers: val.viewer_percentage,
                                                                valper: value.is_predefined,
                                                                valuesel: value.selected
                                                            };
                                                            this.valTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    // <-------------------------------------------------- calender Social Causes stats----------------------->

    socialLoadDayDatePicker() {

        this.brandprofileService
            .GetBrandSocialCausesFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.scBrandProfile.followerMatch = +res['match_percentage'];
                this.scBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandSocialCausesProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((scpm) => {
                        this.scBrandProfile.profileViewerMatch = +scpm['match_percentage'];
                        this.scBrandProfile.profileViewerMatchprev = +scpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandSocialCausesClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((sccm) => {
                                this.scBrandProfile.clickThroughMatch = +sccm['match_percentage'];
                                this.scBrandProfile.clickThroughMatchprev = +sccm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandSocialCausesRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((scrm) => {
                                        this.scBrandProfile.recommendations = +scrm['match_percentage'];
                                        this.scBrandProfile.recommendationsprev = +scrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandSocialCausesBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((scbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.scBrandProfile.breakdown = scbm['response'] as Array<any>;
                                                this.scTableData = [];
                                                this.allSocialCauses.forEach((socialcauses) => {
                                                    this.scBrandProfile.breakdown.forEach((sc) => {
                                                        if (sc.social_cause_id === socialcauses.id) {
                                                            const newObj = {
                                                                image: socialcauses.image,
                                                                title: socialcauses.title,
                                                                avgAge: sc.avg_age,
                                                                followers: sc.total_followers,
                                                                perOfFollwers: sc.percentage_followers,
                                                                viewers: sc.viewer,
                                                                perOfViewers: sc.viewer_percentage,
                                                                scper: socialcauses.is_predefined,
                                                                scsel: socialcauses.selected
                                                            };
                                                            this.scTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    socialLoadWeekDatePicker() {

        this.brandprofileService
            .GetBrandSocialCausesFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.scBrandProfile.followerMatch = +res['match_percentage'];
                this.scBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandSocialCausesProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((scpm) => {
                        this.scBrandProfile.profileViewerMatch = +scpm['match_percentage'];
                        this.scBrandProfile.profileViewerMatchprev = +scpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandSocialCausesClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((sccm) => {
                                this.scBrandProfile.clickThroughMatch = +sccm['match_percentage'];
                                this.scBrandProfile.clickThroughMatchprev = +sccm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandSocialCausesRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((scrm) => {
                                        this.scBrandProfile.recommendations = +scrm['match_percentage'];
                                        this.scBrandProfile.recommendationsprev = +scrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandSocialCausesBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((scbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.scBrandProfile.breakdown = scbm['response'] as Array<any>;
                                                this.scTableData = [];
                                                this.allSocialCauses.forEach((socialcauses) => {
                                                    this.scBrandProfile.breakdown.forEach((sc) => {
                                                        if (sc.social_cause_id === socialcauses.id) {
                                                            const newObj = {
                                                                image: socialcauses.image,
                                                                title: socialcauses.title,
                                                                avgAge: sc.avg_age,
                                                                followers: sc.total_followers,
                                                                perOfFollwers: sc.percentage_followers,
                                                                viewers: sc.viewer,
                                                                perOfViewers: sc.viewer_percentage,
                                                                scper: socialcauses.is_predefined,
                                                                scsel: socialcauses.selected
                                                            };
                                                            this.scTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    socialLoadMonthDatePicker() {

        this.brandprofileService
            .GetBrandSocialCausesFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.scBrandProfile.followerMatch = +res['match_percentage'];
                this.scBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandSocialCausesProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((scpm) => {
                        this.scBrandProfile.profileViewerMatch = +scpm['match_percentage'];
                        this.scBrandProfile.profileViewerMatchprev = +scpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandSocialCausesClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((sccm) => {
                                this.scBrandProfile.clickThroughMatch = +sccm['match_percentage'];
                                this.scBrandProfile.clickThroughMatchprev = +sccm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandSocialCausesRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((scrm) => {
                                        this.scBrandProfile.recommendations = +scrm['match_percentage'];
                                        this.scBrandProfile.recommendationsprev = +scrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandSocialCausesBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((scbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.scBrandProfile.breakdown = scbm['response'] as Array<any>;
                                                this.scTableData = [];
                                                this.allSocialCauses.forEach((socialcauses) => {
                                                    this.scBrandProfile.breakdown.forEach((sc) => {
                                                        if (sc.social_cause_id === socialcauses.id) {
                                                            const newObj = {
                                                                image: socialcauses.image,
                                                                title: socialcauses.title,
                                                                avgAge: sc.avg_age,
                                                                followers: sc.total_followers,
                                                                perOfFollwers: sc.percentage_followers,
                                                                viewers: sc.viewer,
                                                                perOfViewers: sc.viewer_percentage,
                                                                scper: socialcauses.is_predefined,
                                                                scsel: socialcauses.selected
                                                            };
                                                            this.scTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    socialLoadYearDatePicker() {

        this.brandprofileService
            .GetBrandSocialCausesFollowerMatch(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.scBrandProfile.followerMatch = +res['match_percentage'];
                this.scBrandProfile.followerMatchprev = +res['match_percentage_previous'];

                this.brandprofileService
                    .GetBrandSocialCausesProfileViewerMatch(this.startDate, this.endDate)
                    .subscribe((scpm) => {
                        this.scBrandProfile.profileViewerMatch = +scpm['match_percentage'];
                        this.scBrandProfile.profileViewerMatchprev = +scpm['match_percentage_previous'];

                        this.brandprofileService
                            .GetBrandSocialCausesClickThroughMatch(this.startDate, this.endDate)
                            .subscribe((sccm) => {
                                this.scBrandProfile.clickThroughMatch = +sccm['match_percentage'];
                                this.scBrandProfile.clickThroughMatchprev = +sccm['match_percentage_previous'];

                                this.brandprofileService
                                    .GetBrandSocialCausesRecommendationsMatch(this.startDate, this.endDate)
                                    .subscribe((scrm) => {
                                        this.scBrandProfile.recommendations = +scrm['match_percentage'];
                                        this.scBrandProfile.recommendationsprev = +scrm['match_percentage_previous'];

                                        this.brandprofileService
                                            .GetBrandSocialCausesBreakdownMatch(this.startDate, this.endDate)
                                            .subscribe((scbm) => {
                                                // tslint:disable-next-line:no-string-literal
                                                this.scBrandProfile.breakdown = scbm['response'] as Array<any>;
                                                this.scTableData = [];
                                                this.allSocialCauses.forEach((socialcauses) => {
                                                    this.scBrandProfile.breakdown.forEach((sc) => {
                                                        if (sc.social_cause_id === socialcauses.id) {
                                                            const newObj = {
                                                                image: socialcauses.image,
                                                                title: socialcauses.title,
                                                                avgAge: sc.avg_age,
                                                                followers: sc.total_followers,
                                                                perOfFollwers: sc.percentage_followers,
                                                                viewers: sc.viewer,
                                                                perOfViewers: sc.viewer_percentage,
                                                                scper: socialcauses.is_predefined,
                                                                scsel: socialcauses.selected
                                                            };
                                                            this.scTableData.push(newObj);
                                                        }
                                                    });
                                                });
                                            });
                                    });
                            });
                    });
            });
    }

    // <-------------------------------------------------- calender stats End----------------------->

    showTabContentDropdown(tabContentID, fragment) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }

        document.getElementById(tabContentID).classList.add('show', 'active');

        this.selected = {startDate: null, endDate: null};

        if (fragment === 'pc') {
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('weekBtn', 'pcfbtn');
                    this.ctShoWeek();
                    break;
                case 'DAY':
                    this.ctShowDay();
                    this.setActive('dayBtn', 'pcfbtn');
                    break;
                case 'MONTH':
                    this.ctShowMonth();
                    this.setActive('monthBtn', 'pcfbtn');
                    break;
                default:
                    break;
            }
        }
        if (fragment === 'vib') {
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('vibeweekBtn', 'vfbtn');
                    this.vibShoWeek();
                    break;
                case 'DAY':
                    this.vibShowDay();
                    this.setActive('vibedayBtn', 'vfbtn');
                    break;
                case 'MONTH':
                    this.vibShowMonth();
                    this.setActive('vibmonthBtn', 'vfbtn');
                    break;
                default:
                    break;
            }
        }
        if (fragment === 'val') {
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('valueweekBtn', 'vafbtn');
                    this.valShoWeek();
                    break;
                case 'DAY':
                    this.valShowDay();
                    this.setActive('valuedayBtn', 'vafbtn');
                    break;
                case 'MONTH':
                    this.valShowMonth();
                    this.setActive('valuemonthBtn', 'vafbtn');
                    break;
                default:
                    break;
            }
        }
        if (fragment === 'sc') {
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('scweekBtn', 'dcfbtn');
                    this.scShowWeek();
                    break;
                case 'DAY':
                    this.scShowDay();
                    this.setActive('scdayBtn', 'dcfbtn');
                    break;
                case 'MONTH':
                    this.scShowMonth();
                    this.setActive('scmonthBtn', 'dcfbtn');
                    break;
                default:
                    break;
            }
        }
    }

    showTabContent(pop: HTMLDivElement, link: HTMLAnchorElement, content: string) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }

        pop.classList.add('show', 'active');
        link.classList.add('active');
        this.selected = {startDate: null, endDate: null};

        if (content === 'pc') {
            this.selectedTab = 'pc';
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('weekBtn', 'pcfbtn');
                    this.ctShoWeek();
                    break;
                case 'DAY':
                    this.ctShowDay();
                    this.setActive('dayBtn', 'pcfbtn');
                    break;
                case 'MONTH':
                    this.ctShowMonth();
                    this.setActive('monthBtn', 'pcfbtn');
                    break;
                case 'CUSTOM':
                    this.ctShowCustom();
                    this.setActive('customBtn', 'pcfbtn');
                    break;
                default:
                    break;
            }
        }
        if (content === 'vib') {
            this.selectedTab = 'vb';
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('vibeweekBtn', 'vfbtn');
                    this.vibShoWeek();
                    break;
                case 'DAY':
                    this.vibShowDay();
                    this.setActive('vibedayBtn', 'vfbtn');
                    break;
                case 'MONTH':
                    this.vibShowMonth();
                    this.setActive('vibmonthBtn', 'vfbtn');
                    break;
                case 'CUSTOM':
                    this.vibShowCustom();
                    this.setActive('vibCustomBtn', 'vfbtn');
                    break;
                default:
                    break;
            }
        }
        if (content === 'val') {
            this.selectedTab = 'vl';
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('valueweekBtn', 'vafbtn');
                    this.valShoWeek();
                    break;
                case 'DAY':
                    this.valShowDay();
                    this.setActive('valuedayBtn', 'vafbtn');
                    break;
                case 'MONTH':
                    this.valShowMonth();
                    this.setActive('valuemonthBtn', 'vafbtn');
                    break;
                case 'CUSTOM':
                    this.valShowCustom();
                    this.setActive('valCustomBtn', 'vafbtn');
                    break;
                default:
                    break;
            }
        }
        if (content === 'sc') {
            this.selectedTab = 'sc';
            switch (this.brandprofileService.currentFilter) {
                case 'WEEK':
                    this.setActive('scweekBtn', 'dcfbtn');
                    this.scShowWeek();
                    break;
                case 'DAY':
                    this.scShowDay();
                    this.setActive('scdayBtn', 'dcfbtn');
                    break;
                case 'MONTH':
                    this.scShowMonth();
                    this.setActive('scmonthBtn', 'dcfbtn');
                    break;
                case 'CUSTOM':
                    this.scShowCustom();
                    this.setActive('scCustomBtn', 'dcfbtn');
                    break;
                default:
                    break;
            }
        }

        setTimeout(() => {
            this.initHorizontalScroll();
        }, 500);

    }

    setActive(selBtn: string, allBtnsClass: string) {
        const selectedBtn = document.getElementById(selBtn);
        const allBtns = document.querySelectorAll('.' + allBtnsClass);

        allBtns.forEach((elem, index) => {
            elem.classList.remove('active');
        });

        selectedBtn.classList.add('active');
    }

    ctShowDay() {
        this.currday = true;
        this.currweek = false;
        this.currmonth = false;
        this.curryear = false;


        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';

            if (this.brandprofileService.currentFilter !== 'DAY') {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(daydate.setDate(daystart), 'yyyy-MM-dd');
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.productLoadDayDatePicker();
                this.brandprofileService.currentFilter = this.currentFilter;

            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.productLoadDayDatePicker();
                this.brandprofileService.currentFilter = this.currentFilter;


            }
        }
    }

    ctShoWeek() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.productLoadWeekDatePicker();
        }
    }

    ctShowMonth() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = true;
        this.curryear = false;

        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandprofileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(monthdate.setDate(monthend), 'yyyy-MM-dd');
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
                this.productLoadMonthDatePicker();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.productLoadMonthDatePicker();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

        }
    }

    ctShowCustom() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.productLoadWeekDatePicker();
        }
    }

    ctShowYear() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = false;
        this.curryear = true;
        if (this.currentFilter !== 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.productLoadYearDatePicker();

        }
    }

    vibShowDay() {
        this.vibcurrday = true;
        this.vibcurrweek = false;
        this.vibcurrmonth = false;
        this.vibcurryear = false;


        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';

            if (this.brandprofileService.currentFilter !== 'DAY') {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(daydate.setDate(daystart), 'yyyy-MM-dd');
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
                this.vibeLoadDayDatePicker();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
                this.vibeLoadDayDatePicker();

            }
        }
    }

    vibShoWeek() {
        this.vibcurrweek = true;
        this.vibcurrday = false;
        this.vibcurrmonth = false;
        this.vibcurryear = false;

        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.selected = {startDate: null, endDate: null};
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.vibeLoadWeekDatePicker();
        }
    }

    vibShowMonth() {
        this.vibcurrweek = false;
        this.vibcurrday = false;
        this.vibcurrmonth = true;
        this.vibcurryear = false;

        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandprofileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(monthdate.setDate(monthend), 'yyyy-MM-dd');
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            }
            this.vibeLoadMonthDatePicker();

        }
    }

    vibShowYear() {
        this.vibcurrweek = false;
        this.vibcurrday = false;
        this.vibcurrmonth = false;
        this.vibcurryear = true;
        if (this.currentFilter != 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.vibeLoadYearDatePicker();

        }
    }

    vibShowCustom() {
        this.vibcurrweek = true;
        this.vibcurrday = false;
        this.vibcurrmonth = false;
        this.vibcurryear = false;

        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.selected = {startDate: null, endDate: null};
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.vibeLoadWeekDatePicker();
        }
    }

    valShowDay() {
        this.valcurrday = true;
        this.valcurrweek = false;
        this.valcurrmonth = false;
        this.valcurryear = false;


        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';
            this.brandprofileService.currentFilter = this.currentFilter;
            if (!this.brandprofileService.selected.startDate) {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(daydate.setDate(daystart), 'yyyy-MM-dd');
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.valueLoadDayDatePicker();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.valueLoadDayDatePicker();

            }
        }
    }

    valShoWeek() {
        this.valcurrweek = true;
        this.valcurrday = false;
        this.valcurrmonth = false;
        this.valcurryear = false;

        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.selected = {startDate: null, endDate: null};
                this.dateSelector();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.valueLoadWeekDatePicker();
        }
    }

    valShowMonth() {
        this.valcurrweek = false;
        this.valcurrday = false;
        this.valcurrmonth = true;
        this.valcurryear = false;

        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandprofileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(monthdate.setDate(monthend), 'yyyy-MM-dd');
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            }
            this.valueLoadMonthDatePicker();

        }
    }

    valShowYear() {
        this.valcurrweek = false;
        this.valcurrday = false;
        this.valcurrmonth = false;
        this.valcurryear = true;
        if (this.currentFilter != 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.valueLoadYearDatePicker();

        }
    }

    valShowCustom() {
        this.valcurrweek = true;
        this.valcurrday = false;
        this.valcurrmonth = false;
        this.valcurryear = false;

        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.selected = {startDate: null, endDate: null};
                this.dateSelector();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.valueLoadWeekDatePicker();
        }
    }

    scShowDay() {
        this.sccurrday = true;
        this.sccurrweek = false;
        this.sccurrmonth = false;
        this.sccurryear = false;
        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';
            this.brandprofileService.currentFilter = this.currentFilter;
            if (!this.brandprofileService.selected.startDate) {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(daydate.setDate(daystart), 'yyyy-MM-dd');
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.socialLoadDayDatePicker();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.socialLoadDayDatePicker();

            }
        }
    }

    scShowWeek() {
        this.sccurrweek = true;
        this.sccurrday = false;
        this.sccurrmonth = false;
        this.sccurryear = false;

        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.selected = {startDate: null, endDate: null};
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.socialLoadWeekDatePicker();
        }
    }

    scShowMonth() {
        this.sccurrweek = false;
        this.sccurrday = false;
        this.sccurrmonth = true;
        this.sccurryear = false;
        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandprofileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(monthdate.setDate(monthend), 'yyyy-MM-dd');
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            }
            this.socialLoadMonthDatePicker();

        }
    }

    scShowYear() {
        this.sccurrweek = false;
        this.sccurrday = false;
        this.sccurrmonth = false;
        this.sccurryear = true;
        if (this.currentFilter !== 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.socialLoadYearDatePicker();
        }
    }

    scShowCustom() {
        this.sccurrweek = true;
        this.sccurrday = false;
        this.sccurrmonth = false;
        this.sccurryear = false;

        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.selected = {startDate: null, endDate: null};
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.socialLoadWeekDatePicker();
        }
    }

    openDatepicker() {
        this.pickerDirective.open();

    }

    initHorizontalScroll(): void {
        switch (this.selectedTab) {
            case 'pc':
                this.setupHorizontalScroll('pcRtBtn', 'pcLtBtn', 'pcCntr');
                break;

            case 'vb':
                this.setupHorizontalScroll('vbRtBtn', 'vbLtBtn', 'vbCntr');
                break;

            case 'vl':
                this.setupHorizontalScroll('vlRtBtn', 'vlLtBtn', 'vlCntr');
                break;

            default:

        }
    }

    private setupHorizontalScroll(btnId, bkBtnId, containerId): void {

        const button = document.getElementById(`${btnId}`);
        const back = document.getElementById(`${bkBtnId}`);
        const container = document.getElementById(`${containerId}`);
        if (button && back && container) {
            if (checkOverflow(container)) {
                button.style.display = 'flex';
                back.style.display = 'flex';

                button.onclick = () => {
                    sideScroll(container, 'right', 25, 100, 10);
                };

                back.onclick = () => {
                    sideScroll(container, 'left', 25, 100, 10);
                };
            } else {
                button.style.display = 'none';
                back.style.display = 'none';
            }
        }


        function sideScroll(element, direction, speed, distance, step) {
            let scrollAmount = 0;
            const slideTimer = setInterval(() => {
                if (direction === 'left') {
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if (scrollAmount >= distance) {
                    // @ts-ignore
                    window.clearInterval(slideTimer);
                }
            }, speed);
        }

        function checkOverflow(el) {
            const curOverflow = el.style.overflow;

            if (!curOverflow || curOverflow === 'visible') {
                el.style.overflow = 'hidden';
            }
            const isOverflowing = el.clientWidth < el.scrollWidth
                || el.clientHeight < el.scrollHeight;

            el.style.overflow = curOverflow;

            return isOverflowing;
        }


    }

}
