import {AfterViewInit, ChangeDetectorRef, Component, Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {Options} from 'ng5-slider';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BrandService} from '../shared/services/brand/brand.service';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../shared/services/user/user.service';
import {AuthService} from '../shared/services/auth/auth.service';
import {Router} from '@angular/router';
import {Brand, BrandProductCategory, BrandSocialCause, BrandValue, BrandVibe} from '../shared/services/brand/brand';
import {ProductCategory} from '../shared/services/product_category/product-category';
import {ProductCategoryService} from '../shared/services/product_category/product-category.service';
import {Vibe} from '../shared/services/vibe/vibe';
import {VibeService} from '../shared/services/vibe/vibe.service';
import {Value} from '../shared/services/value/value';
import {ValueService} from '../shared/services/value/value.service';
import {SocialCause} from '../shared/services/social_cause/social_cause';
import {SocialCauseService} from '../shared/services/social_cause/social-cause.service';
import {BrandStoriesService} from '../shared/services/brand_stories/brand-stories.service';
import {BrandStoryType} from '../shared/services/brand_stories/brand-stories';
import {VideoProcessingService} from '../video-processing-service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {environment} from '../../environments/environment';
import {VideoUploadServiceService} from '../shared/services/video-upload-service.service';
import {numbersOnly} from '../shared/helpers/numbersOnly';
import Timer = NodeJS.Timer;

// import {log} from 'util';

@Directive({
    selector: '[appNumbersOnly]',
})
export class NumbersOnlyDirective {
    // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    private regex: RegExp = new RegExp(/^[0-9]*$/);
    private specialKeys: Array<string> = ['Backspace', 'Tab'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}

@Directive({
    selector: '[appMultiplesOfFive]',
})
export class MutiplesOfFiveDirective {
    private regex: RegExp = new RegExp(/^[-+]?([0-9]|[1-9][0-9]|100)*\.?[0-9]+$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
        setTimeout(() => {
            // tslint:disable-next-line:radix
            if (parseInt(next) % 5 !== 0) {
                event.preventDefault();
            }
        }, 800);
    }
}

@Component({
    selector: 'app-brand-account',
    templateUrl: './brand-account.component.html',
    styleUrls: ['./brand-account.component.scss'],
})
export class BrandAccountComponent implements OnInit, AfterViewInit {
    public responsiveWidth: any;
    cdnUrl = environment.webStaticResources;
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    uploadlink = false;
    alertTimeout: Timer;
    alertErrorTimeout: Timer;
    public myText = '';
    public proDText = '';
    public vibeTxt = '';
    public valueTxt = '';
    public scsTxt = '';
    public desTxt = '';
    public cusDesTxt = '';
    public pubTxt = '';
    isMobile = false;
    widthScreen: number = window.innerWidth;
    height: number = window.innerHeight;
    mobileWidth = 768;
    // For storing authenticated users brand information
    brandInfo: Brand;
    currentUser: AuthenticatedUser;
    // Flag to update user/brand profile on coming back from 2nd step to 1st step
    updateStep1 = false;
    public thumbnailData: any;
    brandUserRegistrationForm: FormGroup;
    brandUserRegistrationFormSubmitted: boolean;
    brandBusinessDetailsForm: FormGroup;
    brandBusinessDetailsFormSubmitted: boolean;
    // For storing all available data for these entities
    productCategories: Array<ProductCategory>;
    productCategoriesTags: Array<string>;
    vibes: Array<Vibe>;
    values: Array<Value>;
    socialCauses: Array<SocialCause>;
    isOtherSocialCauseShown = false; // hidden by default
    allBrandStoryTypes: Array<BrandStoryType>;
    defaultBrandStoryType: BrandStoryType;
    items = [
        {id: 1, name: 'Technology'},
        {id: 2, name: 'Care'},
        {id: 3, name: 'Women'},
        {id: 4, name: 'Wellness'},
        {id: 5, name: 'luxury'},
        {id: 6, name: 'Food'},
        {id: 7, name: 'Travel'},
        {id: 8, name: 'Outdoor'},
    ];
    selected = [
        {id: 2, name: 'Care'},
        {id: 8, name: 'Outdoor'},
    ];
    listOfYears: number[] = [];
    selectedYear: number = new Date().getFullYear();
    // <===================range value ============================>
    value1 = 50;
    singleSliderRange: Options = {
        floor: 0,
        ceil: 100,
        showOuterSelectionBars: true,
        animate: false
    };
    value = 20;
    highValue = 60;
    ageRangeSliderRange: Options = {
        floor: 0,
        ceil: 100,
        showOuterSelectionBars: true,
        animate: false
    };
    // <================================================================>
    public error: any;
    isDisabled = true;
    amt = 0;
    limit = false;
    showTab = 1;
    width = 10;
    // <----------------------- Product Category Chart & Sliders ---------------------->

    public total = 0;
    public totalBrandValuesPer = 0;
    valsArr = [];
    brandvalsArr = [];
    public vibesTotalPercentage = 0;
    autoProductCategory = [];
    autoVibes = [];
    autoValues = [];
    autoSetProductCategories = true;
    autoSetVibes = true;
    autoSetValues = true;
    productCategoriesSliderOptions: Array<Options>;
    options1: Options = {
        floor: 0,
        ceil: 100,
        animate: false
    };
    options2: Options = {
        floor: 0,
        ceil: 100,
        animate: false
    };
    options3: Options = {
        floor: 0,
        ceil: 100,
        animate: false
    };
    options4: Options = {
        floor: 0,
        ceil: 100,
        animate: false
    };
    totalPercent = 100;
    public progress: number;
    chartOption = {
        cutoutPercentage: 65,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false
            }
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        animation: {
            animateRotate: false,
        }
    };
    defaultChartOption = {
        cutoutPercentage: 65,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false
            }
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        animation: {
            duration: 10,
            animateRotate: false,
        }
    };
    maxCharacters = 25;
    public errr: string;
    progressInfos: any;
    tagAdded = false;
    prevStateUrl = 2;
    ageRequirement: number = null;
    ageRequirementSelected = -1;
    pcMaxLength = 3;
    vbMaxLength = 3;
    valMaxLength = 3;
    pcLimitExceed = false;
    vibLimitExceed = false;
    valLimitExceed = false;
    /**
     * Brand Intro Video Upload Step
     */
    private selectedIntroVideo;

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private authService: AuthService,
        private userService: UserService,
        private brandService: BrandService,
        private vibeService: VibeService,
        private valueService: ValueService,
        private productCategoryService: ProductCategoryService,
        private socialCauseService: SocialCauseService,
        private brandStoriesService: BrandStoriesService,
        private videoService: VideoProcessingService,
        private loadingBar: LoadingBarService,
        private videoUpload: VideoUploadServiceService,
        private changeDetectorRef: ChangeDetectorRef
    ) {

        const urlReg = '(https?://)?(www?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        const validUrlRegEx = '(http(s)?:\\/\\/)?(www\\.)?[A-Za-z0-9]+([\\-\\.]{1}[A-Za-z0-9]+)*\\.[A-Za-z]{2,10}(:[0-9]{1,5})?(\\/.*)?$';
        this.brandUserRegistrationForm = this.fb.group(
            {
                business_name: new FormControl('', {
                    validators: [Validators.required, Validators.minLength(3)],
                    updateOn: 'blur',
                }),
                username: new FormControl('', {
                    validators: [Validators.required, Validators.minLength(3), this.uniqueUsername.bind(this)],
                    updateOn: 'blur',
                }),
                // fullName: ['', [Validators.required]],
                firstName: ['', [Validators.required]],
                lastName: ['', [Validators.required]],
                title: [''],
                phone: [''],
                sales_channel: ['', [Validators.required]],
                website: ['', [Validators.required, Validators.pattern(validUrlRegEx)]],
            },
            {
                updateOn: 'blur'
            }
        );
        this.brandBusinessDetailsForm = this.fb.group({
            taxID: ['', Validators.required],
            headquarterLocation: ['', Validators.required],
            yearsInBusiness: ['', Validators.required],
            year_founded: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            numberOfLocations: ['', Validators.required],
            amountBusinessRange: ['' || 50, Validators.required],
        });
        this.showTab = -1;
    }

    /**
     * convenience getter for easy access to form fields of Brand User Registration Form
     */

    get burf() {
        return this.brandUserRegistrationForm.controls;
    }

    /**
     * convenience getter for easy access to form fields of Brand Business Details Form
     */
    get bbdf() {
        return this.brandBusinessDetailsForm.controls;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {

        // let activeTab = window.location.href.split('#')[1];
        let activeTab = this.showTab;
        if ((+window.location.href.split('#')[1]) === this.prevStateUrl) {
            activeTab = this.showTab - 1;
        }
        if (+activeTab < 2) {
            window.history.forward();
            return;
        }

        this.prevStateUrl = +window.location.href.split('#')[1];
        this.moveToPreviousOnBoardingStep(+activeTab - 1);
        this.tabToggle(+activeTab - 1);
        // this.router.navigate([], {fragment: `${(+window.location.href.split('#')[1]) - 1}`});

        this.tabToggle(+activeTab - 1);

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.responsiveWidth = event.target.innerWidth;
        this.widthScreen = event.target.innerWidth;
        this.height = event.target.innerHeight;
        this.isMobile = this.width < this.mobileWidth;
        // this.initBrandInfo();
    }

    /**
     * Update entity lists with brand specific information
     * e.g
     * updating product categories with data like if they're selected or not etc
     */
    initBrandInfo() {
        if (!this.brandInfo.brandcustomerprofile.male_to_female_ratio) {
            this.brandInfo.brandcustomerprofile.male_to_female_ratio = 50;
        }
        if (!this.brandInfo.brandcustomerprofile.min_age) {
            this.brandInfo.brandcustomerprofile.min_age = 20;
        }
        if (!this.brandInfo.brandcustomerprofile.max_age) {
            this.brandInfo.brandcustomerprofile.max_age = 60;
        }
        this.productCategoryService.GetAllProductCategories().subscribe(
            (productCategories) => {
                this.productCategories = productCategories.results;
                this.productCategories.forEach((productCategory, index) => {
                    if (productCategory.is_predefined) {
                        if (!productCategory.percentage) {
                            productCategory.percentage = 0;
                        }
                    }
                    productCategory.selected = false;
                    this.brandInfo.product_categories.forEach((brandProductCategory, index2) => {
                        if (brandProductCategory.id === productCategory.id) {
                            productCategory.selected = true;
                            productCategory.min_price = brandProductCategory.min_price;
                            productCategory.max_price = brandProductCategory.max_price;
                            productCategory.avg_price = brandProductCategory.avg_price;
                            productCategory.tags = brandProductCategory.tags;
                            productCategory.percentage = brandProductCategory.percentage;
                            if (this.isMobile) {
                                productCategory.tags_placeholders = this.first((productCategory.tags_placeholders), 3);
                            }
                        }
                    });
                });
                this.vibeService.GetAllVibes().subscribe((vibes) => {
                    this.vibes = vibes.results;
                    this.vibes.forEach((vibe, index) => {
                        vibe.selected = false;
                        if (!vibe.percentage) {
                            vibe.percentage = 0;
                        }
                        this.brandInfo.vibes.forEach((brandVibe, index2) => {
                            if (brandVibe.id === vibe.id) {
                                vibe.selected = true;
                                vibe.percentage = brandVibe.percentage;
                            }
                        });
                    });
                    this.valueService.GetAllValues().subscribe((values) => {
                        this.values = values.results;
                        this.values.forEach((value, index) => {
                            value.selected = false;
                            if (value.is_predefined) {
                                if (!value.percentage) {
                                    value.percentage = 0;
                                }
                            }
                            this.brandInfo.values.forEach((brandValue, index2) => {
                                if (brandValue.id === value.id) {
                                    value.selected = true;
                                    value.percentage = brandValue.percentage;
                                }
                            });
                        });
                        this.socialCauseService.GetAllSocialCauses().subscribe((socialCauses) => {
                            this.socialCauses = socialCauses.results;
                            this.socialCauses.forEach((socialCause, index) => {
                                socialCause.selected = false;
                                this.brandInfo.social_causes.forEach((brandSocialCause, index2) => {
                                    if (brandSocialCause.id === socialCause.id) {
                                        socialCause.selected = true;
                                    }
                                });
                            });
                            // SORTING FUNCTION
                            this.socialCauses.sort((a, b) => {
                                const nameA = a.title.toUpperCase();
                                const nameB = b.title.toUpperCase();
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }
                                return 0;
                            });
                        });
                    });
                });
            },
            (err) => {
            }
        );
    }

    /**
     * Basic Setup like, setting up BrandInfo object as an object of Brand class
     * Initialize Brand Info
     * Show the tab where the user left off etc
     */
    initSignupAuthenticatedForms() {
        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;
        this.brandService.GetBrandById(currentBrand.id).subscribe(
            (brand) => {
                this.brandInfo = new Brand(
                    brand.id,
                    brand.business_name,
                    brand.description,
                    brand.tags,
                    brand.brandsignupchecklist,
                    brand.brandcustomerprofile,
                    brand.product_categories,
                    brand.vibes,
                    brand.values,
                    brand.social_causes,
                    brand.sales_channel,
                    brand.website,
                    brand.headquarter_location,
                    brand.tax_id,
                    brand.years_in_business,
                    brand.year_founded,
                    brand.number_of_locations,
                    brand.business_percent_online,
                    brand.business_percent_instore,
                    brand.brand_username,
                    brand.logo_url,
                    brand.minimum_age_required
                );
                this.brandInfo.initBrandInfo();
                const urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
                const validUrlRegEx = '(http(s)?:\\/\\/)?(www\\.)?[A-Za-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';

                this.brandUserRegistrationForm = this.fb.group({
                    business_name: new FormControl(this.brandInfo.business_name, {
                        validators: [Validators.required, Validators.minLength(3)],
                        updateOn: 'blur',
                    }),
                    username: new FormControl(this.currentUser.user.branduserprofile.brand.brand_username || '', {
                        validators: [Validators.required, Validators.minLength(3), this.uniqueUsername.bind(this)],
                        updateOn: 'blur',
                    }),
                    firstName: [this.currentUser.user.first_name, [Validators.required]],
                    lastName: [this.currentUser.user.last_name, [Validators.required]],
                    title: [this.currentUser.user.branduserprofile.title],
                    phone: [this.currentUser.user.branduserprofile.phone || this.currentUser.user.phone],
                    sales_channel: [this.currentUser.user.branduserprofile.brand.sales_channel, [Validators.required]],
                    website: [this.currentUser.user.branduserprofile.brand.website, [Validators.pattern(validUrlRegEx)]],
                });

                this.brandBusinessDetailsForm = this.fb.group({
                    headquarterLocation: [this.brandInfo.headquarter_location, Validators.required],
                    taxID: [this.brandInfo.tax_id || '', Validators.required],
                    year_founded: [this.brandInfo.year_founded, Validators.required],
                    numberOfLocations: [this.brandInfo.number_of_locations, Validators.required],
                    amountBusinessRange: [this.brandInfo.business_percent_instore || 50, Validators.required],
                });

                if (this.brandInfo.minimum_age_required && this.brandInfo.minimum_age_required > 1) {
                    this.ageRequirementSelected = 2;
                    this.ageRequirement = +this.brandInfo.minimum_age_required;
                } else if (!this.brandInfo.minimum_age_required && this.brandInfo.minimum_age_required === 0) {
                    this.ageRequirementSelected = 1;
                    this.ageRequirement = null;
                } else if (!this.brandInfo.minimum_age_required && this.ageRequirementSelected > 0 && this.ageRequirementSelected < 3) {
                    this.ageRequirementSelected = 1;
                    this.ageRequirement = null;
                } else {
                    this.ageRequirementSelected = -1;
                    this.ageRequirement = null;
                }

                this.initBrandInfo();
                const brandCheckList = this.brandInfo.brandsignupchecklist;
                const elem = document.getElementById('myBar');

                const nextTab = sessionStorage.getItem('next_tab');
                sessionStorage.removeItem('next_tab');

                if (nextTab !== undefined && nextTab !== '' && nextTab !== null) {
                    if (nextTab !== '10') {
                        this.showTab = +nextTab;
                        elem.style.width = `${nextTab}0%`;
                    } else if (nextTab === '10' && brandCheckList.uploaded_intro_video) {
                        // TODO: replace redirection here to dashboard | later
                        window.location.href = '/brand-journey';
                    } else {
                        this.showTab = +nextTab;
                        elem.style.width = `97%`;
                    }
                } else {
                    if (!this.currentUser.user.first_name && !brandCheckList.provided_business_details) {
                        this.showTab = 1;
                        elem.style.width = 10 + '%';
                    } else if (!brandCheckList.provided_business_details) {
                        this.showTab = 2;
                        elem.style.width = 20 + '%';
                    } else if (!brandCheckList.selected_product_categories) {
                        this.showTab = 3;
                        elem.style.width = 30 + '%';
                    } else if (!brandCheckList.provided_category_details) {
                        this.showTab = 4;
                        elem.style.width = 40 + '%';
                    } else if (!brandCheckList.selected_brand_vibes) {
                        this.showTab = 5;
                        elem.style.width = 50 + '%';
                    } else if (!brandCheckList.selected_brand_values) {
                        this.showTab = 6;
                        elem.style.width = 60 + '%';
                    } else if (!brandCheckList.selected_social_values) {
                        this.showTab = 7;
                        elem.style.width = 70 + '%';
                    } else if (!brandCheckList.provided_brand_description) {
                        this.showTab = 8;
                        elem.style.width = 80 + '%';
                    } else if (!brandCheckList.shared_customer_profile) {
                        this.showTab = 9;
                        elem.style.width = 90 + '%';
                    } else if (!brandCheckList.uploaded_intro_video) {
                        this.showTab = 10;
                        elem.style.width = 97 + '%';
                    } else {
                        // TODO: replace redirection here to dashboard | later
                        window.location.href = '/brand-journey';
                    }

                    this.router.navigate([], {fragment: `${this.showTab}`});

                }
            },
            (error1) => {
                console.log(error1);
            }
        );
    }

    ngOnInit() {
        this.isMobile = this.widthScreen < this.mobileWidth;
        this.authService
            .isAuthenticated()
            .then((user: AuthenticatedUser) => {
                if (user && !user.user.is_email_verified) {
                    console.log('Authenticated but email not verified');
                    this.router.navigate(['/signup']);
                } else if (user.user.branduserprofile) {
                    console.log('Authenticated, verified and brand user profile is not null');
                    // this.showTab = 2;
                    this.currentUser = user;
                    this.initSignupAuthenticatedForms();
                } else {
                    console.log('Authenticated, verified but brand user profile is null');
                    this.showTab = 1;

                }
            })
            .catch(() => {
                console.log('No User found!');
                this.router.navigate(['/login']);
            });

        const currYear = new Date().getFullYear();
        for (let i = 0; i <= 200; i++) {
            this.listOfYears.push(currYear - i);
        }

    }

    ngAfterViewInit() {
        this.responsiveWidth = window.innerWidth;
    }

    first(array, n) {
        if (array == null) {
            return void 0;
        }
        if (n == null) {
            return array[0];
        }
        if (n < 0) {
            return [];
        }
        return array.slice(0, n);
    }

    /**
     * Validator for Brand User Registration Form to check if the Business/Brand Name is available
     */
    uniqueBrandName(control: AbstractControl) {
        const businessName = control.value;

        if (this.updateStep1) {
            const businessNameMatched = businessName === this.currentUser.user.branduserprofile.brand.business_name;
            if (businessNameMatched) {
                return null;
            }
        }

        if (businessName.length > 2) {
            this.brandService.GetBrandByName(businessName).subscribe(
                (res) => {
                    control.setErrors({notUniqueBrandName: true});
                },
                (err) => {
                    if (err.status === 404) {
                        return null;
                    }
                    control.setErrors({notUniqueBrandName: true});
                }
            );
        }
        return null;
    }

    // Unique Username Validator
    uniqueUsername(control: AbstractControl) {
        const username = control.value;
        if (this.updateStep1) {
            const usernameMatched = username === this.currentUser.user.branduserprofile.brand.brand_username;

            if (usernameMatched) {
                return null;
            }
        }

        if (username.length > 2) {
            this.brandService.checkBrandUsernameAvailability(username).subscribe(
                (res) => {
                    if (res.message === 'available'){
                        return null;
                    }
                    control.setErrors({notUniqueUsername: true});
                },
                (err) => {
                    control.setErrors({notUniqueUsername: true});
                }
            );
        }

        return null;
    }

    tabToggle(index) {
        const elem = document.getElementById('myBar');
        this.showTab = index;
        elem.style.width = index * 10 + '%';
        this.hideAlert();

        if (index === 2) {
            setTimeout(() => {
                const ele = document.getElementById('onBoarding-ng-select').getElementsByTagName('input')[0];
                ele.maxLength = 4;
                numbersOnly(ele, (value) => {
                    return /^\d*$/.test(value);
                });
            }, 800);

        }
        this.router.navigate([], {fragment: `${index}`});
    }

    validateAllFormFields(formGroup: FormGroup) {
        // {1}
        Object.keys(formGroup.controls).forEach((field) => {
            // {2}
            const control = formGroup.get(field); // {3}
            if (control instanceof FormControl) {
                // {4}
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {
                // {5}
                this.validateAllFormFields(control); // {6}
            }
        });
    }

    // Set Suggested Username
    setUsername() {
        this.brandService
            .GetSuggestedUsername(this.brandUserRegistrationForm.get('business_name').value)
            .subscribe((username) => {
                this.brandUserRegistrationForm.patchValue({username: username.message});
            });
    }

    /**
     * Submission handler for Brand User Registration Form
     */
    createBrandAccountOnSubmit() {
        if (this.brandUserRegistrationFormSubmitted) {
            return false;
        } else {
            this.brandUserRegistrationFormSubmitted = true;
            if (this.brandUserRegistrationForm.valid) {

                if (!this.brandUserRegistrationForm.getRawValue().website) {
                    this.brandUserRegistrationForm.controls.website.setErrors({required: true});
                    this.brandUserRegistrationFormSubmitted = false;
                    return;
                }

                const brandData = {
                    business_name: this.brandUserRegistrationForm.get('business_name').value,
                    brand_username: this.brandUserRegistrationForm.get('username').value,
                    sales_channel: this.brandUserRegistrationForm.get('sales_channel').value,
                    website: this.getHostname(this.brandUserRegistrationForm.get('website').value).replace('www.', '').toLowerCase(),
                };

                // And if you have an object that you want to save, stringify it like this
                localStorage.setItem('some-key', JSON.stringify(brandData));

                if (this.updateStep1) {
                    this.brandService.UpdateBrandById(this.brandInfo.id, brandData).subscribe(resp => {

                            // tslint:disable-next-line:no-string-literal
                            if (resp['exception']) {
                                if (this.responsiveWidth < 768) {
                                    // tslint:disable-next-line:no-string-literal
                                    this.toastr.error(resp['exception'][0][0]);
                                } else {
                                    // tslint:disable-next-line:no-string-literal
                                    this.showAlertError(resp['exception'][0][0]);
                                }
                                this.brandUserRegistrationFormSubmitted = false;
                                return;
                            }

                            this.brandInfo = resp;
                            this.currentUser.user.branduserprofile.brand = resp;

                            const userData = {
                                first_name: this.brandUserRegistrationForm.get('firstName').value,
                                last_name: this.brandUserRegistrationForm.get('lastName').value,
                                title: this.brandUserRegistrationForm.get('title').value,
                                phone: this.brandUserRegistrationForm.get('phone').value,
                                brand: resp.id,
                            };

                            this.userService.updateBrandUserProfile(userData).subscribe(
                                (user) => {
                                    this.currentUser.user = user;
                                    this.tabToggle(2);
                                    this.updateStep1 = false;
                                    this.brandUserRegistrationFormSubmitted = false;
                                },
                                (err) => {
                                    if (err) {
                                        if (this.responsiveWidth < 768) {
                                            this.toastr.error('“Brand Registered but User was not updated!');
                                        } else {
                                            this.showAlertError('“Brand Registered but User was not updated!');
                                        }
                                    }
                                }
                            );

                            this.authService
                                .isAuthenticated()
                                .then((user: AuthenticatedUser) => {
                                    if (user && !user.user.is_email_verified) {
                                        console.log('Authenticated but email not verified');
                                        this.router.navigate(['/signup']);
                                    } else if (user.user.branduserprofile) {
                                        console.log('Authenticated, verified and brand user profile is not null');
                                        this.currentUser = user;
                                        this.initSignupAuthenticatedForms();
                                    } else {
                                        console.log('Authenticated, verified but brand user profile is null');
                                        this.showTab = 1;
                                    }
                                })
                                .catch(() => {
                                    console.log('No User found!');
                                    this.router.navigate(['/login']);
                                });

                        }, err => {
                            if (err) {
                                if (this.responsiveWidth < 768) {
                                    // tslint:disable-next-line:no-string-literal
                                    this.toastr.error(err.error.detail.website);
                                } else {
                                    // tslint:disable-next-line:no-string-literal
                                    this.showAlertError(err.error.detail.website);
                                }
                                this.brandUserRegistrationFormSubmitted = false;
                                return;
                            }
                        }
                    );

                } else {
                    this.brandService.RegisterBrand(brandData).subscribe(
                        (brand) => {
                            this.brandInfo = brand;
                            const userData = {
                                first_name: this.brandUserRegistrationForm.get('firstName').value,
                                last_name: this.brandUserRegistrationForm.get('lastName').value,
                                title: this.brandUserRegistrationForm.get('title').value,
                                phone: this.brandUserRegistrationForm.get('phone').value,
                                brand: brand.id,
                            };

                            // tslint:disable-next-line:no-string-literal
                            if (brand['exception']) {
                                if (this.responsiveWidth < 768) {
                                    // tslint:disable-next-line:no-string-literal
                                    this.toastr.error(brand['exception'][0][0]);
                                } else {
                                    // tslint:disable-next-line:no-string-literal
                                    this.showAlertError(brand['exception'][0][0]);
                                }
                                this.brandUserRegistrationFormSubmitted = false;
                                return;
                            }

                            this.userService.updateBrandUserProfile(userData).subscribe(
                                (user) => {
                                    this.tabToggle(2);
                                    this.brandUserRegistrationFormSubmitted = false;
                                    this.currentUser.user = user;
                                    this.currentUser.user.branduserprofile.phone = user.phone.toString();
                                    this.brandUserRegistrationForm.patchValue({phone: user.phone.toString()});
                                },
                                (err) => {
                                    if (err) {
                                        if (this.responsiveWidth < 768) {
                                            this.toastr.error('“Brand Registered but User was not updated!');
                                        } else {
                                            this.showAlertError('“Brand Registered but User was not updated!');
                                        }
                                    }

                                }
                            );

                            this.authService
                                .isAuthenticated()
                                .then((user: AuthenticatedUser) => {
                                    if (user && !user.user.is_email_verified) {
                                        console.log('Authenticated but email not verified');
                                        this.router.navigate(['/signup']);
                                    } else if (user.user.branduserprofile) {
                                        this.currentUser = user;
                                        console.log('Authenticated, verified and brand user profile is not null');
                                        this.initSignupAuthenticatedForms();
                                    } else {
                                        console.log('Authenticated, verified but brand user profile is null');
                                        this.showTab = 1;
                                    }
                                })
                                .catch(() => {
                                    console.log('No User found!');
                                    this.router.navigate(['/login']);
                                });
                        },
                        (err) => {
                            if (this.responsiveWidth < 768) {
                                this.toastr.error('Brand with this domain already exists');
                            } else {
                                this.showAlertError('Brand with this domain already exists');
                            }
                            this.brandUserRegistrationFormSubmitted = false;
                        }
                    );

                }


            } else {
                this.brandUserRegistrationFormSubmitted = false;
                this.validateAllFormFields(this.brandUserRegistrationForm);
                return false;
            }
        }
    }

    /**
     * Submission handler for Brand Business Details Form
     */
    submitBrandBusinessDetail() {
        if (this.brandBusinessDetailsFormSubmitted) {
            return false;
        } else {
            this.brandBusinessDetailsFormSubmitted = true;
            if (this.brandBusinessDetailsForm.valid) {
                const brandDetails = {
                    headquarter_location: this.brandBusinessDetailsForm.get('headquarterLocation').value,
                    tax_id: this.brandBusinessDetailsForm.get('taxID').value,
                    year_founded: this.brandBusinessDetailsForm.get('year_founded').value,
                    number_of_locations: this.brandBusinessDetailsForm.get('numberOfLocations').value,
                    business_percent_instore: 100 - this.brandBusinessDetailsForm.get('amountBusinessRange').value,
                    business_percent_online: this.brandBusinessDetailsForm.get('amountBusinessRange').value,
                    brandsignupchecklist: {
                        provided_business_details: true,
                    },
                };

                this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
                    (brand) => {
                        this.authService.updateCurrentUserBrand(brand);
                        this.brandBusinessDetailsFormSubmitted = false;
                        this.tabToggle(3);
                    },
                    (err) => {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Something went wrong while updating the brand info!');
                        } else {
                            this.showAlertError('Something went wrong while updating the brand info!');
                        }
                        this.brandBusinessDetailsFormSubmitted = false;
                    }
                );
            } else {
                this.brandBusinessDetailsFormSubmitted = false;
                this.validateAllFormFields(this.brandBusinessDetailsForm);
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Something went wrong while updating the brand info!');
                } else {
                    this.showAlertError('Something went wrong while updating the brand info!');
                }
                return false;
            }
        }
    }

    /**
     * Routine for updating the updated product categories in the Database
     */
    updateBrandProductCategories(stepClear: true | false) {
        return new Promise((resolve, reject) => {
            const updatedProductCategories = [];

            this.brandInfo.product_categories.forEach((productCategory, index2) => {
                const upc = new BrandProductCategory(
                    productCategory.id,
                    productCategory.min_price,
                    productCategory.max_price,
                    productCategory.avg_price,
                    productCategory.tags,
                    productCategory.percentage
                );
                updatedProductCategories.push(upc._obj());
            });

            const brandDetails = {
                brandsignupchecklist: {
                    selected_product_categories: stepClear,
                },
                minimum_age_required: this.ageRequirementSelected === 2 ? this.ageRequirement : 0,
                product_categories: updatedProductCategories,
            };

            if (!stepClear) {
                brandDetails.minimum_age_required = null;
            }

            this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
                (brand) => {
                    this.authService.updateCurrentUserBrand(brand);
                    this.initBrandInfo();
                    this.changeDetectorRef.markForCheck();
                    resolve(true);
                },
                (err) => {
                    if (this.responsiveWidth < 768) {
                        this.toastr.error('Something went wrong while updating the brand info!');
                    } else {
                        this.showAlertError('Something went wrong while updating the brand info!');
                    }

                    reject(false);
                }
            );
        });
    }

    /**
     * Handler for adding custom product categories
     */
    addCustomProductCategory() {
        let timer: Timer;
        this.tagAdded = false;
        clearTimeout(timer);
        this.myText = '';
        const customCategories = document.getElementById(`ProductCategories`) as HTMLInputElement;
        const customCategoriesArray: Array<string> = customCategories.value.split(',');

        if (customCategories.value === null || customCategories.value === '') {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Product Categories Should not be Null!');
            } else {
                this.showAlertError('Product Categories Should not be Null!');
            }
        } else {
            customCategoriesArray.forEach((customCat, index) => {
                let exists = false;
                customCat = customCat.trim();
                this.productCategories.forEach((productCategory, index2) => {
                    if (customCat !== '') {
                        if (productCategory.title === customCat && !productCategory.is_predefined) {
                            exists = true;
                            const bpc = new BrandProductCategory(productCategory.id);
                            this.brandInfo.product_categories.push(bpc);
                        }
                    }
                });

                if (!exists && customCat !== '') {
                    const data = {
                        title: customCat,
                    };
                    this.productCategoryService.CreateProductCategory(data).subscribe(
                        (productCategory) => {
                            const bpc = new BrandProductCategory(productCategory.id);
                            this.brandInfo.product_categories.push(bpc);
                            this.tagAdded = true;
                            timer = setTimeout(() => {
                                this.tagAdded = false;
                            }, 3000);
                            this.updateBrandProductCategories(false);
                        },
                        (err) => {
                            if (this.responsiveWidth < 768) {
                                this.toastr.error('Tag is same as Category name!');
                            } else {
                                this.showAlertError('Tag is same as Category name!');
                            }
                        }
                    );
                } else {
                    this.tagAdded = true;
                    timer = setTimeout(() => {
                        this.tagAdded = false;
                    }, 3000);
                    this.updateBrandProductCategories(false);

                }
            });
            customCategories.value = '';
        }
    }

    removeCustomProductCategory(tagid) {
        const index: number = this.brandInfo.product_categories.indexOf(
            this.brandInfo.product_categories.find((x) => x.id === tagid)
        );
        if (index !== -1) {
            this.brandInfo.product_categories.splice(index, 1);
        }
        this.updateBrandProductCategories(false);
    }

    addProductCategoryDetailsTags(pid) {
        this.proDText = '';
        const customTagCategories = document.getElementById(`productDetail-${pid}`) as HTMLInputElement;
        const minPrice = document.getElementById(`min-price-${pid}`) as HTMLInputElement;
        const maxPrice = document.getElementById(`max-price-${pid}`) as HTMLInputElement;
        const avgPrice = document.getElementById(`avg-price-${pid}`) as HTMLInputElement;
        const tagAdded = document.querySelectorAll(`.productDetail-tag-${pid}`);

        let customTagCategoriesArray: Array<string> = customTagCategories.value.split(',');
        customTagCategoriesArray.forEach((tag, index) => {
            customTagCategoriesArray[index] = tag.trim();
        });
        customTagCategoriesArray = customTagCategoriesArray.filter((e) => e);
        if (customTagCategories.value === null || customTagCategories.value === '') {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Product Categories Tags Empty!');
            } else {
                this.showAlertError('Product Categories Tags Empty!');
            }
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customTagCategoriesArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            this.brandInfo.product_categories.forEach((productCategory, index2) => {
                if (productCategory.id === pid) {
                    if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                        // tslint:disable-next-line:radix
                        productCategory.min_price = parseInt(minPrice.value);
                        // tslint:disable-next-line:radix
                        productCategory.max_price = parseInt(maxPrice.value);
                        // tslint:disable-next-line:radix
                        productCategory.avg_price = parseInt(avgPrice.value);
                        if (productCategory.tags != null) {
                            // tslint:disable-next-line:prefer-for-of
                            for (let i = 0; i < productCategory.tags.length; i++) {
                                uniqueTags.push(productCategory.tags[i]);
                            }
                        }
                        // tslint:disable-next-line:only-arrow-functions
                        productCategory.tags = uniqueTags.filter(function(elem, index, self) {
                            return index === self.indexOf(elem);
                        });

                    } else {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Price range for product category is required');
                        } else {
                            this.showAlertError('Price range for product category is required');
                        }
                    }
                }
            });

            this.updateBrandProductCategories(false).then(() => {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < tagAdded.length; i++) {
                    tagAdded[i].classList.add('d-block');
                }
                this.initBrandInfo();
            });
        }
    }

    addProductDetailsTags(pid) {
        this.proDText = '';
        const customTagCategories = document.getElementById(`DetailTag-${pid}`) as HTMLInputElement;
        const minPrice = document.getElementById(`min-price-${pid}`) as HTMLInputElement;
        const maxPrice = document.getElementById(`max-price-${pid}`) as HTMLInputElement;
        const avgPrice = document.getElementById(`avg-price-${pid}`) as HTMLInputElement;
        const tagAdded = document.querySelectorAll(`.productDetail-tag-${pid}`);
        let customTagCategoriesArray: Array<string> = customTagCategories.value.split(',');
        customTagCategoriesArray.forEach((tag, index) => {
            customTagCategoriesArray[index] = tag.trim();
        });
        customTagCategoriesArray = customTagCategoriesArray.filter((e) => e);
        if (customTagCategories.value === null || customTagCategories.value === '') {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Product Categories Tags Empty!');
            } else {
                this.showAlertError('Product Categories Tags Empty!');
            }
        } else {
            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customTagCategoriesArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            this.brandInfo.product_categories.forEach((productCategory, index2) => {
                if (productCategory.id === pid) {
                    if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                        // tslint:disable-next-line:radix
                        productCategory.min_price = parseInt(minPrice.value);
                        // tslint:disable-next-line:radix
                        productCategory.max_price = parseInt(maxPrice.value);
                        // tslint:disable-next-line:radix
                        productCategory.avg_price = parseInt(avgPrice.value);
                        if (productCategory.tags != null) {
                            // tslint:disable-next-line:prefer-for-of
                            for (let i = 0; i < productCategory.tags.length; i++) {
                                uniqueTags.push(productCategory.tags[i]);
                            }
                        }
                        // tslint:disable-next-line:only-arrow-functions
                        productCategory.tags = uniqueTags.filter(function(elem, index, self) {
                            return index === self.indexOf(elem);
                        });

                    } else {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Price range for product category is required');
                        } else {
                            this.showAlertError('Price range for product category is required');
                        }
                    }
                }
            });

            this.updateBrandProductCategories(false).then(() => {

                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < tagAdded.length; i++) {
                    tagAdded[i].classList.add('d-block');
                }

                this.initBrandInfo();
            });
        }
    }

    removeProductCategoryTags(pid, tagWord) {
        const productCategory = this.brandInfo.product_categories.find((x) => x.id === pid);
        const index: number = productCategory.tags.indexOf(tagWord);
        if (index !== -1) {
            productCategory.tags.splice(index, 1);
        }

        this.brandInfo.product_categories.find((x) => x.id === pid).tags = productCategory.tags;
        this.updateBrandProductCategories(false);
    }

    /**
     * Check if product category is already selected or not
     */
    productCategorySelected(productCategory: ProductCategory) {
        for (const selectedCategory of this.brandInfo.product_categories) {
            if (selectedCategory.id === productCategory.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Routine to remove a selected category
     */
    removeSelectedCategory(productCategory: ProductCategory) {
        this.brandInfo.product_categories = this.brandInfo.product_categories.filter(
            (obj) => obj.id !== productCategory.id
        );
    }

    /**
     * Handler for product category toggle
     */
    toggleBrandCategory(productCategory: ProductCategory) {
        const alreadySelected: boolean = this.productCategorySelected(productCategory);

        const index = this.autoProductCategory.indexOf(productCategory);
        if (index > -1) {
            this.autoProductCategory.splice(index, 1);
        } else {
            this.autoProductCategory.push(productCategory);
        }

        if (alreadySelected) {
            this.removeSelectedCategory(productCategory);

            if (this.brandInfo.product_categories === undefined || this.brandInfo.product_categories.length === 0) {
                this.brandInfo.product_categories = [];
            }
        } else {
            const bpc = new BrandProductCategory(productCategory.id);
            this.brandInfo.product_categories.push(bpc);
        }

        if (+this.brandInfo.getProductCategoryCount(this.productCategories) > 1) {
            this.pcMaxLength = 2;
        } else {
            this.pcMaxLength = 3;
            this.autoSetProductCategories = true;
        }

        if (this.autoSetProductCategories) {
            this.setAutoPercentage();
        }

        this.updateBrandProductCategories(false);

    }

    setAutoPercentage() {
        const selected = this.autoProductCategory;
        // const selNumber = this.brandInfo.product_categories.length;
        const selNumber = this.brandInfo.getProductCategoryCount(this.productCategories, true);
        let currentSet = [];
        currentSet = this.getSliderValuesSet(selNumber);
        this.productCategories.forEach((productCategory, index2) => {
            if (productCategory.is_predefined) {
                this.brandInfo.product_categories.forEach((element, index) => {
                    if (productCategory.id === element.id) {
                        // tslint:disable-next-line:radix
                        element.percentage = parseInt(currentSet.shift());
                    }
                });
            }
        });
    }

    /**
     * On click handler for Product Categories "next" click
     */
    submitBrandProductCategories() {
        if (this.ageRequirementSelected < 1) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Minimum age requirement field is mandatory.');
            } else {
                this.showAlertError('Minimum age requirement field is mandatory.');
            }
            return;
        }
        if (this.ageRequirementSelected === 2 && (!this.ageRequirement || this.ageRequirement < 1)) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Minimum age requirement field is mandatory.');
            } else {
                this.showAlertError('Minimum age requirement field is mandatory.');
            }
            return;
        }


        this.updateMinimumAgeRequirement();

        if (this.brandInfo.getProductCategoryCount(this.productCategories) > 0) {
            if (this.total === 100) {
                this.updateBrandProductCategories(true)
                    .then((resp) => {
                        sessionStorage.setItem('next_tab', '4');
                        this.tabToggle(4);
                        return false;
                    })
                    .catch((err) => {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Something went wrong!');
                        } else {
                            this.showAlertError('Something went wrong!');
                        }

                        return false;
                    });
            } else if (this.total > 100) {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Whoops! Allocate 100% To Proceed!');
                } else {
                    this.showAlertError('Oops! You’ve gone too far. Allocate 100% before continuing.');
                }
            } else if (this.total < 100 || this.total === 0 || !this.total) {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Allocate remaining percentage points before continuing.');
                } else {
                    this.showAlertError('Allocate remaining percentage points before continuing.');
                }
            } else {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Select a Category to continue.');
                } else {
                    this.showAlertError('Select a Category to continue.');
                }
            }
        } else {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Select a Category to continue.');
            } else {
                this.showAlertError('Select a Category to continue.');
            }
        }
    }

    /**
     * Handler for Product Category Details Submission, including the update to DB
     */
    submitBrandProductCategoriesDetails() {
        const updatedProductCategories = [];
        let invalidCount = 0;

        const validate = new Promise((resolve) => {
            this.brandInfo.product_categories.forEach((productCategory, index) => {
                const errorSpan = document.getElementById(`pc-error-${productCategory.id}`) as HTMLElement;
                if (errorSpan !== null) {
                    errorSpan.innerText = '';
                    let minPrice;
                    let maxPrice;
                    let avgPrice;
                    if (window.innerWidth < 770) {
                        minPrice = document.getElementById(`min-price-${productCategory.id}-d`) as HTMLInputElement;
                        maxPrice = document.getElementById(`max-price-${productCategory.id}-d`) as HTMLInputElement;
                        avgPrice = document.getElementById(`avg-price-${productCategory.id}-d`) as HTMLInputElement;
                    } else {
                        minPrice = document.getElementById(`min-price-${productCategory.id}`) as HTMLInputElement;
                        maxPrice = document.getElementById(`max-price-${productCategory.id}`) as HTMLInputElement;
                        avgPrice = document.getElementById(`avg-price-${productCategory.id}`) as HTMLInputElement;
                    }

                    if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                        // tslint:disable-next-line:radix
                        if (parseInt(minPrice.value) > parseInt(maxPrice.value)) {
                            errorSpan.innerText = 'Min Price should be less than Max Price';
                            invalidCount++;
                            // tslint:disable-next-line:radix
                        } else if (parseInt(maxPrice.value) < parseInt(avgPrice.value)) {
                            errorSpan.innerText = 'Max Price should be greater than Average Price';
                            invalidCount++;
                            // tslint:disable-next-line:radix
                        } else if (parseInt(minPrice.value) > parseInt(avgPrice.value)) {
                            errorSpan.innerText = 'Min Price should be less than Average Price';
                            invalidCount++;
                        } else {
                            // tslint:disable-next-line:radix
                            const upc = new BrandProductCategory(
                                productCategory.id,
                                // tslint:disable-next-line:radix
                                parseInt(minPrice.value),
                                // tslint:disable-next-line:radix
                                parseInt(maxPrice.value),
                                // tslint:disable-next-line:radix
                                parseInt(avgPrice.value),
                                productCategory.tags,
                                productCategory.percentage
                            );
                            updatedProductCategories.push(upc._obj());
                        }
                    }
                } else {
                    const upc = new BrandProductCategory(
                        productCategory.id,
                        null,
                        // tslint:disable-next-line:radix
                        null,
                        null,
                        productCategory.tags,
                        productCategory.percentage
                    );
                    updatedProductCategories.push(upc._obj());
                }
                if (index === this.brandInfo.product_categories.length - 1) {
                    resolve(true);
                }
            });
        });

        validate.then(() => {
            if (invalidCount < 1) {
                const brandDetails = {
                    brandsignupchecklist: {
                        provided_category_details: true,
                    },
                    product_categories: updatedProductCategories,
                };

                this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
                    (brand) => {
                        this.authService.updateCurrentUserBrand(brand);
                        this.productCategories.forEach( (item, index) => {
                            if (item.is_predefined && item.selected) {
                                updatedProductCategories.forEach( (upc, index2) => {
                                    if (item.id === upc.id) {
                                        item.min_price = upc.min_price;
                                        item.max_price = upc.max_price;
                                        item.avg_price = upc.avg_price;
                                        item.tags = upc.tags;
                                    }
                                });
                            }
                        });
                        sessionStorage.setItem('next_tab', '5');
                        this.tabToggle(5);
                    },
                    (err) => {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Something went wrong while updating the brand info!');
                        } else {
                            this.showAlertError('Something went wrong while updating the brand info!');
                        }
                    }
                );
            }
        });

    }

    // <======================================================vibes=======================================================================>

    updateVibe() {
        return new Promise((resolve, reject) => {
            const updatedVibes = [];

            this.brandInfo.vibes.forEach((vibe, index) => {
                const uv = new BrandVibe(vibe.id, vibe.percentage);
                updatedVibes.push(uv._obj());
            });

            const brandDetails = {
                brandsignupchecklist: {
                    selected_brand_vibes: true,
                },
                vibes: updatedVibes,
            };

            this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
                (brand) => {
                    this.authService.updateCurrentUserBrand(brand);
                    this.initBrandInfo();
                    resolve(true);
                },
                (err) => {
                    if (this.responsiveWidth < 768) {
                        this.toastr.error('Something went wrong while updating the brand info!');
                    } else {
                        this.showAlertError('Something went wrong while updating the brand info!');
                    }
                    reject(false);
                }
            );
        });
    }

    addCustomVibeTags() {
        let timer: Timer;
        this.tagAdded = false;
        clearTimeout(timer);
        this.vibeTxt = '';
        const customVibeTag = document.getElementById(`vibeTags`) as HTMLInputElement;
        const customVibeTagsArray: Array<string> = customVibeTag.value.split(',');
        if (customVibeTag.value === null || customVibeTag.value === '') {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Vibe Tags Empty!');
            } else {
                this.showAlertError('Vibe Tags Empty!');
            }
        } else {
            customVibeTagsArray.forEach((customTag, index) => {
                let exists = false;
                customTag = customTag.trim();
                this.vibes.forEach((vibe, index2) => {
                    if (customTag !== '') {
                        if (vibe.title === customTag && !vibe.is_predefined) {
                            exists = true;
                            const bv = new BrandVibe(vibe.id);
                            this.brandInfo.vibes.push(bv);
                        }
                    }
                });

                if (!exists && customTag !== '') {
                    const data = {
                        title: customTag,
                    };
                    this.vibeService.CreateVibes(data).subscribe(
                        (vibe) => {
                            const bv = new BrandVibe(vibe.id);
                            this.brandInfo.vibes.push(bv);
                            this.updateVibe();
                            this.tagAdded = true;
                            timer = setTimeout(() => {
                                this.tagAdded = false;
                            }, 3000);
                        },
                        (err) => {
                            if (this.responsiveWidth < 768) {
                                this.toastr.error('Tag is same as Vibes name!');
                            } else {
                                this.showAlertError('Tag is same as Vibes name!');
                            }
                        }
                    );
                } else {
                    // this.toastr.error('Tag already exists');
                    this.tagAdded = true;
                    timer = setTimeout(() => {
                        this.tagAdded = false;
                    }, 3000);
                    this.updateVibe();
                }
            });
            customVibeTag.value = '';
        }
    }

    vibeSelected(vibe: Vibe) {
        for (const selectedVibe of this.brandInfo.vibes) {
            if (selectedVibe.id === vibe.id) {
                return true;
            }
        }
        return false;
    }

    removeSelectedVibe(vibe: Vibe) {
        this.brandInfo.vibes = this.brandInfo.vibes.filter((obj) => obj.id !== vibe.id);
    }

    toggleBrandVibe(vibe: Vibe) {
        const alreadySelected: boolean = this.vibeSelected(vibe);

        const index = this.autoVibes.indexOf(vibe);
        if (index > -1) {
            this.autoVibes.splice(index, 1);
        } else {
            this.autoVibes.push(vibe);
        }

        if (alreadySelected) {
            this.removeSelectedVibe(vibe);

            if (this.brandInfo.vibes === undefined || this.brandInfo.vibes.length === 0) {
                this.brandInfo.vibes = [];
            }
        } else {
            const bv = new BrandVibe(vibe.id);
            this.brandInfo.vibes.push(bv);
        }

        if (+this.brandInfo.getVibesCount(this.vibes) > 1) {
            this.vbMaxLength = 2;
        } else {
            this.vbMaxLength = 3;
            this.autoSetVibes = true;
        }

        if (this.autoSetVibes) {
            this.setAutoVibePercentage();
        }


        this.changeDetectorRef.markForCheck();

        this.updateVibe();
    }

    setAutoVibePercentage() {
        const selected = this.autoVibes;
        const selNumber = this.brandInfo.getVibesCount(this.vibes, true);
        let currentSet = [];
        currentSet = this.getSliderValuesSet(selNumber);
        this.vibes.forEach((vibe, index2) => {
            if (vibe.is_predefined) {
                this.brandInfo.vibes.forEach((element, index) => {
                    if (vibe.id === element.id) {
                        // tslint:disable-next-line:radix
                        element.percentage = parseInt(currentSet.shift());
                    }
                });
            }
        });
    }

    submitBrandVibes() {
        if (this.brandInfo.getVibesCount(this.vibes) > 0) {
            if (this.vibesTotalPercentage === 100) {
                this.updateVibe()
                    .then((resp) => {
                        sessionStorage.setItem('next_tab', '6');
                        this.tabToggle(6);
                        return false;
                    })
                    .catch((err) => {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Something went wrong!');
                        } else {
                            this.showAlertError('Something went wrong!');
                        }
                        return false;
                    });
            } else if (this.vibesTotalPercentage > 100) {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Whoops! Allocate 100% To Proceed!');
                } else {
                    this.showAlertError('Oops! You’ve gone too far. Allocate 100% before continuing.');
                }
            } else if (this.vibesTotalPercentage < 100) {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Allocate remaining percentage points before continuing.');
                } else {
                    this.showAlertError('Allocate remaining percentage points before continuing.');
                }
            } else {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Select a vibe to continue.');
                } else {
                    this.showAlertError('Select a vibe to continue.');
                }
            }
        } else {
            if (this.responsiveWidth < 768) {
                this.toastr.error('We know you’ve got a great vibe, please select at least one so others know too!');
            } else {
                this.showAlertError('We know you’ve got a great vibe, please select at least one so others know too!');
            }
        }
    }

    removeVibesTags(tagid) {
        if (this.brandInfo.vibes.length !== 1) {
            const index: number = this.brandInfo.vibes.indexOf(this.brandInfo.vibes.find((x) => x.id === tagid));
            if (tagid !== -1) {
                this.brandInfo.vibes.splice(index, 1);
            }
            this.updateVibe();
        } else {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Minimum one vibe is required!');
            } else {
                this.showAlertError('Minimum one vibe is required!');
            }
        }
    }

    // <===================================================Value================================================================>

    updateValue() {
        return new Promise((resolve, reject) => {
            const updatedValues = [];

            this.brandInfo.values.forEach((value, index) => {
                const uv = new BrandValue(value.id, value.percentage);
                updatedValues.push(uv._obj());
            });

            const brandDetails = {
                brandsignupchecklist: {
                    selected_brand_values: true,
                },
                values: updatedValues,
            };

            this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
                (brand) => {
                    this.authService.updateCurrentUserBrand(brand);
                    this.initBrandInfo();
                    resolve(true);
                },
                (err) => {
                    if (this.responsiveWidth < 768) {
                        this.toastr.error('Something went wrong while updating the brand info!');
                    } else {
                        this.showAlertError('Something went wrong while updating the brand info!');
                    }
                    reject(false);
                }
            );
        });
    }

    addCustomValueTags() {
        let timer: Timer;
        this.tagAdded = false;
        clearTimeout(timer);
        this.valueTxt = '';
        const customValueTag = document.getElementById(`valueTags`) as HTMLInputElement;
        const customValueTagsArray: Array<string> = customValueTag.value.split(',');
        if (customValueTag.value === null || customValueTag.value === '') {
            this.showAlertError('Value Tags Empty!');
        } else {
            customValueTagsArray.forEach((customTag, index) => {
                let exists = false;
                customTag = customTag.trim();
                this.values.forEach((value, index2) => {
                    if (customTag !== '') {
                        if (value.title === customTag && !value.is_predefined) {
                            exists = true;
                            const bv = new BrandValue(value.id);
                            this.brandInfo.values.push(bv);
                        }
                    }
                });

                if (!exists && customTag !== '') {
                    const data = {
                        title: customTag,
                    };
                    this.valueService.CreateValue(data).subscribe(
                        (value) => {
                            const bv = new BrandValue(value.id);
                            this.brandInfo.values.push(bv);
                            this.tagAdded = true;
                            timer = setTimeout(() => {
                                this.tagAdded = false;
                            }, 3000);
                            this.updateValue();
                        },
                        (err) => {
                            this.showAlertError('Tag is same as Values name!');
                        }
                    );
                } else {
                    // this.toastr.error('Tag already exists');
                    this.tagAdded = true;
                    timer = setTimeout(() => {
                        this.tagAdded = false;
                    }, 3000);
                    this.updateValue();
                }
            });
            customValueTag.value = '';
        }
    }

    valueSelected(value: Value) {
        for (const selectedValue of this.brandInfo.values) {
            if (selectedValue.id === value.id) {
                return true;
            }
        }
        return false;
    }

    removeSelectedValue(value: Value) {
        this.brandInfo.values = this.brandInfo.values.filter((obj) => obj.id !== value.id);
    }

    toggleBrandValue(value: Value) {
        const alreadySelected: boolean = this.valueSelected(value);

        const index = this.autoVibes.indexOf(value);
        if (index > -1) {
            this.autoVibes.splice(index, 1);
        } else {
            this.autoVibes.push(value);
        }

        if (alreadySelected) {
            this.removeSelectedValue(value);

            if (this.brandInfo.values === undefined || this.brandInfo.values.length === 0) {
                this.brandInfo.values = [];
            }
        } else {
            const bv = new BrandValue(value.id);
            this.brandInfo.values.push(bv);
        }

        if (+this.brandInfo.getValuesCount(this.values) > 1) {
            this.valMaxLength = 2;
        } else {
            this.valMaxLength = 3;
            this.autoSetValues = true;
        }

        if (this.autoSetValues) {
            this.setAutoValuePercentage();
        }
        this.updateValue();
    }

    setAutoValuePercentage() {
        const selNumber = this.brandInfo.getValuesCount(this.values, true);
        let currentSet = [];
        currentSet = this.getSliderValuesSet(selNumber);
        this.values.forEach((value, index2) => {
            if (value.is_predefined) {
                this.brandInfo.values.forEach((element, index) => {
                    if (value.id === element.id) {
                        // tslint:disable-next-line:radix
                        element.percentage = parseInt(currentSet.shift());
                    }
                });
            }
        });
    }

    submitBrandValues() {
        if (this.brandInfo.getValuesCount(this.values) > 0) {
            if (this.totalBrandValuesPer === 100) {
                this.updateValue()
                    .then((resp) => {
                        this.tabToggle(7);
                        sessionStorage.setItem('next_tab', '7');
                        return false;
                    })
                    .catch((err) => {
                        if (this.responsiveWidth < 768) {
                            this.toastr.error('Something went wrong!');
                        } else {
                            this.showAlertError('Something went wrong!');
                        }
                        return false;
                    });
            } else if (this.totalBrandValuesPer > 100) {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Whoops! Allocate 100% To Proceed!');
                } else {
                    this.showAlertError('Oops! You’ve gone too far. Allocate 100% before continuing.');
                }
            } else if (this.total < 100) {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Allocate remaining percentage points before continuing.');
                } else {
                    this.showAlertError('Allocate remaining percentage points before continuing.');
                }
            } else {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Select a value to continue.');
                } else {
                    this.showAlertError('Select a value to continue.');
                }
            }
        } else {
            if (this.responsiveWidth < 768) {
                this.toastr.error(
                    'Values are important to let consumers know what you’re working with! Please select at least one!'
                );
            } else {
                this.showAlertError(
                    'Values are important to let consumers know what you’re working with! Please select at least one!'
                );
            }
        }
    }

    removeValuesTags(tagid) {
        if (this.brandInfo.values.length !== 1) {
            const index: number = this.brandInfo.values.indexOf(this.brandInfo.values.find((x) => x.id === tagid));
            if (tagid !== -1) {
                this.brandInfo.values.splice(index, 1);
            }
            this.updateValue();
        } else {
            this.showAlertError('Minimum one value is required!');
        }
    }

    // <===================================================Social Causes================================================================>

    updateCauses() {
        const updatedSocialCauses = [];

        this.brandInfo.social_causes.forEach((value, index) => {
            const uv = new BrandSocialCause(value.id);
            updatedSocialCauses.push(uv._obj());
        });

        const brandDetails = {
            brandsignupchecklist: {
                selected_social_values: true,
            },
            social_causes: updatedSocialCauses,
        };

        this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
                this.initBrandInfo();
            },
            (err) => {
                this.showAlertError('Something went wrong while updating the brand info!');
            }
        );
    }

    addCustomSocialTags() {
        let timer: Timer;
        this.tagAdded = false;
        clearTimeout(timer);
        this.scsTxt = '';
        const customSocialTag = document.getElementById(`socialTags`) as HTMLInputElement;
        const customSocialTagsArray: Array<string> = customSocialTag.value.split(',');
        if (customSocialTag.value === null || customSocialTag.value === '') {
            this.showAlertError('Social Causes Tags Empty!');
        } else {
            customSocialTagsArray.forEach((customTag, index) => {
                let exists = false;
                customTag = customTag.trim();
                this.socialCauses.forEach((socialCause, index2) => {
                    if (customTag !== '') {
                        if (socialCause.title === customTag && !socialCause.is_predefined) {
                            exists = true;
                            const bsc = new BrandSocialCause(socialCause.id);
                            this.brandInfo.social_causes.push(bsc);
                        }
                    }
                });

                if (!exists && customTag !== '') {
                    const data = {
                        title: customTag,
                    };
                    this.socialCauseService.CreateSocialCause(data).subscribe(
                        (socialCause) => {
                            const bsc = new BrandSocialCause(socialCause.id);
                            this.brandInfo.social_causes.push(bsc);
                            this.tagAdded = true;
                            timer = setTimeout(() => {
                                this.tagAdded = false;
                            }, 3000);
                            this.updateCauses();
                        },
                        (err) => {
                            this.showAlertError('Tag is same as Social Cause name!');
                        }
                    );
                } else {
                    this.tagAdded = true;
                    timer = setTimeout(() => {
                        this.tagAdded = false;
                    }, 3000);
                    this.updateCauses();
                }
            });
            customSocialTag.value = '';
        }
    }

    addCustomSocialOthers(e) {
        const customSocialOther = document.getElementById(`socialCauseother`) as HTMLInputElement;
        const customSocialOtherArray: Array<string> = customSocialOther.value.split(',');
        if (customSocialOther.value === null || customSocialOther.value === '') {
        } else {

            customSocialOtherArray.forEach((customOther, index) => {
                let exists = false;
                this.socialCauses.forEach((socialCause, index2) => {
                    if (socialCause.title === customOther) {
                        exists = true;
                        const bsc = new BrandSocialCause(socialCause.id);
                        this.brandInfo.social_causes.push(bsc);
                        this.updateCauses();
                        customSocialOther.value = '';
                    }
                });

                if (!exists) {
                    const data = {
                        title: customOther,
                        is_other: true,
                    };
                    this.socialCauseService.CreateSocialCause(data).subscribe((socialCause) => {
                        const bsc = new BrandSocialCause(socialCause.id);
                        this.brandInfo.social_causes.push(bsc);

                        this.updateCauses();
                        customSocialOther.value = '';
                    }, error => {
                        console.log(error);
                        this.updateCauses();
                        customSocialOther.value = '';

                    });
                } else {
                    this.updateCauses();
                    customSocialOther.value = '';
                }
            });
        }
    }

    socialCauseSelected(socialCause: SocialCause) {
        for (const selectedSocialCause of this.brandInfo.social_causes) {
            if (selectedSocialCause.id === socialCause.id) {
                return true;
            }
        }
        return false;
    }

    removeSelectedSocialCause(socialCause: SocialCause) {
        this.brandInfo.social_causes = this.brandInfo.social_causes.filter((obj) => obj.id !== socialCause.id);
    }

    toggleBrandSocialCause(socialCause: SocialCause) {
        const alreadySelected: boolean = this.socialCauseSelected(socialCause);

        if (alreadySelected) {
            this.removeSelectedSocialCause(socialCause);

            if (this.brandInfo.social_causes === undefined || this.brandInfo.social_causes.length === 0) {
                this.brandInfo.social_causes = [];
            }
        } else {
            const bsc = new BrandSocialCause(socialCause.id);
            this.brandInfo.social_causes.push(bsc);
        }

        const updatedSocialCauses = [];

        this.brandInfo.social_causes.forEach((sc, index) => {
            const ubsc = new BrandSocialCause(sc.id);
            updatedSocialCauses.push(ubsc._obj());
        });

        const brandDetails = {
            brandsignupchecklist: {
                selected_social_values: true,
            },
            social_causes: updatedSocialCauses,
        };

        this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
            },
            (err) => {
                this.showAlertError('Something went wrong while updating the brand info!');
            }
        );
    }

    submitBrandSocialCauses() {


        const brandDetails = {
            brandsignupchecklist: {
                selected_social_values: true,
            },
        };

        this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
            },
            (err) => {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Something went wrong while updating the brand info!');
                }
            }
        );
        sessionStorage.setItem('next_tab', '8');
        this.tabToggle(8);

        return false;
    }

    removeSocialCausesTags(tagid) {
        if (this.brandInfo.social_causes.length !== 1) {
            const index: number = this.brandInfo.social_causes.indexOf(
                this.brandInfo.social_causes.find((x) => x.id === tagid)
            );
            if (tagid !== -1) {
                this.brandInfo.social_causes.splice(index, 1);
            }
            this.updateCauses();
        } else {
            this.showAlertError('Minimum one social cause is required!');
        }
    }

    toggleOtherSocialCauseShow() {
        this.isOtherSocialCauseShown = !this.isOtherSocialCauseShown;
    }

    // <===================================================Brand Description===============================================================>

    submitBrandDescription() {
        const description = document.getElementById(`branddescriptioncomment`) as HTMLInputElement;

        if (description.value.length < 3) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Brand Description is required!');
            } else {
                this.showAlertError('Brand Description is required!');
            }
        } else {
            const brandDetails = {
                brandsignupchecklist: {
                    provided_brand_description: true,
                },
                description: description.value,
                tags: this.brandInfo.tags,
            };

            this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
                (brand) => {
                    this.authService.updateCurrentUserBrand(brand);
                    sessionStorage.setItem('next_tab', '9');
                    this.tabToggle(9);
                    return false;
                },
                (err) => {
                    if (this.responsiveWidth > 768) {
                        this.showAlertError('Something went wrong while updating the brand info!');
                    }
                }
            );
        }
    }

    addBrandDescriptionTags() {
        let timer: Timer;
        this.tagAdded = false;
        clearTimeout(timer);
        this.desTxt = '';
        const customBrandTags = document.getElementById(`brandDescriptionTags`) as HTMLInputElement;
        let customBrandTagsArray: Array<string> = customBrandTags.value.split(',');
        customBrandTagsArray.forEach((tag, index) => {
            customBrandTagsArray[index] = tag.trim();
        });
        customBrandTagsArray = customBrandTagsArray.filter((e) => e);
        if (customBrandTags.value === null || customBrandTags.value === '') {
            if (this.responsiveWidth > 768) {
                this.showAlertError('Brand Description Tags Empty!');
            }
        } else {

            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customBrandTagsArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            if (this.brandInfo.tags != null) {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.brandInfo.tags.length; i++) {
                    uniqueTags.push(this.brandInfo.tags[i]);
                }
            }
            this.brandInfo.tags = uniqueTags.filter((elem, index, self) => index === self.indexOf(elem));
            customBrandTags.value = '';
            this.tagAdded = true;
            timer = setTimeout(() => {
                this.tagAdded = false;
            }, 3000);
        }
    }

    removeBrandDescriptionTags(tagWord) {
        const index: number = this.brandInfo.tags.indexOf(tagWord);
        if (index !== -1) {
            this.brandInfo.tags.splice(index, 1);
        }
    }

    // <===================================================Customer Profile================================================================>

    submitCustomerProfile() {
        const customerProfile = {
            brandsignupchecklist: {
                shared_customer_profile: true,
            },
            brandcustomerprofile: this.brandInfo.brandcustomerprofile,
        };

        this.brandService.UpdateBrandById(this.brandInfo.id, customerProfile).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
                sessionStorage.setItem('next_tab', '10');
                this.tabToggle(10);
                return false;
            },
            (err) => {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Something went wrong while updating the customer Profile!');
                } else {
                    this.showAlertError('Something went wrong while updating the customer Profile!');
                }
            }
        );
    }

    addCustomerInterestsTags() {
        this.pubTxt = '';
        const customerInterestsTags = document.getElementById(`customerinterest`) as HTMLInputElement;
        let customerInterestsTagsArray: Array<string> = customerInterestsTags.value.split(',');
        customerInterestsTagsArray.forEach((tag, index) => {
            customerInterestsTagsArray[index] = tag.trim();
        });
        const tagAdded = document.getElementById('customerInterestTags') as HTMLElement;
        customerInterestsTagsArray = customerInterestsTagsArray.filter((e) => e);
        if (customerInterestsTags.value === null || customerInterestsTags.value === '') {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Customer Interest Tags Empty!');
            } else {
                this.showAlertError('Customer Interest Tags Empty!');
            }
        } else {

            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customerInterestsTagsArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            if (this.brandInfo.brandcustomerprofile.customer_interests != null) {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.brandInfo.brandcustomerprofile.customer_interests.length; i++) {
                    uniqueTags.push(this.brandInfo.brandcustomerprofile.customer_interests[i]);
                }
            }


            this.brandInfo.brandcustomerprofile.customer_interests = uniqueTags.filter(
                (elem, index, self) => index === self.indexOf(elem)
            );
            customerInterestsTags.value = '';
            tagAdded.style.display = 'block';
            setTimeout(() => {
                tagAdded.style.display = 'none';
            }, 3000);
        }
    }

    removeCustomerInterestTag(tagWord) {
        const index: number = this.brandInfo.brandcustomerprofile.customer_interests.indexOf(tagWord);
        if (index !== -1) {
            this.brandInfo.brandcustomerprofile.customer_interests.splice(index, 1);
        }
    }

    addCustomerDescriptionTags() {
        this.cusDesTxt = '';
        const customerDescriptionTags = document.getElementById(`customerDescription`) as HTMLInputElement;
        let customerDescriptionTagsArray: Array<string> = customerDescriptionTags.value.split(',');
        const tagAdded = document.getElementById('customerLifeTags') as HTMLElement;
        customerDescriptionTagsArray.forEach((tag, index) => {
            customerDescriptionTagsArray[index] = tag.trim();
        });
        customerDescriptionTagsArray = customerDescriptionTagsArray.filter((e) => e);
        if (customerDescriptionTags.value === null || customerDescriptionTags.value === '') {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Customer Description Tags Empty!');
            } else {
                this.showAlertError('Customer Description Tags Empty!');
            }
        } else {

            // tslint:disable-next-line:only-arrow-functions
            const uniqueTags = customerDescriptionTagsArray.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });

            if (this.brandInfo.brandcustomerprofile.customer_life_stages != null) {
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < this.brandInfo.brandcustomerprofile.customer_life_stages.length; i++) {
                    uniqueTags.push(this.brandInfo.brandcustomerprofile.customer_life_stages[i]);
                }
            }
            this.brandInfo.brandcustomerprofile.customer_life_stages = uniqueTags.filter(
                (elem, index, self) => index === self.indexOf(elem)
            );
            tagAdded.style.display = 'block';
            setTimeout(() => {
                tagAdded.style.display = 'none';
            }, 3000);
            customerDescriptionTags.value = '';
        }
    }

    removeCustomerDescriptionTag(tagWord) {
        const index: number = this.brandInfo.brandcustomerprofile.customer_life_stages.indexOf(tagWord);
        if (index !== -1) {
            this.brandInfo.brandcustomerprofile.customer_life_stages.splice(index, 1);
        }
    }

    // <=================================================Product Category Chart & Sliders================================================>

    sumOfProductCategoriesPercentages() {
        this.total = 0;
        this.brandInfo.product_categories.forEach((productCategory, index) => {
            // @ts-ignore
            // tslint:disable-next-line:radix
            this.total += parseFloat(productCategory.percentage);
        });

        return this.total;
    }

    getProductCategoryChartData() {
        // tslint:disable-next-line:prefer-for-of
        if (this.sumOfProductCategoriesPercentages() === 0) {
            return ['100'];
        }
        this.valsArr = [];
        if (Array.isArray(this.productCategories) && this.productCategories.length) {
            this.productCategories.forEach((productCategory, index) => {
                this.valsArr.push(productCategory.percentage);
            });
        }
        return this.valsArr;
    }

    getProductCategoryChartsColors() {
        if (this.sumOfProductCategoriesPercentages() === 0) {
            return [{backgroundColor: ['#f0f0f0']}];
        }
        const colors = [];
        if (Array.isArray(this.productCategories) && this.productCategories.length) {
            this.productCategories.forEach((productCategory, index) => {
                if (productCategory.is_predefined) {
                    colors.push(productCategory.color);
                }
            });
        }
        return [{backgroundColor: colors}];
    }

    getProductCategoryChartsLabels() {
        if (this.sumOfProductCategoriesPercentages() === 0) {
            return ['Unallocated'];
        }
        const arr = [];
        if (Array.isArray(this.productCategories) && this.productCategories.length) {
            this.productCategories.forEach((productCategory, index) => {
                if (productCategory.is_predefined) {
                    arr.push(productCategory.title);
                }
            });
        }
        return arr;
    }

    onProductCategorySliderChange($event, productCategory: ProductCategory) {
        let val = $event.target.value;
        this.autoSetProductCategories = false;
        const alreadySelected: boolean = this.productCategorySelected(productCategory);
        const prevTotal = this.total;

        if (val > 100) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Oops! You are not allowed to Enter value greater then 100');
            } else {
                this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
            }

        } else if (val < 1) {
            if (alreadySelected) {
                this.removeSelectedCategory(productCategory);


                if (this.brandInfo.product_categories === undefined || this.brandInfo.product_categories.length === 0) {
                    this.brandInfo.product_categories = [];
                }

                this.updateBrandProductCategories(false).then(() => {
                    this.setAutoPercentage();
                });

            }
        } else {
            if (!alreadySelected) {
                const bpc = new BrandProductCategory(productCategory.id);
                this.brandInfo.product_categories.push(bpc);
                this.updateBrandProductCategories(false).then(() => {
                    this.changeDetectorRef.markForCheck();
                });
            }

            // tslint:disable-next-line:radix
            productCategory.percentage = parseInt(val);
            this.brandInfo.product_categories.forEach((pc, index) => {
                if (pc.id === productCategory.id) {
                    // tslint:disable-next-line:radix
                    pc.percentage = parseInt(val);
                }
            });

            const currTotal = this.sumOfProductCategoriesPercentages();

            if (currTotal > 100) {
                const diff = currTotal - 100;
                val = val - diff;
                this.pcLimitExceed = true;

                // tslint:disable-next-line:radix
                productCategory.percentage = parseInt(val);
                this.brandInfo.product_categories.forEach((pc, index) => {
                    if (pc.id === productCategory.id) {
                        // tslint:disable-next-line:radix
                        pc.percentage = parseInt(val);
                    }
                });
                $event.target.value = `${val}`;
                this.changeDetectorRef.markForCheck();
            } else {
                this.pcLimitExceed = false;
            }

        }
    }

    updateMinimumAgeRequirement(): void {
        const brandData = {
            minimum_age_required: +this.ageRequirement
        };

    }

    // Set value of selected PRODUCT CATEGORY by entering value in input field
    maxPcValueCheck(event: any, productCategory: ProductCategory) {

        // Check if number key is pressed
        const isNumber = /^[0-9]$/i.test(event.key) || event.key === 'Backspace';

        if (isNumber) {

            if (+event.target.value > 100) {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
                }
            }

            this.brandInfo.product_categories.forEach((pc, index) => {
                if (pc.id === productCategory.id) {
                    pc.percentage = +event.target.value;
                    productCategory.percentage = +event.target.value;
                }
            });

        }

    }

    // <=================================================Brand Values Chart & Sliders================================================>

    sumOfBrandValuesPercentages() {
        this.totalBrandValuesPer = 0;
        this.brandInfo.values.forEach((brandValue, index) => {
            // @ts-ignore
            // tslint:disable-next-line:radix
            this.totalBrandValuesPer += parseFloat(brandValue.percentage);
        });

        return this.totalBrandValuesPer;
    }

    getBrandValuesChartData() {
        // tslint:disable-next-line:prefer-for-of
        if (this.sumOfBrandValuesPercentages() === 0) {
            return ['100'];
        }
        this.brandvalsArr = [];
        if (Array.isArray(this.values) && this.values.length) {
            this.values.forEach((brandValue, index) => {
                this.brandvalsArr.push(brandValue.percentage);
            });
        }
        return this.brandvalsArr;
    }

    getBrandValuesChartsColors() {
        if (this.sumOfBrandValuesPercentages() === 0) {
            return [{backgroundColor: ['#f0f0f0']}];
        }
        const brandValuesColors = [];
        if (Array.isArray(this.values) && this.values.length) {
            this.values.forEach((brandValue, index) => {
                if (brandValue.is_predefined) {
                    brandValuesColors.push(brandValue.color);
                }
            });
        }
        return [{backgroundColor: brandValuesColors}];
    }

    getBrandValuesChartsLabels() {
        if (this.sumOfBrandValuesPercentages() === 0) {
            return ['Unallocated'];
        }
        const brandValueArr = [];
        if (Array.isArray(this.values) && this.values.length) {
            this.values.forEach((brandValue, index) => {
                if (brandValue.is_predefined) {
                    brandValueArr.push(brandValue.title);
                }
            });
        }
        return brandValueArr;
    }

    onBrandValuesSliderChange($event, brandValue: Value) {
        let val3 = $event.target.value;
        this.autoSetValues = false;
        const alreadySelected: boolean = this.valueSelected(brandValue);

        if (val3 < 1) {
            if (alreadySelected) {
                this.removeSelectedValue(brandValue);

                if (this.brandInfo.values === undefined || this.brandInfo.values.length === 0) {
                    this.brandInfo.values = [];
                }

                this.updateValue().then(() => {
                    this.setAutoValuePercentage();
                    this.changeDetectorRef.markForCheck();
                });
            }
        } else {
            if (!alreadySelected) {
                const bv = new BrandValue(brandValue.id);
                this.brandInfo.values.push(bv);
                this.updateValue();
                this.changeDetectorRef.markForCheck();
            }
        }


        // tslint:disable-next-line:radix
        brandValue.percentage = parseInt(val3);

        this.brandInfo.values.forEach((bv, index) => {
            if (bv.id === brandValue.id) {
                // tslint:disable-next-line:radix
                bv.percentage = parseInt(val3);
            }
        });

        const currTotal = this.sumOfBrandValuesPercentages();

        if (currTotal > 100) {
            const diff = currTotal - 100;
            val3 = val3 - diff;
            this.valLimitExceed = true;

            // tslint:disable-next-line:radix
            brandValue.percentage = parseInt(val3);
            this.brandInfo.values.forEach((bv, index) => {
                if (bv.id === brandValue.id) {
                    // tslint:disable-next-line:radix
                    bv.percentage = parseInt(val3);
                }
            });
            $event.target.value = `${val3}`;
            this.changeDetectorRef.markForCheck();
        } else {
            this.valLimitExceed = false;
        }


    }

    // Set value of selected VALUE by entering value in input field
    maxVlValueCheck(event: any, value: Value) {

        // Check if number key is pressed
        const isNumber = /^[0-9]$/i.test(event.key) || event.key === 'Backspace';

        if (isNumber) {

            if (+event.target.value > 100) {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
                }
            }

            this.brandInfo.values.forEach((vl, index) => {
                if (vl.id === value.id) {
                    vl.percentage = +event.target.value;
                    value.percentage = +event.target.value;
                }
            });

        }

    }

    // <=================================================Vibes Chart & Sliders================================================>

    sumOfVibesPercentages() {
        this.vibesTotalPercentage = 0;
        this.brandInfo.vibes.forEach((vibe, index) => {
            // @ts-ignore
            // tslint:disable-next-line:radix
            this.vibesTotalPercentage += parseFloat(vibe.percentage);
        });

        return this.vibesTotalPercentage;
    }

    getVibesChartData() {
        // tslint:disable-next-line:prefer-for-of
        if (this.sumOfVibesPercentages() === 0) {
            return ['100'];
        }
        this.valsArr = [];
        if (Array.isArray(this.vibes) && this.vibes.length) {
            this.vibes.forEach((vibe, index) => {
                this.valsArr.push(vibe.percentage);
            });
        }
        return this.valsArr;
    }

    getVibesChartsColors() {
        if (this.sumOfVibesPercentages() === 0) {
            return [{backgroundColor: ['#f0f0f0']}];
        }
        const colors = [];
        if (Array.isArray(this.vibes) && this.vibes.length) {
            this.vibes.forEach((vibe, index) => {
                if (vibe.is_predefined) {
                    colors.push(vibe.color);
                }
            });
        }
        return [{backgroundColor: colors}];
    }

    getVibesChartsLabels() {
        if (this.sumOfVibesPercentages() === 0) {
            return ['Unallocated'];
        }
        const arr = [];
        if (Array.isArray(this.vibes) && this.vibes.length) {
            this.vibes.forEach((vibe, index) => {
                if (vibe.is_predefined) {
                    arr.push(vibe.title);
                }
            });
        }
        return arr;
    }

    onVibesSliderChange($event, vibe: Vibe) {
        let val2 = $event.target.value;
        this.autoSetVibes = false;
        const alreadySelected: boolean = this.vibeSelected(vibe);
        if (val2 > 100) {
            if (this.responsiveWidth < 768) {
                this.toastr.error('Oops! You are not allowed to Enter value greater then 100');
            } else {
                this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
            }
        } else if (val2 < 1) {
            if (alreadySelected) {
                this.removeSelectedVibe(vibe);

                if (this.brandInfo.vibes === undefined || this.brandInfo.vibes.length === 0) {
                    this.brandInfo.vibes = [];
                }

                this.updateVibe().then(() => {
                    this.setAutoVibePercentage();
                });
            }
        } else {

            if (!alreadySelected) {
                const bv = new BrandVibe(vibe.id);
                this.brandInfo.vibes.push(bv);
                this.updateVibe();
                this.changeDetectorRef.markForCheck();
            }

            // tslint:disable-next-line:radix
            vibe.percentage = parseInt(val2);
            this.brandInfo.vibes.forEach((v, index) => {
                if (v.id === vibe.id) {
                    // tslint:disable-next-line:radix
                    v.percentage = parseInt(val2);
                }
            });

            const currTotal = this.sumOfVibesPercentages();

            if (currTotal > 100) {
                this.vibLimitExceed = true;
                const diff = currTotal - 100;
                val2 = val2 - diff;

                this.vibesTotalPercentage = currTotal;

                // tslint:disable-next-line:radix
                vibe.percentage = parseInt(val2);
                this.brandInfo.vibes.forEach((v, index) => {
                    if (v.id === vibe.id) {
                        // tslint:disable-next-line:radix
                        v.percentage = parseInt(val2);
                    }
                });
                $event.target.value = `${val2}`;
                this.changeDetectorRef.markForCheck();
            } else {
                this.vibLimitExceed = false;
            }
        }
    }

    // Set value of selected VIBE by entering value in input field
    maxVbValueCheck(event: any, vibe: Vibe) {

        // Check if number key is pressed
        const isNumber = /^[0-9]$/i.test(event.key) || event.key === 'Backspace';

        if (isNumber) {

            if (+event.target.value > 100) {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Oops! You are not allowed to Enter value greater then 100');
                }
            }

            this.brandInfo.vibes.forEach((pc, index) => {
                if (pc.id === vibe.id) {
                    pc.percentage = +event.target.value;
                    vibe.percentage = +event.target.value;
                }
            });

        }

    }

    // <================================================= Upload Brand Story ================================================>

    uploadBrandStory() {
        if (this.selectedIntroVideo) {
            this.videoUpload.holdData(this.brandInfo.id, this.selectedIntroVideo);
            this.uploadStoryLater();
        } else {
            this.uploadStoryLater();
        }
    }

    add($event?: Event): void {
        this.thumbnailData = null;
        this.selectedIntroVideo = null;
        // @ts-ignore
        const videoFile = $event.target.files[0];
        if (videoFile) {
            // const mbSize = videoFile.size / 1024 / 1024;
            const fileIsMp4 =
                videoFile.type === 'video/mp4' ||
                'video/3gpp' ||
                'video/m4v' ||
                'application/x-mpegURL' ||
                'video/MP2T' ||
                'video/quicktime' ||
                // || 'video/x-msvideo' || 'video/x-flv'
                'video/x-m4v' ||
                'video/wmv' ||
                'video/mov';

            if (!fileIsMp4) {
                this.thumbnailData = 'assets/images/invalidFile.png';
            } else {
                this.generateThumbnail(videoFile)
                    .then(resp => {
                        this.thumbnailData = resp;
                        this.selectedIntroVideo = videoFile;
                    });
            }
        }
    }

    uploadStoryLater() {
        const brandDetails = {
            brandsignupchecklist: {
                uploaded_intro_video: true,
                greeted_on_dashboard: true
            },
        };
        this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
                this.initBrandInfo();
                setTimeout(() => {
                    this.router.navigateByUrl('/brand-journey');
                }, 1000);
            },
            (err) => {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Something went wrong while updating the brand info!');
                } else {
                    this.showAlertError('Something went wrong while updating the brand info!');
                }
            }
        );
    }

    directUpload($event: Event) {
        this.add($event);
        setTimeout(() => {
            this.uploadBrandStory();
        }, 500);
    }

    // <================================================= Other Functions ================================================>

    logout() {
        this.authService.logout();
        this.router.navigate(['/']);
    }

    getSliderValuesSet(selNumber) {
        const slidersSplitValues = [
            [100],
            [50, 50],
            [35, 35, 30],
            [25, 25, 25, 25],
            [20, 20, 20, 20, 20],
            [20, 20, 15, 15, 15, 15],
            [20, 20, 20, 10, 10, 10, 10],
            [20, 20, 10, 10, 10, 10, 10, 10],
            [20, 10, 10, 10, 10, 10, 10, 10, 10],
        ];
        switch (selNumber) {
            case 1:
                return slidersSplitValues[0];

            case 2:
                return slidersSplitValues[1];

            case 3:
                return slidersSplitValues[2];

            case 4:
                return slidersSplitValues[3];

            case 5:
                return slidersSplitValues[4];

            case 6:
                return slidersSplitValues[5];

            case 7:
                return slidersSplitValues[6];

            case 8:
                return slidersSplitValues[7];

            case 9:
                return slidersSplitValues[8];
        }
    }

    maxlengthCheck(event: Event) {
        const inputField = event.target as HTMLInputElement;
        const maxAllowed = inputField.maxLength;
        if (inputField.value.length === maxAllowed) {
            if (this.responsiveWidth < 768) {
                this.toastr.error(`Tags not allowed more then ${maxAllowed} characters!`);
            } else {
                this.showAlertError(`Tags not allowed more then ${maxAllowed} characters!`);
            }
        }
    }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 5000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }

    tagInputCounter(inputElem: HTMLInputElement, elemId: string) {
        const len = inputElem.value.length;
        const max = 25;
        if (len > max) {
            inputElem.value = inputElem.value.substring(0, max);
        } else {
            const remainingCount = max - len;
            document.getElementById(elemId).innerText = `${remainingCount}/${max}`;
        }
    }

    onKeyUp(event) {
        if (event.key === ',') {
            const customCategoriesArray: Array<string> = event.target.value.split(',');

            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Product Categories Should not be Null!');
            } else {
                customCategoriesArray.forEach((customCat, index) => {
                    let exists = false;
                    customCat = customCat.trim();
                    this.productCategories.forEach((productCategory, index2) => {
                        if (customCat !== '') {
                            if (productCategory.title === customCat && !productCategory.is_predefined) {
                                exists = true;
                                const bpc = new BrandProductCategory(productCategory.id);
                                this.brandInfo.product_categories.push(bpc);
                            }
                        }
                    });

                    if (!exists && customCat !== '') {
                        const data = {
                            title: customCat,
                        };
                        this.productCategoryService.CreateProductCategory(data).subscribe(
                            (productCategory) => {
                                const bpc = new BrandProductCategory(productCategory.id);
                                this.brandInfo.product_categories.push(bpc);
                                this.updateBrandProductCategories(false);
                            },
                            (err) => {
                                this.showAlertError('Tag is same as Category name!');
                            }
                        );
                    } else {
                        this.updateBrandProductCategories(false);
                    }
                });
                event.target.value = '';
            }
        }

    }

    onKeyUpCategory(event, pid) {
        if (event.key === ',') {
            const minPrice = document.getElementById(`min-price-${pid}`) as HTMLInputElement;
            const maxPrice = document.getElementById(`max-price-${pid}`) as HTMLInputElement;
            const avgPrice = document.getElementById(`avg-price-${pid}`) as HTMLInputElement;
            let customTagCategoriesArray: Array<string> = event.target.value.split(',');
            customTagCategoriesArray.forEach((tag, index) => {
                customTagCategoriesArray[index] = tag.trim();
            });
            customTagCategoriesArray = customTagCategoriesArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Product Categories Tags Empty!');
            } else {
                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customTagCategoriesArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });

                this.brandInfo.product_categories.forEach((productCategory, index2) => {
                    if (productCategory.id === pid) {
                        if (minPrice !== null && maxPrice !== null && avgPrice !== null) {
                            // tslint:disable-next-line:radix
                            productCategory.min_price = parseInt(minPrice.value);
                            // tslint:disable-next-line:radix
                            productCategory.max_price = parseInt(maxPrice.value);
                            // tslint:disable-next-line:radix
                            productCategory.avg_price = parseInt(avgPrice.value);
                            if (productCategory.tags != null) {
                                // tslint:disable-next-line:prefer-for-of
                                for (let i = 0; i < productCategory.tags.length; i++) {
                                    uniqueTags.push(productCategory.tags[i]);
                                }
                            }
                            // tslint:disable-next-line:only-arrow-functions
                            productCategory.tags = uniqueTags.filter(function(elem, index, self) {
                                return index === self.indexOf(elem);
                            });
                            this.initBrandInfo();
                        } else {
                            this.showAlertError('Price range for product category is required');
                        }
                    }
                });
                this.updateBrandProductCategories(false);
            }
        }
    }

    onKeyUpVibe(event) {
        if (event.key === ',') {
            const customVibeTagsArray: Array<string> = event.target.value.split(',');
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Vibe Tags Empty!');
            } else {
                customVibeTagsArray.forEach((customTag, index) => {
                    let exists = false;
                    customTag = customTag.trim();
                    this.vibes.forEach((vibe, index2) => {
                        if (customTag !== '') {
                            if (vibe.title === customTag && !vibe.is_predefined) {
                                exists = true;
                                const bv = new BrandVibe(vibe.id);
                                this.brandInfo.vibes.push(bv);
                            }
                        }
                    });

                    if (!exists && customTag !== '') {
                        const data = {
                            title: customTag,
                        };
                        this.vibeService.CreateVibes(data).subscribe(
                            (vibe) => {
                                const bv = new BrandVibe(vibe.id);
                                this.brandInfo.vibes.push(bv);
                                this.updateVibe();
                            },
                            (err) => {
                                this.showAlertError('Tag is same as Vibes name!');
                            }
                        );
                    } else {
                        this.updateVibe();
                    }
                });
                event.target.value = '';
            }
        }
    }

    onKeyUpValue(event) {
        if (event.key === ',') {
            const customValueTagsArray: Array<string> = event.target.value.split(',');
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Value Tags Empty!');
            } else {
                customValueTagsArray.forEach((customTag, index) => {
                    let exists = false;
                    customTag = customTag.trim();
                    this.values.forEach((value, index2) => {
                        if (customTag !== '') {
                            if (value.title === customTag && !value.is_predefined) {
                                exists = true;
                                const bv = new BrandValue(value.id);
                                this.brandInfo.values.push(bv);
                            }
                        }
                    });

                    if (!exists && customTag !== '') {
                        const data = {
                            title: customTag,
                        };
                        this.valueService.CreateValue(data).subscribe(
                            (value) => {
                                const bv = new BrandValue(value.id);
                                this.brandInfo.values.push(bv);
                                this.updateValue();
                            },
                            (err) => {
                                this.showAlertError('Tag is same as Values name!');
                            }
                        );
                    } else {
                        this.updateValue();
                    }
                });
                event.target.value = '';
            }
        }
    }

    onKeyUpSocial(event) {
        if (event.key === ',') {
            const customSocialTagsArray: Array<string> = event.target.value.split(',');
            if (event.target.value === null || event.target.value === '') {
                this.showAlertError('Social Causes Tags Empty!');
            } else {
                customSocialTagsArray.forEach((customTag, index) => {
                    let exists = false;
                    customTag = customTag.trim();
                    this.socialCauses.forEach((socialCause, index2) => {
                        if (customTag !== '') {
                            if (socialCause.title === customTag && !socialCause.is_predefined) {
                                exists = true;
                                const bsc = new BrandSocialCause(socialCause.id);
                                this.brandInfo.social_causes.push(bsc);
                            }
                        }
                    });

                    if (!exists && customTag !== '') {
                        const data = {
                            title: customTag,
                        };
                        this.socialCauseService.CreateSocialCause(data).subscribe(
                            (socialCause) => {
                                const bsc = new BrandSocialCause(socialCause.id);
                                this.brandInfo.social_causes.push(bsc);
                                this.updateCauses();
                            },
                            (err) => {
                                this.showAlertError('Tag is same as Social Cause name!');
                            }
                        );
                    } else {
                        this.updateCauses();
                    }
                });
                event.target.value = '';
            }

        }
    }

    onKeyUpDesc(event) {
        if (event.key === ',') {
            let customBrandTagsArray: Array<string> = event.target.value.split(',');
            customBrandTagsArray.forEach((tag, index) => {
                customBrandTagsArray[index] = tag.trim();
            });
            customBrandTagsArray = customBrandTagsArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                if (this.responsiveWidth > 768) {
                    this.showAlertError('Brand Description Tags Empty!');
                }
            } else {

                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customBrandTagsArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });

                if (this.brandInfo.tags != null) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.brandInfo.tags.length; i++) {
                        uniqueTags.push(this.brandInfo.tags[i]);
                    }
                }
                this.brandInfo.tags = uniqueTags.filter((elem, index, self) => index === self.indexOf(elem));
                event.target.value = '';
            }
        }
    }

    onKeyUpInterest(event) {
        if (event.key === ',') {
            let customerInterestsTagsArray: Array<string> = event.target.value.split(',');
            customerInterestsTagsArray.forEach((tag, index) => {
                customerInterestsTagsArray[index] = tag.trim();
            });
            customerInterestsTagsArray = customerInterestsTagsArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Customer Interest Tags Empty!');
                } else {
                    this.showAlertError('Customer Interest Tags Empty!');
                }
            } else {

                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customerInterestsTagsArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });

                if (this.brandInfo.brandcustomerprofile.customer_interests != null) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.brandInfo.brandcustomerprofile.customer_interests.length; i++) {
                        uniqueTags.push(this.brandInfo.brandcustomerprofile.customer_interests[i]);
                    }
                }

                this.brandInfo.brandcustomerprofile.customer_interests = uniqueTags.filter(
                    (elem, index, self) => index === self.indexOf(elem)
                );
                event.target.value = '';
            }

        }
    }

    onKeyUpDescriptionTags(event) {
        if (event.key === ',') {
            let customerDescriptionTagsArray: Array<string> = event.target.value.split(',');
            customerDescriptionTagsArray.forEach((tag, index) => {
                customerDescriptionTagsArray[index] = tag.trim();
            });
            customerDescriptionTagsArray = customerDescriptionTagsArray.filter((e) => e);
            if (event.target.value === null || event.target.value === '') {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Customer Description Tags Empty!');
                } else {
                    this.showAlertError('Customer Description Tags Empty!');
                }
            } else {

                // tslint:disable-next-line:only-arrow-functions
                const uniqueTags = customerDescriptionTagsArray.filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });

                if (this.brandInfo.brandcustomerprofile.customer_life_stages != null) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.brandInfo.brandcustomerprofile.customer_life_stages.length; i++) {
                        uniqueTags.push(this.brandInfo.brandcustomerprofile.customer_life_stages[i]);
                    }
                }
                this.brandInfo.brandcustomerprofile.customer_life_stages = uniqueTags.filter(
                    (elem, index, self) => index === self.indexOf(elem)
                );

                event.target.value = '';
            }
        }
    }

    openDropdown() {
        document.getElementById('brandAccountNav').classList.toggle('show');
    }

    getHostname(url) {
        if (url.includes('http')) {
            return new URL(url).hostname;
        }
        return url;
    }

    getHostnameFromRegex(url) {
        // run against regex
        const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        // extract hostname (will be null if no match is found)
        return matches && matches[1];
    }

    ageRequirementSelection(selection: number) {
        if (this.ageRequirementSelected === selection) {
            this.ageRequirementSelected = -1;
        } else {
            this.ageRequirementSelected = selection;
            if (selection === 1) {
                this.ageRequirement = null;
            }
        }
    }

    private generateThumbnail(videoFile: Blob): Promise<string> {
        const video: HTMLVideoElement = document.createElement('video');
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');

        return new Promise<string>((resolve, reject) => {
            canvas.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                resolve(canvas.toDataURL());
            });
            if (videoFile.type) {
                video.setAttribute('type', videoFile.type);
            }
            video.preload = 'auto';
            video.src = window.URL.createObjectURL(videoFile);
            video.load();
        });
    }

    private moveToPreviousOnBoardingStep(stepNumber: number): void {
        let brandSignUpCheckList: {
            selected_product_categories: boolean;
            provided_business_details: boolean;
            selected_social_values: boolean;
            selected_brand_values: boolean;
            selected_brand_vibes: boolean;
            uploaded_intro_video: boolean;
            provided_brand_description: boolean;
            shared_customer_profile: boolean;
            provided_category_details: boolean
        };

        switch (stepNumber) {
            case 1: {
                brandSignUpCheckList = {
                    provided_business_details: false,
                    selected_product_categories: false,
                    provided_category_details: false,
                    selected_brand_vibes: false,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                this.updateStep1 = true;
                break;
            }

            case 2: {
                brandSignUpCheckList = {
                    provided_business_details: false,
                    selected_product_categories: false,
                    provided_category_details: false,
                    selected_brand_vibes: false,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 3: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: false,
                    provided_category_details: false,
                    selected_brand_vibes: false,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 4: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: false,
                    selected_brand_vibes: false,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 5: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: true,
                    selected_brand_vibes: false,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 6: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: true,
                    selected_brand_vibes: true,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 7: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: true,
                    selected_brand_vibes: true,
                    selected_brand_values: true,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 8: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: true,
                    selected_brand_vibes: true,
                    selected_brand_values: true,
                    selected_social_values: true,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 9: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: true,
                    selected_brand_vibes: true,
                    selected_brand_values: true,
                    selected_social_values: true,
                    provided_brand_description: true,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }

            case 9: {
                brandSignUpCheckList = {
                    provided_business_details: true,
                    selected_product_categories: true,
                    provided_category_details: true,
                    selected_brand_vibes: true,
                    selected_brand_values: true,
                    selected_social_values: true,
                    provided_brand_description: true,
                    shared_customer_profile: true,
                    uploaded_intro_video: false
                };
                break;
            }

            default: {
                brandSignUpCheckList = {
                    provided_business_details: false,
                    selected_product_categories: false,
                    provided_category_details: false,
                    selected_brand_vibes: false,
                    selected_brand_values: false,
                    selected_social_values: false,
                    provided_brand_description: false,
                    shared_customer_profile: false,
                    uploaded_intro_video: false
                };
                break;
            }
        } // switch end

        const brandDetails = {
            brandsignupchecklist: brandSignUpCheckList
        };

        this.brandService.UpdateBrandById(this.brandInfo.id, brandDetails).subscribe(
            (brand) => {
                this.authService.updateCurrentUserBrand(brand);
                this.changeDetectorRef.markForCheck();
            },
            (err) => {
                if (this.responsiveWidth < 768) {
                    this.toastr.error('Something went wrong while updating the brand info!');
                } else {
                    this.showAlertError('Something went wrong while updating the brand info!');
                }
            }
        );
    }

}
