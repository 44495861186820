import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {UserService} from '../shared/services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {ContactUsService} from '../shared/services/ContactUs/contact-us.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {environment} from '../../environments/environment';
import {filter, first} from 'rxjs/operators';
import {promptGlobalAnalytics} from '@angular/cli/models/analytics';

@Component({
    selector: 'app-user-signup',
    templateUrl: './user-signup.component.html',
    styleUrls: ['./user-signup.component.scss'],
})
export class UserSignupComponent implements OnInit, OnDestroy {
    environmentText: string;
    // Pre Signup Code
    signUpCode: Array<string> = ['letsdothis', 'opensesame', 'knockknock', 'comeonin', 'onthelist'];
    verifySuccess = false;
    verCodeSent = false;
    requestVerficationCodeForm: FormGroup;
    requestVerficationCodeFormSubmitted = false;
    canProceed = false;

    message: any;
    emailmessage: any;

    // Signup form vairables
    userData: any;
    currentUser: any;
    signupForm: FormGroup;
    forgotForm: FormGroup;
    isSignupError = false;
    signupFormSubmitted = false;
    error: string;

    // 0 for Signup Form : 1 for Code Verification
    signupState = -1;

    // Forget Password Variables
    forgotPasswordFormSubmitted = false;
    forgotPasswordSuccessMessage = false;
    forgotPasswordSuccessMessageText = '';
    forgotPasswordErrorMessage = false;
    forgotPasswordErrorMessageText = '';

    // Code Verification Variables
    isVerifying = false;
    isVerified = false;
    isVerifyFailed = false;
    isResendCode = false;

    cdnUrl = environment.webStaticResources;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        public fb: FormBuilder,
        public userService: UserService,
        public toastr: ToastrService,
        private contactUsService: ContactUsService
    ) {
        this.message = {};
        this.emailmessage = {};
        this.signupForm = this.fb.group({
            email: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.email,
                    this.uniqueEmail.bind(this),
                    Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$'),
                ],
                updateOn: 'change',
            }),
            password: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
                ],
                updateOn: 'change',
            }),
        });
        this.forgotForm = this.fb.group({
            forgotEmail: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.email,
                    Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$'),
                ],
                updateOn: 'blur',
            }),
        });
        this.requestVerficationCodeForm = this.fb.group({
            email: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.email
                ]
            }),
        });
    }

    get f() {
        return this.signupForm.controls;
    }

    // Verfication Subscribe Form
    get vrf() {
        return this.requestVerficationCodeForm.controls;
    }

    get resetPasswordForm() {
        return this.forgotForm.controls;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        if (this.signupState === 2) {
            this.signupState = 2;
            window.history.forward();
            this.logout();
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        // {1}
        Object.keys(formGroup.controls).forEach((field) => {
            // {2}
            const control = formGroup.get(field); // {3}
            if (control instanceof FormControl) {
                // {4}
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {
                // {5}
                this.validateAllFormFields(control); // {6}
            }
        });
    }

    uniqueEmail(control: AbstractControl) {
        const email = control.value;

        if (email.length > 1) {
            this.userService.GetUserEmailAvailability(email).subscribe(
                (res: any) => {
                    if (res.available === true){
                        return null;
                    }
                    control.setErrors({notUniqueEmail: true});
                },
                (err) => {
                    control.setErrors({notUniqueEmail: true});
                }
            );
        }
    }

    ngOnInit() {

        this.environmentText = null;
        if (environment.env !== 'Prod') {
            this.environmentText = `Current Environment: ${environment.env}`;
        }
        this.authService.isAuthenticated()
            .then((user: AuthenticatedUser) => {
                if (user && !user.user.is_email_verified) {
                    this.signupState = 2;
                    this.currentUser = user.user;
                } else if (user && user.user.is_email_verified) {
                    this.canProceed = true;
                    this.router.navigate(['/brandaccount']);
                } else {
                    this.signupState = 0;
                }
            }).catch(res => {
            this.signupState = 0;
        });
    }

    // Pre SignUp Code Verification

    verifyPreCode(preCode: string) {
        const code = preCode.trim();
        const found = this.signUpCode.find((verCode) => verCode === code.toLowerCase());
        if (found) {
            // this.toastr.success('Code Verified Successfully!');
            this.message = {
                msg: 'Code Verified Successfully!',
                success: true
            };
            setTimeout(() => {
                this.signupState = 1;
                this.canProceed = false;
                this.message = {};
            }, 1000);
        } else {
            // this.toastr.error('OOPS! Invalid Code!');
            this.message = {
                msg: 'OOPS! Invalid Code!',
                success: false
            };
            this.signupState = 0;
            setTimeout(() => {
                this.message = {};
            }, 2500);
        }
    }

    requestPreVerificationCode() {
        this.emailmessage = {};
        this.requestVerficationCodeFormSubmitted = true;
        if (!this.requestVerficationCodeFormSubmitted) {
            this.requestVerficationCodeFormSubmitted = false;
            return false;
        } else {
            if (this.requestVerficationCodeForm.valid) {
                const verEmail = document.getElementById(`ver_email`) as HTMLInputElement;
                // const verPhone = document.getElementById(`ver_phone`) as HTMLInputElement;
                const contactMail = {
                    full_name: 'REQUIRED SIGNUP CODE',
                    email: verEmail.value,
                    type: 'brand',
                    message: 'Verification Code Required',
                };

                this.contactUsService.ContactSubmissions(contactMail).subscribe(
                    (Contactus) => {
                        // this.toastr.success('Your Request for the code has been sent succesfully');
                        this.emailmessage = {
                            msg: 'Your Request for the code has been sent successfully',
                            success: true
                        };
                        this.requestVerficationCodeForm.reset();
                        this.verCodeSent = true;
                        this.requestVerficationCodeFormSubmitted = false;
                        return false;
                    },
                    (err) => {
                        this.verCodeSent = false;
                        this.toastr.error('Something went wrong! Try again.');
                        this.requestVerficationCodeFormSubmitted = false;
                        this.emailmessage = {
                            msg: 'Something went wrong! Try again.',
                            success: false
                        };
                    }
                );
            } else {

                this.validateAllFormFields(this.requestVerficationCodeForm);
                this.verCodeSent = false;
                setTimeout(() => {
                    this.requestVerficationCodeFormSubmitted = false;
                }, 2500);
                return false;
            }
        }
    }

    // SignUp and Email Verification

    verificationCodeChangeFocus(event: any) {

        if (event.data) {
            const element = event.target.nextElementSibling;
            if (element.type !== 'number') {
                this.verifyCode();
                // return;             // check if its null
            } else {
                element.focus();   // focus if not null
            }
            let allFilled = true;
            for (let i = 0; i <= 5; i++) {
                const val = (document.getElementsByName('code')[i] as HTMLInputElement).value;
                if (val === '' || !val) {
                    allFilled = false;
                }
            }

            if (allFilled) {
                this.verifyCode();
            }
        }
    }

    // Move back to previous input
    focusBack(event: any) {
        event.preventDefault();
        if (event.key === 'Backspace') {
            event.target.value = '';
            const prevEle = event.target.previousElementSibling;
            if (prevEle) {
                prevEle.focus();
            }
        }
    }

    onVerificationCodePaste(event: ClipboardEvent) {
        event.preventDefault();
        for (let i = 0; i <= 5; i++) {
            (document.getElementsByName('code')[i] as HTMLInputElement).value = '';
        }
        // @ts-ignore
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text').replace(/\D/g, '');
        if (Number(pastedText)) {
            for (let i = 0; i <= 5; i++) {
                (document.getElementsByName('code')[i] as HTMLInputElement).value = pastedText.charAt(i);
            }

            this.verifyCode();
        }
    }

    onUserSignup() {
        if (this.signupFormSubmitted) {
            return false;
        } else {
            this.signupFormSubmitted = true;

            if (this.signupForm.valid) {
                this.userData = {
                    email: this.signupForm.get('email').value,
                    password: this.signupForm.get('password').value,
                    user_type: 'brand'
                };
                this.userService.RegisterUser(this.userData).subscribe(
                    (user) => {
                        console.log('User Registered!');
                        this.authService.GetAuthToken(user.email, this.userData.password).subscribe(
                            (res) => {
                                this.signupState = 2;
                                this.canProceed = false;
                            },
                            (err) => {
                                console.log(err);
                                this.signupFormSubmitted = false;
                                this.toastr.error('Something Went Wrong! Try again.');
                            }
                        );
                    },
                    (err) => {
                        console.log(err);
                        this.signupFormSubmitted = false;
                        this.toastr.error('User registration Failed! Try again.');
                    }
                );

            } else {
                this.signupFormSubmitted = false;
                this.validateAllFormFields(this.signupForm);
                return false;
            }
        }
    }

    verifyCode() {
        this.isVerifying = true;
        this.isResendCode = false;

        let myCode = '';
        for (let i = 0; i <= 5; i++) {
            myCode += (document.getElementsByName('code')[i] as HTMLInputElement).value;
        }
        if (myCode.length < 6) {
            this.isVerifying = false;
            this.isVerifyFailed = true;
        } else {
            this.userService.VerifyEmail(Number(myCode)).subscribe(
                res => {
                    if (res.message === 'Invalid Code') {
                        this.isVerified = false;
                        this.isVerifying = false;
                        this.isVerifyFailed = true;
                        this.canProceed = false;
                        document.getElementById('lastInput').focus();
                    } else if (res.message === 'Email already Verified') {
                        this.isVerifyFailed = false;
                        this.canProceed = true;
                        this.router.navigate(['/brandaccount']);
                    } else {
                        this.isVerified = true;
                        this.isVerifying = false;

                        setTimeout(() => {
                            this.isVerifyFailed = false;
                            this.canProceed = true;
                            this.router.navigate(['/brandaccount']);
                        }, 1000);

                        // TODO: Remove below auth function if there no issue ever faced on signup screen
                        // this.authService.GetAuthToken(this.userData.email, this.userData.password).pipe(first()).subscribe(resp => {
                        //     if (resp.user.is_email_verified) {
                        //         setTimeout(() => {
                        //             this.isVerifyFailed = false;
                        //             this.router.navigate(['/brandaccount']);
                        //         }, 700);
                        //     }
                        // });
                    }
                },
                err => {
                    console.dir(err);
                    this.isVerified = false;
                    this.isVerifying = false;
                    this.isVerifyFailed = true;
                    this.canProceed = false;
                    document.getElementById('lastInput').focus();
                }
            );
        }
    }

    resendCode() {
        this.canProceed = false;
        this.isResendCode = true;
        for (let i = 0; i <= 5; i++) {
            (document.getElementsByName('code')[i] as HTMLInputElement).value = '';

        }
        this.userService.ResendCode().subscribe(
            res => {
                this.isResendCode = true;
            },
            err => {
                this.isResendCode = false;
                this.toastr.error('Something went wrong while resending code. Try Again');
            }
        );
    }

    onResetFormSubmit() {
        this.forgotPasswordSuccessMessage = false;
        this.forgotPasswordErrorMessage = false;
        this.forgotPasswordErrorMessageText = '';
        this.forgotPasswordSuccessMessageText = '';

        if (this.forgotPasswordFormSubmitted) {
            return false;
        } else {
            this.forgotPasswordFormSubmitted = true;
            const regExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
            const fEmail = (document.getElementById('forgot-email') as HTMLInputElement).value;
            if (fEmail === '') {
                this.forgotPasswordSuccessMessage = false;
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Email is Required`;
                this.forgotPasswordFormSubmitted = false;
            } else if (!regExp.test(fEmail)) {
                this.forgotPasswordSuccessMessage = false;
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Enter a valid Email`;
                this.forgotPasswordFormSubmitted = false;
            } else {
                this.authService.RequestPasswordReset(fEmail).subscribe(obj => {
                    this.forgotPasswordSuccessMessage = true;
                    // @ts-ignore
                    if (obj.status === 'OK') {
                        this.forgotPasswordSuccessMessage = true;
                        this.forgotPasswordSuccessMessageText = `A reset link has been sent to the email address provided.`;
                        (document.getElementById('forgot-email') as HTMLInputElement).value = '';
                    } else {
                        this.forgotPasswordErrorMessage = true;
                        this.forgotPasswordErrorMessageText = `Something unexpected happened`;
                    }
                }, error1 => {
                    this.forgotPasswordErrorMessage = true;
                    // tslint:disable-next-line:max-line-length
                    console.log(error1);
                    if (error1.error.errors[0].email[0] === 'There is no active user associated with this e-mail address or the password can not be changed') {
                        this.forgotPasswordErrorMessageText = `Oops, you must have registered another way. Try again!`;
                    } else if (error1.error.errors[0].email[0] === 'Enter a valid email address.') {
                        this.forgotPasswordErrorMessageText = `Oops, please enter a valid email address.`;
                    } else if (error1.error.errors[0].email[0] === 'We couldn\'t find an account associated with that email. Please try a different e-mail address.') {
                        this.forgotPasswordErrorMessageText = 'We couldn\'t find an account associated with that email. Please try a different e-mail address.';
                    } else {
                        this.forgotPasswordErrorMessageText = `Something unexpected happened.`;
                    }
                    this.forgotPasswordFormSubmitted = false;
                }, () => {
                    this.forgotPasswordFormSubmitted = false;
                });
            }
        }
    }

    logout() {
        this.authService.logout();
    }

    openModal(elementId: string) {
        const ele = document.getElementById(elementId) as HTMLDivElement;
        ele.classList.add('show');
        document.getElementsByTagName('body')[0].classList.add('modal-open');
    }

    closeModal(elementId: string) {
        const ele = document.getElementById(elementId) as HTMLDivElement;
        ele.classList.remove('show');
        document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }

    ngOnDestroy() {
        if (!this.canProceed) {
            window.localStorage.clear();
            this.authService.resetCurrentUserValue();
        }
    }
}
