import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
// --------- SENTRY Integration Imports --------
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
// SwiperOptions from 'swiper' could also be used here instead of SwiperConfigInterface
const DEFAULT_SWIPER_CONFIG = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 9,
    centeredSlides: true,
};
// ----------- SENTRY Integration -----------
const env = environment.env;
if (env !== 'local') {
    Sentry.init({
        dsn: 'https://68447f73f1804785a9fc53342db3aa93@o361225.ingest.sentry.io/3807924',
        environment: env,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Integrations.BrowserTracing({
                tracingOrigins: ['localhost', 'https://staging.tailorie.com/', 'https://tailorie.com/'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const ɵ0 = DEFAULT_SWIPER_CONFIG;
export class AppModule {
}
export { ɵ0 };
