import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {Value, Values} from './value';
import {Vibe} from '../vibe/vibe';

@Injectable({
    providedIn: 'root',
})
export class ValueService extends ApiService {
    selected = {
        startDate: null,
        endDate: null,
    };
    currentFilter: String = 'WEEK';
    constructor(private http: HttpClient) {
        super();
    }

    CreateValue(data): Observable<Value> {
        const newData = {
            title: data.title
        };
        return this.http.post<Value>(`${this.apiUrl}/values/`, JSON.stringify(newData), this.httpOptions).pipe();
    }

    GetAllValues(): Observable<Values> {
        return this.http.get<Values>(`${this.apiUrl}/values/`).pipe();
    }

    FetchFollowerData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/value_follower/?date_from=${startDate}&date_to=${endDate}&value_id=${id}`)
        .pipe();
    }

    FetchRecommendersData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/value_recommendation/?date_from=${startDate}&date_to=${endDate}&value_id=${id}`)
        .pipe();
    }
    FetchJourneyData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/value_viewer/?date_from=${startDate}&date_to=${endDate}&value_id=${id}`)
        .pipe();
    }
}
