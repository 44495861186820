import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BrandService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    // POST
    CreateBrand(data) {
        return this.http.post(`${this.apiUrl}/brands/`, JSON.stringify(data), this.httpOptions).pipe();
    }
    // New Register Brand API
    RegisterBrand(data) {
        return this.http
            .post(`${this.apiUrl}/brands/register/`, JSON.stringify(data), this.httpOptions)
            .pipe();
    }
    UpdateBrandById(id, brandData) {
        return this.http
            .patch(`${this.apiUrl}/brands/${id}/`, JSON.stringify(brandData), this.httpOptions)
            .pipe();
    }
    UpdateBrandLogo(brandId, formData) {
        return this.http.post(`${this.apiUrl}/brands/${brandId}/logo/`, formData);
    }
    // GET
    GetBrandByName(name) {
        return this.http
            .get(`${this.apiUrl}/brands/business_name/${name}/`)
            .pipe();
    }
    // Brand User Name Suggestion
    GetSuggestedUsername(busniessName) {
        return this.http
            .post(`${this.apiUrl}/brands/suggest-brand-username/`, {
            action: 'suggest',
            text: busniessName,
        }, this.httpOptions)
            .pipe();
    }
    checkBrandUsernameAvailability(userName) {
        return this.http.post(`${this.apiUrl}/brands/suggest-brand-username/`, JSON.stringify({
            action: 'check_availability',
            text: userName
        }), this.httpOptions).pipe();
    }
    // Get Brand by Username
    GetBrandByUsername(userName) {
        return this.http.get(`${this.apiUrl}/brands/username/${userName}/`).pipe();
    }
    GetBrandById(id) {
        return this.http.get(`${this.apiUrl}/brands/${id}/`).pipe();
    }
    GetBrandSignupChecklist(brandId) {
        return this.http.get(`${this.apiUrl}/brands_signup_checklist/${brandId}/`).pipe();
    }
    GetBrandSignupChecklistResolve(brandId) {
        return this.http.get(`${this.apiUrl}/brands_signup_checklist/${brandId}/`);
    }
    // Dashboard Account Info
    GetBrandContactDetails(brandId) {
        return this.http.get(`${this.apiUrl}/brand/${brandId}/contact/`).pipe();
    }
    PostBrandContactDetails(brandId, data) {
        return this.http.post(`${this.apiUrl}/brand/${brandId}/contact/`, JSON.stringify(data), this.httpOptions);
    }
    GetBrandBillingAddress(brandId) {
        return this.http.get(`${this.apiUrl}/brand/${brandId}/billing/`).pipe();
    }
    PostBrandBillingAddress(brandId, data) {
        return this.http.post(`${this.apiUrl}/brand/${brandId}/billing/`, JSON.stringify(data), this.httpOptions);
    }
}
BrandService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandService_Factory() { return new BrandService(i0.ɵɵinject(i1.HttpClient)); }, token: BrandService, providedIn: "root" });
