import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Brand} from '../../shared/services/brand/brand';
import {SocialCauseService} from '../../shared/services/social_cause/social-cause.service';
import {AuthService} from '../../shared/services/auth/auth.service';
import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import {BrandProfileService} from '../../shared/services/Brand Profile/brand-profile.service';
import {AuthenticatedUser} from '../../shared/services/auth/authenticateduser';

@Component({
    selector: 'app-socialcause',
    templateUrl: './socialcause.component.html',
    styleUrls: ['./socialcause.component.scss']
})
export class SocialcauseComponent implements OnInit {
    currentUser: AuthenticatedUser;
    public allProductCategories;
    public productCategory = {post_views: '22k', percentage: '12'};
    public filter = `1`;
    currentFilter = 'CUSTOM';
    startDate: any;
    endDate: any;
    public selected = {startDate: null, endDate: null};
    currentBrandInfo: Brand;
    currday = true;
    currweek = false;
    currmonth = false;
    curryear = false;
    options: any = {
        autoApply: false,
        alwaysShowCalendars: false,
        showCancel: false,
        showClearButton: false,
        linkedCalendars: true,
        singleDatePicker: true,
        showWeekNumbers: false,
        showISOWeekNumbers: false,
        customRangeDirection: false,
        lockStartDate: false,
        closeOnAutoApply: true,
    };
    maxDate = moment().max(moment().add(1, 'd'));
    minDate = moment().startOf('year');
    years = [];
    selectedYear: any;
    selectedTab = null;
    public socialData: any;
    public followersData: any;
    public recommendData: any;
    public journeyData: any;

    public proSelected: any;
    currentIndex: any;
    prevBtn = false;
    nextBtn = false;
    public today = new Date();
    public yearLimit: any;
    @ViewChild('Slider', {static: false}) componentRef?: any;

    constructor(
        private socialCauseService: SocialCauseService,
        private authService: AuthService,
        private datepipe: DatePipe,
        private brandprofileService: BrandProfileService,
    ) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setupHorizontalScroll('rtBtn', 'ltBtn', 'cntnr');
    }

    ngOnInit() {
        this.nextBtn = true;
        this.yearLimit = this.today.getFullYear();
        this.generateYears();
        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;
        this.currentBrandInfo = new Brand(
            currentBrand.id,
            currentBrand.business_name,
            currentBrand.description,
            currentBrand.tags,
            currentBrand.brandsignupchecklist,
            currentBrand.brandcustomerprofile,
            currentBrand.product_categories,
            currentBrand.vibes,
            currentBrand.values,
            currentBrand.social_causes,
            currentBrand.sales_channel,
            currentBrand.website,
            currentBrand.headquarter_location,
            currentBrand.tax_id,
            currentBrand.years_in_business,
            currentBrand.number_of_locations,
            currentBrand.business_percent_online,
            currentBrand.business_percent_instore
        );
        this.currentBrandInfo.initBrandInfo();
        this.dateSelector();
        this.fetchSocialCauseData();
        this.fetchData();

    }

    generateYears() {
        const count = this.yearLimit - 2020;
        for (let i = 0; i <= count; i++) {
            this.years.push(2020 + i);
        }
    }

    fetchSocialCauseData() {
        this.socialCauseService.GetAllSocialCauses().subscribe((productCategories) => {
            this.allProductCategories = productCategories.results;
            this.socialData = productCategories.results[0];
            let isSelected = false;
            this.allProductCategories.forEach((socialCause, index) => {
                socialCause.selected = false;
                this.currentBrandInfo.social_causes.forEach((brandSocialCause, index2) => {
                    if (brandSocialCause.id === socialCause.id) {
                        socialCause.selected = true;
                        if (socialCause.is_predefined && !isSelected) {
                            this.proSelected = socialCause.title;
                            isSelected = true;
                            // console.log(this.proSelected.reverse());
                        }
                    }
                });
            });
            setTimeout(() => {
                this.setupHorizontalScroll('rtBtn', 'ltBtn', 'cntnr');
            }, 100);
        });
        // this.socialCauseService.GetAllSocialCauses().subscribe(
        //     (productCategories) => {
        //         this.allProductCategories = productCategories.results;
        //         this.socialData = productCategories.results[0];
        //
        //     });
    }

    changeSliderIndex(event?) {
        if (event) {
            this.currentIndex = event;
        }
        setTimeout(() => {
            this.componentRef.isAtLast ? (this.nextBtn = false) : (this.nextBtn = true);
            this.componentRef.isAtFirst ? (this.prevBtn = false) : (this.prevBtn = true);
        });

    }

    slideNext() {
        this.componentRef.directiveRef.nextSlide();
    }

    slidePrev() {
        this.componentRef.directiveRef.prevSlide();
    }

    fetchData() {
        this.socialCauseService
            .FetchFollowerData(this.filter, this.startDate, this.endDate)
            .subscribe((res) => {
                this.followersData = res;
            });
        this.socialCauseService
            .FetchRecommendersData(this.filter, this.startDate, this.endDate)
            .subscribe((res) => {
                this.recommendData = res;
            });
        this.socialCauseService
            .FetchJourneyData(this.filter, this.startDate, this.endDate)
            .subscribe((res) => {
                this.journeyData = res;
            });
    }

    onChange(event) {
        this.filter = '';
        this.filter = event.id;
        this.socialData = event;
        this.fetchData();
    }

    onChangeValue(value: any) {
        const vibeObject = this.allProductCategories.filter((item) => {
            return item.title === value;
        })[0];
        this.filter = '';
        this.filter = vibeObject.id.toString();
        this.socialData = vibeObject;
        this.fetchData();
    }

    dateSelector() {
        if (
            this.brandprofileService.selected.startDate === null &&
            this.brandprofileService.currentFilter === 'CUSTOM'
        ) {
            this.selected = {startDate: null, endDate: null};
            const weekdate = new Date();
            const weekend = new Date(weekdate);
            weekend.setDate(weekdate.getDate());
            const weekstart = new Date(weekdate);
            weekstart.setDate(weekdate.getDate() - 6);
            const createdDate = this.datepipe.transform(this.currentUser.user.branduserprofile.brand.created_at, 'yyyy-MM-dd');
            this.startDate = this.datepipe.transform(createdDate, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
        } else {
            this.startDate = this.brandprofileService.selected.startDate;
            this.endDate = this.brandprofileService.selected.endDate;
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
        }

        switch (this.brandprofileService.currentFilter) {
            case 'WEEK':
                this.ctShoWeek();
                this.setActive('weekBtn', 'pcfbtn');
                break;
            case 'DAY':
                this.ctShowDay();
                this.setActive('dayBtn', 'pcfbtn');
                break;
            case 'MONTH':
                this.ctShowMonth();
                this.setActive('monthBtn', 'pcfbtn');
                break;
            case 'CUSTOM':
                this.ctCustomDate();
                this.setActive('customBtn', 'pcfbtn');
                break;
            default:
                break;
        }
    }

    selectYear(year, category) {
        this.startDate = year + '-01-01';
        this.endDate = year + '-12-31';
        this.fetchData();
    }

    selectedChange(event) {
        if (event.startDate === null) {
            return;
        }
        if (this.currentFilter === 'WEEK') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.fetchData();
        }
        if (this.currentFilter === 'DAY') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.fetchData();
        }
        if (this.currentFilter === 'MONTH') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.fetchData();
        }
        if (this.currentFilter === 'CUSTOM') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.fetchData();
        }
    }

    // <-------------------------------------------------- calender stats End----------------------->
    showTabContent(
        pop: HTMLDivElement,
        link: HTMLAnchorElement,
        content: string
    ) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }

        pop.classList.add('show', 'active');
        link.classList.add('active');
        this.selected = {startDate: null, endDate: null};
    }

    setActive(selBtn: string, allBtnsClass: string) {
        const selectedBtn = document.getElementById(selBtn);
        const allBtns = document.querySelectorAll('.' + allBtnsClass);

        allBtns.forEach((elem, index) => {
            elem.classList.remove('active');
        });

        selectedBtn.classList.add('active');
    }

    ctShowDay() {
        this.currday = true;
        this.currweek = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';

            if (this.brandprofileService.currentFilter !== 'DAY') {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(
                    daydate.setDate(daystart),
                    'yyyy-MM-dd'
                );
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandprofileService.currentFilter = this.currentFilter;
            }
        }
    }

    ctShoWeek() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;
        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.fetchData();
        }
    }

    ctShowMonth() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = true;
        this.curryear = false;
        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandprofileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(
                    monthdate.getFullYear(),
                    monthdate.getMonth(),
                    1
                );
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(
                    monthdate.setDate(monthend),
                    'yyyy-MM-dd'
                );
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
                this.fetchData();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandprofileService.currentFilter = this.currentFilter;
            }
        }
    }

    ctShowYear() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = false;
        this.curryear = true;
        if (this.currentFilter !== 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.fetchData();
        }
    }

    ctCustomDate() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;
        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandprofileService.currentFilter !== 'CUSTOM') {
                // const monthdate = new Date();
                // const monthstart = new Date(
                //     monthdate.getFullYear(),
                //     monthdate.getMonth(),
                //     1
                // );
                // const monthend = monthdate.getDate() - 1;
                // this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                // this.endDate = this.datepipe.transform(
                //     monthdate.setDate(monthend),
                //     'yyyy-MM-dd'
                // );
                // this.selected = {startDate: null, endDate: null};
                // this.selected.startDate = moment(this.startDate);
                // this.selected.endDate = moment(this.endDate);
                // this.brandprofileService.currentFilter = this.currentFilter;
                // this.fetchData();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandprofileService.currentFilter = this.currentFilter;
            }
        }
    }

    private setupHorizontalScroll(btnId, bkBtnId, containerId): void {

        const button = document.getElementById(`${btnId}`);
        const back = document.getElementById(`${bkBtnId}`);
        const container = document.getElementById(`${containerId}`);
        if (checkOverflow(container)) {
            // button.style.display = 'flex';
            // back.style.display = 'flex';

            button.onclick = () => {
                sideScroll(container, 'right', 25, 100, 10);
            };
            back.onclick = () => {
                sideScroll(container, 'left', 25, 100, 10);
            };
        } else {
            // button.style.display = 'none';
        }


        function sideScroll(element, direction, speed, distance, step) {
            let scrollAmount = 0;
            const slideTimer = setInterval(() => {
                if (direction === 'left') {
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if (scrollAmount >= distance) {
                    // @ts-ignore
                    window.clearInterval(slideTimer);
                }
            }, speed);
        }

        function checkOverflow(el) {
            const curOverflow = el.style.overflow;

            if (!curOverflow || curOverflow === 'visible') {
                el.style.overflow = 'hidden';
            }
            const isOverflowing = el.clientWidth < el.scrollWidth
                || el.clientHeight < el.scrollHeight;

            el.style.overflow = curOverflow;

            return isOverflowing;
        }


    }
}
