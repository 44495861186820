import { ApiService } from '../../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MomentsService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    getMoments(brandId) {
        return this.http.get(`${this.apiUrl}/brands/${brandId}/posts/?brand_post_category=moment`);
    }
    getJourneys(brandId) {
        return this.http.get(`${this.apiUrl}/brands/${brandId}/posts/?brand_post_category=journey`);
    }
    getRecommendations(brandId) {
        return this.http.get(`${this.apiUrl}/brands/${brandId}/recommendations/?v1=true`);
    }
    getJourneyCounts(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/journey_views_count/?date_from=${startDate}&date_to=${endDate}`);
    }
    getMomentCounts(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/moment_views_count/?date_from=${startDate}&date_to=${endDate}`);
    }
    getRepliesCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/replies_count/?date_from=${startDate}&date_to=${endDate}`);
    }
    getLikesCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/likes_count/?date_from=${startDate}&date_to=${endDate}`);
    }
    fetchTopSymbols(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/posts/${id}/?date_from=${startDate}&date_to=${endDate}`);
    }
    getViewersPostMetrics(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/post_metrics_count/?post_id=${id}&date_from=${startDate}&date_to=${endDate}`);
    }
}
MomentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MomentsService_Factory() { return new MomentsService(i0.ɵɵinject(i1.HttpClient)); }, token: MomentsService, providedIn: "root" });
