import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OverviewService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    FetchTopFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/overview_top_follower_category/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    FetchImportantData(id, filter, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/brand_community_important_view/?filter_interval=${filter}&date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    FetchTopRecommenderData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/overview_top_recommendation_category/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    FetchTopViewerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/overview_top_viewer_Category/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
}
OverviewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OverviewService_Factory() { return new OverviewService(i0.ɵɵinject(i1.HttpClient)); }, token: OverviewService, providedIn: "root" });
