<div class="content-wrapper">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap">
            <img alt="dashboard-banner-image" class="side-img" src="{{cdnUrl}}/assets/images/brand-account-images/image1.png">
        </div>
        <div class="pr-2">
            <h1>Brand Dashboard</h1>
            <p>Hello, {{currentUser.user.branduserprofile.brand.business_name}}! We are glad you are here!</p>
        </div>

    </div>
    <!-- /Dates & day------------------------------------------------ -->
    <div class="main-section">

        <div class="row">
            <div class="col-lg-12">
                <div class="date-period">
                    <div class="fc-center">
                        <h5 *ngIf="currday">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="currweek">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="currmonth">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="curryear">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>

                        <div class="btn-group btn-group-toggle" data-toggle="buttons">

                            <label (click)="setActive('dayBtn', 'pcfbtn'); ctShowDay()" class="btn pcfbtn active" id="dayBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply"
                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars"
                                       [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate"
                                       [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers"
                                       applyLabel="Okay" autocomplete="off"
                                       emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day"
                                       id="daypicker"
                                       lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day"
                                       matInput name="options"
                                       ngxDaterangepickerMd
                                       opens="left" singleDatePicker="true" type="radio">
                                Day
                            </label>
                            <label (click)="setActive('weekBtn', 'pcfbtn'); ctShoWeek()" class="btn pcfbtn" id="weekBtn">
                                <input (datesUpdated)="selectedChange($event)"
                                       [(ngModel)]="selected"
                                       [autoApply]="options.autoApply"
                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars"
                                       [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate"
                                       [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers"
                                       applyLabel="Okay" autocomplete="off"
                                       dateLimit="6"
                                       emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day"
                                       id="weekpicker" lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day"
                                       matInput name="options" ngxDaterangepickerMd
                                       opens="left"
                                       type="radio">
                                Week
                            </label>
                            <label (click)="setActive('monthBtn', 'pcfbtn'); ctShowMonth()" class="btn pcfbtn" id="monthBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply"
                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars"
                                       [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate"
                                       [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers"
                                       applyLabel="Okay"
                                       autocomplete="off"
                                       dateLimit="30" emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day"
                                       lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day"
                                       matInput
                                       name="options"
                                       ngxDaterangepickerMd opens="center" type="radio">
                                Month
                            </label>
                            <!--  Custom Calender - Placed her for faking position  -->

                            <label (click)="setActive('yearBtn', 'pcfbtn');myProduct.open()" class="btn pcfbtn" id="yearBtn" style="line-height: 28px">
                                <mat-select #myProduct style="visibility:hidden;width: 0">
                                    <mat-option (click)="selectYear(year,'pc')"
                                                *ngFor="let year of years"
                                                [value]="year">{{ year }}
                                    </mat-option>
                                </mat-select>
                                Year
                            </label>
                            <label (click)="setActive('customBtn', 'pcfbtn'); ctShowCustom()" class="btn pcfbtn" id="customBtn" style="width:60px">
                                <input #customDate (datesUpdated)="selectedChange($event)"
                                       [(ngModel)]="selected"
                                       [autoApply]="options.autoApply"
                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="false"
                                       [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate"
                                       [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers"
                                       applyLabel="Okay"
                                       autocomplete="off" emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day"
                                       hidden
                                       id="customDate" lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day"
                                       matInput name="options" ngxDaterangepickerMd
                                       opens="right"
                                       type="radio"
                                />
                                Custom
                            </label>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Cards------------------------------------------------ -->
        <div class="row no-gutters metric-containers">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Followers</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="cd-body">
                        <div class="card-data">
                            <h1>{{statsBrandDashboard.followerCount | shortNumber }}</h1>
                            <h5 *ngIf="statsBrandDashboard.followerCountPrev !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'count' : statsBrandDashboard.followerCount >= 0,
                            'positive' :
                            statsBrandDashboard.followerCountPrev >= 0,
                                'negative' : statsBrandDashboard.followerCountPrev < 0}">
                                {{statsBrandDashboard.followerCountPrev | number: '0.0-0'}}%</h5>
                            <h5 *ngIf="statsBrandDashboard.followerCountPrev === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                        </div>
                        <p>Total # of users who follow your brand.</p>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Brand Views</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="cd-body">
                        <div class="card-data">
                            <h1>{{statsBrandDashboard.profileViewerCount | shortNumber }}</h1>
                            <h5 *ngIf="statsBrandDashboard.profileViewerCountPre !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'count' : statsBrandDashboard.followerCount >= 0, 'positive' :
                            statsBrandDashboard.profileViewerCountPre >= 0,
                                                                'negative' : statsBrandDashboard.profileViewerCountPre < 0}">
                                {{statsBrandDashboard.profileViewerCountPre | number: '0.0-0'}}%</h5>

                            <h5 *ngIf="statsBrandDashboard.profileViewerCountPre === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                        </div>
                        <p>Total # of times users viewed your brand.</p>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Click-throughs</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="cd-body">
                        <div class="card-data">
                            <h1>{{statsBrandDashboard.clickThroughCount | shortNumber }}</h1>
                            <h5 *ngIf="statsBrandDashboard.clickThroughCountPre !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'count' : statsBrandDashboard.followerCount >= 0, 'positive' :
                            statsBrandDashboard.clickThroughCountPre >= 0,
                                    'negative' : statsBrandDashboard.clickThroughCountPre < 0}">
                                {{statsBrandDashboard.clickThroughCountPre | number: '0.0-0'}}%</h5>
                            <h5 *ngIf="statsBrandDashboard.clickThroughCountPre === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                        </div>
                        <p>Total # of times users clicked through to your website.</p>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                <div class="panel panel-blue">
                    <div class="card mb-5">
                        <div class="card-head">
                            <h3 class="card-title">Collections</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="cd-body">

                            <div class="card-data">
                                <h1>{{ statsBrandDashboard.collectionCount | shortNumber}}</h1>
                                <h5 *ngIf="statsBrandDashboard.collectionCountPre !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'count' : statsBrandDashboard.followerCount >= 0, 'positive' :
                                statsBrandDashboard.collectionCountPre >= 0,
                                                'negative' : statsBrandDashboard.collectionCountPre < 0}">
                                    {{statsBrandDashboard.collectionCountPre}}%</h5>
                                <h5 *ngIf="statsBrandDashboard.collectionCountPre === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                            </div>

                            <p>Total # of times your brand was tagged to a user’s collection.</p>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Recs</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="cd-body">
                        <div class="card-data">
                            <h1>{{statsBrandDashboard.recommendationCount | shortNumber }}</h1>
                            <h5 *ngIf="statsBrandDashboard.recommendationCountPre !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'count' : statsBrandDashboard.followerCount >= 0, 'positive' :
                            statsBrandDashboard.recommendationCountPre >= 0,
                                    'negative' : statsBrandDashboard.recommendationCountPre < 0}">
                                {{statsBrandDashboard.recommendationCountPre | number: '0.0-0'}}%</h5>
                            <h5 *ngIf="statsBrandDashboard.recommendationCountPre === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                        </div>
                        <p>Total # of times users recommended your brand on their recommendations.</p>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
        </div>

        <!-- Charts----------------------------------------------- -->
        <div class="row" id="charts-data">
            <!-- Left col -->
            <section class="col-lg-12 connectedSortable">
                <!-- Custom tabs (Charts with tabs)-->
                <div class="cad mb-5">
                    <div class="header">
                        <h3 class="header-title">
                            Overview

                            <div *ngIf="currentFilter!=='DAY' && currentFilter!=='CUSTOM'" class="mobile d-inline-block">
                                <span appDropdown class="dropdown" style="margin-top: 16px">
                                    <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown"
                                       id="DropdownMenuLink1" role="button">
                                         <img alt="caret" src="assets/images/arrow-up.svg"
                                              style="width: 25px; color: #1E537E">
                                    </a>
                                    <div aria-labelledby="DropdownMenuLink1" class="dropdown-menu dropdown-menu-side">
                                        <a (click)="chartsBreakdown('days')" *ngIf="currentFilter==='WEEK' || currentFilter==='MONTH' " class="dropdown-item">
                                            by Days
                                        </a>
                                         <div *ngIf="currentFilter==='WEEK' || currentFilter==='MONTH' " class="dropdown-divider"></div>
                                        <a (click)="chartsBreakdown('weeks')" [ngStyle]="{'margin-top': currentFilter==='WEEK' || currentFilter==='MONTH' ? '19px' : 'unset'}"
                                           class="dropdown-item">
                                            by Weeks
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="chartsBreakdown('months')" *ngIf="currentFilter==='YEAR'" class="dropdown-item" style="margin-top:
                                        19px;">
                                            by Months
                                        </a>
                                        <div *ngIf="currentFilter==='YEAR'" class="dropdown-divider"></div>
                                    </div>
                                </span>
                            </div>

                            <div *ngIf="isLoading" class="tiny-loader"></div>

                        </h3>


                        <div class="d-flex mr-2">
                            <div class="dot-label">
                                <h1><span class="dot1"></span>Brand Views</h1>
                                <h1><span class="dot2"></span>Followers</h1>
                                <h1><span class="dot3"></span>Click-throughs</h1>
                                <h1><span class="dot4"></span>Collections</h1>
                                <h1><span class="dot5"></span>Recommendations</h1>
                            </div>
                            <!--                            <div class="mobile d-inline-block">-->
                            <!--                                <span appDropdown class="dropdown" style="margin-top: 16px">-->
                            <!--                                    <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown"-->
                            <!--                                       id="DropdownMenuLink3" role="button">-->
                            <!--                                         <img alt="caret" src="assets/images/arrow-up.svg"-->
                            <!--                                              style="width: 25px; color: #1E537E">-->
                            <!--                                    </a>-->
                            <!--                                    <div aria-labelledby="DropdownMenuLink3" class="dropdown-menu dropdown-menu-side">-->
                            <!--                                        <a (click)="tabToggle(1)" class="dropdown-item">Line-->
                            <!--                                            Chart</a>-->
                            <!--                                        <div class="dropdown-divider"></div>-->
                            <!--                                        <a (click)="tabToggle(2)" class="dropdown-item" style="margin-top: 19px;">Pie Grid Chart</a>-->
                            <!--                                        <div class="dropdown-divider"></div>-->
                            <!--                                    </div>-->
                            <!--                                </span>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <!-- /.card-body -->
                    <div *ngIf="showTab == 1" class="cad-body" style="padding-bottom: 40px; margin-top: 11px;">
                        <ngx-charts-line-chart (select)="onSelect($event)" (window:resize)="onResize($event)" [autoScale]="autoScale" [gradient]="gradient" [legendTitle]="false"
                                               [results]="data" [scheme]="colorScheme" [showXAxisLabel]="false" [showYAxisLabel]="false" [view]="view"
                                               [xAxisLabel]="xAxisLabel" [xAxis]="showXAxis" [yAxisLabel]="yAxisLabel" [yAxis]="showYAxis" class="lend">
                            <!--                            <ng-template #seriesTooltipTemplate let-model="model">-->
                            <!--                                <span class="chart__tooltip-sub-label">{{model.name}}</span>-->
                            <!--                                <div *ngFor="let mod of model; let i = index">-->
                            <!--                                    <h3 class="m-none">{{mod.series}}-{{mod.value | graphToolTip}}</h3>-->
                            <!--                                </div>-->
                            <!--                            </ng-template>-->
                        </ngx-charts-line-chart>
                    </div>
                    <!--                    &lt;!&ndash; /.card-body &ndash;&gt;-->
                    <div *ngIf="showTab == 2" class="cad-body">

                        <ngx-charts-advanced-pie-chart (select)="onSelect($event)" (window:resize)="onResize($event)" [gradient]="gradient" [results]="singleData"
                                                       [scheme]="colorScheme" [view]="view">
                        </ngx-charts-advanced-pie-chart>
                    </div>
                </div>
                <!-- /.card -->
            </section>
        </div>
        <!-- tabel1----------------------------------------------- -->
        <div class="row">
            <div class="col-md-6">
                <div class="table-header">
                    <div class="table-title">
                        <h4>Brand Profile vs Customer Profile</h4>
                    </div>
                </div>
                <ul class="topSymbollist">
                    <li class="toplist d-flex flex-row align-items-center">
                        <div class="cate-list">
                            <div class="cate-name">
                                <!-- <h4>Kendra L</h4>-->
                                <h4>Follower Match</h4>
                                <p>How closely your brand profile<br> matches users who follow your brand.</p>
                            </div>
                        </div>
                        <div class="num-per d-flex align-items-center justify-content-end justify-content-md-between">
                            <h1>{{brandprofilevscustomer.followerMatch | number: '0.0-0'}}%</h1>
                            <h5 *ngIf="brandprofilevscustomer.followerMatchprev !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : brandprofilevscustomer.followerMatchprev >= 0,
                                'negative' : brandprofilevscustomer.followerMatchprev < 0}">
                                {{brandprofilevscustomer.followerMatchprev | number: '0.0-0'}}%</h5>
                            <h5 *ngIf="brandprofilevscustomer.followerMatchprev === 0 && this.currentFilter !== 'CUSTOM'" class="positive">No Change</h5>
                        </div>
                    </li>
                    <li class="toplist d-flex flex-row align-items-center">
                        <div class="cate-list">
                            <div class="cate-name">
                                <!--                                     <h4>Kendra L</h4>-->
                                <h4>Profile Viewer Match</h4>
                                <p>How closely a user who clicks on your<br>profile matches your brand Profile.</p>
                            </div>
                        </div>
                        <div class="num-per d-flex align-items-center justify-content-end justify-content-md-between">
                            <h1>{{brandprofilevscustomer.profileViewerMatch | number: '0.0-0'}}%</h1>
                            <h5 *ngIf="brandprofilevscustomer.profileViewerMatchprev !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : brandprofilevscustomer.profileViewerMatchprev >= 0,
                                'negative' : brandprofilevscustomer.profileViewerMatchprev < 0}">
                                {{brandprofilevscustomer.profileViewerMatchprev | number: '0.0-0'}}%</h5>
                            <h5 *ngIf="brandprofilevscustomer.profileViewerMatchprev === 0 && this.currentFilter !== 'CUSTOM'" class="positive">No Change</h5>
                        </div>
                    </li>
                    <li class="toplist d-flex flex-row align-items-center">
                        <div class="cate-list">
                            <div class="cate-name">
                                <!--                                     <h4>Kendra L</h4>-->
                                <h4>Click-Through Profile Match</h4>
                                <p>How closely a user who went to your<br>website matched to your brand profile.</p>
                            </div>
                        </div>
                        <div class="num-per d-flex align-items-center justify-content-end justify-content-md-between">
                            <h1>{{brandprofilevscustomer.clickThroughMatch | number: '0.0-0'}}%</h1>
                            <h5 *ngIf="brandprofilevscustomer.clickThroughMatchprev !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : brandprofilevscustomer.clickThroughMatch >= 0,
                                'negative' : brandprofilevscustomer.clickThroughMatch < 0}">
                                {{brandprofilevscustomer.clickThroughMatchprev | number: '0.0-0'}}%</h5>

                            <h5 *ngIf="brandprofilevscustomer.clickThroughMatchprev === 0 && this.currentFilter !== 'CUSTOM'" class="positive">No Change</h5>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <div class="table-header">
                    <div class="table-title">
                        <h4>Community Demographics</h4>
                    </div>
                </div>
                <ul class="topSymbollist">
                    <li class="toplist d-flex flex-row align-items-center">
                        <div class="cate-list">
                            <div class="cate-name">
                                <!--                                     <h4>Kendra L</h4>-->
                                <h4>Your Audience Leans <span style="color:sandybrown">{{gerderRatio.title}}</span></h4>
                                <p>The male to female percentage ratio of your followers. </p>
                            </div>
                        </div>
                        <div class="num-per d-flex align-items-center justify-content-end justify-content-md-between">
                            <h1>{{gerderRatio.ratio | number: '0.0-0'}}%</h1>
                            <!-- <h5 *ngIf="gerderRatioPrev.ratio !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : gerderRatioPrev.ratio >= 0,
                                'negative' : gerderRatioPrev.ratio < 0}">
                                {{gerderRatioPrev.ratio | number: '0.0-0'}}%</h5>

                            <h5 *ngIf="gerderRatioPrev.ratio === 0 && this.currentFilter !== 'CUSTOM'" class="positive">No Change</h5> -->

                        </div>
                    </li>
                    <li class="toplist d-flex flex-row align-items-center">
                        <div class="cate-list">
                            <div class="cate-name">
                                <!--                                     <h4>Kendra L</h4>-->
                                <h4>Average Follower Age</h4>
                                <p>The average age of your followers.</p>
                            </div>
                        </div>
                        <div class="num-per d-flex align-items-center justify-content-end justify-content-md-between">
                            <h1>{{communityDemographic.followerAvgAge | number: '0.0-0'}}</h1>
                            <!-- <h5 [ngClass]="{'positive' : communityDemographic.followerAvgAgePrev >= 0,
                                'negative' : communityDemographic.followerAvgAgePrev < 0}">
                                {{communityDemographic.followerAvgAgePrev | number: '0.0-0'}}</h5> -->
                        </div>
                    </li>
                    <li class="toplist d-flex flex-row align-items-center">
                        <div class="cate-list">
                            <div class="cate-name">
                                <!--                                     <h4>Kendra L</h4>-->
                                <h4>Average Profile Viewer Age</h4>
                                <p>The average age of users who clicked on your profile.</p>
                            </div>
                        </div>
                        <div class="num-per d-flex align-items-center justify-content-end justify-content-md-between">
                            <h1>{{communityDemographic.profileAvgAge | number: '0.0-0'}}</h1>
                            <!-- <h5 [ngClass]="{'positive' : communityDemographic.profileAvgAgePrev >= 0,
                                'negative' : communityDemographic.profileAvgAgePrev < 0}">
                                {{communityDemographic.profileAvgAgePrev | number: '0.0-0'}}</h5> -->
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- tabel2----------------------------------------------- -->
        <div class="row" style="margin-bottom: 106px">
            <div class="col-md-6 mb-4 mt-5">
                <div class="header-pro">
                    <div class="table-title d-flex flex-row align-items-center">
                        <h4 *ngIf="btnName === 'followers' || btnName === 'recommenders' || btnName === 'viewers'">Top symbols from your {{btnName}}</h4>
                        <h4 *ngIf="btnName === 'all'">Top symbols from everyone</h4>
                        <div class="d-flex">
                            <label class="mobile-lab">{{btnName | titlecase}}</label>
                            <div class="mobile d-inline-block">
                            <span appDropdown class="dropdown">
                                    <a aria-expanded="false" aria-haspopup="true"
                                       data-toggle="dropdown" id="DropdownMenuLink"
                                       role="button">
                                        <img alt="caret" src="assets/images/arrow-up.svg"
                                             style="width: 25px; color: #1E537E">
                                    </a>
                                    <div aria-labelledby="DropdownMenuLink" class="dropdown-menu dropdown-menu-side">
                                        <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='all'}"
                                           class="dropdown-item" name="all">All</a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='followers'}"
                                           class="dropdown-item" name="followers"
                                           style="margin-top: 19px;">By # of Followers</a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='recommenders'}"
                                           class="dropdown-item" name="recommenders"
                                           style="margin-top: 19px;">
                                            By # of Recommenders</a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='viewers'}"
                                           class="dropdown-item" name="viewers"
                                           style="margin-top: 19px;">By # of Viewers</a>
                                    </div>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>


                <ul class="topSymbollist">
                    <ngx-simplebar style="height: 454px;">
                        <ng-container *ngFor="let symbols of brandSymbolsAll">
                            <li *ngIf="symbols?.is_predefined" class="toplist d-flex flex-row align-items-center">
                                <img alt="image" class="stories-image img-fluid" src="{{symbols?.image}}">
                                <div class="top-list">
                                    <div class="top-name">
                                        <h4>{{symbols?.title}}</h4>
                                        <p>{{symbols?.name.toString().replace("Category", "Product Category")}}</p>
                                    </div>
                                    <div class="users-pro">
                                        <h2 *ngIf="filter === 'followers'">{{symbols?.total_followers | shortNumber }}</h2>
                                        <h2 *ngIf="filter === 'recommenders'">{{symbols?.total_recommendation | shortNumber }}</h2>
                                        <h2 *ngIf="filter === 'viewers'">{{symbols?.total_viewer | shortNumber }}</h2>
                                        <h2 *ngIf="filter === 'all'">{{symbols?.total}}</h2>

                                        <h5 *ngIf="filter !== 'all'">{{symbols?.followers || symbols?.recommendations || symbols?.viewers }}</h5>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ngx-simplebar>
                </ul>
            </div>
            <div class="col-md-6 mb-4 mt-5">
                <div class="header-pro">
                    <div class="table-title d-flex flex-row align-items-center">
                        <h4>Your followers also follow</h4>
                        <div class="d-flex">
                            <label class="mobile-lab">{{dropName | titlecase}}</label>
                            <div class="mobile d-inline-block" style="margin-top: 16px;">
                            <span appDropdown class="dropdown" style="margin-top: 16px; margin-right: 11px;">
                                    <a aria-expanded="false" aria-haspopup="true"
                                       data-toggle="dropdown" id="DropdownMenuLink2"
                                       role="button">
                            <img alt="caret" src="assets/images/arrow-up.svg" style="width: 25px; color: #1E537E">
                            </a>
                            <div aria-labelledby="DropdownMenuLink2" class="dropdown-menu dropdown-menu-side">
                                <a (click)="followerFilter($event)" [ngClass]="{'active': dropName==='all'}" class="dropdown-item" name="all">All</a>
                                <div class="dropdown-divider"></div>
                                <a (click)="followerFilter($event)" [ngClass]="{'active': dropName==='followers'}" class="dropdown-item" name="followers">By # of followers</a>
                                <div class="dropdown-divider"></div>
                                <a (click)="followerFilter($event)" [ngClass]="{'active': dropName==='recommenders'}" class="dropdown-item" name="recommenders" style="margin-top: 19px;"> By # of Recommenders</a>
                                <div class="dropdown-divider"></div>
                                <a (click)="followerFilter($event)" [ngClass]="{'active': dropName==='viewers'}" class="dropdown-item" name="viewers" style="margin-top: 19px;">By # of Viewers</a>
                            </div>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>
                <ul class="topSymbollist">
                    <ngx-simplebar style="height: 454px;">
                        <li *ngFor="let list of pcTableData" class="toplist d-flex flex-row align-items-center">
                            <img alt="cate-image" class="brand-image img-fluid" src="{{list.logo_url || 'assets/images/images-removebg-preview.png'}}">
                            <div class="top-list">
                                <div class="top-brand">
                                    <h4>{{list.brand_username}}</h4>
                                    <h6>{{list.match_percentage}}% Match</h6>
                                    <p><!--Category--></p>
                                </div>
                                <div class="users-pro">
                                    <h2>{{list.count}}</h2>
                                    <h5 *ngIf="list.count >=2">Followers</h5>
                                    <h5 *ngIf="list.count < 2">Follower</h5>
                                </div>
                            </div>
                        </li>
                    </ngx-simplebar>
                </ul>
            </div>
        </div>

    </div>

</div>
