import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {first} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    cdnUrl = environment.webStaticResources;

    loginForm: FormGroup;
    forgotForm: FormGroup;

    isLoginError = false;
    error: string;
    returnUrl: string;

    loginFormSubmitted = false;
    forgotPasswordFormSubmitted = false;

    forgotPasswordSuccessMessage = false;
    forgotPasswordSuccessMessageText = '';
    forgotPasswordErrorMessage = false;
    forgotPasswordErrorMessageText = '';

    LOGIN_PAGE_ERRORS = {
        not_logged_in: 'Please login before accessing that page.',
        role_not_permitted: 'You do not have access to that page',
        something_went_wrong: 'Something went wrong.',
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        public fb: FormBuilder,
    ) {
        this.loginForm = this.fb.group({
            // email: ['', [Validators.required, Validators.email]],
            email: new FormControl('', {
                validators: [Validators.required, Validators.email,
                    Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')],
                updateOn: 'blur'
            }),
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.forgotForm = this.fb.group({
            forgotEmail: new FormControl('', {
                validators: [Validators.required, Validators.email,
                    Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')],
                updateOn: 'blur'
            })
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    get resetPasswordForm() {
        return this.forgotForm.controls;
    }

    validateAllFormFields(formGroup: FormGroup) {         // {1}
        Object.keys(formGroup.controls).forEach(field => {  // {2}
            const control = formGroup.get(field);             // {3}
            if (control instanceof FormControl) {             // {4}
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {        // {5}
                this.validateAllFormFields(control);            // {6}
            }
        });
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = sessionStorage.getItem('login_redirect_url');
        sessionStorage.removeItem('login_redirect_url');

        this.error = sessionStorage.getItem('login_page_error');
        sessionStorage.removeItem('login_page_error');

        if (this.error) {
            this.error = this.LOGIN_PAGE_ERRORS[this.error];
            this.isLoginError = true;
        }
    }

    onLoginSubmit() {
        if (this.loginFormSubmitted) {
            return false;
        } else {
            this.loginFormSubmitted = true;
            if (this.loginForm.valid) {
                const email = this.loginForm.get('email').value;
                const password = this.loginForm.get('password').value;
                this.authService.GetAuthToken(email, password)
                    .pipe(first())
                    .subscribe(
                        data => {
                            if (data.user.is_email_verified) {
                                if (!data.user.is_active) {
                                    this.error = 'Your account has been deactivated due to reported behavior.';
                                    this.isLoginError = true;
                                } else if (data.user.user_type !== 'brand') {
                                    this.error = 'Only Brand users can login here';
                                    this.isLoginError = true;
                                } else {
                                    // get return url from route parameters or default to '/'
                                    this.returnUrl = this.returnUrl || '/brandaccount';
                                    this.router.navigate([this.returnUrl]);
                                    // window.location.href = this.returnUrl;
                                }
                            } else {
                                this.router.navigate(['/signup']);
                            }


                            this.loginFormSubmitted = false;
                        },
                        error => {
                            this.isLoginError = true;

                            if (error.status === 400) {
                                if ('non_field_errors' in error.error) {
                                    this.error = error.error.non_field_errors[0];
                                }
                            }
                            this.loginFormSubmitted = false;
                        });

            } else {
                this.loginFormSubmitted = false;
                this.validateAllFormFields(this.loginForm);
                return false;
            }
        }
    }

    onResetFormSubmit() {
        this.forgotPasswordSuccessMessage = false;
        this.forgotPasswordErrorMessage = false;
        this.forgotPasswordErrorMessageText = '';
        this.forgotPasswordSuccessMessageText = '';

        if (this.forgotPasswordFormSubmitted) {
            return false;
        } else {
            this.forgotPasswordFormSubmitted = true;
            const regExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
            const fEmail = (document.getElementById('forgot-email') as HTMLInputElement).value;
            if (fEmail === '') {
                this.forgotPasswordSuccessMessage = false;
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Oops, please enter a valid email address.`;
                this.forgotPasswordFormSubmitted = false;
            } else if (!regExp.test(fEmail)) {
                console.log(regExp.test(fEmail));
                this.forgotPasswordSuccessMessage = false;
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Oops, please enter a valid email address.`;
                this.forgotPasswordFormSubmitted = false;
            } else {
                console.log(fEmail);
                this.authService.RequestPasswordReset(fEmail).subscribe(obj => {
                    this.forgotPasswordSuccessMessage = true;
                    // @ts-ignore
                    if (obj.status === 'OK') {
                        this.forgotPasswordSuccessMessage = true;
                        this.forgotPasswordSuccessMessageText = `Nice! A link to reset your password has been sent.`;
                        (document.getElementById('forgot-email') as HTMLInputElement).value = '';
                    } else {
                        this.forgotPasswordErrorMessage = true;
                        this.forgotPasswordErrorMessageText = `Something unexpected happened`;
                    }
                }, error1 => {
                    this.forgotPasswordErrorMessage = true;
                    // tslint:disable-next-line:max-line-length
                    if (error1.error.errors[0].email[0] === 'There is no active user associated with this e-mail address or the password can not be changed') {
                        this.forgotPasswordErrorMessageText = `Oops, you must have registered another way. Try again!`;
                    } else if (error1.error.errors[0].email[0] === 'Enter a valid email address.') {
                        this.forgotPasswordErrorMessageText = `Oops, please enter a valid email address.`;
                    } else if (error1.error.errors[0].email[0] === 'We couldn\'t find an account associated with that email. Please try a different e-mail address.') {
                        this.forgotPasswordErrorMessageText = 'We couldn\'t find an account associated with that email. Please try a different e-mail address.';
                    } else {
                        this.forgotPasswordErrorMessageText = `Something unexpected happened.`;
                    }
                    this.forgotPasswordFormSubmitted = false;
                }, () => {
                    this.forgotPasswordFormSubmitted = false;
                });
            }
        }
    }

    openModal(elementId: string) {
        const ele = document.getElementById(elementId) as HTMLDivElement;
        ele.classList.add('show');
        document.getElementsByTagName('body')[0].classList.add('modal-open');
    }

    closeModal(elementId: string) {
        const ele = document.getElementById(elementId) as HTMLDivElement;
        ele.classList.remove('show');
        document.getElementsByTagName('body')[0].classList.remove('modal-open');
        (document.getElementById('forgot-email') as HTMLInputElement).value = '';
        this.forgotPasswordErrorMessage = false;
        this.forgotPasswordSuccessMessage = false;
        this.forgotPasswordFormSubmitted = false;
    }
}
