import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactUsService} from '../shared/services/ContactUs/contact-us.service';
import {ToastrService} from 'ngx-toastr';
import {SubScribeService} from '../shared/services/Subscribe/sub-scribe.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import Timer = NodeJS.Timer;

@Component({
    selector: 'app-company-page',
    templateUrl: './company-page.component.html',
    styleUrls: ['../../assets/css/mainPages.min.css', './company-page.component.scss']
})
export class CompanyPageComponent implements OnInit {

    // email = new FormControl();
    userSubscribeForm: FormGroup;
    userSubscribeFormSubmitted: boolean;

    success = false;
    environmentText: string;
    imageTag: string;
    imageTagRef: string;
    cdnUrl = environment.webStaticResources;
    mailSent = false;
    sending = false;
    errorMessage: string;
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    alertTimeout: Timer;
    alertErrorTimeout: Timer;

    constructor(public fb: FormBuilder,
                private  subScribeService: SubScribeService,
                private toastr: ToastrService,
                private titleService: Title,
    ) {
        this.userSubscribeForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    get usf() {
        return this.userSubscribeForm.controls;
    }

    ngOnInit() {
        this.environmentText = null;
        if (environment.env !== 'Prod') {
            this.environmentText = `Current Environment: ${environment.env}`;
        }
        this.imageTag = environment.imageTag;
        this.imageTagRef = environment.imageTagRef;
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    subscribe() {
        this.sending = true;
        this.success = false;

        if (this.userSubscribeFormSubmitted) {
            this.success = false;
            return false;

        } else {
            this.userSubscribeFormSubmitted = true;
            if (this.userSubscribeForm.valid) {

                const email = document.getElementById(`email`) as HTMLInputElement;
                const subScribe = {
                    email: email.value,
                };

                this.subScribeService.addSubscribers(subScribe).subscribe(subScriber => {
                    this.success = true;
                    this.mailSent = true;
                    this.sending = false;
                    this.showAlert('Request invite sent');
                    setTimeout(() => {
                        this.mailSent = false;
                    }, 100);
                    (document.getElementById(`email`) as HTMLInputElement).value = '';
                    this.userSubscribeFormSubmitted = false;
                    return false;
                }, err => {
                    console.log('error', err);
                    this.success = false;
                    this.showAlertError(err.error.detail.email);
                    this.userSubscribeFormSubmitted = false;
                    this.mailSent = false;
                    this.sending = false;
                });
            } else {
                this.userSubscribeFormSubmitted = false;
                this.success = false;
                this.validateAllFormFields(this.userSubscribeForm);
                this.mailSent = false;
                this.sending = false;
                return false;
            }
        }
    }

    validateAllFormFields(formGroup: FormGroup) {         // {1}
        Object.keys(formGroup.controls).forEach(field => { // {2}
            const control = formGroup.get(field);             // {3}
            if (control instanceof FormControl) {             // {4}
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {        // {5}
                this.validateAllFormFields(control);            // {6}
            }
        });
    }

    openDropdown() {
        document.getElementById('topNav').classList.toggle('show');
    }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 5000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }
}
