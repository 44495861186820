import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {SocialCause, SocialCauses} from './social_cause';

@Injectable({
    providedIn: 'root',
})
export class SocialCauseService extends ApiService {
    selected = {
        startDate: null,
        endDate: null,
    };
    currentFilter: String = 'WEEK';
    constructor(private http: HttpClient) {
        super();
    }

    CreateSocialCause(data): Observable<SocialCause> {
        return this.http
            .post<SocialCause>(`${this.apiUrl}/social_causes/`, JSON.stringify(data), this.httpOptions)
            .pipe();
    }

    GetAllSocialCauses(): Observable<SocialCauses> {
        return this.http.get<SocialCauses>(`${this.apiUrl}/social_causes/`).pipe();
    }


    FetchFollowerData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/Social_causes_followers/?date_from=${startDate}&date_to=${endDate}&social_causes_id=${id}`)
        .pipe();
    }

    FetchRecommendersData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/social_causes_recommenders/?date_from=${startDate}&date_to=${endDate}&social_causes_id=${id}`)
        .pipe();
    }
    FetchJourneyData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/social_causes_viewers/?date_from=${startDate}&date_to=${endDate}&social_causes_id=${id}`)
        .pipe();
    }
}
