<!-- Main Sidebar Container -->
<div *ngIf="loadDashboard">
    <app-dash-header></app-dash-header>
    <app-dash-side-menu></app-dash-side-menu>
    <router-outlet></router-outlet>

    <div class="video-upload-wrapper border border-top" id="video-upload-wrapper">
        <div class="intro-video-progress">

            <div class="container">
                <div class="row justify-content-end">
                    <div class="col-md-8 offset-md-2">
                        <div class="progress">
                            <div [style.width]="videoUpload.uploadPercentage + '%'" aria-valuemax="100"
                                 aria-valuemin="0"
                                 aria-valuenow="70" class="progress-bar" id="progress-bar" role="progressbar">
                            </div>
                        </div>
                        <span class="uploading-text" id="uploading-text">
                            Uploading Video
                        </span>
<!--                        <span *ngIf="!videoUpload.er" class="error-text" id="error-text">-->
<!--                            Uploading Video-->
<!--                        </span>-->
                    </div>

                    <div class="col-md-2 col-lg-1 p-md-0 m-md-0 text-center">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf2hA0OtD4NlRvhfIOgPJcFNBGqyKIRIYLi7cL8QTKqTj9WAw/viewform"
                           target="_blank" class="help-btn">Help</a>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>



