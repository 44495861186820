import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class VideoProcessingService {
    videoSizeError;

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    public promptForVideo(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            // make file input element in memory
            const fileInput: HTMLInputElement = this.document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'video/*, video/mp4, video/3gpp, ' +
                // 'video/x-flv, video/x-msvideo,' +
                ' video/m4v, application/x-mpegURL, ' +
                'video/MP2T, video/quicktime, video/x-m4v, video/wmv,video/mov';
            // fileInput.setAttribute('capture', 'camera');
            // fileInput['capture'] = 'camera';
            fileInput.addEventListener('error', event => {
                reject(event.error);
            });
            fileInput.addEventListener('change', event => {
                resolve(fileInput.files[0]);
            });
            // prompt for video file
            fileInput.click();
        });
    }

    public generateThumbnail(videoFile: Blob): Promise<string> {
        console.log('In Video Thumbnail');
        const video: HTMLVideoElement = this.document.createElement('video');
        const canvas: HTMLCanvasElement = this.document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        return new Promise<string>((resolve, reject) => {
            canvas.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                resolve(canvas.toDataURL());
            });
            if (videoFile.type) {
                video.setAttribute('type', videoFile.type);
            }
            video.preload = 'auto';
            video.src = window.URL.createObjectURL(videoFile);
            video.load();
        });
    }

    getDuration(e) {
        const duration = e.target.duration;
        this.videoSizeError = duration > 30;
    }
}
