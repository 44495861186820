<div class="tab-content" id="nav-tabContent">

    <div aria-labelledby="nav-home-tab" class="tab-pane fade show active" id="nav-home" role="tabpanel">
        <div class="row">
            <div class="col-lg-12">
                <div class="date-period">
                    <div class="fc-center">
                        <h5 *ngIf="currday">{{startDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="currweek">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="currmonth">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="curryear">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">

                            <label (click)="setActive('dayBtn', 'pcfbtn'); ctShowDay()" class="btn pcfbtn active"
                                   id="dayBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply" [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars" [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate" [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay" autocomplete="off"
                                       emptyWeekRowClass="empty-week" firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day" id="daypicker"
                                       lastDayOfPreviousMonthClass="last-previous-day" lastMonthDayClass="last-day"
                                       matInput name="options" ngxDaterangepickerMd opens="left" singleDatePicker="true"
                                       type="radio"> Day
                            </label>
                            <label (click)="setActive('weekBtn', 'pcfbtn'); ctShoWeek()" class="btn pcfbtn"
                                   id="weekBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply" [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars" [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate" [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay" autocomplete="off"
                                       dateLimit="6" emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day" firstMonthDayClass="first-day"
                                       id="weekpicker" lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day" matInput name="options" ngxDaterangepickerMd
                                       opens="left" type="radio">
                                Week
                            </label>
                            <label (click)="setActive('monthBtn', 'pcfbtn'); ctShowMonth()" class="btn pcfbtn"
                                   id="monthBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply" [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars" [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate" [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay" autocomplete="off"
                                       dateLimit="30" emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day" firstMonthDayClass="first-day"
                                       lastDayOfPreviousMonthClass="last-previous-day" lastMonthDayClass="last-day"
                                       matInput name="options" ngxDaterangepickerMd opens="center" type="radio">
                                Month
                            </label>
                            <label (click)="setActive('yearBtn', 'pcfbtn');myProduct.open()" class="btn pcfbtn"
                                   id="yearBtn" style="line-height: 28px">
                                <mat-select #myProduct style="visibility:hidden;width: 0">
                                    <mat-option (click)="selectYear(year,'pc')" *ngFor="let year of years"
                                                [value]="year">{{ year }}
                                    </mat-option>
                                </mat-select>
                                Year
                            </label>
                            <label (click)="setActive('customBtn', 'pcfbtn'); ctCustomDate()"
                                   class="ngx-daterangepicker-action btn pcfbtn" id="customBtn" style="width:60px">
                                <input #customDate (datesUpdated)="selectedChange($event)"
                                       [(ngModel)]="selected"
                                       [autoApply]="options.autoApply"
                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="false"
                                       [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate"
                                       [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                       autocomplete="off"
                                       emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day" id="customDate"
                                       lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day" matInput name="options"
                                       ngxDaterangepickerMd
                                       opens="right"
                                       type="radio"
                                />
                                Custom
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- cards -->
        <div class="row no-gutters metric-containers">
            <div class="col-sm-4 col-md-6 col-xl-4 col-12 card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Follower’s Top Category</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="body-text">
                            Total # of followers who have
                            selected {{topFollowerCategory?.top_category?.title | titlecase}} as an interest.
                        </div>
                    </div>
                    <div class="cd-body">
                        <div class="card-image">
                            <div class="c-img">
                                <img [src]="topFollowerCategory?.top_category?.image">
                            </div>
                            <div class="c-content">
                                {{topFollowerCategory?.top_category?.title | titlecase}}
                            </div>
                        </div>
                        <div class="card-data">
                            <h1>{{topFollowerCategory?.top_category?.total_followers | numberConversion:1}}</h1>
                            <h5 *ngIf="topFollowerCategory?.top_category_prev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : topFollowerCategory?.top_category_prev>=0,
                                'negative' : topFollowerCategory?.top_category_prev< 0}" class="percent">
                                <p *ngIf="topFollowerCategory?.top_category_prev===0" style="color: #3F9194">+/-</p>
                                {{topFollowerCategory?.top_category_prev | number: '0.0-0'}}%
                            </h5>
                            <h5 *ngIf="topFollowerCategory?.top_category_prev === 0 && currentFilter !== 'CUSTOM'" class="percent positive">No Change</h5>
                        </div>

                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-xl-4 col-12 card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Recommender’s Top Category</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="body-text">
                            The majority of people who recommend your brand
                            have {{topRecommenderCategory?.top_category?.title | titlecase}} in common.
                        </div>
                    </div>
                    <div class="cd-body">
                        <div class="card-image">
                            <div class="c-img">
                                <img [src]="topRecommenderCategory?.top_category?.image">
                            </div>
                            <div class="c-content">
                                {{topRecommenderCategory?.top_category?.title | titlecase}}
                            </div>
                        </div>
                        <div class="card-data">
                            <h1> {{topRecommenderCategory?.top_category?.total_recommendation | numberConversion:1 }}
                            </h1>
                            <h5 *ngIf="topRecommenderCategory?.top_category_prev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : topRecommenderCategory?.top_category_prev>=0,
                                'negative' :topRecommenderCategory?.top_category_prev< 0}" class="percent">
                                <p *ngIf="topRecommenderCategory?.top_category_prev===0" style="color: #3F9194">+/-</p>


                                {{topRecommenderCategory?.top_category_prev | number: '0.0-0' }}%
                            </h5>
                            <h5 *ngIf="topRecommenderCategory?.top_category_prev === 0 && currentFilter !== 'CUSTOM'" class="percent positive">No Change</h5>
                        </div>

                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-xl-4 col-12 card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Viewer’s Top Category</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="body-text">
                            The majority of those who view your brand
                            have {{topViewerCategory?.top_category?.title | titlecase}} in common.
                        </div>
                    </div>
                    <div class="cd-body">
                        <div class="card-image">
                            <div class="c-img">
                                <!-- TODO: loading image when there is no image found -->
                                <!--                                <img [src]="topViewerCategory?.top_category?.image"-->
                                <!--                                     onerror="this.onerror=null;this.src='https://icon-library.com/images/loading-icon-transparent-background/loading-icon-transparent-background-12.jpg';">-->
                                <img [src]="topViewerCategory?.top_category?.image">
                            </div>
                            <div class="c-content">
                                {{topViewerCategory?.top_category?.title | titlecase}}
                            </div>
                        </div>
                        <div class="card-data">
                            <h1>{{topViewerCategory?.top_category?.total_viewer | numberConversion:1}}</h1>
                            <h5 *ngIf="topViewerCategory?.top_category_prev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : topViewerCategory?.top_category_prev>=0,
                                'negative' : topViewerCategory?.top_category_prev< 0}" class="percent">
                                <p *ngIf="topViewerCategory?.top_category_prev===0" style="color: #3F9194">+/-</p>


                                {{topViewerCategory?.top_category_prev | number: '0.0-0'}}%
                            </h5>
                            <h5 *ngIf="topViewerCategory?.top_category_prev === 0 && currentFilter !== 'CUSTOM'" class="percent positive">No Change</h5>
                        </div>

                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
        </div>
        <!--cards-end-->
        <!---table-start-->
        <div class="row">
            <div class="col-xl-12 col-12 ">
                <div class="right-part cards">
                    <div class="table-header">
                        <div class="left">
                            What’s important to your community
                            <div *ngIf="isLoading" class="tiny-loader"></div>

                        </div>
                        <div class="right">
                            <div class="drop-option d-flex">
                                <label class="mobile-lab">{{btnName | titlecase}}</label>
                                <div class="mobile d-inline-block">
                                    <span appDropdown class="dropdown">
                                        <a aria-expanded="false" aria-haspopup="true" data-toggle="dropdown"
                                           id="DropdownMenuLink" role="button">
                                            <img alt="caret" src="../../../assets/images/arrow-up.svg"
                                                 style="width: 25px; color: #1E537E">
                                        </a>
                                        <div aria-labelledby="DropdownMenuLink"
                                             class="dropdown-menu dropdown-menu-side">
                                            <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='all'}"
                                               class="dropdown-item" name="all">All</a>
                                            <div class="dropdown-divider"></div>
                                            <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='followers'}"
                                               class="dropdown-item" name="followers" style="margin-top: 19px;">By # of
                                                Followers</a>
                                            <div class="dropdown-divider"></div>
                                            <a (click)="tabFilter($event)"
                                               [ngClass]="{'active': btnName==='recommenders'}" class="dropdown-item"
                                               name="recommenders" style="margin-top: 19px;">
                                                By % of Recommenders</a>
                                            <div class="dropdown-divider"></div>
                                            <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='viewers'}"
                                               class="dropdown-item" name="viewers" style="margin-top: 19px;">By % of
                                                Viewers</a>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ngx-simplebar style="height: 483px;">
                            <ng-container *ngFor="let data of brandImportantData">

                                <div *ngIf="data.is_predefined" class="item-sections">
                                    <div class="left-item">
                                        <div class="item-img">
                                            <img [src]="data?.image">
                                        </div>
                                        <div class="item-content">
                                            <div class="label-name">{{data?.title | titlecase}}</div>
                                            <div class="label-value">{{data?.name | titlecase}}</div>
                                        </div>
                                    </div>
                                    <div class="right-item">
                                        <div *ngIf="filter==='recommenders'" class="rupee">
                                            {{data?.total_recommendation || 0 | number: '0.0-0'}}%
                                        </div>
                                        <div *ngIf="filter==='viewers'" class="rupee">
                                            {{data?.total_viewer || 0 | number: '0.0-0'}}%
                                        </div>
                                        <div *ngIf="filter==='followers'" class="rupee">
                                            {{data?.total_followers || 0 |numberConversion:1}}
                                        </div>
                                        <div *ngIf="filter==='all'" class="rupee">
                                            <p *ngIf="data?.total_followers>=0" class="mb-0">
                                                {{data?.total_followers | numberConversion:1}} </p>
                                            <p *ngIf="data?.total_recommendation>=0 || data?.total_viewer>=0"
                                               class="mb-0"> {{data?.total_recommendation | number: '0.0-0'}}
                                                {{data?.total_viewer | number: '0.0-0'}}
                                                %</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ngx-simplebar>
                    </div>
                </div>
            </div>
        </div>
        <!---table-ends-->
    </div>
</div>
