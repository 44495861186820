<header class="mainHeader">
    <nav class="navbar navbar-expand-lg navbar-light sticky-top">
        <div class="container px-4 px-md-1">
            <a class="navbar-brand" href="" style="margin-left: 10px">
                <img alt="" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">
            </a>
            <p style="flex: 1">Discover. Connect. Share. Repeat.</p>
            <button (click)="openDropdown()" aria-controls="topNav" aria-expanded="false"
                    aria-label="Toggle navigation" class="navbar-toggler">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="topNav">
                <ul class="navbar-nav ml-auto text-center">
                    <li class="nav-item">
                        <a class="nav-link px-2" href="https://tailorie.com/contact-us" style="margin-top: 12px !important;">
                            Contact Us
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<main style="overflow: hidden">
    <!-- Hero Area -->
    <section class="heroArea">
        <div class="container position-relative">
            <img alt="cloud" class="cloud" src="{{cdnUrl}}/assets/images/company-page/background-object.svg">
            <div class="row text-center text-md-left">
                <!-- Left Vector Area -->
                <div class="col-md-5 hero-left d-flex flex-column justify-content-center align-items-center align-items-lg-start">
                    <!--                    <img class="iphone-mob" src="../../assets/images/Group_14542x.png" alt="iphone-mob">-->
                    <img alt="iphone" class="iphone" src="{{cdnUrl}}/assets/images/company-page/iphone-scaled.png">
                    <img alt="sparkles" class="sparkles" src="{{cdnUrl}}/assets/images/company-page/sparkles.svg">
                </div>
                <!-- Right Text Area -->
                <div class="col-md-7">
                    <img alt="sparkles" class="sparkles-few"
                         src="{{cdnUrl}}/assets/images/company-page/sparkles-few.svg">
                    <div class="sub-col">
                        <h1>
                            Discover your<br>
                            new favorites.
                        </h1>
                        <p class="desk-p">
                            Tailored recommendations — inspired by <i>what’s</i> most<br>
                            important to you and <i>who’s</i> most important to you.
                        </p>
                        <p class="mobile-p">
                            Tailored recommendations —<br> inspired by <i>what’s</i> most
                            important to<br> you and <i>who’s</i> most important to you.
                        </p>
                        <h2>
                            Sign up for early access.
                        </h2>
                        <div class="form">
                            <form [formGroup]="userSubscribeForm">
                                <div class="col-xs-4 input-group mx-auto">
                                    <input [ngClass]="{ 'is-invalid': (usf.email.dirty || userSubscribeFormSubmitted)
                                    && usf.email.errors }"
                                           aria-describedby="button-addon2" aria-label="user email" aria-required="true"
                                           class="form-control" formControlName="email" id="email"
                                           placeholder="Email"
                                           required
                                           type="email">

                                    <div *ngIf="userSubscribeForm.get('email').hasError('required')"
                                         class="invalid-feedback">Email is
                                        required
                                    </div>
                                    <div *ngIf="userSubscribeForm.get('email').hasError('email')"
                                         class="invalid-feedback">
                                        Invalid Email
                                    </div>

                                    <p *ngIf="showAlertMessage || showAlertErrorMassage" class="msg">
                                        <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                        </span>
                                        <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                                        </span>
                                    </p>
                                </div>
                                <div class="d-block pb-lg-0" style="margin-top: 19px;">
                                    <a (click)="subscribe()" class="nav-link req-btn" href="javascript:void(0)">
                                         <span *ngIf="mailSent" class="mail-check">
                                        <img alt="check" src="{{cdnUrl}}/assets/images/icons/check.svg">
                                    </span>
                                        <span *ngIf="sending" class="mail-check">
                                        <img alt="loader" src="{{cdnUrl}}/assets/images/icons/loader.svg">
                                    </span>
                                        <span *ngIf="!mailSent && !sending">
                                        Request Invite
                                    </span>
                                    </a>
                                </div>
                                <i class="i-tag">Tailorie is currently in private beta.</i>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>
<app-footer-homepage></app-footer-homepage>
