<div class="content-wrapper">
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap">
            <img alt="edit-persona-banner-image" class="side-img h-auto img-fluid"
                 src="{{cdnUrl}}/assets/images/PersonalBrand_Girl-and-Mirror-Trimmed.png">
        </div>
        <div class="pr-2">
            <h1>Community Profile</h1>
            <p>Let’s get to know your community.</p>
        </div>
    </div>
    <div class="row main-class">
        <section class="col-lg-12 ">
            <nav>
                <div class="nav nav-tabs " id="nav-tab" role="tablist" style="margin-right: -86px;">
                    <a #link1 (click)="showTabContent(pop1,link1)" aria-controls="pop1" aria-selected="true"
                       class="nav-item bs-tab-link nav-link active" data-toggle="tab" href="javascript:void(0)"
                       id="pop1-tab" role="tab">Overview</a>
                </div>
            </nav>
            <div class="productCategory">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group mb-0">
                            <ng-select (change)="loadMenu(selectedMenu)" [(ngModel)]="selectedMenu" [clearable]="false"
                                       [items]="navMenuBindingList" [searchable]="false">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-content" id="nav-tabContent">
                <div #pop1 aria-labelledby="pop1-tab" class="tab-pane bs-tab-content fade show active" id="pop1"
                     role="tabpanel">
                    <!-- Tab 8 - Brand Description -->
                    <div class="create-account-tab tab-9">
                        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                              (ngSubmit)="submitCustomerProfile()">
                            <div class="row main-brand-form">
                                <div class="col-md-9 col-xl-6">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h2 class="busName" style="margin-bottom: 17px">
                                                Tell us about your community.
                                            </h2>
                                            <h2 class="busName-m" style="margin-bottom: 15px">
                                                {{currentUser.user.branduserprofile.brand.business_name}}
                                            </h2>
                                            <h4 class="busName" style="margin-bottom: 34px;">Describe your ideal customer.</h4>
                                            <h4 class="busName-m" style="margin-bottom: 15px;">Give us your best elevator pitch.</h4>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-md-9">
                                            <div class="text-erea">
                                                <div class="form-group mb-0">

                                                        <textarea class="form-control" id="branddescriptioncomment"
                                                                  maxlength="255" onkeyup="countChar250(this)"
                                                                  placeholder="Write here..."
                                                                  rows="5">{{currentBrandInfo.brandcustomerprofile.persona_description}}</textarea>
                                                    <label class="labelclass" id="charNum250">
                                                        {{(currentBrandInfo.brandcustomerprofile.persona_description ? currentBrandInfo.brandcustomerprofile.persona_description.length :
                                                        0)}}/250
                                                        Characters
                                                    </label>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <!--                                        <div class="row">-->
                                    <!--                                            <div class="col-sm-12">-->

                                    <!--                                                <div class="form-group mt-3">-->
                                    <!--                                                        <textarea onkeyup="countChar(this)" class="form-control"-->
                                    <!--                                                                  id="customerPersonaDescription" maxlength="250"-->
                                    <!--                                                                  placeholder="Write here..." rows="5"-->
                                    <!--                                                                  style="height: 180px;width: 441px;  background-color: white; border: 1px solid #B8BFCC; font-size: 20px;padding-top: 20px;"></textarea>-->
                                    <!--                                                </div>-->
                                    <!--                                                <label id="charNum"-->
                                    <!--                                                       style="margin-right: 50px;font-family: serif; float: right;color: #c6c8ca;font-size: 14px; letter-spacing: -0.16px;  margin-top: 24px;"-->
                                    <!--                                                       *ngIf="!currentBrandInfo.brandcustomerprofile.persona_description">-->
                                    <!--                                                    {{255}}/255 Characters-->
                                    <!--                                                </label>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->

                                    <!-- Slider Item -->
                                    <div class="row" style="margin-top: 66px;">
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <h5>
                                                    Customer Age Range
                                                </h5>
                                                <div class="custom-slider">
                                                    <ng5-slider (highValueChange)="toggleSaveBar()"
                                                                (valueChange)="toggleSaveBar()"
                                                                [(highValue)]=currentBrandInfo.brandcustomerprofile.max_age
                                                                [(value)]=currentBrandInfo.brandcustomerprofile.min_age
                                                                [options]="ageRangeSliderRange"
                                                                id="customer-age-range">
                                                    </ng5-slider>
                                                </div>
                                                <label for="customer-age-range" style="float: left;">
                                                    {{currentBrandInfo.brandcustomerprofile.min_age}} years old
                                                </label>
                                                <label for="customer-age-range" style="float: right;">
                                                    {{currentBrandInfo.brandcustomerprofile.max_age}} years old
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Slider item -->
                                    <div class="row mt-5">
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <h5>
                                                    Male to Female Ratio
                                                </h5>
                                                <div class="custom-slider">
                                                    <ng5-slider (valueChange)="toggleSaveBar()"
                                                                [(value)]="currentBrandInfo.brandcustomerprofile.male_to_female_ratio"
                                                                [options]="singleSliderRange"
                                                                id="male-to-female-ratio">
                                                    </ng5-slider>
                                                </div>
                                                <label for="male-to-female-ratio" style="float: left;">
                                                    {{100 - currentBrandInfo.brandcustomerprofile.male_to_female_ratio}}% Male
                                                </label>
                                                <label for="male-to-female-ratio" style="float: right;">
                                                    {{currentBrandInfo.brandcustomerprofile.male_to_female_ratio}}%
                                                    Female
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Tags -->
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="form-group custom-tags" data-select2-id="45">
                                                <p for="name">Customer Activities, Interests, and Hobbies<br>
                                                    <span class="text-muted">
                                                            To add multiple tags, separate tags by comma
                                                        </span>
                                                </p>
                                                <div class="input-group mycustom">
                                                    <input #cusLife (keyup)="onKeyUpInterest($event)"
                                                           (keyup.enter)="addCustomerInterestsTags()" class="form-control rounded-0 tagName"
                                                           id="customerinterest" maxlength="50"
                                                           placeholder="politics, reading, skiing, yoga, painting"
                                                           required type="text">
                                                    <input #cusLife
                                                           (keyup)="onKeyUpInterest($event)"
                                                           (keyup.enter)="addCustomerInterestsTags()" class="form-control rounded-0 tagName-m"
                                                           id="customerinterest" maxlength="50"
                                                           placeholder="politics, reading, skiing, yoga" required
                                                           style="border: solid 1.2px #e4e4e4;" type="text">
                                                    <div class="input-group-prepend">
                                                        <input (click)="addCustomerInterestsTags()"
                                                               id="customerinterest2" type="button" value="Add">
                                                    </div>
                                                    <div class="tagCounter">
                                                        <label id="cusCounter">
                                                            {{cusTxt.length}}/50
                                                        </label>
                                                    </div>
                                                </div>
                                                <form class="form-inline">
                                                    <div *ngFor="let customerInterest of currentBrandInfo.brandcustomerprofile.customer_interests"
                                                         class="mb-3 mt-2">
                                                        <div *ngIf="customerInterest">
                                                            <div class="tag">
                                                                    <span>
                                                                        {{customerInterest}}&nbsp;&nbsp;
                                                                        <a
                                                                                (click)="removeCustomerInterestTag(customerInterest)">
                                                                            &times;
                                                                        </a>
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Tags -->
                                    <div class="row mb-5">
                                        <div class="col-md-11">
                                            <div class="form-group custom-tags" data-select2-id="45">
                                                <p for="name">Customer Life Stages<br>
                                                    <span class="text-muted">
                                                            To add multiple tags, separate tags by comma
                                                        </span>
                                                </p>
                                                <div class="input-group mycustom">
                                                    <input #cuLife
                                                           (keyup)="onKeyUpDescriptionTags($event)" (keyup.enter)="addCustomerDescriptionTags()"
                                                           class="form-control rounded-0" id="customerDescription"
                                                           maxlength="50"
                                                           placeholder="married, creative, professional"
                                                           required type="text">
                                                    <div class="input-group-prepend">
                                                        <input (click)="addCustomerDescriptionTags()"
                                                               id="customerDescription2" type="button" value="Add">
                                                    </div>
                                                    <div class="tagCounter">
                                                        <label id="valCounter">
                                                            {{csLifeTxt.length}}/50
                                                        </label>
                                                    </div>
                                                </div>
                                                <form class="form-inline">
                                                    <div *ngFor="let customerDescription of currentBrandInfo.brandcustomerprofile.customer_life_stages"
                                                         class="mb-3 mt-2">
                                                        <div *ngIf="customerDescription">
                                                            <div class="tag">
                                                                    <span>
                                                                        {{customerDescription}}&nbsp;&nbsp;
                                                                        <a
                                                                                (click)="removeCustomerDescriptionTag(customerDescription)">
                                                                            &times;
                                                                        </a>
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                                <div class="d-flex align-items-center">
                                    <p class="msg">
                                        <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png">{{alertMessage}}
                                        </span>
                                        <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                                        </span>
                                    </p>
                                    <button class="btn btnsave" type="submit">save</button>
                                </div>
                            </div>
                            <!--                            <div *ngIf="showSaveBar" class="col-12 d-flex justify-content-between saveBar">-->
                            <!--                                 <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">-->
                            <!--                                            <img alt="error" src="assets/images/ErrorAlert.png">&nbsp;{{alertMessage}}-->
                            <!--                                        </span>-->
                            <!--                                <span *ngIf="showAlertMessage" class="alertMessage">-->
                            <!--                                            {{alertMessage}}-->
                            <!--                                        </span>-->
                            <!--                                &nbsp;-->
                            <!--                                <div class="d-flex flex-nowrap align-items-center">-->
                            <!--                                    <button class="btn btn-primary" type="submit">save</button>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                        </form>
                    </div>

                </div>
                <!--                <div #pop2 aria-labelledby="pop2-tab" class="tab-pane fade" id="pop2" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->

                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop3 aria-labelledby="pop3-tab" class="tab-pane fade" id="pop3" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->

                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop4 aria-labelledby="pop4-tab" class="tab-pane fade" id="pop4" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->

                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop5 aria-labelledby="pop5-tab" class="tab-pane fade" id="pop5" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->

                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop6 aria-labelledby="pop6-tab" class="tab-pane fade" id="pop6" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->

                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop7 aria-labelledby="pop7-tab" class="tab-pane fade" id="pop7" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->

                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop8 aria-labelledby="pop8-tab" class="tab-pane fade" id="pop8" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->
                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div #pop9 aria-labelledby="pop9-tab" class="tab-pane fade" id="pop9" role="tabpanel">-->
                <!--                    <div class="sab-heading">-->
                <!--                        <h5>Answer question as your ideal customer Would.<br>-->
                <!--                            We'll use these question to help match your brand.</h5>-->
                <!--                    </div>-->
                <!--                    <div class="row flex-column">-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What is your fit?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of fitButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>Price you spend on clothes & accessories?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of priceButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>What's your style vibe?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of styleVibeButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How do you Identify?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of genderButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="col-lg-5 left-section">-->
                <!--                            <div class="que-wrap">-->
                <!--                                <h4>How old do you dress?</h4>-->
                <!--                                <div class="ans-wrap">-->
                <!--                                    <button (click)="button.isClicked = !button.isClicked"-->
                <!--                                            *ngFor="let button of dressButtons"-->
                <!--                                            [ngClass]="{'active': button.isClicked}"-->
                <!--                                            class="fitButton">-->
                <!--                                        {{ button.text }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
                <!--                                <div class="bor-bottom"></div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </section>
    </div>
</div>


<!--<div class="content-wrapper" style="background-color: #FFFBF8;">-->
<!--    &lt;!&ndash; Content Header (Page header) &ndash;&gt;-->

<!--    &lt;!&ndash; /.content-header &ndash;&gt;-->
<!--    &lt;!&ndash; Main content &ndash;&gt;-->
<!--&lt;!&ndash;    <section class="content p-0 pr-0">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="container-fluid">&ndash;&gt;-->

<!--&lt;!&ndash;            <div class="row mb-2" style="border-bottom: 1px solid #b6c2cc;">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-sm-4 col-md-4 col-lg-1 mt-4 mr-5">&ndash;&gt;-->
<!--&lt;!&ndash;                    <img class="brand-img" src="{{cdnUrl}}/assets/images/Bitmap.png"&ndash;&gt;-->
<!--&lt;!&ndash;                        style="width: 100px; height: 100px; margin-left: -40px;">&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; ./col &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-sm-6 col-md-6 col-lg-5  mt-4 mb-4">&ndash;&gt;-->
<!--&lt;!&ndash;                    <h1 class="m-0 mt-3 ml-0 text-dark mr-5"&ndash;&gt;-->
<!--&lt;!&ndash;                        style="font-family: k22-didoni; font-size: -webkit-xxx-large;">Edit Persona</h1>&ndash;&gt;-->
<!--&lt;!&ndash;                    <p style="font-weight: 500;">Tell us all about your brand! We use this info for your profile, to help users find your brand through search, and to update your canvas.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash; ./col &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="col-sm-2 col-md-2 col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                    &lt;!&ndash; small box &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="row ml-5 mr-5">&ndash;&gt;-->
<!--&lt;!&ndash;                <section class="col-lg-12 ">&ndash;&gt;-->
<!--&lt;!&ndash;                    <nav>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="nav nav-tabs " id="nav-tab" role="tablist" style="margin-right: -86px;padding-top: 15px;">&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop1" aria-selected="true" class="nav-item nav-link active"&ndash;&gt;-->
<!--&lt;!&ndash;                                data-toggle="tab" href="#pop1" id="pop1-tab" role="tab">Basics</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop2" aria-selected="true" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop2" id="pop2-tab" role="tab">Fashion</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop3" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop3" id="pop3-tab" role="tab">Personal Care</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop4" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop4" id="pop4-tab" role="tab">Wellness</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop5" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop5" id="pop5-tab" role="tab">Outdoors</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop6" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop6" id="pop6-tab" role="tab">Kids</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop7" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop7" id="pop7-tab" role="tab">Food & Drink</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop8" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop8" id="pop8-tab" role="tab">Home</a>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a aria-controls="pop9" aria-selected="false" class="nav-item nav-link" data-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;                                href="#pop69" id="pop9-tab" role="tab">Travel</a>&ndash;&gt;-->

<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </nav>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="tab-content" id="nav-tabContent">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div aria-labelledby="pop1-tab" class="tab-pane fade show active" id="pop1" role="tabpanel">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="pt-3"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                            &lt;!&ndash; Tab 8 - Brand Description &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="create-account-tab tab-8">&ndash;&gt;-->

<!--&lt;!&ndash;                                <form (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()"  (ngSubmit)="submitCustomerProfile()">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="row main-brand-form">&ndash;&gt;-->

<!--&lt;!&ndash;                                        <div class="col-md-6">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="col-lg-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <h4>Describe your ideal customer</h4>&ndash;&gt;-->

<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="form-group mt-3">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <textarea onkeyup="countChar(this)" class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            id="customerPersonaDescription" maxlength="250"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            placeholder="Write here..." rows="5"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            style="height: 250px;  background-color: white; border: 1px solid #B8BFCC; font-size: 20px;padding-top: 20px;">{{currentBrandInfo.brandcustomerprofile.persona_description}}</textarea>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <label id="charNum"&ndash;&gt;-->
<!--&lt;!&ndash;                                                        *ngIf="!currentBrandInfo.brandcustomerprofile.persona_description"&ndash;&gt;-->
<!--&lt;!&ndash;                                                        style="font-size: 14px; margin-right:16px; float: right; color: #333F48;">{{255}}/255&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            &lt;!&ndash; Tags &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="row main-brand-form">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="col-md-10 col-12 p-bottom">&ndash;&gt;-->

<!--&lt;!&ndash;                                                    <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <div class="col-sm-12&ndash;&gt;-->
<!--&lt;!&ndash;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <div class="form-group mb-0 ">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <label for="male-to-female-ratio"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        style="font-weight: bold;">Male to Female&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        Ratio</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="custom-slider">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <ng5-slider&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            [(value)]="currentBrandInfo.brandcustomerprofile.male_to_female_ratio"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            [options]="singleSliderRange"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            id="male-to-female-ratio">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        </ng5-slider>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <label style="float: left; color: orange;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        {{currentBrandInfo.brandcustomerprofile.male_to_female_ratio}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        % Male&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <label style="float: right; color: orange;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        {{100 - currentBrandInfo.brandcustomerprofile.male_to_female_ratio}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        % Female&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="row mt-3">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <div class="form-group mb-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <label for="customer-age-range"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        style="font-weight: bold;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        Customer Age Range&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="custom-slider2">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <ng5-slider&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            [(highValue)]=currentBrandInfo.brandcustomerprofile.max_age&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            [(value)]=currentBrandInfo.brandcustomerprofile.min_age&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            [options]="ageRangeSliderRange"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            id="customer-age-range">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        </ng5-slider>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                                    <label style="float: left; color: orange;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        {{currentBrandInfo.brandcustomerprofile.min_age}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        year old&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <label style="float: right; color: orange;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        {{currentBrandInfo.brandcustomerprofile.max_age}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        year old&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                    <div class="row mb-5 mt-5">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="form-group mt-3" data-select2-id="45">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <p for="name" style="color: #1E2A40; font-weight: 500; font-size: 22px;">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    Customer Interests<br><span class="text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        style="color: #92959e;font-size: 18px;">what do your customers enjoy?</span></p>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <div class="input-group mycustom">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <input (keyup.enter)="addCustomerInterestsTags()" aria-describedby="inputGroupPrepend2"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        class="form-control rounded-0"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                           placeholder="yoga, reading, politics, community, etc"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        id="customerinterest" required type="text">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="input-group-prepend">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <input (click)="addCustomerInterestsTags();"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            class="btn10 btn-sm rounded-0"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            id="customerinterest2"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            style="width: 60px; background-color: #006AA5; color:#ffffff;border: none;"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            type="button" value="Add">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <form class="form-inline">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <ng-container *ngFor="let customerInterest of currentBrandInfo.brandcustomerprofile.customer_interests"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        class="mb-3 mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <div *ngIf="customerInterest">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            <div class="tag">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                <span&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    class="badge badge-pill badge-info mx-auto bod">{{customerInterest}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                        (click)="removeCustomerInterestTag(customerInterest)"><i&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                            class="fas fa-times pointer"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                </span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </form>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="row mb-5">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="form-group mt-3" data-select2-id="45">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <p for="name" style="color: #1E2A40; font-weight: 500;font-size: 22px;">Customer Characteristics<br><span class="text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        style="color: #92959e; font-size: 18px;">what do your customers enjoy?</span></p>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <div class="input-group mycustom">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <input (keyup.enter)="addCustomerDescriptionTags()" aria-describedby="inputGroupPrepend2"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        class="form-control rounded-0"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                           placeholder="married, creative, mother, professional, etc."&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        id="customerDescription" required type="text">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="input-group-prepend">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <input (click)="addCustomerDescriptionTags();"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            class="btn10 btn-sm rounded-0"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            id="customerDescription2"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            style="width: 60px; background-color: #006AA5; color:#ffffff;border: none;"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            type="button" value="Add">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <form class="form-inline">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <ng-container *ngFor="let customerDescription of currentBrandInfo.brandcustomerprofile.customer_life_stages"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        class="mb-3 mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <div *ngIf="customerDescription">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            <div class="tag">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                <span&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    class="badge badge-pill badge-info mx-auto bod">{{customerDescription}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                        (click)="removeCustomerDescriptionTag(customerDescription)">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                        <i&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                            class="fas fa-times pointer"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </form>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="saveBar">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <button class="btn btn-primary" type="submit">save</button>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </form>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->

<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div aria-labelledby="pop2-tab" class="tab-pane fade" id="pop2" role="tabpanel">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="pt-3"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="comingSoon shadow-lg bg-white rounded">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="row no-gutters justify-content-center align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="col-6 col-xl-4 text-center img-wrap">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <img class="img-fluid" src="{{cdnUrl}}/assets/images/Dashboard_Pop-up_Coming-Soon_Idea-Image@2x.png"&ndash;&gt;-->
<!--&lt;!&ndash;                                             alt="cate-image">&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="col text-center">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="coming-text">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <h1>Coming Soon!</h1>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <p>Stay tuned for updates.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="row border-bottom bg-image">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="create-account-tab tab-3">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <form>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="row main-brand-form product-categories justify-content-between">&ndash;&gt;-->

<!--&lt;!&ndash;                                            <div class="col-md-8 left-section">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="col-lg-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <p style="font-weight: 500;">Answer question as your ideal&ndash;&gt;-->
<!--&lt;!&ndash;                                                            customer Would.<br>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            We'll use these question to help match your brand.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->


<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                &lt;!&ndash; CheckBox / Select Items &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="col-md-12 col-lg-6">&ndash;&gt;-->

<!--&lt;!&ndash;                                                        <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="col-sm-4 mb-3">&ndash;&gt;-->

<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                &lt;!&ndash; Tags &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <div class="form-group mt-5" data-select2-id="45">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <p for="name"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                style="color: #1E2A40; font-weight: 500;margin: 35px 0 5px 0; font-size: 22px">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                Add Additional Categories<br><span class="text-muted"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    style="color: #92959e;font-size: 18px;">To&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    add mutliple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    tags seperate tags by comma</span></p>&ndash;&gt;-->

<!--&lt;!&ndash;                                                            <form>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                <div class="col-lg-10 mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="input-group mycustom">&ndash;&gt;-->

<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <form class="form-inline">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <div class="mb-3 mt-2">&ndash;&gt;-->

<!--&lt;!&ndash;                                                                        </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                                    </form>&ndash;&gt;-->

<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                            </form>&ndash;&gt;-->

<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="create-account-tab tab-4">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="container">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="row main-brand-form">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="col-md-12">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <h4>Add Price and Product Tags</h4>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <p class="mt-2">This is your brands product categories.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        &lt;!&ndash;  &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                        &lt;!&ndash; Single Product Item &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            &lt;!&ndash;                                <div class="col-sm-1"></div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <div class="offset-sm-3 col-sm-6 mb-3">&ndash;&gt;-->

<!--&lt;!&ndash;                                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                    </form>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </section>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </section>&ndash;&gt;-->

<!--    &lt;!&ndash; /.content &ndash;&gt;-->
<!--</div>-->
