import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BrandTopUsersService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    GetBrandTopUsers() {
        return this.http.get(`${this.apiUrl}/get_brand_admin_top_users`)
            .pipe();
    }
}
BrandTopUsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandTopUsersService_Factory() { return new BrandTopUsersService(i0.ɵɵinject(i1.HttpClient)); }, token: BrandTopUsersService, providedIn: "root" });
