import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CanvasCrateService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.listCrates = new BehaviorSubject(false);
        this.listcrate = this.listCrates.asObservable();
    }
    // Function to reset canvas crate list in observable
    setlistCrates(value) {
        this.listCrates.next(value);
    }
    // POST TO CREATE CANVAS CRATE
    CreateCanvasCrate(data) {
        return this.http.post(`${this.apiUrl}/brand_canvas_crate/`, data).pipe();
    }
    // GET CANVAS CRATES
    ListCanvasCrates() {
        return this.http.get(`${this.apiUrl}/brand_canvas_crate/`).pipe();
    }
    // GET CANVAS CRATES
    GetCanvasCrate(crateId) {
        return this.http.get(`${this.apiUrl}/brand_canvas_crate/?crate_id=${crateId}`).pipe();
    }
    // GET CANVAS CRATES
    UpdateCanvasCrate(crateId, data) {
        return this.http.put(`${this.apiUrl}/brand_canvas_crate/?crate_id=${crateId}`, data).pipe();
    }
    // Upload Canvas Crate Media
    UploadCanvasCrateMedia(crateId, data) {
        return this.http.post(`${this.apiUrl}/canvas_crate/media/${crateId}/`, data).pipe();
    }
    // Delete Canvas Crate Media
    DeleteCanvasCrateMedia(crateId, mediaId) {
        return this.http.delete(`${this.apiUrl}/canvas_crate/media/${crateId}/?media_id=${mediaId}`).pipe();
    }
}
CanvasCrateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanvasCrateService_Factory() { return new CanvasCrateService(i0.ɵɵinject(i1.HttpClient)); }, token: CanvasCrateService, providedIn: "root" });
