import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/auth/auth.service";
export class AuthGuard {
    constructor(authService) {
        this.authService = authService;
    }
    canActivate(route, state) {
        if (this.authService.currentUserValue) {
            if (['company-page', 'login', 'brandaccount'].includes(route.routeConfig.path)) {
                // this.router.navigate(['/dashboard']);
                // TODO: replace redirect to dashboard once production screens are approved
                window.location.href = '/brand-journey';
                return false;
            }
            // logged in so return true
            return true;
        }
        else {
            if (!['login', 'brandaccount'].includes(route.routeConfig.path)) {
                // not logged in so redirect to login page with the return url
                sessionStorage.setItem('login_page_error', 'not_logged_in');
                sessionStorage.setItem('login_redirect_url', state.url);
                // this.router.navigate(['/login']);
                window.location.href = '/login';
                return false;
            }
            return true;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService)); }, token: AuthGuard, providedIn: "root" });
