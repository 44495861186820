/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dash-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../shared/directives/dropdown.directive";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./dash-header.component";
import * as i7 from "../shared/services/auth/auth.service";
import * as i8 from "../shared/services/user/user.service";
import * as i9 from "../shared/services/brand/brand.service";
import * as i10 from "ngx-toastr";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../shared/services/toggle-sidebar.service";
var styles_DashHeaderComponent = [i0.styles];
var RenderType_DashHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashHeaderComponent, data: {} });
export { RenderType_DashHeaderComponent as RenderType_DashHeaderComponent };
export function View_DashHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "nav", [["class", "navbar navbar-expand-md navbar-light bg-white fixed-top"], ["style", "height: 65px;border-bottom: 1px solid rgba(0,0,0,0.1);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "div", [["class", "navbar-collapse justify-content-between"], ["id", "nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "hamburgerButton"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSideMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "="], ["loading", "lazy"], ["style", "width: 20px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 22, "form", [["class", "form-inline ml-3 vl"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(7, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "label", [["class", "hoverable d-flex align-items-center"], ["for", "fileInput"], ["style", "margin-left: 33px;margin-bottom: 4px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "user-icon"]], [[2, "profilePic", null], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["accept", "image/*"], ["hidden", ""], ["id", "fileInput"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSelectFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 12, "div", [["appDropdown", ""], ["class", "nav-item dropdown"], ["style", "margin-left: 13px;"]], null, [[null, "click"], ["document", "click"], ["document", "touchstart"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onclick() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).handleOutsideClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16).handleOutsideClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i3.DropdownDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "a", [["class", "nav-link customCaret"], ["href", "javascript:void(0)"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", " "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 7, "div", [["class", "dropdown-menu"], ["style", "margin-left: -20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "a", [["class", "dropdown-item border-bottom"], ["href", "javascript:void(0)"], ["routerLink", "/edit-brand-profile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["alt", "user-icon"], ["style", "width: 24px;height: 23px;"]], [[2, "profilePic", null], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 Profile"])), (_l()(), i1.ɵeld(25, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "img", [["alt", "logout-icon"], ["src", "https://webcdn.tailorie.com/assets/images/icons/Log%20out@2x.png"], ["style", "width: 15px; margin-left: 7px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0 Logout"]))], function (_ck, _v) { var currVal_13 = "/edit-brand-profile"; _ck(_v, 22, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cdnUrl, "/assets/images/icons/hamburger.svg"); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.currentUser.user.branduserprofile.brand.logo_url; var currVal_9 = (_co.currentUser.user.branduserprofile.brand.logo_url ? _co.currentUser.user.branduserprofile.brand.logo_url : "https://webcdn.tailorie.com/assets/images/icons/add-logo-md.png"); _ck(_v, 11, 0, currVal_8, currVal_9); var currVal_10 = _co.currentUser.user.branduserprofile.brand.business_name; _ck(_v, 18, 0, currVal_10); var currVal_11 = i1.ɵnov(_v, 22).target; var currVal_12 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_11, currVal_12); var currVal_14 = _co.currentUser.user.branduserprofile.brand.logo_url; var currVal_15 = (_co.currentUser.user.branduserprofile.brand.logo_url ? _co.currentUser.user.branduserprofile.brand.logo_url : "https://webcdn.tailorie.com/assets/images/icons/Group 1624@2x.png"); _ck(_v, 23, 0, currVal_14, currVal_15); }); }
export function View_DashHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dash-header", [], null, null, null, View_DashHeaderComponent_0, RenderType_DashHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.DashHeaderComponent, [i4.Router, i7.AuthService, i8.UserService, i9.BrandService, i10.ToastrService, i11.Title, i12.ToggleSidebarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashHeaderComponentNgFactory = i1.ɵccf("app-dash-header", i6.DashHeaderComponent, View_DashHeaderComponent_Host_0, {}, {}, []);
export { DashHeaderComponentNgFactory as DashHeaderComponentNgFactory };
