<!--<header class="mainHeader">-->
<!--    <nav class="navbar navbar-expand-lg navbar-light sticky-top">-->
<!--        <div class="container">-->
<!--            <a class="navbar-brand" routerLink="/">-->
<!--                <img alt="tailorie-logo" class="tailorie-logo"-->
<!--                     src="https://webcdn.tailorie.com/assets/images/Tailorie-logo_dark.svg">-->
<!--            </a>-->
<!--            <button aria-controls="topNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"-->
<!--                    data-target="#topNav" data-toggle="collapse">-->
<!--                <span class="navbar-toggler-icon"></span>-->
<!--            </button>-->
<!--            <div class="collapse navbar-collapse" id="topNav">-->
<!--                &lt;!&ndash;                <ul class="navbar-nav ml-auto">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <li class="nav-item">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <a class="nav-link px-2" routerLink="/contact-us">Contact Us</a>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                    <li class="nav-item" *ngIf="!currentUser">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <a class="nav-link" href="#" routerLink="/login">Login</a>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                    <li class="nav-item" *ngIf="!currentUser">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <a class="nav-link join-btn" href="#" routerLink="/brandaccount">Join Now</a>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                    <li class="nav-item ml-4" *ngIf="currentUser">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <a class="nav-link join-btn" href="#" routerLink="/dashboard">Go to Dashboard</a>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </li>&ndash;&gt;-->
<!--                &lt;!&ndash;                </ul>&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
<!--    </nav>-->
<!--</header>-->

<app-header-homepage></app-header-homepage>

<main style="overflow: hidden">
    <!-- Hero Area -->
    <section class="heroArea">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="terms-and-condition">
                        <h1>
                            Terms and Conditions ("Terms")
                        </h1>
                        <p style="margin-top: 56px">
                            Last updated: February 10th, 2020
                        </p>
                        <p>
                            Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before
                            using the <a href="https://tailorie.com">http://tailorie.com</a> website and the tailorie Mobile App
                            mobile application (the
                            "Service") operated by tailorie Inc. ("us", "we", or "our").
                        </p>
                        <p>
                            Your access to and use of the Service is conditioned on your acceptance of and compliance
                            with these Terms. These Terms apply to all visitors, users and others who access or use the
                            Service.
                        </p>
                        <h5>
                            By accessing or using the Service you agree to be bound by these Terms. If you disagree with
                            any part of the terms then you may not access the Service.
                        </h5>
                        <!--                        -->
                        <h5>
                            Subscriptions
                        </h5>
                        <p>
                            Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will
                            be billed in advance on a monthly recurring basis.
                        </p>
                        <!--                        -->
                        <h5>
                            Content
                        </h5>
                        <p>
                            Our Service allows you to post, link, store, share and otherwise make available certain
                            information, text, graphics, videos, or other material ("Content"). You are responsible for
                            the content you post and grant tailoire an non-exclusive right to use uploaded content.
                        </p>
                        <!--                        -->
                        <h5>
                            Links To Other Web Sites
                        </h5>
                        <p>
                            This Our Service may contain links to third-party web sites or services that are not owned
                            or controlled by tailorie.
                        </p>
                        <p>
                            Tailorie has no control over, and assumes no responsibility for, the content, privacy
                            policies, or practices of any third party web sites or services. You further acknowledge and
                            agree that Tailorie shall not be responsible or liable, directly or indirectly, for any
                            damage or loss caused or alleged to be caused by or in connection with use of or reliance on
                            any such content, goods or services available on or through any such web sites or services.
                        </p>
                        <!--                        -->
                        <h5>
                            Changes
                        </h5>
                        <p>
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
                            If a revision is material we will try to provide at least 30 days' notice prior to any new
                            terms taking effect. What constitutes a material change will be determined at our sole
                            discretion.
                        </p>
                        <!--                        -->
                        <h5>
                            Contact Us
                        </h5>
                        <p>
                            If you have any questions about these Terms, please <a href="https://tailorie.com/contact-us">contact
                            us</a>.
                        </p>

                        <br>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

<!-- Footer -->
<app-footer-homepage></app-footer-homepage>
