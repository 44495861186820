import {AfterViewInit, Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {Brand, BrandBillingAddress, BrandContactDetails} from '../shared/services/brand/brand';
import {BrandService} from '../shared/services/brand/brand.service';
import {AuthService} from '../shared/services/auth/auth.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UsStatesModel} from './us-states.model';
import {AccountsCountriesService} from '../shared/services/accounts/accounts-countries.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import Timer = NodeJS.Timer;

@Component({
    selector: 'app-account-info',
    templateUrl: './account-info.component.html',
    styleUrls: ['./account-info.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({opacity: 1, transform: 'translateY(0)'})),
            transition('void => *', [
                style({opacity: 0, transform: 'translateY(100%)'}),
                animate(400)
            ]),
            transition('* => void', [
                animate(400, style({opacity: 0, transform: 'translateY(100%)'}))
            ])
        ])
    ]
})

export class AccountInfoComponent implements OnInit, AfterViewInit {
    cdnUrl = environment.webStaticResources;
    currentUser: AuthenticatedUser;
    currentBrandInfo: Brand;

    forgotForm: FormGroup;
    forgotPasswordFormSubmitted = false;

    forgotPasswordSuccessMessage = false;
    forgotPasswordSuccessMessageText = '';
    forgotPasswordErrorMessage = false;
    forgotPasswordErrorMessageText = '';
    updateProfilePicFlag = false;
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    alertTimeout: Timer;
    alertErrorTimeout: Timer;
    contactDetails: BrandContactDetails = {
        contact_name: '',
        phone: null,
        title: ''
    };

    billingAddress: BrandBillingAddress = {
        address_line_1: '',
        address_line_2: '',
        city: null,
        state: 'mn',
        country: 'usa',
        zip_code: null
    };

    defaultStateBindingsList = [
        {value: '', label: ''},
        {value: '', label: 'Select Country'}
    ];
    defaultCountryBindingList = [];

    selectedState: { value: string, label: string } = null;
    selectedCountry: { value: string, label: string } = null;

    showMsg = {
        type: 'success',
        msg: 'Success! Your changes have been saved.',
        show: false,
    };

    showSaveBar = false;

    visible = false;

    constructor(private authService: AuthService, private brandService: BrandService, private toastr: ToastrService, private accountsService: AccountsCountriesService) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {

        this.visible = localStorage.getItem('visible') === 'true';

        this.selectedState = this.defaultStateBindingsList[0];
        this.selectedCountry = this.defaultCountryBindingList[0];

        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;

        this.currentBrandInfo = new Brand(currentBrand.id, currentBrand.business_name, currentBrand.description, currentBrand.tags,
            currentBrand.brandsignupchecklist, currentBrand.brandcustomerprofile,
            currentBrand.product_categories, currentBrand.vibes, currentBrand.values, currentBrand.social_causes,
            currentBrand.sales_channel, currentBrand.website, currentBrand.headquarter_location, currentBrand.tax_id,
            currentBrand.years_in_business, currentBrand.number_of_locations, currentBrand.business_percent_online,
            currentBrand.business_percent_instore);
        this.currentBrandInfo.initBrandInfo();
        this.loadData();
    }

    ngAfterViewInit() {
        this.changeDetector();
    }

    changeDetector() {
        const allInputs = document.querySelectorAll('input');
        const allTextareas = document.querySelectorAll('textarea');

        allInputs.forEach((value, key) => {
            value.addEventListener('keyup', () => {
                this.showSaveBar = true;
            });
        });

        allTextareas.forEach((value, key) => {
            value.addEventListener('keyup', () => {
                this.showSaveBar = true;
            });
        });
    }

    showTabContent(pop: HTMLDivElement, link: HTMLAnchorElement) {
        this.changeDetector();

        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }
        pop.classList.add('show', 'active');
        link.classList.add('active');
    }

    loadData() {

        this.defaultCountryBindingList = new UsStatesModel().getCountries();
        this.brandService.GetBrandContactDetails(this.currentBrandInfo.id).subscribe((cd) => {
            this.contactDetails = cd;
            // this.contactDetails.contact_name = this.currentUser.user.branduserprofile.;
            this.brandService.GetBrandBillingAddress(this.currentBrandInfo.id).subscribe(async ba => {
                this.billingAddress = ba;

                this.selectedCountry = this.defaultCountryBindingList.find(y => y.value === ba.country);

                if (this.defaultCountryBindingList.find(y => y.value === ba.country)) {
                    await this.getStates(this.selectedCountry.label).then(states => {
                        this.defaultStateBindingsList = states;
                        this.selectedState = this.defaultStateBindingsList.find(x => x.value === ba.state);
                    });
                }
            }, err => {
                console.log(err.error.errors[0].error);
            });

        });
    }

    getStates(country: string) {
        // this.defaultStateBindingsList = new UsStatesModel().getUsStates();
        const states = this.accountsService.getStates(country).toPromise();
        return states;
    }

    loadStates(country: string) {
        this.getStates(this.selectedCountry.label).then(states => {
            this.defaultStateBindingsList = states;
        });
        this.selectedState = {value: '', label: ''};

    }

    submitData() {
        this.brandService.PostBrandContactDetails(this.currentBrandInfo.id, this.contactDetails).subscribe(cd => {
            this.contactDetails = cd;
            this.showAlert('Success! Your changes have been saved.');
            setTimeout(() => {
                this.showSaveBar = false;
            }, 2000);
        }, error => {
            this.showAlertError('Something went wrong!');
        });
        this.billingAddress.state = this.selectedState.value;
        this.billingAddress.country = this.selectedCountry.value;
        this.brandService.PostBrandBillingAddress(this.currentBrandInfo.id, this.billingAddress).subscribe(ba => {
            this.billingAddress = ba;
            this.showAlert('Success! Your changes have been saved.');
        }, error => {
            this.showAlertError('Something went wrong!');
        });
    }

    onResetFormSubmit() {
        this.forgotPasswordSuccessMessage = false;
        this.forgotPasswordErrorMessage = false;
        this.forgotPasswordErrorMessageText = '';
        this.forgotPasswordSuccessMessageText = '';

        if (this.forgotPasswordFormSubmitted) {
            return false;
        } else {
            this.forgotPasswordFormSubmitted = true;
            const regExp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}$');
            const fEmail = (document.getElementById('reset-email') as HTMLInputElement).value;
            if (fEmail === '') {
                this.forgotPasswordSuccessMessage = false;
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Email is Required`;
                this.forgotPasswordFormSubmitted = false;
            } else if (!regExp.test(fEmail)) {
                this.forgotPasswordSuccessMessage = false;
                this.forgotPasswordErrorMessage = true;
                this.forgotPasswordErrorMessageText = `Enter a valid Email`;
                this.forgotPasswordFormSubmitted = false;
            } else {
                this.authService.RequestPasswordReset(fEmail).subscribe(obj => {
                    this.forgotPasswordSuccessMessage = true;
                    // @ts-ignore
                    if (obj.status === 'OK') {
                        this.forgotPasswordSuccessMessage = true;
                        this.forgotPasswordSuccessMessageText = `An email has been sent with the reset password link on ${fEmail}`;
                        (document.getElementById('reset-email') as HTMLInputElement).value = '';
                        this.openModal('resetPasswordModal');
                    } else {
                        this.forgotPasswordErrorMessage = true;
                        this.forgotPasswordErrorMessageText = `Something unexpected happened`;
                    }
                }, error1 => {
                    this.forgotPasswordErrorMessage = true;
                    // tslint:disable-next-line:max-line-length
                    if (error1.error.errors[0].email[0] === 'There is no active user associated with this e-mail address or the password can not be changed') {
                        this.forgotPasswordErrorMessageText = `We can't find anyone with ${fEmail}.`;
                        this.forgotPasswordFormSubmitted = false;
                    } else {
                        this.forgotPasswordErrorMessageText = `Something unexpected happened`;
                        this.forgotPasswordFormSubmitted = false;
                    }
                    this.forgotPasswordFormSubmitted = false;
                }, () => {
                    this.forgotPasswordFormSubmitted = false;
                });
            }
        }
    }

    openModal(elementId: string) {
        const ele = document.getElementById(elementId) as HTMLDivElement;
        ele.classList.add('show');
        document.getElementsByTagName('body')[0].classList.add('modal-open');
    }

    closeModal(elementId: string) {
        const ele = document.getElementById(elementId) as HTMLDivElement;
        ele.classList.remove('show');
        document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }

    togglePanel(elemId: string) {
        document.getElementById(elemId).classList.toggle('open');
    }

    toggleSaveBar() {
        this.showMsg.show = false;
        this.showSaveBar = true;
    }

    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 5000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }
}
