import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VibeService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    CreateVibes(data) {
        const newData = {
            title: data.title
        };
        return this.http.post(`${this.apiUrl}/vibes/`, JSON.stringify(newData), this.httpOptions).pipe();
    }
    GetAllVibes() {
        return this.http.get(`${this.apiUrl}/vibes/`).pipe();
    }
    FetchFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/vibe_follower/?date_from=${startDate}&date_to=${endDate}&vibe_id=${id}`)
            .pipe();
    }
    FetchRecommendersData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/vibe_recommenders/?date_from=${startDate}&date_to=${endDate}&vibe_id=${id}`)
            .pipe();
    }
    FetchJourneyData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/vibe_viewers/?date_from=${startDate}&date_to=${endDate}&vibe_id=${id}`)
            .pipe();
    }
}
VibeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VibeService_Factory() { return new VibeService(i0.ɵɵinject(i1.HttpClient)); }, token: VibeService, providedIn: "root" });
