import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {BrandStories, BrandStory, BrandStoryTypes, FilterBrandStories} from './brand-stories';

@Injectable({
    providedIn: 'root',
})
export class BrandStoriesService extends ApiService {

    // Private Observable to store and update boolean status of video uploaded status
    private storyUploaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // Public property of observable type which return storyUpload observable value as a observable
    brandStoryUploaded = this.storyUploaded.asObservable();

    constructor(private http: HttpClient) {
        super();
    }

    // Function to set video uploaded status (boolean) in storyUpload observable
    setBrandStoryUploaded(value: boolean) {
        this.storyUploaded.next(value);
    }

    GetAllBrandStoryTypes(filter: string): Observable<BrandStoryTypes> {
        return this.http
            .get<BrandStoryTypes>(
                `${this.apiUrl}/post_types/brand/?brand_post_category=${filter}`,
                this.httpOptions
            )
            .pipe();
    }

    // POST
    CreateBrandStory(brandId, data): Observable<BrandStory> {
        return this.http.post<BrandStory>(`${this.apiUrl}/brands/${brandId}/posts/`, data).pipe();
    }

    GetAllBrandStory(brandId, filter: string): Observable<BrandStories> {
        return this.http
            .get<BrandStories>(
                `${this.apiUrl}/brands/${brandId}/posts/?brand_post_category=${filter}`,
                this.httpOptions
            )
            .pipe();
    }

    GetBrandFilteredStory(brandId, filter: string): Observable<FilterBrandStories> {
        return this.http
            .get<FilterBrandStories>(
                `${this.apiUrl}/brands/${brandId}/posts_filtered/?brand_post_category=${filter}`,
                this.httpOptions
            )
            .pipe();
    }

    RemoveBrandStory(Id, brandStoryId): Observable<object> {
        return this.http
            .delete<object>(`${this.apiUrl}/brands/${Id}/posts/${brandStoryId}/`, this.httpOptions)
            .pipe();
    }

    // Update Brand Story
    updateBrandStory(brandId, postId, data): Observable<BrandStory> {
        return this.http.patch<BrandStory>(`${this.apiUrl}/brands/${brandId}/posts/${postId}/`, data).pipe();
    }

    // Update Video Thumbnail
    updateThumbnail(timeFrame: number, brandId: number, postId: number): Observable<any> {
        const params = new HttpParams({
            fromObject: {frame_number: timeFrame.toString()},
        });

        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
        };
        // tslint:disable-next-line:max-line-length
        return this.http.post<any>(`${this.apiUrl}/brands/${brandId}/posts/${postId}/select_video_thumbnail/`, params.toString(), httpOptions).pipe();
    }
}
