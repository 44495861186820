export const environment = {
	production: true,
	apiBaseUrl: window['env']['apiBaseUrl'] || '',
	apiVersion: window['env']['apiVersion'] || 'v1',
	debug: window['env']['debug'] || false,
	env: window['env']['env'] || 'local',
	imageTag: window['env']['imageTag'] || '',
	imageTagRef: window['env']['imageTagRef'] || '',
	webStaticResources: window['env']['webStaticResources'] || '',
};
