import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    // POST
    CreateUser(data) {
        return this.http.post(`${this.apiUrl}/users/`, JSON.stringify(data), this.httpOptions).pipe();
    }
    // POST (New Sign Up)
    RegisterUser(userData) {
        // const formdata: FormData = userData;
        return this.http
            .post(`${this.apiUrl}/users/signup/`, {
            email: userData.email,
            password: userData.password,
            user_type: userData.user_type,
        })
            .pipe();
    }
    // Email Verification
    VerifyEmail(code) {
        return this.http
            .post(`${this.apiUrl}/users/email-verification/`, {
            action: 'verify_code',
            verification_code: code,
        }, this.httpOptions)
            .pipe();
    }
    // Resend Code for Email Verification
    ResendCode() {
        return this.http
            .post(`${this.apiUrl}/users/email-verification/`, {
            action: 'resend_code',
        }, this.httpOptions)
            .pipe();
    }
    GetUserByEmail(email) {
        return this.http
            .get(`${this.apiUrl}/users/email/${email}/`)
            .pipe();
    }
    GetUserEmailAvailability(email) {
        return this.http
            .get(`${this.apiUrl}/users/check_availability/email/${email}/`)
            .pipe();
    }
    updateUserProfileById(id, data) {
        return this.http.patch(`${this.apiUrl}/users/${id}/`, data).pipe();
    }
    updateBrandUserProfile(data) {
        return this.http.patch(`${this.apiUrl}/users/profile/`, data).pipe();
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
