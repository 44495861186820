import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../shared/services/auth/auth.service';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {UserService} from '../shared/services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';
import {ToggleSidebarService} from '../shared/services/toggle-sidebar.service';
import {environment} from '../../environments/environment';
import {BrandService} from '../shared/services/brand/brand.service';

@Component({
    selector: 'app-dash-header',
    templateUrl: './dash-header.component.html',
    styleUrls: ['./dash-header.component.scss']
})
export class DashHeaderComponent implements OnInit {

    cdnUrl = environment.webStaticResources;

    currentUser: AuthenticatedUser;

    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private brandService: BrandService,
        private toastr: ToastrService,
        private titleService: Title,
        private sideMenuService: ToggleSidebarService
    ) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    ngOnInit() {
        this.currentUser = this.authService.currentUserValue;
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/']);
    }

    onSelectFile(event: any) {
        const data = new FormData();
        if (event.target.files && event.target.files[0]) {
            data.append('logo', event.target.files[0]);
            // const reader = new FileReader();
            //
            // reader.readAsDataURL(event.target.files[0]); // read file as data url
            //
            // reader.onload = (event: any) => { // called once readAsDataURL is completed
            //     this.url = event.target.result;
            // };
        }
        this.brandService.UpdateBrandLogo(this.currentUser.user.branduserprofile.brand.id, data)
            .subscribe(resp => {
                const currentBrand = this.currentUser.user.branduserprofile.brand;
                currentBrand.logo_url = resp.logo;
                this.authService.updateCurrentUserBrand(currentBrand);
                this.toastr.success('Image upload Successfully!');
            }, error => {
                this.toastr.error('Something went wrong please try again!');
            });
    }

    toggleSideMenu() {
        this.sideMenuService.toggleStatus.emit(true);
    }
}
