import { NavigationEnd } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class PreviousRouteService {
    constructor(router) {
        this.router = router;
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }
    getPreviousUrl() {
        return this.previousUrl;
    }
    getCurrentUrl() {
        return this.router.url;
    }
}
PreviousRouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreviousRouteService_Factory() { return new PreviousRouteService(i0.ɵɵinject(i1.Router)); }, token: PreviousRouteService, providedIn: "root" });
