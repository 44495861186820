import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/auth/auth.service";
export class LoggedInGuard {
    constructor(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    canActivate(route, state) {
        if (this.authService.currentUserValue) {
            this.router.navigate(['brand-journey']);
        }
        return true;
    }
}
LoggedInGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInGuard_Factory() { return new LoggedInGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: LoggedInGuard, providedIn: "root" });
