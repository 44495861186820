<div class="content-wrapper">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap">
            <img class="side-img w-auto" src="{{cdnUrl}}/assets/images/brand-account-images/image6-fliped.png"
                 style="margin-left: 20px">
        </div>
        <div class="pr-2">
            <h1>Brand Profile</h1>
            <p>Your brand profile generates your brand’s page in the Tailorie app.</p>
        </div>
    </div>
    <!------------------------------------ TABS ------------------------------------>
    <div class="row mx-md-5 ml-0 mr-0 position-relative">
        <section class="col-lg-12 py-0 pr-0 pl-0">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a #link1 (click)="showTabContent(pop1,link1)" [ngClass]="{'active' : selectedTab === 'bd'}"
                       aria-controls="pop1" aria-selected="true" class="nav-item nav-link bs-tab-link" data-toggle="tab"
                       fragment="bd" href="javascript:void(0)" id="pop1-tab" role="tab"
                       routerLink="/edit-brand-profile">Basics</a>
                    <a #link2 (click)="showTabContent(pop2,link2)" [ngClass]="{'active' : selectedTab === 'pc'}"
                       aria-controls="pop2" aria-selected="true" class="nav-item nav-link bs-tab-link" data-toggle="tab"
                       fragment="pc" href="javascript:void(0)" id="pop2-tab" role="tab"
                       routerLink="/edit-brand-profile">Product Categories</a>
                    <a #link3 (click)="showTabContent(pop3,link3)" [ngClass]="{'active' : selectedTab === 'vb'}"
                       aria-controls="pop3" aria-selected="false" class="nav-item nav-link bs-tab-link"
                       data-toggle="tab" fragment="vb" href="javascript:void(0)" id="pop3-tab" role="tab"
                       routerLink="/edit-brand-profile">Vibes</a>
                    <a #link4 (click)="showTabContent(pop4,link4)" [ngClass]="{'active' : selectedTab === 'vl'}"
                       aria-controls="pop4" aria-selected="false" class="nav-item nav-link bs-tab-link"
                       data-toggle="tab" fragment="vl" href="javascript:void(0)" id="pop4-tab" role="tab"
                       routerLink="/edit-brand-profile">Values</a>
                    <a #link5 (click)="showTabContent(pop5,link5)" [ngClass]="{'active' : selectedTab === 'sc'}"
                       aria-controls="pop6" aria-selected="false" class="nav-item nav-link bs-tab-link"
                       data-toggle="tab" fragment="sc" href="javascript:void(0)" id="pop6-tab" role="tab"
                       routerLink="/edit-brand-profile">Social Causes</a>

                </div>
            </nav>
            <div class="productCategory">
                <div class="row" style="padding-left: 25px !important; padding-right: 25px !important;">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <ng-select (change)="loadMenu(selectedMenu)" [(ngModel)]="selectedMenu" [clearable]="false"
                                       [items]="navMenuBindingList"
                                       [searchable]="false">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-content" id="nav-tabContent">

                <!------------------------------------ TAB (Brand Description & Brand info) ------------------------------------>
                <div #pop1 [ngClass]="{'fade show active' : selectedTab === 'bd'}" aria-labelledby="pop1-tab"
                     class="tab-pane bs-tab-content" id="pop1" role="tabpanel">
                    <div class="create-account-tab tab-8">
                        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                              (ngSubmit)="updateBasicInfo()" [formGroup]="brandInfoForm">

                            <div class="row main-brand-form pb-0">
                                <div class="col-lg-12 pl-o pr-xl-5">
                                    <div class="logo-website">
                                        <div class="d-flex">
                                            <div (click)="imagePicker.click()"
                                                 [ngClass]="{noBorder: this.currentBrandInfo.logo_url}"
                                                 class="brand-logo" style="cursor: pointer">
                                                <input #imagePicker (change)="setProfilePic($event, 'imgPre')"
                                                       accept="image/*" hidden id="profilePic" type="file">
                                                <img *ngIf="!this.currentBrandInfo.logo_url" alt="brand-logo"
                                                     id="imgPre" is-invalid src='{{cdnUrl}}/assets/images/AddLogo.svg'>
                                                <img *ngIf="this.currentBrandInfo.logo_url" alt="brand-logo"
                                                     class="profilePic" id="imgPre"
                                                     src="{{this.currentBrandInfo.logo_url || (cdnUrl+'/assets/images/AddLogo.svg')}}">
                                            </div>
                                            <div class="d-flex flex-column website">
                                                <h2 style="white-space: nowrap">
                                                    {{currentBrandInfo.business_name}}
                                                </h2>
                                                <label class="show-on-desktop" for="website">Website</label>
                                                <input [(ngModel)]="currentBrandInfo.website"
                                                       [ngModelOptions]="{standalone: true}" class="form-control show-on-desktop"
                                                       type="url">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="margin-top: 53px">
                                        <div class="col-lg-12">
                                            <h4>Brand Description</h4>
                                            <p class="sub-head" style="margin-bottom: 0 !important;">
                                                Introduce your brand and share why you are here!
                                            </p>
                                            <p class="ca-label">
                                                Your bio will appear on your brand page in the Tailorie app
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-md-12">
                                            <div class="form-group" style="max-width: 440px">
                                                <textarea [ngClass]="{ '': (bif.description.dirty || brandInfoFormSubmitted) && bif.description.errors }"
                                                          class="form-control" formControlName="description"
                                                          id="branddescriptioncomment" maxlength="415"
                                                          onkeyup="countChar415(this)"
                                                          placeholder="Write here..."
                                                          rows="5"></textarea>
                                                <label id="charNum415" style="padding-top: 10px;">
                                                    {{bif.description.value.length}}/415 Characters
                                                </label>
                                                <div *ngIf="(bif.description.dirty || brandInfoFormSubmitted) && (bif.description.errors) "
                                                     class="invalid-feedback">
                                                    <div *ngIf="bif.description.errors.required">
                                                        Brand description is required
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- Tags -->
                                    <!--                                    <div class="row">-->
                                    <!--                                        <div class="col-md-10">-->
                                    <!--                                            <div class="form-group custom-tags" data-select2-id="45">-->
                                    <!--                                                <p for="name">What words are often used to describe your brand?<br>-->
                                    <!--                                                    <span class="text-muted">To add multiple tags, separate tags by-->
                                    <!--                                                        comma-->
                                    <!--                                                    </span>-->
                                    <!--                                                </p>-->
                                    <!--                                                <div class="input-group mycustom">-->
                                    <!--                                                    <input #descInp (input)="tagInputCounter(descInp, 'bdCounter')"-->
                                    <!--                                                        (keyup.enter)="addCustomDescriptionTags()"-->
                                    <!--                                                        aria-describedby="inputGroupPrepend2"-->
                                    <!--                                                        class="form-control rounded-0" id="descriptionTags"-->
                                    <!--                                                        maxlength="25"-->
                                    <!--                                                        placeholder="thoughtful, trendy, spunky, happy, etc." required-->
                                    <!--                                                        type="text">-->
                                    <!--                                                    <div class="input-group-prepend">-->
                                    <!--                                                        <input (click)="addCustomDescriptionTags()"-->
                                    <!--                                                            class="btn10 btn-sm rounded-0" id="description"-->
                                    <!--                                                            style="width: 60px;border:none; background-color: #006AA5; color:white;"-->
                                    <!--                                                            type="submit" value="Add">-->
                                    <!--                                                    </div>-->
                                    <!--                                                    <div class="tagCounter">-->
                                    <!--                                                        <label id="bdCounter">-->
                                    <!--                                                            {{25}}/25-->
                                    <!--                                                        </label>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                                <form class="form-inline">-->
                                    <!--                                                    <ng-container *ngFor="let brandTag of currentBrandInfo.tags">-->
                                    <!--                                                        <div *ngIf="brandTag" class="mb-3 mt-2">-->
                                    <!--                                                            <div class="tag">-->
                                    <!--                                                                <span>-->
                                    <!--                                                                    {{brandTag}}&nbsp;&nbsp;-->
                                    <!--                                                                    <a (click)="removeBrandDescriptionTags(brandTag)">-->
                                    <!--                                                                        &times;-->
                                    <!--                                                                    </a>-->
                                    <!--                                                                </span>-->
                                    <!--                                                            </div>-->
                                    <!--                                                        </div>-->
                                    <!--                                                    </ng-container>-->
                                    <!--                                                </form>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                </div>
                            </div>
                            <hr style="width: 100%; border-width: 2px; margin-top: 66px">
                            <div class="row main-brand-form brand-info">
                                <div class="col-md-6 col-lg-5">
                                    <h4 style="margin-bottom: 33px;">Brand Info</h4>
                                    <div style="max-width: 440px">
                                        <div class="form-group">
                                            <p class="ca-label">Business Name</p>
                                            <input (blur)="setUsername()"
                                                   [ngClass]="{ 'is-invalid': (bif.business_name.dirty || brandInfoFormSubmitted) && bif.business_name.errors }"
                                                   class="form-control" formControlName="business_name" required
                                                   type="text">
                                            <div *ngIf="(bif.business_name.dirty || brandInfoFormSubmitted) && (bif.business_name.errors) "
                                                 class="invalid-feedback">
                                                <div *ngIf="bif.business_name.errors.required">What’s the name of your
                                                    business?
                                                </div>
                                                <div *ngIf="bif.business_name.errors.minlength">Business Name should not
                                                    be less than 3 characters
                                                </div>
                                                <div *ngIf="bif.business_name.errors.notUniqueBrandName">Business Name
                                                    is not available.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <p class="ca-label">Username</p>
                                            <input [ngClass]="{ 'is-invalid': (bif.username.dirty || brandInfoFormSubmitted) && bif.username.errors }"
                                                   class="form-control" formControlName="username"
                                                   id="username" type="text">
                                            <div *ngIf="(bif.username.dirty || brandInfoFormSubmitted) && (bif.username.errors) "
                                                 class="invalid-feedback">
                                                <div *ngIf="bif.username.errors.required">Username is a required field.</div>
                                                <div *ngIf="bif.username.errors.minlength">Username should not be less than 3
                                                    characters
                                                </div>
                                                <div *ngIf="bif.username.errors.notUniqueUsername">Username is not available.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <p class="ca-label">Headquarter Location (City, State)</p>
                                            <input [ngClass]="{ 'is-invalid': (bif.headquarterLocation.dirty || brandInfoFormSubmitted) && bif.headquarterLocation.errors }"
                                                   class="form-control" formControlName="headquarterLocation"
                                                   id="headquarterLocation" type="text">
                                            <div *ngIf="(bif.headquarterLocation.dirty || brandInfoFormSubmitted) && (bif.headquarterLocation.errors) "
                                                 class="invalid-feedback">
                                                <div *ngIf="bif.headquarterLocation.errors.required">Where is your brand
                                                    located?
                                                </div>
                                                <div *ngIf="bif.headquarterLocation.errors.minlength">Headquarter
                                                    Location should not be less than 5 characters
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <p class="ca-label"> Year Founded</p>
                                                <ng-select (change)="revealSaveBar()" [clearable]="false"
                                                           [items]="listOfYears"
                                                           [ngClass]="{ 'is-invalid': (bif.year_founded.dirty || brandInfoFormSubmitted) && bif.year_founded.errors }"
                                                           formControlName="year_founded"
                                                           required>
                                                </ng-select>
                                                <div *ngIf="(bif.year_founded.dirty || brandInfoForm) && bif.year_founded.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="bif.year_founded.errors.required">
                                                        How long has your brand been around?
                                                    </div>
                                                    <div *ngIf="bif.year_founded.errors.pattern">
                                                        Only numbers allowed
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <p class="ca-label">Number of Locations</p>
                                                <input [ngClass]="{ 'is-invalid': (bif.numberOfLocations.dirty || brandInfoFormSubmitted) && bif.numberOfLocations.errors }"
                                                       appNumbersOnly class="form-control"
                                                       formControlName="numberOfLocations" id="numberOfLocations"
                                                       maxlength="4" type="text">
                                                <div *ngIf="(bif.numberOfLocations.dirty || brandInfoFormSubmitted) && bif.numberOfLocations.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="bif.numberOfLocations.errors.required">How many stores
                                                        do you have?
                                                    </div>
                                                    <div *ngIf="bif.numberOfLocations.errors.minlength">number Of
                                                        Locations should be correct
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <p class="ca-label" for="sales_channel">Primary Sales Channel</p>
                                                <select (change)="toggleSaveBar()"
                                                        [ngClass]="{ 'is-invalid': (bif.sales_channel.dirty || brandInfoFormSubmitted) && bif.sales_channel.errors }"
                                                        class="form-control" formControlName="sales_channel"
                                                        id="sales_channel" required style="height: 43px">
                                                    <option value=""></option>
                                                    <option value="direct_to_consumer">Direct to Consumer</option>
                                                    <option value="retailer">Retailer</option>
                                                    <option value="wholesaler">Wholesaler</option>
                                                    <option value="subscription_box">Subscription Box</option>
                                                </select>
                                                <div *ngIf="(bif.sales_channel.dirty || brandInfoFormSubmitted) && bif.sales_channel.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="bif.sales_channel.errors.required">Oops, please choose
                                                        one!
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12 show-on-mobile">
                                                <p class="ca-label">Website</p>
                                                <input [(ngModel)]="currentUser.user.branduserprofile.brand.website"
                                                       [ngClass]="{ 'is-invalid': (bif.website.dirty || brandInfoFormSubmitted) && bif.website.errors }"
                                                       [ngModelOptions]="{standalone: true}" class="form-control"
                                                       id="website" name="website"
                                                       type="url">
                                            </div>
                                            <div class="form-group col-12">
                                                <div class="form-group">
                                                    <label class="amountBusinessRange mb-2"
                                                           for="amountBusinessRange">Amount
                                                        of business
                                                        done in-store vs online</label>
                                                    <input (change)="toggleSaveBar()" class="bi-slider"
                                                           formControlName="amountBusinessRange"
                                                           id="amountBusinessRange" max="100" min="0" name="range"
                                                           type="range">

                                                    <label class="sliderrangelabel" for="amountBusinessRange"
                                                           style="float: left;">{{100 - bif.amountBusinessRange.value}}%
                                                        In-store</label>
                                                    <label class="sliderrangelabel" for="amountBusinessRange"
                                                           style="float: right;">{{bif.amountBusinessRange.value}}%
                                                        Online</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                                <div class="d-flex flex-nowrap align-items-center">
                                         <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                         </span>
                                    <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"
                                                 style="width: 19px;"> {{alertMessage}}
                                        </span> &nbsp;&nbsp;&nbsp;
                                    <button [class.disableBtn]="brandInfoForm.invalid" [disabled]="brandInfoForm.invalid" class="btn btnsave" type="submit">save</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <!------------------------------------ TAB (Product Categories) ------------------------------------>
                <div #pop2 [ngClass]="{'fade show active' : selectedTab === 'pc'}" aria-labelledby="pop2-tab"
                     class="tab-pane bs-tab-content" id="pop2" role="tabpanel">

                    <div class="create-account-tab tab-3">
                        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                              (ngSubmit)="submitBrandProductCategories();">

                            <div class="container-fluid" style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                                <div class="row main-brand-form product-categories justify-content-between">
                                    <!-- Left Section -->
                                    <div class="col-md-6 col-xl-7 left-section">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <h4>What types of products do you carry?</h4>
                                                <p class="sub-head">These are your brand's product categories.</p>
                                            </div>
                                        </div>
                                        <!-- CheckBox / Select Items -->
                                        <div class="row">
                                            <div class="col-md-12 col-lg-7">

                                                <div class="select-box-wrap">

                                                    <!-- CheckBox/Select Item -->
                                                    <ng-container *ngFor="let productCategory of allProductCategories">
                                                        <ng-container *ngIf="productCategory.is_predefined">
                                                            <div class="select-box">
                                                                <input [checked]="productCategory.selected" hidden
                                                                       id="pc-{{productCategory.id}}" type="checkbox"/>
                                                                <label (click)="toggleBrandCategory(productCategory)"
                                                                       for="pc-{{productCategory.id}}">
                                                                    <img alt="{{productCategory.title}}"
                                                                         src="{{productCategory.image}}">
                                                                    {{productCategory.title}}
                                                                </label>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>

                                                </div>

                                            </div>
                                        </div>
                                        <!-- Tags -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group custom-tags" data-select2-id="45">
                                                    <p for="name">Add Subcategories <br>
                                                        <span class="text-muted">
                                                            To add multiple tags, separate tags by comma
                                                        </span>
                                                    </p>
                                                    <div class="input-group mycustom">
                                                        <input #pcInp (keyup)="myText = pcInp.value"
                                                               (keyup)="onKeyUp($event)"
                                                               (keyup.enter)="addCustomProductCategory()"
                                                               class="form-control rounded-0"
                                                               id="ProductCategories" maxlength="50"
                                                               placeholder="technology, luxury, skin care" required
                                                               type="text">
                                                        <div class="input-group-prepend">
                                                            <input (click)="addCustomProductCategory()"
                                                                   id="ProductCategories1" type="button" value="Add">
                                                        </div>
                                                        <span class="counter">{{pcInp.value.length}}/50</span>
                                                    </div>

                                                    <form class="form-inline">
                                                        <div *ngFor="let productCategory of allProductCategories"
                                                             class="mb-3 mt-2">
                                                            <div *ngIf="!productCategory.is_predefined && productCategory.selected">
                                                                <div class="tag">
                                                                    <span>
                                                                        {{productCategory.title}}&nbsp;&nbsp;&nbsp;
                                                                        <a (click)="removeCustomProductCategory(productCategory.id)">
                                                                            &times;
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div class="question-module">
                                                        <div class="head-question">
                                                            <div class="img-section">
                                                                <div class="inner-image">
                                                                    <img alt=""
                                                                         src="{{cdnUrl}}/assets/images/question-mark.png">
                                                                </div>
                                                                <div class="arrow-sign">

                                                                </div>
                                                            </div>
                                                            <div class="question-main">
                                                                Do any of your products have a minimum age requirement?*
                                                            </div>
                                                        </div>
                                                        <div class="answer-section">
                                                            <mat-checkbox (change)="ageRequirementSelection(1)" [checked]="ageRequirementSelected === 1" class="example-margin">No,
                                                                suitable for all
                                                                ages
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="answer-section">
                                                            <mat-checkbox (change)="ageRequirementSelection(2)" [checked]="ageRequirementSelected === 2" class="example-margin">Yes,
                                                                must be
                                                            </mat-checkbox>
                                                            <div class="type">
                                                                <input #ageInput (click)="ageRequirementSelected = 2" [(ngModel)]="ageRequirement"
                                                                       [ngClass]="{ 'is-invalid': (ageRequirementSelected === 2 && ageInput.value === '')}"
                                                                       [ngModelOptions]="{standalone: true}"
                                                                       appNumbersOnly maxlength="2"
                                                                       placeholder="Age"
                                                                       type="text"><span>years old</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Right Section -->
                                    <div class="col-md-5 col-xxl-4 border-left right-section">
                                        <label class="allocation">Allocation Breakdown</label>
                                        <!-- DONUT CHART -->
                                        <div class="chart-wrapper">
                                            <canvas [chartType]="'doughnut'" [colors]="getProductCategoryChartsColors()"
                                                    [data]="getProductCategoryChartData()"
                                                    [labels]="getProductCategoryChartsLabels()"
                                                    [legend]="false"
                                                    [options]="+currentBrandInfo.getProductCategoryCount(allProductCategories) > 0 ? chartOptions : defaultChartOption" baseChart
                                                    height="100" width="100">
                                            </canvas>
                                            <div class="donut-center-text">
                                                <label>Categories</label>
                                                <div [class.text-danger]="total > 100" class="total-allocated">
                                                    {{sumOfProductCategoriesPercentages() }}% Allocated
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!pcLimitExceed">
                                            <div *ngIf="total <= 100" [class.text-danger1]="total < 100"
                                                 class="total-left1">
                                                {{100 - sumOfProductCategoriesPercentages()}}% left to allocate
                                            </div>
                                            <div *ngIf="total > 100" [class.text-danger]="total > 100" class="total-left">
                                                Cannot exceed 100% allocation
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="pcLimitExceed">
                                            <div class="total-left">
                                                Cannot exceed 100% allocation
                                            </div>
                                        </ng-container>
                                        <!-- CHARTS SLIDER -->
                                        <div class="sliders-wrapper">
                                            <ng-container *ngFor="let productCategory of allProductCategories">
                                                <div *ngIf="productCategory.is_predefined"
                                                     class="slider d-flex justify-content-center align-items-center">
                                                    <label class="productLabels">{{productCategory.title}}</label>
                                                    <div class="custom-chart-sliders">
                                                        <input (input)="onProductCategorySliderChange($event, productCategory)"
                                                               [ngClass]="!productCategory.selected? 'disable': '' "
                                                               [ngStyle]="{'backgroundColor' : productCategory.selected ? productCategory.color : '#E7EDF3',
                                                            'color': productCategory.selected ? '#1E2A40' : '#b8b8b8'}"
                                                               class="chart-slider" max="100" min="0" step="5"
                                                               type="range" value="{{productCategory.percentage}}">
                                                    </div>
                                                    <!--                                                    <label class="productPerLabels">{{productCategory.percentage |-->
                                                    <!--                                                        number : '1.0-0'}}-->
                                                    <!--                                                        %</label>-->
                                                    <label [ngStyle]="{ 'color': productCategory.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"
                                                           class="productPerLabels">
                                                        <input (keyup)="maxPcValueCheck($event, productCategory)" [(ngModel)]="productCategory.percentage"
                                                               [disabled]="!productCategory.selected" [ngModelOptions]="{standalone: true}"
                                                               appNumbersOnly max="100" maxlength="{{pcMaxLength}}"
                                                               style="background-color: #00000000; border: none; outline: none; width: 33px; text-align: right" type="text">%
                                                    </label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container-fluid">
                                <div class="row main-brand-form">
                                    <div class="col-md-7 pl-0">
                                        <h4 style="margin-top: 70px;">Category Details</h4>
                                        <p>Add your product types and price ranges.</p>
                                        <!-- Product Category Item -->
                                        <ng-container
                                                *ngFor="let productCategory of allProductCategories; let index = index;">
                                            <div *ngIf="productCategory.selected && productCategory.is_predefined"
                                                 class="row product-price no-gutters ">


                                                 <div class="col-7 col-md-2 col-xl-2 text-center mobile-show">
                                                    <img alt="{{productCategory.title}}" class="prod-img"
                                                         src="{{productCategory.image}}">
                                                </div>

                                                <div class="col-10 pad-adj-md-sc">
                                                    <div class="row no-gutters">
                                                        <div class="col-12 p-lg-0">
                                                            <h5 class="">{{productCategory.title}}</h5>
                                                            <span class="text-danger"
                                                                  id="pc-error-{{productCategory.id}}"></span>
                                                            <h6>Price Range</h6>
                                                        </div>
                                                    </div>
                                                    <!-- Product Price -->
                                                    <div class="row no-gutters">
                                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                                            <input (change)="toggleSaveBar()" appNumbersOnly
                                                                   autocomplete="off" class="form-control"
                                                                   id="min-price-{{productCategory.id}}" min="0"
                                                                   placeholder="$ MIN" type="number"
                                                                   value="{{productCategory.min_price}}">

                                                            <input (change)="toggleSaveBar()" appNumbersOnly
                                                                   autocomplete="off" class="form-control"
                                                                   id="max-price-{{productCategory.id}}" min="0"
                                                                   placeholder="$ MAX" type="number"
                                                                   value="{{productCategory.max_price}}">

                                                            <input (change)="toggleSaveBar()" appNumbersOnly
                                                                   autocomplete="off" class="form-control"
                                                                   id="avg-price-{{productCategory.id}}" min="0"
                                                                   placeholder="$ AVG" type="number"
                                                                   value="{{productCategory.avg_price}}">
                                                        </div>
                                                    </div>
                                                    <!-- Tags -->
                                                    <div class="row pr-2 no-gutters pr-md-0">
                                                        <div class="form-group custom-tags">
                                                            <span>
                                                                To add multiple tags, separate tags by comma
                                                            </span>
                                                            <div class="input-group mycustom">
                                                                <input #pcdInput
                                                                       (keyup)="onKeyUpCategory($event,productCategory.id)"
                                                                       (keyup.enter)="ProductDetailsTags(productCategory.id)"
                                                                       class="form-control rounded-0"
                                                                       id="productTag-{{productCategory.id}}"
                                                                       placeholder="{{productCategory.tags_placeholders.join(', ') }}"
                                                                       required type="text">
                                                                <div class="input-group-prepend">
                                                                    <input (click)="ProductDetailsTags(productCategory.id, pcdInput.value)"
                                                                           type="button" value="Add">
                                                                </div>
                                                            </div>
                                                            <form class="form-inline">
                                                                <ng-container *ngFor="let tag of productCategory.tags"
                                                                              class="mb-3 mt-2">
                                                                    <div class="tag mt-2">
                                                                        <span>
                                                                            {{tag}}&nbsp;&nbsp;&nbsp;
                                                                            <a
                                                                                    (click)="removeProductCategoryTags(productCategory.id, tag)">
                                                                                &times;
                                                                            </a>
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                                <div class="d-flex flex-nowrap align-items-center">
                                         <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                         </span>
                                    <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"
                                                 style="width: 19px;"> {{alertMessage}}
                                        </span> &nbsp;&nbsp;&nbsp;
                                    <button [class.disableBtn]="ageRequirementSelected < 1" class="btn btnsave" type="submit">save</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <!------------------------------------ TAB (Brand Vibes) ------------------------------------>
                <div #pop3 [ngClass]="{'fade show active' : selectedTab === 'vb'}" aria-labelledby="pop3-tab"
                     class="tab-pane bs-tab-content" id="pop3" role="tabpanel">
                    <div class="create-account-tab tab-5">
                        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                              (ngSubmit)="submitBrandVibes()">
                            <div class="container-fluid">
                                <div class="row main-brand-form product-categories justify-content-between">
                                    <!-- Left Section -->
                                    <div class="col-md-6 col-xl-7 left-section">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <h4>Your Brand’s Vibe</h4>
                                                <p class="sub-head">How would you describe your brand aesthetic?</p>
                                            </div>
                                        </div>
                                        <!-- CheckBox / Select Items -->
                                        <div class="row">
                                            <div class="col-md-12 col-lg-7">
                                                <div class="select-box-wrap">
                                                    <!-- CheckBox/Select Item -->
                                                    <ng-container *ngFor="let vibe of allVibes">
                                                        <ng-container *ngIf="vibe.is_predefined">
                                                            <div class="select-box">
                                                                <input [checked]="vibe.selected" hidden
                                                                       id="v-{{vibe.id}}" type="checkbox"/>
                                                                <label (click)="toggleBrandVibe(vibe)"
                                                                       for="v-{{vibe.id}}">
                                                                    <img alt="{{vibe.title}}" src="{{vibe.image}}">
                                                                    {{vibe.title}}
                                                                </label>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Tags -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group custom-tags" data-select2-id="45">
                                                    <p for="name">Add Additional Brand Vibes<br>
                                                        <span class="text-muted">
                                                            To add multiple tags, separate tags by comma
                                                        </span>
                                                    </p>
                                                    <div class="input-group mycustom">
                                                        <input #vibInp (keyup)="vibeTxt = vibInp.value"
                                                               (keyup)="onKeyUpVibe($event)"
                                                               (keyup.enter)="addCustomVibeTags()"
                                                               class="form-control rounded-0"
                                                               id="vibeTags" placeholder="colorful, loud, west coast" required
                                                               type="text">
                                                        <div class="input-group-prepend">
                                                            <input (click)="addCustomVibeTags()" id="vibe2"
                                                                   type="button" value="Add">
                                                        </div>
                                                    </div>
                                                    <form class="form-inline">
                                                        <ng-container *ngIf="allVibes">
                                                            <ng-container *ngFor="let vibe of allVibes">
                                                                <div *ngIf="!vibe.is_predefined && vibe.selected"
                                                                     class="mb-3 mt-2">
                                                                    <div class="tag">
                                                                    <span>
                                                                        {{vibe.title}}&nbsp;&nbsp;
                                                                        <a (click)="removeVibesTags(vibe.id)">
                                                                            &times;
                                                                        </a>
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Right Section -->
                                    <div class="col-md-5 col-xxl-4 border-left right-section">
                                        <label class="allocation">Allocation Breakdown</label>
                                        <!-- DONUT CHART -->
                                        <div class="chart-wrapper">
                                            <canvas [chartType]="'doughnut'" [colors]="getVibesChartsColors()"
                                                    [data]="getVibesChartData()" [labels]="getVibesChartsLabels()"
                                                    [legend]="false" [options]="+currentBrandInfo.getVibesCount(allVibes) > 0 ? chartOptions : defaultChartOption"
                                                    baseChart
                                                    height="100" width="100">
                                            </canvas>
                                            <div class="donut-center-text">
                                                <label>Vibes</label>
                                                <div [class.text-danger]="vibesTotalPercentage > 100"
                                                     class="total-allocated">
                                                    {{sumOfVibesPercentages()}}% Allocated
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!vibLimitExceed">
                                            <div *ngIf="vibesTotalPercentage <= 100"
                                                 [class.text-danger1]="vibesTotalPercentage < 100" class="total-left1">
                                                {{100 - sumOfVibesPercentages()}}% left to allocate
                                            </div>
                                            <div *ngIf="vibesTotalPercentage > 100"
                                                 [class.text-danger]="vibesTotalPercentage > 100" class="total-left">
                                                Cannot exceed 100% allocation
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="vibLimitExceed">
                                            <div class="total-left">
                                                Cannot exceed 100% allocation
                                            </div>
                                        </ng-container>
                                        <!-- CHARTS SLIDER -->
                                        <div class="sliders-wrapper">
                                            <ng-container *ngFor="let vibe of allVibes">
                                                <div *ngIf="vibe.is_predefined"
                                                     class="slider d-flex justify-content-center align-items-center">
                                                    <label class="productLabels">{{vibe.title}}</label>
                                                    <div class="custom-chart-sliders">
                                                        <input (input)="onVibesSliderChange($event, vibe)"
                                                               [ngClass]="!vibe.selected? 'disable': '' " [ngStyle]="{'backgroundColor' : vibe.selected ? vibe.color : '#E7EDF3',
                                                            'color': vibe.selected ? '#1E2A40' : '#b8b8b8'}"
                                                               class="chart-slider" max="100" min="0" step="5"
                                                               type="range" value="{{vibe.percentage}}">
                                                    </div>
                                                    <!--                                                    <label class="productPerLabels">{{vibe.percentage | number :-->
                                                    <!--                                                        '1.0-0'}}-->
                                                    <!--                                                        %</label>-->

                                                    <label [ngStyle]="{ 'color': vibe.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"
                                                           class="productPerLabels">
                                                        <input (keyup)="maxVbValueCheck($event, vibe)" [(ngModel)]="vibe.percentage"
                                                               [disabled]="!vibe.selected" [ngModelOptions]="{standalone: true}"
                                                               appNumbersOnly max="100" maxlength="{{vbMaxLength}}"
                                                               style="background-color: #00000000; border: none; outline: none; width: 33px; text-align: right" type="text">%
                                                    </label>

                                                    <!--                                                    <input min="0" max="100" #inp2 class="customSliderInput" type="text"-->
                                                    <!--                                                           pattern=""-->
                                                    <!--                                                           maxlength="3" [disabled]="!vibe.selected"-->
                                                    <!--                                                           [value]="vibe.percentage" placeholder="0" appNumbersOnly-->
                                                    <!--                                                           [ngStyle]="{'borderColor':  (inp2.value > 100) ? '#e17400' : '#fffaf7' }"-->
                                                    <!--                                                           (keyup)="onVibesSliderChange($event.target.value, vibe)">%-->
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                                <div class="d-flex flex-nowrap align-items-center">
                                         <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                         </span>
                                    <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"
                                                 style="width: 19px;"> {{alertMessage}}
                                        </span> &nbsp;&nbsp;&nbsp;
                                    <button class="btn btnsave" type="submit">save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!------------------------------------ TAB (Brand Values) ------------------------------------>
                <div #pop4 [ngClass]="{'fade show active' : selectedTab === 'vl'}" aria-labelledby="pop4-tab"
                     class="tab-pane bs-tab-content" id="pop4" role="tabpanel">
                    <div class="create-account-tab tab-6">
                        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                              (ngSubmit)="submitBrandValues()">
                            <div class="container-fluid">
                                <div class="row main-brand-form product-categories justify-content-between">
                                    <!-- Left Section -->
                                    <div class="col-md-6 col-xl-7 left-section">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <h4>What do your customers value most?</h4>
                                                <p class="sub-head">This what your brand is known for.</p>
                                            </div>
                                        </div>
                                        <!-- CheckBox / Select Items -->
                                        <div class="row">
                                            <div class="col-md-12 col-lg-7">
                                                <div class="select-box-wrap">
                                                    <!-- CheckBox/Select Item -->
                                                    <ng-container *ngIf="allValues">
                                                        <ng-container *ngFor="let value of allValues">
                                                            <ng-container *ngIf="value.is_predefined">
                                                                <div class="select-box">
                                                                    <input [checked]="value.selected" hidden
                                                                           id="v-{{value.id}}" type="checkbox"/>
                                                                    <label (click)="toggleBrandValue(value)"
                                                                           for="v-{{value.id}}">
                                                                        <img alt="{{value.title}}" src="{{value.image}}">
                                                                        {{value.title}}
                                                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Tags -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group custom-tags" data-select2-id="45">
                                                    <p for="name">Add Additional Brand Values<br>
                                                        <span class="text-muted">
                                                            To add multiple tags, separate tags by comma
                                                        </span>
                                                    </p>
                                                    <div class="input-group mycustom">
                                                        <input #valInp (keyup)="valueTxt = valInp.value"
                                                               (keyup)="onKeyUpValue($event)"
                                                               (keyup.enter)="addCustomValueTags()"
                                                               class="form-control rounded-0" id="valueTags"
                                                               placeholder="female founded, family friendly, transparent"
                                                               required type="text">
                                                        <div class="input-group-prepend">
                                                            <input (click)="addCustomValueTags()" id="value2"
                                                                   type="button" value="Add">
                                                        </div>
                                                    </div>
                                                    <form class="form-inline">
                                                        <ng-container *ngFor="let value of allValues">
                                                            <div *ngIf="!value.is_predefined && value.selected"
                                                                 class="mb-3 mt-2">
                                                                <div class="tag">
                                                                    <span>
                                                                        {{value.title}}&nbsp;&nbsp;
                                                                        <a (click)="removeValuesTags(value.id)">
                                                                            &times;
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Right Section -->
                                    <div class="col-md-5 col-xxl-4 border-left right-section">
                                        <label class="allocation">Allocation Breakdown</label>
                                        <!-- DONUT CHART -->
                                        <div class="chart-wrapper">
                                            <canvas [chartType]="'doughnut'" [colors]="getBrandValuesChartsColors()"
                                                    [data]="getBrandValuesChartData()"
                                                    [labels]="getBrandValuesChartsLabels()" [legend]="false"
                                                    [options]="+currentBrandInfo.getValuesCount(allValues) > 0 ? chartOptions : defaultChartOption" baseChart
                                                    height="100" width="100">
                                            </canvas>
                                            <div class="donut-center-text">
                                                <label>Values</label>
                                                <div [class.text-danger]="totalBrandValuesPer > 100"
                                                     class="total-allocated">
                                                    {{sumOfBrandValuesPercentages()}}% Allocated
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!valLimitExceed">
                                            <div *ngIf="totalBrandValuesPer <= 100"
                                                 [class.text-danger1]="totalBrandValuesPer < 100" class="total-left1">
                                                {{100 - sumOfBrandValuesPercentages()}}% left to allocate
                                            </div>
                                            <div *ngIf="totalBrandValuesPer > 100"
                                                 [class.text-danger]="totalBrandValuesPer > 100" class="total-left">
                                                Cannot exceed 100% allocation
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="valLimitExceed">
                                            <div class="total-left">
                                                Cannot exceed 100% allocation
                                            </div>
                                        </ng-container>
                                        <!-- CHARTS SLIDER -->
                                        <div class="sliders-wrapper">
                                            <ng-container *ngFor="let brandValue of allValues">
                                                <div *ngIf="brandValue.is_predefined"
                                                     class="slider d-flex justify-content-center align-items-center">
                                                    <label class="productLabels">{{brandValue.title}}</label>
                                                    <div class="custom-chart-sliders">
                                                        <input (input)="onBrandValuesSliderChange($event, brandValue)"
                                                               [ngClass]="!brandValue.selected? 'disable': '' "
                                                               [ngStyle]="{'backgroundColor' : brandValue.selected ? brandValue.color : '#E7EDF3',
                                                            'color': brandValue.selected ? '#1E2A40' : '#b8b8b8'}"
                                                               class="chart-slider" max="100" min="0" step="5"
                                                               type="range" value="{{brandValue.percentage}}">

                                                    </div>

                                                    <label [ngStyle]="{ 'color': brandValue.selected ? '#1E2A40' : 'rgba(30,42,64,0.5)'}"
                                                           class="productPerLabels">
                                                        <input (keyup)="maxVlValueCheck($event, brandValue)" [(ngModel)]="brandValue.percentage"
                                                               [disabled]="!brandValue.selected" [ngModelOptions]="{standalone: true}"
                                                               appNumbersOnly max="100" maxlength="{{vbMaxLength}}"
                                                               style="background-color: #00000000; border: none; outline: none; width: 33px; text-align: right" type="text">%
                                                    </label>

                                                    <!--                                                    <label class="productPerLabels">{{brandValue.percentage | number :-->
                                                    <!--                                                        '1.0-0'}}-->
                                                    <!--                                                        %</label>-->

                                                    <!--                                                    <input min="0" max="100" #inp3 class="customSliderInput" type="text"-->
                                                    <!--                                                           pattern=""-->
                                                    <!--                                                           maxlength="3" [disabled]="!brandValue.selected"-->
                                                    <!--                                                           [value]="brandValue.percentage" placeholder="0"-->
                                                    <!--                                                           appNumbersOnly-->
                                                    <!--                                                           [ngStyle]="{'borderColor':  (inp3.value > 100) ? '#e17400' : '#fffaf7' }"-->
                                                    <!--                                                           (keyup)="onBrandValuesSliderChange($event.target.value, brandValue)">%-->
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                                <div class="d-flex flex-nowrap align-items-center">
                                         <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                         </span>
                                    <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"
                                                 style="width: 19px;"> {{alertMessage}}
                                        </span> &nbsp;&nbsp;&nbsp;
                                    <button class="btn btnsave" type="submit">save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!------------------------------------ TAB (Social Cause) ------------------------------------>
                <div #pop5 [ngClass]="{'fade show active' : selectedTab === 'sc'}" aria-labelledby="pop6-tab"
                     class="tab-pane bs-tab-content" id="pop6" role="tabpanel">
                    <div class="create-account-tab tab-7">
                        <form (keydown.enter)="$event.preventDefault()" (keydown.shift.enter)="$event.preventDefault()"
                              (ngSubmit)="submitBrandSocialCauses()">
                            <div class="container">
                                <div class="row main-brand-form">
                                    <div class="col-md-8 pl-0">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <h4>Does your brand giveback?</h4>
                                                <p class="sub-head">This is your brands interest in giving back and
                                                    social causes.</p>
                                            </div>
                                        </div>
                                        <!-- Social Causes Checkboxes -->
                                        <div class="row no-gutters mt-5">
                                            <!-- SINGLE CHECK BOX ITEM -->
                                            <ng-container *ngFor="let socialCause of allSocialCauses">
                                                <div *ngIf="socialCause.is_predefined && !socialCause.is_other"
                                                     class="col-6 col-md-4 p-0 mb-4">
                                                    <label class="social-chkbox">{{socialCause.title}}
                                                        <input (change)="toggleBrandSocialCause(socialCause)"
                                                               [checked]="socialCause.selected" type="checkbox">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </ng-container>
                                            <!-- Other Social Cause Input -->
                                            <ng-container *ngFor="let socialCause of allSocialCauses">
                                                <div *ngIf="!socialCause.is_predefined && socialCause.is_other && socialCause.selected"
                                                     class="col-6 col-md-4 p-0 mb-4">
                                                    <label class="social-chkbox">{{socialCause.title}}
                                                        <input (change)="toggleBrandSocialCause(socialCause)"
                                                               [checked]="socialCause.selected" type="checkbox">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </ng-container>
                                            <div *ngIf="!isOtherSocialCauseShown" class="col-6 col-md-4 p-0 mb-4">
                                                <label class="social-chkbox">Other
                                                    <input (click)="toggleOtherSocialCauseShow()" type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div *ngIf="isOtherSocialCauseShown" class="col-6 col-md-4 p-0 mb-4">
                                                <input (focusout)="addCustomSocialOthers($event)" class="form-control"
                                                       id="socialCauseother" placeholder="Enter Custom Social Cause"
                                                       type="text">
                                            </div>

                                        </div>
                                        <!-- Tags -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group custom-tags" data-select2-id="45">
                                                    <p for="name">Do you support any specific organizations?<br>
                                                        <span class="text-muted">
                                                            To add multiple tags, separate tags by comma
                                                        </span>
                                                    </p>
                                                    <div class="input-group mycustom">
                                                        <input #soInp (keyup)="scsTxt = soInp.value"
                                                               (keyup)="onKeyUpSocial($event)"
                                                               (keyup.enter)="addCustomSocialTags()"
                                                               class="form-control rounded-0" id="socialTags"
                                                               placeholder="St. Judes, Doctors without Borders, Everytown"
                                                               required type="text">
                                                        <div class="input-group-prepend">
                                                            <input (click)="addCustomSocialTags()" id="socail2"
                                                                   type="button" value="Add">
                                                        </div>
                                                    </div>
                                                    <form class="form-inline">
                                                        <ng-container *ngFor="let socialCause of allSocialCauses">
                                                            <div *ngIf="!socialCause.is_predefined && !socialCause.is_other && socialCause.selected"
                                                                 class="mb-3 mt-2">
                                                                <div class="tag">
                                                                    <span>
                                                                        {{socialCause.title}}&nbsp;&nbsp;
                                                                        <a
                                                                                (click)="removeSocialCausesTags(socialCause.id)">
                                                                            &times;
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                                <div class="d-flex flex-nowrap align-items-center">
                                         <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                            <img alt="error" src="assets/images/ErrorAlert.png"> {{alertMessage}}
                                         </span>
                                    <span *ngIf="showAlertMessage" class="alertMessage">
                                            <img alt="success" src="assets/images/checkMark.png"
                                                 style="width: 19px;"> {{alertMessage}}
                                        </span> &nbsp;&nbsp;&nbsp;
                                    <button class="btn btnsave" type="submit">save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
