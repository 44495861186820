import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '../../shared/services/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                // window.location.href = '/login';
            }

            let errorMessage = '';
            let errorMessageObject = {};
            if (err.error instanceof ErrorEvent) {
                // Get client-side error
                errorMessage = err.error.message;
                errorMessageObject = {
                    message: err.error.message
                };
            } else {
                // Get server-side error
                errorMessage = `Error Code: ${err.status}\nMessage: ${err.error.message}`;
                errorMessageObject = {
                    status: err.status,
                    error: err.error
                };
            }
            return throwError(errorMessageObject);
        }));
    }
}
