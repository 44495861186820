import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {Brand, BrandBillingAddress, BrandContactDetails, BrandSignupChecklist} from './brand';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class BrandService extends ApiService {


    constructor(private http: HttpClient) {
        super();
    }

    // POST
    CreateBrand(data): Observable<Brand> {
        return this.http.post<Brand>(`${this.apiUrl}/brands/`, JSON.stringify(data), this.httpOptions).pipe();
    }

    // New Register Brand API
    RegisterBrand(data): Observable<Brand> {
        return this.http
            .post<Brand>(`${this.apiUrl}/brands/register/`, JSON.stringify(data), this.httpOptions)
            .pipe();
    }

    UpdateBrandById(id, brandData): Observable<Brand> {
        return this.http
            .patch<Brand>(`${this.apiUrl}/brands/${id}/`, JSON.stringify(brandData), this.httpOptions)
            .pipe();
    }

    UpdateBrandLogo(brandId: number, formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/brands/${brandId}/logo/`, formData);
    }

    // GET
    GetBrandByName(name): Observable<Brand> {
        return this.http
            .get<Brand>(`${this.apiUrl}/brands/business_name/${name}/`)
            .pipe
            // retryWhen(e => e.pipe(scan((errorCount, error) => {
            //         if (errorCount >= 2) {
            //             throw error;
            //         }
            //         return errorCount + 1;
            //     }, 0),
            //     delay(1000)
            // )),
            ();
    }

    // Brand User Name Suggestion
    GetSuggestedUsername(busniessName: string): Observable<any> {
        return this.http
            .post<any>(
                `${this.apiUrl}/brands/suggest-brand-username/`,
                {
                    action: 'suggest',
                    text: busniessName,
                },
                this.httpOptions
            )
            .pipe();
    }

    checkBrandUsernameAvailability(userName: string): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/brands/suggest-brand-username/`, JSON.stringify({
            action: 'check_availability',
            text: userName
        }), this.httpOptions).pipe();
    }

    // Get Brand by Username
    GetBrandByUsername(userName: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/brands/username/${userName}/`).pipe();
    }

    GetBrandById(id): Observable<Brand> {
        return this.http.get<Brand>(`${this.apiUrl}/brands/${id}/`).pipe();
    }

    GetBrandSignupChecklist(brandId): Observable<BrandSignupChecklist> {
        return this.http.get<BrandSignupChecklist>(`${this.apiUrl}/brands_signup_checklist/${brandId}/`).pipe();
    }

    GetBrandSignupChecklistResolve(brandId): Observable<BrandSignupChecklist> {
        return this.http.get<BrandSignupChecklist>(`${this.apiUrl}/brands_signup_checklist/${brandId}/`);
    }

    // Dashboard Account Info

    GetBrandContactDetails(brandId): Observable<BrandContactDetails> {
        return this.http.get<BrandContactDetails>(`${this.apiUrl}/brand/${brandId}/contact/`).pipe();
    }

    PostBrandContactDetails(brandId, data): Observable<BrandContactDetails> {
        return this.http.post<BrandContactDetails>(`${this.apiUrl}/brand/${brandId}/contact/`, JSON.stringify(data), this.httpOptions);
    }

    GetBrandBillingAddress(brandId): Observable<BrandBillingAddress> {
        return this.http.get<BrandBillingAddress>(`${this.apiUrl}/brand/${brandId}/billing/`).pipe();
    }

    PostBrandBillingAddress(brandId, data): Observable<BrandBillingAddress> {
        return this.http.post<BrandBillingAddress>(`${this.apiUrl}/brand/${brandId}/billing/`, JSON.stringify(data), this.httpOptions);
    }


}
