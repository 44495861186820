import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Root/Home Components
import {TailorieComponent} from './tailorie/tailorie.component';
import {CompanyPageComponent} from './company-page/company-page.component';
import {BrandAccountComponent} from './brand-account/brand-account.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './_helpers/guards/auth.guard';
import {LogoutComponent} from './logout/logout.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {Error404PageComponent} from './error404-page/error404-page.component';
import {UserSignupComponent} from './user-signup/user-signup.component';

// Dashboard Components
import {DashLayoutComponent} from './dash-layout/dash-layout.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PerformanceComponent} from './performance/performance.component';
import {BrandProfileComponent} from './brand-profile/brand-profile.component';
import {EditBrandProfileComponent} from './edit-brand-profile/edit-brand-profile.component';
import {CommunityProfileComponent} from './community-profile/community-profile.component';
import {EditCommunityProfileComponent} from './edit-community-profile/edit-community-profile.component';
import {BrandJourneyComponent} from './brand-journey/brand-journey.component';
import {CanvasCrateComponent} from './canvas-crate/canvas-crate.component';
import {AccountInfoComponent} from './account-info/account-info.component';
import {OverviewComponent} from './community-profile/overview/overview.component';
import {ProductcategoryComponent} from './community-profile/productcategory/productcategory.component';
import {VibeComponent} from './community-profile/vibe/vibe.component';
import {ValueComponent} from './community-profile/value/value.component';
import {SocialcauseComponent} from './community-profile/socialcause/socialcause.component';
import {InterestsComponent} from './community-profile/interests/interests.component';
import {LoggedInGuard} from './shared/helpers/LoggedIn.guard';

const routes: Routes = [
    // User Signup Email Verification Route
    {path: 'signup', component: UserSignupComponent},

    // Logout Component
    {path: 'logout', component: LogoutComponent},

    // tailorie ui routes goes here
    {
        path: '',
        children: [
            {path: '', component: CompanyPageComponent, pathMatch: 'full', canActivate: [LoggedInGuard]},
            {path: 'brandaccount', component: BrandAccountComponent},
            {path: 'test/:id', component: BrandAccountComponent},
        ],
    },
    {path: 'brandsHome', component: TailorieComponent},
    {path: 'canvas-crate-shopify', component: CanvasCrateComponent},

    // Dashboard routes goes here here
    {
        path: '',
        component: DashLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'brand',
        },
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {path: 'performance', component: PerformanceComponent},
            {path: 'brand-profile', component: BrandProfileComponent},
            {path: 'edit-brand-profile', component: EditBrandProfileComponent},
            {
                path: 'community-profile', component: CommunityProfileComponent,
                children: [
                    {path: '', redirectTo: 'overview', pathMatch: 'full'},
                    {path: 'overview', component: OverviewComponent},
                    {path: 'product-category', component: ProductcategoryComponent},
                    {path: 'vibe', component: VibeComponent},
                    {path: 'value', component: ValueComponent},
                    {path: 'interest', component: InterestsComponent},
                    {path: 'social-cause', component: SocialcauseComponent}
                ]
            },
            {path: 'edit-community-profile', component: EditCommunityProfileComponent},
            {path: 'brand-journey', component: BrandJourneyComponent},
            {path: 'canvas-crate', component: CanvasCrateComponent},
            {path: 'account-info', component: AccountInfoComponent},
        ],
    },
    // otherwise redirect to home
    // no layout routes
    {path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'contact-us', component: ContactUsComponent},
    {path: 'company-page', component: CompanyPageComponent, canActivate: [LoggedInGuard]},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-and-condition', component: TermsAndConditionsComponent},
    {path: 'Error-404', component: Error404PageComponent},

    // otherwise redirect to home
    {
        path: '**',
        pathMatch: 'full',
        component: Error404PageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
