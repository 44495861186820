export class BrandProfile {
    followerMatch?: number;
    followerMatchprev?: number;
    profileViewerMatch?: number;
    profileViewerMatchprev?: number;
    clickThroughMatch?: number;
    clickThroughMatchprev?: number;
    recommendations?: number;
    recommendationsprev?: number;
    breakdown?: Array<any>;
}
export class NavMenuModel {
    private navMenu = [
        {label: 'Product Categories', id: 'pop1', fragment: 'pc'},
        {label: 'Vibes', id: 'pop2', fragment: 'vib'},
        {label: 'Values', id: 'pop3', fragment: 'val'},
        {label: 'Social Causes', id: 'pop4', fragment: 'sc'},
    ];

    getNavMenu() {
        return this.navMenu.slice();
    }
}
export class NavEditMenuModel {
    private navEditMenu = [
        {label: 'Basics', id: 'pop1', fragment: 'bd'},
        {label: 'Product Categories', id: 'pop2', fragment: 'pc'},
        {label: 'Vibes', id: 'pop3', fragment: 'vib'},
        {label: 'Values', id: 'pop4', fragment: 'val'},
        {label: 'Social Causes', id: 'pop6', fragment: 'sc'},
    ];

    getNavEditMenu() {
        return this.navEditMenu.slice();
    }
}
export class NavEditcommunityModel {
    private navEditMenu = [
        {label: 'Overview', id: 'pop1', fragment: 'bd'},
    ];

    getNavEditMenu() {
        return this.navEditMenu.slice();
    }
}