import {Directive, ElementRef, HostBinding, HostListener} from '@angular/core';

@Directive({
    selector: '[appDropdown]'
})
export class DropdownDirective {

    constructor(private el: ElementRef) {
    }

    // Binding click to Add show class
    @HostListener('click') onclick() {

        const parent = this.el.nativeElement; // parent element
        parent.classList.toggle('show');
        const child = [].filter.call(this.el.nativeElement.children, (ele) => {
            return ele.classList.contains('dropdown-menu');
        }); // Identify the child element on dropdown clicked- dropdown menu

        if (child.length) {
            child[0].classList.toggle('show');
        }
    }

    // Close all dropdowns when user clicks anywhere else on document
    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    handleOutsideClick(event) {
        if (!this.el.nativeElement.contains(event.target)) {
            const parent = this.el.nativeElement; // parent element
            parent.classList.remove('show');
            const child = [].filter.call(this.el.nativeElement.children, (ele) => {
                return ele.classList.contains('dropdown-menu');
            }); // Identify the child element on dropdown clicked- dropdown menu

            if (child.length) {
                child[0].classList.remove('show');
            }
        }
    }

    //
    // @HostBinding('class.show') isOpen = false;
    //
    // @HostListener('click') toggleOpen() {
    //     this.isOpen = !this.isOpen;
    //     console.log('open');
    // }
    //
    // @HostListener('mouseleave') closeDropdown() {
    //     this.isOpen = false;
    //     console.log('close');
    // }


}
