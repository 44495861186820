import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OverviewService extends ApiService {
  selected = {
    startDate: null,
    endDate: null,
};
currentFilter: String = 'WEEK';
  constructor(private http: HttpClient) {
    super();
  }
  FetchTopFollowerData(id,startDate, endDate){
    return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/overview_top_follower_category/?date_from=${startDate}&date_to=${endDate}`)
    .pipe();
}

FetchImportantData(id,filter,startDate, endDate){
    return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/brand_community_important_view/?filter_interval=${filter}&date_from=${startDate}&date_to=${endDate}`)
    .pipe();
}
FetchTopRecommenderData(id,startDate, endDate){
  return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/overview_top_recommendation_category/?date_from=${startDate}&date_to=${endDate}`)
  .pipe();
}
FetchTopViewerData(id,startDate, endDate){
  return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/overview_top_viewer_Category/?date_from=${startDate}&date_to=${endDate}`)
  .pipe();
}
}
