<!-- Footer -->
<footer>
    <div class="footer-wrap">
        <div class="foobar">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-3">
                        <div class="brand-icon">
                            <a class="brand-name" href="https://tailorie.com/brands-home">
                                <img alt="logo-white" src="https://webcdn.tailorie.com/assets/images/Tailorie-logo_white.svg">
                                <span *ngIf="false">Brands</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row justify-content-end mt-3 mt-lg-0">
                            <div class="col-md-3 mt-5 mt-lg-0 d-flex flex-column">
                                <h5>
                                    resources
                                </h5>
                                <a href="https://tailorie.com/press/">Press</a>
                            </div>
                            <div class="col-md-3 d-flex flex-column mt-5 mt-lg-0">
                                <h5>
                                    company
                                </h5>
                                <a href="https://tailorie.com/contact-us/">Contact Us</a>
                                <a href="https://tailorie.com/terms-conditions/">Terms & Conditions</a>
                                <a href="https://tailorie.com/privacy-policy/">Privacy Policy</a>
                            </div>
                            <div class="col-md-3 d-flex flex-column mt-5 mt-lg-0">
                                <h5>
                                    dashboard
                                </h5>
                                <a *ngIf="!currentUser" href="https://tailorie.com/brands-home/">Join Now</a>
                                <!-- TODO: replace redirection here to dashboard | later-->
                                <a *ngIf="currentUser" routerLink="/brand-journey">Dashboard</a>
                                <a *ngIf="!currentUser" routerLink="/login">login</a>
                                <a *ngIf="currentUser" routerLink="/logout">logout</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-md-3 pr-lg-5">
                        <div class="link-wrap">
                            <div class="social-icons">
                                <a href="https://www.facebook.com/thetailorie" target="_blank">
                                    <img alt="facebook" src="{{cdnUrl}}/assets/images/icons/facebook.png">
                                </a>
                                <a href="https://www.instagram.com/tailorie/" target="_blank">
                                    <img alt="instagram" src="{{cdnUrl}}/assets/images/icons/instagram.png">
                                </a>
                                <a href="https://twitter.com/TailorieApp" target="_blank">
                                    <img alt="twitter" src="{{cdnUrl}}/assets/images/icons/twitter.png">
                                </a>
                            </div>
                        </div>
                        <p class="copyrights">
                            Version {{ version }}
                            <br> ©2021 All rights reserved.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>

<!-- OLD FOOTER -->
<!--<footer>-->
<!--    <div class="footer-wrap">-->
<!--        <div class="container py-5">-->
<!--            <div class="row justify-content-between">-->
<!--                <div class="col-lg-2">-->
<!--                    <a class="navbar-brand" href="">-->
<!--                        tailorie-->
<!--                    </a>-->
<!--                </div>-->
<!--                <div class="col-lg-5 offset-lg-right-1 pt-3">-->
<!--                    <h5>-->
<!--                        Join the growing community of hundreds of brands connecting with users like never before.-->
<!--                    </h5>-->
<!--                    <div class="link-wrap">-->
<!--                        <a class="d-block text-center d-md-inline-block mobapp-btn" href="#">-->
<!--                            <img class="img-fluid" src="assets/images/icons/getfromappstoreCS.png"-->
<!--                                 style="width: 180px;">-->
<!--                        </a>-->
<!--                        <a class="d-block text-center d-md-inline-block ml-md-2 mobapp-btn" href="#">-->
<!--                            <img class="img-fluid" src="assets/images/icons/getitifrom-PlayStoreCS.png"-->
<!--                                 style="width: 180px;">-->
<!--                        </a>-->
<!--                        <div class="social-icons">-->
<!--                            <a href="https://www.facebook.com/thetailorie" target="_blank">-->
<!--                                <img alt="facbook" src="assets/images/icons/facebook.png">-->
<!--                            </a>-->
<!--                            <a href="https://www.instagram.com/tailorie/" target="_blank">-->
<!--                                <img alt="instgram" src="assets/images/icons/instagram.png">-->
<!--                            </a>-->
<!--                            <a href="https://twitter.com/TailorieApp" target="_blank">-->
<!--                                <img alt="twitter" src="assets/images/icons/twitter.png">-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="foobar">-->
<!--            <div class="container">-->
<!--                <div class="row justify-content-end">-->
<!--                    <div class="col-lg-8">-->
<!--                        <div class="row">-->
<!--                            <div class="col-lg-4 d-flex flex-column">-->
<!--                                <h5>-->
<!--                                    resources-->
<!--                                </h5>-->
<!--                                <a href="#">Blog</a>-->
<!--                                <a href="#">Press</a>-->
<!--                                <a href="#">Press Kit</a>-->
<!--                            </div>-->
<!--                            <div class="col-lg-4 d-flex flex-column mt-4 mt-lg-0">-->
<!--                                <h5>-->
<!--                                    company-->
<!--                                </h5>-->
<!--                                <a routerLink="/contact-us">Contact Us</a>-->
<!--                                <a routerLink="/terms-and-condition">Terms & Conditions</a>-->
<!--                                <a routerLink="/privacyPolicy">Privacy Policy</a>-->
<!--                            </div>-->
<!--                            <div class="col-lg-4 d-flex flex-column mt-4 mt-lg-0">-->
<!--                                <h5>-->
<!--                                    Brand Portal-->
<!--                                </h5>-->
<!--                                <a (click)="setTitle( 'Join Now | Tailorie' )" routerLink="/brandsHome">Join Now</a>-->
<!--                                <a routerLink="/login">Login</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <p class="copyrights">-->
<!--                    &copy; 2020 Tailorie-->
<!--                </p>-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->
<!--</footer>-->
