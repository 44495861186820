<!-- navbar -->

<nav class="navbar navbar-expand-md navbar-light bg-white fixed-top"
     style="height: 65px;border-bottom: 1px solid rgba(0,0,0,0.1);">
    <!--    <button type="button" class="navbar-toggler bg-light" data-toggle="collapse" data-target="#nav">-->
    <!--        <span class="navbar-toggler-icon"></span>-->
    <!--    </button>-->
    <div class="navbar-collapse justify-content-between" id="nav">
        <a (click)="toggleSideMenu()" class="hamburgerButton" href="javascript:void(0)">
            <!--            <i class="fa fa-bars" id="favIcon"> </i>-->
            <img alt="=" loading="lazy" src="{{cdnUrl}}/assets/images/icons/hamburger.svg" style="width: 20px">
        </a>
        <ul class="navbar-nav">

            <!--            <li class="nav-item" >-->

            <!--                <a class="nav-link text-light font-weight-bold px-3" href="#">HOME</a>-->

            <!--            </li>-->

            <!--            <li class="nav-item dropdown" data-toggle="dropdown">-->

            <!--                <a class="nav-link text-light font-weight-bold px-3 dropdown-toggle" href="#">CATEGORIES</a>-->

            <!--                <div class="dropdown-menu">-->

            <!--                    <a class="dropdown-item" href="#">Fast Food</a>-->

            <!--                    <a class="dropdown-item" href="#">Lunch</a>-->

            <!--                    <a class="dropdown-item" href="#">Dessert</a>-->

            <!--                </div>-->

            <!--            </li>-->

            <!--            <li class="nav-item">-->

            <!--                <a class="nav-link text-light font-weight-bold px-3" href="#">ABOUT US</a>-->

            <!--            </li>-->

            <!--            <li class="nav-item">-->

            <!--                <a class="nav-link text-light font-weight-bold px-3" href="#">CONTACT</a>-->

            <!--            </li>-->

        </ul>
        <!-- navbar bar -->

        <form class="form-inline ml-3 vl">
            <label class="hoverable d-flex align-items-center" for="fileInput"
                   style="margin-left: 33px;margin-bottom: 4px;">
                <img [class.profilePic]="currentUser.user.branduserprofile.brand.logo_url"
                     [src]="currentUser.user.branduserprofile.brand.logo_url ? currentUser.user.branduserprofile.brand.logo_url : 'https://webcdn.tailorie.com/assets/images/icons/add-logo-md.png'"
                     alt="user-icon">
                <div class="background"></div>
            </label>
            <br/>
            <input (change)="onSelectFile($event)" accept="image/*" hidden id="fileInput" type='file'>

            <div appDropdown class="nav-item dropdown" style="margin-left: 13px;">

                <a class="nav-link customCaret"
                   href="javascript:void(0)">{{currentUser.user.branduserprofile.brand.business_name}}
                    <span class="caret"></span>
                </a>

                <div class="dropdown-menu" style="margin-left: -20px;">
                    <a class="dropdown-item border-bottom" href="javascript:void(0)" routerLink="/edit-brand-profile">
                        <img [class.profilePic]="currentUser.user.branduserprofile.brand.logo_url"
                             [src]="currentUser.user.branduserprofile.brand.logo_url ? currentUser.user.branduserprofile.brand.logo_url : 'https://webcdn.tailorie.com/assets/images/icons/Group 1624@2x.png'"
                             alt="user-icon" style="width: 24px;height: 23px;">&nbsp; Profile</a>
                    <a (click)="logout()" class="dropdown-item" href="javascript:void(0)">
                        <img alt="logout-icon" src="https://webcdn.tailorie.com/assets/images/icons/Log%20out@2x.png"
                             style="width: 15px; margin-left: 7px;">&nbsp;&nbsp; Logout</a>
                </div>

            </div>

        </form>

    </div>

</nav>

