<div class="wrapper d-flex align-items-stretch">

    <nav [ngClass]="{'active' : isOpen}" class="custom-scrollbar-css" id="sidebar">

        <div class="custom-menu">
            <a (click)="toggleSidebar()" href="javascript:void(0)" id="sidebarCollapse">
                <!--                <i class="fa fa-times" id="favIcon"> </i>-->
                <img alt="=" src="{{cdnUrl}}/assets/images/icons/times-white.svg" style="width: 20px">
            </a>
        </div>
        <div class="p-4">
            <h1>
                <a class="logo" href="https://tailorie.com">
                    <img alt="logo" src="https://webcdn.tailorie.com/assets/images/Tailorie-logo_white.svg">
                </a>
            </h1>
            <ul class="list-unstyled components nav navbar-nav inside-nav">
                <li *ngIf="visible" class="sub_menu" id="menu-1">
                    <a (click)="closePanels()" routerLink="/dashboard" routerLinkActive="active-list-item">
                        <img alt="image"
                             class="d-icon mr-3" routerLinkActive="active-list-item"
                             src="assets/images/side-bar-icon/dashboard@2x.png"
                             style="width: 15px !important;">Dashboard</a>
                </li>
                <li *ngIf="visible">
                    <a (click)="closePanels()" routerLink="/performance" routerLinkActive="active-list-item">
                        <img alt="image" class="d-icon mr-3" routerLinkActive="active-list-item" src="assets/images/side-bar-icon/bars.png" style="width: 15px !important;">
                        Performance
                    </a>
                </li>
                <li>
                    <a (click)="openSubmenu1()" class="customCaret" routerLink="/edit-brand-profile" routerLinkActive="active-list-item">
                        <img alt="image" class=" mr-3" src="assets/images/side-bar-icon/clip@2x.png" style="width: 12.3px;">
                        <span> Brand Profile</span>
                    </a>
                    <ul class="collapse sub-menu" id="submenu-1">
                        <li *ngIf="visible" style="border-bottom: none; margin-top: -18px;">
                            <a (click)="closePanels()" routerLink="/brand-profile" routerLinkActive="active-list-item">Analysis</a>
                        </li>
                        <li style="margin-top: -18px;border-bottom: none !important;">
                            <a (click)="closePanels()" routerLink="/edit-brand-profile" routerLinkActive="active-list-item">Edit Profile</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a (click)="closePanels()" routerLink="/canvas-crate" routerLinkActive="active-list-item"><img alt="image" class="mr-3"
                                                                                                                   routerLinkActive="active-list-item"
                                                                                                                   src="assets/images/side-bar-icon/crate_icon.png"
                                                                                                                   style="width: 16px;"> Canvas Crate</a>
                </li>
                <li class="main-list">
                    <a (click)="openSubmenu2()" class="customCaret" routerLink="/edit-community-profile" routerLinkActive="active-list-item">
                        <img alt="image" class="d-icon" src="assets/images/side-bar-icon/Shape@2x.png" style="font-size: 22px; margin-right: 12px; width: 22px !important;">
                        Community Profile
                    </a>
                    <ul class="collapse sub-menu" id="submenu-2">
                        <li *ngIf="visible" style="border-bottom: none; margin-top: -18px;">
                            <a (click)="closePanels()" routerLink="/community-profile" routerLinkActive="active-list-item">Analysis</a>
                        </li>
                        <li style="margin-top: -18px; border-bottom: none !important;">
                            <a (click)="closePanels()" routerLink="/edit-community-profile" routerLinkActive="active-list-item">Edit Profile</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a (click)="closePanels()" routerLink="/brand-journey" routerLinkActive="active-list-item"><img alt="image" class="d-icon mr-3"
                                                                                                                    routerLinkActive="active-list-item"
                                                                                                                    src="assets/images/side-bar-icon/Sub@2x.png"
                                                                                                                    style="width: 18px !important;"> Brand Journey</a>
                </li>
                <li>
                    <a (click)="closePanels()" routerLink="/account-info" routerLinkActive="active-list-item"><img alt="image" class="mr-3"
                                                                                                                   routerLinkActive="active-list-item"
                                                                                                                   src="assets/images/side-bar-icon/user.png"
                                                                                                                   style="width: 16px;"> Account Info</a>
                </li>
            </ul>
        </div>

    </nav>
</div>


<!--<aside style="background-color: #034B4E;" class="main-sidebar sidebar-dark-primary elevation-4">-->
<!--    <div class="remove-bar" data-widget="pushmenu">-->
<!--        <i class="fas fa-times"></i>-->
<!--    </div>-->
<!--    &lt;!&ndash; Brand Logo &ndash;&gt;-->
<!--    <a href="" class="brand-link">-->
<!--        <span class="brand-text font-weight-light ml-5" id="tailorie-title"-->
<!--              style="font-family: k22-didoni; font-size:35px; color: #fff">tailorie</span>-->
<!--    </a>-->

<!--    &lt;!&ndash; Sidebar &ndash;&gt;-->
<!--    <div class="sidebar sidebar-expand-lg">-->
<!--        &lt;!&ndash; Sidebar user panel (optional) &ndash;&gt;-->
<!--        <div class="mt-3 pb-3 mb-3 d-flex">-->
<!--            &lt;!&ndash; <div class="info">-->
<!--              <a href="#" class="d-block">Alexander Pierce</a>-->
<!--            </div> &ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash; Sidebar Menu &ndash;&gt;-->
<!--        <nav class="mt-2">-->
<!--            <ul class="nav nav-pills nav-sidebar flex-column" routerLinkActive="active" data-widget="treeview"-->
<!--                role="menu" data-accordion="false">-->

<!--                <li class="nav-item">-->
<!--                    <a routerLink="/dashboard" routerLinkActive="active" class="nav-link">-->
<!--                        <i class="nav-icon fas fa-th"></i>-->
<!--                        <p>-->
<!--                            Dashboard-->
<!--                        </p>-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li class="nav-item" style="border-top: 1px solid #16595B;">-->
<!--                    <a routerLink="/performance" routerLinkActive="active" class="nav-link">-->
<!--                        <i class="nav-icon fas fa-chart-bar"></i>-->
<!--                        <p>-->
<!--                            Performance-->
<!--                        </p>-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li class="nav-item has-treeview" style="border-top: 1px solid #16595B;">-->
<!--                    <a class="nav-link" data-toggle="collapse" data-target="#submenu-2">-->
<!--                        <i class="nav-icon fas fa-file-alt"></i>-->
<!--                        <p>-->
<!--                            Brand Persona-->
<!--                            <i class="fas fa-caret-up right"></i>-->
<!--                        </p>-->
<!--                    </a>-->
<!--                    <ul id="submenu-2" class="collapse nav-treeview">-->
<!--                        <li class="nav-item">-->
<!--                            <a routerLink="/brand-profile" routerLinkActive="active" class="nav-link">-->
<!--                                <i class="far fa-circle nav-icon" style="opacity: 0;"></i>-->
<!--                                <p>Analysis</p>-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li class="nav-item">-->
<!--                            <a routerLink="/edit-brand-profile" routerLinkActive="active" class="nav-link">-->
<!--                                <i class="far fa-circle nav-icon" style="opacity: 0;"></i>-->
<!--                                <p>Edit Persona</p>-->
<!--                            </a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li class="nav-item has-treeview" style="border-top: 1px solid #16595B;">-->
<!--                    <a class="nav-link" data-toggle="collapse" data-target="#submenu-1">-->
<!--                        <i class="nav-icon fas fa-user-friends"></i>-->
<!--                        <p>-->
<!--                            Customer Profile-->
<!--                            <i class="fas fa-caret-up right"></i>-->
<!--                        </p>-->
<!--                    </a>-->
<!--                    <ul id="submenu-1" class="nav-treeview collapse">-->
<!--                        <li class="nav-item">-->
<!--                            <a routerLink="/community-profile" routerLinkActive="active" class="nav-link">-->
<!--                                <i class="far fa-circle nav-icon" style="opacity: 0;"></i>-->
<!--                                <p>Analysis</p>-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li class="nav-item">-->
<!--                            <a routerLink="/edit-community-profile" routerLinkActive="active" class="nav-link">-->
<!--                                <i class="far fa-circle nav-icon" style="opacity: 0;"></i>-->
<!--                                <p>Edit Profile</p>-->
<!--                            </a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                &lt;!&ndash;          <li class="nav-item" style="border-top: 1px solid #16595B;">&ndash;&gt;-->
<!--                &lt;!&ndash;            <a href="#" class="nav-link">&ndash;&gt;-->
<!--                &lt;!&ndash;              <i class="nav-icon fas fa-user-friends"></i>&ndash;&gt;-->
<!--                &lt;!&ndash;              <p>&ndash;&gt;-->
<!--                &lt;!&ndash;                Customer Profile&ndash;&gt;-->
<!--                &lt;!&ndash;              </p>&ndash;&gt;-->
<!--                &lt;!&ndash;            </a>&ndash;&gt;-->
<!--                &lt;!&ndash;          </li>&ndash;&gt;-->
<!--                <li class="nav-item" style="border-top: 1px solid #16595B;">-->
<!--                    <a routerLink="/brand-journey" routerLinkActive="active" class="nav-link">-->
<!--                        <i class="nav-icon fas fa-play-circle"></i>-->
<!--                        <p>-->
<!--                            Brand Stories-->
<!--                        </p>-->
<!--                    </a>-->
<!--                </li>-->

<!--            </ul>-->

<!--            <div class="collapse navbar-collapse navbar-ex1-collapse">-->
<!--                <ul class="nav navbar-nav side-nav">-->

<!--                </ul>-->
<!--            </div>-->
<!--            &lt;!&ndash; /.navbar-collapse &ndash;&gt;-->
<!--        </nav>-->
<!--        &lt;!&ndash; /.sidebar-menu &ndash;&gt;-->
<!--    </div>-->

<!--    &lt;!&ndash; /.sidebar &ndash;&gt;-->
<!--</aside>-->
