import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {BrandService} from './brand/brand.service';
import {BrandStoriesService} from './brand_stories/brand-stories.service';
import {VideoProcessingService} from '../../video-processing-service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AuthService} from './auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {BrandStory} from './brand_stories/brand-stories';
import {environment} from '../../../environments/environment';
import * as tus from 'tus-js-client';

@Injectable({
    providedIn: 'root'
})
export class VideoUploadServiceService extends ApiService {

    uploading = false;
    uploadPercentage = 0;
    uploadService: any;
    isDataHold = false;
    videoFile: any;
    brandId: number;

    constructor(private http: HttpClient,
                private brandService: BrandService,
                private brandStoriesService: BrandStoriesService,
                private videoService: VideoProcessingService,
                private loadingBar: LoadingBarService,
                private authService: AuthService,
                private toastr: ToastrService) {
        super();
    }

    // Hold Intro Video Data
    holdData(id, data: FormData) {
        this.brandId = +id;
        this.videoFile = data;
        this.isDataHold = true;
        this.uploading = false;
    }

    showUploadBar() {
        this.uploading = true;
    }

    hideUploadBar() {
        this.uploading = false;
    }

    // Upload Intro Video
    UploadIntroVideo() {
        if (this.isDataHold) {
            this.uploading = true;
            this.uploadPercentage = 1;
            this.http.post<BrandStory>(`${this.apiUrl}/brands/${this.brandId}/posts/`, this.videoFile, {
                reportProgress: true,
                observe: 'events'
            }).pipe(
                map((event: any) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.uploadPercentage = Math.round((100 / event.total) * event.loaded);
                    } else if (event.type === HttpEventType.Response) {
                        this.uploadPercentage = null;
                    }
                }),
                catchError((err: any) => {
                    this.uploadPercentage = null;
                    this.toastr.error(err.message);
                    return throwError(err.message);
                })
            ).toPromise().then(res => {
                this.uploadPercentage = 95;
                setTimeout(() => {
                    this.toastr.success('Intro Video Uploaded Successfully');
                    this.uploading = false;
                    window.location.reload();
                }, 4000);

            }).catch(err => {
                this.toastr.error('Intro Video Uploading Failed!');
            });
        }
    }

    // Upload Intro VIdeo
    UploadIntroVideoTUSWay() {
        if (this.isDataHold) {
            console.log('Uploading Video...');
            // const mbSize = videoFile.size / 1024 / 1024;

            const fileIsMp4 = (this.videoFile.type === 'video/mp4' || 'video/3gpp' || 'video/m4v'
                || 'application/x-mpegURL' || 'video/MP2T' || 'video/quicktime'

                // || 'video/x-msvideo' || 'video/x-flv'
                || 'video/x-m4v' || 'video/wmv');

            if (!fileIsMp4) {
                this.toastr.error('Video File Not Supported!');
                this.isDataHold = false;
                return false;
            } else {

                // -----------------------------   TUS UPLOADER

                // Get the selected file
                const file = this.videoFile;
                let currPer = 0;
                const brandId = this.brandId;

                const serv = this.brandStoriesService;
                const toastrSer = this.toastr;
                const brandSer = this.brandStoriesService;

                const postDataObj = {
                    post_content_type: 'video_post',
                    post_type: 'Brand Intro',
                    guid: '0'
                };

                const envUrl = environment.apiBaseUrl || 'http://192.168.100.100:8000';
                const uploadAPIUrl = envUrl + '/api/v1/files/';

                // Create a new tus upload
                const upload = new tus.Upload(file, {
                    endpoint: uploadAPIUrl,
                    retryDelays: [0, 3000, 5000, 10000, 20000],
                    metadata: {
                        filename: file.name,
                        filetype: file.type
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.authService.currentUserValue.token
                    },
                    onError(error) {
                        console.log('Failed because: ' + error);
                    },
                    onProgress(bytesUploaded, bytesTotal) {

                        document.getElementById('video-upload-wrapper').classList.add('showBar');

                        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                        console.log(bytesUploaded, bytesTotal, percentage + '%');
                        currPer = Math.round(+percentage);
                        if (+percentage > 70 && +percentage < 90) {
                            document.getElementById('progress-bar').style.width = +currPer + '%';
                        } else if (+percentage > 95 && +percentage <= 100) {
                            document.getElementById('progress-bar').style.width = '95%';
                        } else {
                            document.getElementById('progress-bar').style.width = +percentage + '%';
                        }
                    },
                    onSuccess() {
                        if ('name' in upload.file) {
                            console.log('Download %s from %s', upload.file.name, upload.url);
                        } else {
                            console.log('Download %s from %s', upload.file, upload.url);
                        }

                        if (currPer === 100) {
                            const upUrl = upload.url.split('/');
                            const guid = upUrl[upUrl.length - 2];
                            postDataObj.guid = guid;
                            serv.CreateBrandStory(brandId, postDataObj).subscribe(res => {
                                document.getElementById('uploading-text').innerText =
                                    'Uploading Video';
                                // const elem = document.createElement('span');
                                // elem.innerHTML = '<br> Reloading Page, Please Wait!';
                                // document.getElementById('uploading-text').appendChild(elem);

                                setTimeout(() => {
                                    document.getElementById('uploading-text').innerText =
                                        'Uploading Video';
                                }, 3000);

                                localStorage.setItem('pageLoad', 'slow');

                                setTimeout(() => {
                                    document.getElementById('progress-bar').style.width = '100%';
                                    document.getElementById('uploading-text').innerText =
                                        'Success! Your video has been uploaded.';

                                    setTimeout(() => {
                                        document.getElementById('video-upload-wrapper').classList.remove('showBar');
                                        serv.setBrandStoryUploaded(true);
                                    }, 5000);
                                }, 10000);
                            }, error => {
                                // document.getElementById('error-text').innerText =
                                //     'Something went wrong!';
                                toastrSer.error('Something went wrong!');
                            });
                        }
                    },


                });

                // Start the upload
                upload.start();
                this.isDataHold = false;

                // -----------------------------   .end TUS UPLOADER

            }
        } else {
            console.log('No Video to upload.');
        }
    }

    // --------- OBSOLETE CODE

    // initiateUpload() {
    //     const timer = setInterval(() => {
    //         this.uploadPercentage++;
    //         console.log(this.uploadPercentage);
    //         if (this.uploadPercentage === 30) {
    //             clearInterval(timer);
    //         }
    //     }, 1000);
    // }

    // startUpload() {
    //     console.log('started');
    //     console.log('Is Data Hold: ' + this.isDataHold);
    //
    //     if (this.isDataHold) {
    //         this.uploading = true;
    //         this.uploadService = this.brandStoriesService.CreateBrandStory(this.brandId, this.videoData).subscribe(
    //             (brandStory) => {
    //                 console.log('uploaded');
    //                 const brandDetails = {
    //                     brandsignupchecklist: {
    //                         uploaded_intro_video: true,
    //                     },
    //                 };
    //                 this.brandService.UpdateBrandById(this.brandId, brandDetails).subscribe(
    //                     (brand) => {
    //                         this.authService.updateCurrentUserBrand(brand);
    //                         this.uploading = false;
    //                     },
    //                     (err) => {
    //                         this.toastr.error('Something went wrong while updating the brand info!');
    //                     }
    //                 );
    //             },
    //             (err) => {
    //                 this.toastr.error('Something went wrong while uploading Brand intro Video!');
    //                 // this.toastr.error(
    //                 //     'If you’re still working on your video, hit ‘Upload later’ to move to the next page!'
    //                 // );
    //             }
    //         );
    //     }
    //
    // }

    // --------- OBSOLETE CODE

}
