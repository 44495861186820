import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AccountsCountriesService extends ApiService {

    constructor(private http: HttpClient) {
        super();
    }

    getStates(countryName: string) {
        return this.http.get<any>(`${this.apiUrl}/misc/states/${countryName}/`).pipe(
            map((resp) => {
                return resp.data.map(state => {
                    return {value: state.state_name, label: state.state_name};
                });
            }));
    }
}
