<div class="content-wrapper">

    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap">
            <img class="side-img w-auto" src="{{cdnUrl}}/assets/images/brand-account-images/image6-fliped.png" style="margin-left: 20px">
        </div>
        <div class="pr-2">
            <h1>Brand Profile</h1>
            <p>What you represent and how your community relate.</p>
        </div>
    </div>

    <!------------------------------------ TABS ------------------------------------>
    <div class="row position-relative ml-0 mr-0">
        <section class="col-lg-12 py-0 pr-0 pl-0">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist" style="margin-left: 50px">
                    <a #poplink1 (click)="showTabContent(pop1,poplink1,'pc')" [ngClass]="{'active' : selectedTab === 'pc'}" aria-controls="pop1" aria-selected="true"
                       class="nav-item nav-link bs-tab-link" data-toggle="tab" fragment="pc" href="javascript:void(0)" id="pop1-tab"
                       role="tab" routerLink="/brand-profile">Product Categories</a>
                    <a #poplink2 (click)="showTabContent(pop2,poplink2,'vib')" [ngClass]="{'active' : selectedTab === 'vb'}" aria-controls="pop2" aria-selected="true"
                       class="nav-item nav-link bs-tab-link" data-toggle="tab" fragment="vb" href="javascript:void(0)" id="pop2-tab"
                       role="tab" routerLink="/brand-profile">Vibes</a>
                    <a #poplink3 (click)="showTabContent(pop3,poplink3,'val')" [ngClass]="{'active' : selectedTab === 'vl'}" aria-controls="pop3" aria-selected="false"
                       class="nav-item nav-link bs-tab-link" data-toggle="tab" fragment="vl" href="javascript:void(0)" id="pop3-tab"
                       role="tab" routerLink="/brand-profile">Values</a>
                    <a #poplink4 (click)="showTabContent(pop4,poplink4,'sc')" [ngClass]="{'active' : selectedTab === 'sc'}" aria-controls="pop4" aria-selected="false"
                       class="nav-item nav-link bs-tab-link" data-toggle="tab" fragment="sc" href="javascript:void(0)" id="pop4-tab"
                       role="tab" routerLink="/brand-profile">Social Causes</a>

                </div>
            </nav>
            <div class="productCategory">
                <div class="row" style="padding-left: 25px !important; padding-right: 25px !important;">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <ng-select (change)="loadMenu(selectedMenu)" [(ngModel)]="selectedMenu" [clearable]="false" [items]="navMenuBindingList" [searchable]="false">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-section">
                <div class="tab-content" id="nav-tabContent">

                    <!------------------------------------ TAB (Product Categories) ------------------------------------>

                    <div #pop1 [ngClass]="{'fade show active' : selectedTab === 'pc'}" aria-labelledby="pop1-tab" class="tab-pane fade bs-tab-content" id="pop1" role="tabpanel">
                        <div [ngStyle]="currentBrandInfo.product_categories.length > 0 ? {'border-bottom': '1px solid #0000001A'} : {}" class="product-heading position-relative">
                            <div class="form-inline" id="pcCntr">
                                <span class="arrow-horizontal-right" id="pcRtBtn">
                                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                                </span>
                                <span class="arrow-horizontal-left" id="pcLtBtn">
                                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                                </span>
                                <div class="col-lg-4 mobile-view pl-0 pr-0" style="margin-bottom: 32px !important;">
                                    <div class="form-group">
                                        <ng-select [(ngModel)]="proSelected" [clearable]="false" [searchable]="false" class="w-100">
                                            <div *ngFor="let productCategory of allProductCategories">
                                                <div *ngIf="productCategory.selected && productCategory.is_predefined">
                                                    <ng-option [value]="productCategory.title"><img alt="{{productCategory.title}}" src="{{productCategory.image}}"
                                                                                                    style="width: 29px; padding-right: 10px">
                                                        <label class="proTitle">{{productCategory.title}}</label>
                                                        <label class="PerLabels">{{productCategory.percentage}}%</label>
                                                    </ng-option>
                                                </div>
                                            </div>
                                        </ng-select>
                                    </div>
                                </div>
                                <ng-container *ngFor="let productCategory of allProductCategories" class="desktop">
                                    <div *ngIf="productCategory.is_predefined && productCategory.selected" class="text-center">
                                        <div class="select-box" fragment="pc" routerLink="/edit-brand-profile">
                                            <input [checked]="productCategory.selected" disabled hidden id="pc-{{productCategory.id}}" type="checkbox"/>
                                            <label for="pc-{{productCategory.id}}">
                                                <img alt="{{productCategory.title}}" src="{{productCategory.image}}">
                                                {{productCategory.title}}
                                            </label>
                                        </div>
                                        <label class="productPerLabels">{{productCategory.percentage}}%</label>

                                    </div>
                                </ng-container>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="date-period">
                                    <div class="fc-center">
                                        <h5 *ngIf="currday">{{startDate || currentDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="currweek">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="currmonth">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="curryear">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">

                                            <label (click)="setActive('dayBtn', 'pcfbtn'); ctShowDay()" class="btn pcfbtn active" id="dayBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       id="daypicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput
                                                       name="options"
                                                       ngxDaterangepickerMd opens="left"
                                                       singleDatePicker="true" type="radio"> Day
                                            </label>
                                            <label (click)="setActive('weekBtn', 'pcfbtn'); ctShoWeek()" class="btn pcfbtn" id="weekBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       dateLimit="6"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="weekpicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       opens="left" type="radio">
                                                Week
                                            </label>
                                            <label (click)="setActive('monthBtn', 'pcfbtn'); ctShowMonth()" class="btn pcfbtn" id="monthBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers"
                                                       applyLabel="Okay"
                                                       autocomplete="off" dateLimit="30"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day"
                                                       matInput
                                                       name="options" ngxDaterangepickerMd
                                                       opens="center" type="radio">
                                                Month
                                            </label>
                                            <label (click)="setActive('yearBtn', 'pcfbtn'); ctShowYear();myProduct.open()" class="btn pcfbtn" id="yearBtn"
                                                   style="line-height: 28px">
                                                <mat-select #myProduct style="visibility:hidden;width: 0">
                                                    <mat-option (click)="selectYear(year,'pc')"
                                                                *ngFor="let year of years"
                                                                [value]="year">{{ year }}
                                                    </mat-option>
                                                </mat-select>
                                                Year
                                            </label>
                                            <label (click)="setActive('customBtn', 'pcfbtn');ctShowCustom();" class="ngx-daterangepicker-action btn pcfbtn" id="customBtn"
                                                   style="width:60px">
                                                <input #customDate (datesUpdated)="selectedChange($event)"
                                                       [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="false"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="customDate"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       opens="right"
                                                       type="radio"
                                                />
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Cards------------------------------------------------ -->
                        <div class="row no-gutters metric-containers">
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Follower Match </h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{pcBrandProfile.followerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="pcBrandProfile?.followerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : pcBrandProfile.followerMatchprev >= 0,
                                    'negative' : pcBrandProfile.followerMatchprev  < 0}" class="percent">
                                                <p *ngIf="pcBrandProfile?.followerMatchprev === 0" style="color: #3F9194">+/-</p>
                                                {{pcBrandProfile.followerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="pcBrandProfile?.followerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely your followers match your categories.</p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Profile Viewer Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{pcBrandProfile.profileViewerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="pcBrandProfile?.profileViewerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : pcBrandProfile.profileViewerMatchprev >= 0,
                                    'negative' : pcBrandProfile.profileViewerMatchprev  < 0}" class="percent">
                                                <p *ngIf="pcBrandProfile?.profileViewerMatchprev === 0" style="color: #3F9194">+/-</p>
                                                {{pcBrandProfile.profileViewerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="pcBrandProfile?.profileViewerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely a user who clicks on your profile matches your categories.</p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-blue">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Click-through Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">

                                            <div class="card-data">
                                                <h1>{{pcBrandProfile.clickThroughMatch | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="pcBrandProfile?.clickThroughMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : pcBrandProfile.clickThroughMatchprev >= 0,
                                    'negative' : pcBrandProfile.clickThroughMatchprev  < 0}" class="percent">
                                                    <p *ngIf="pcBrandProfile?.clickThroughMatchprev===0" style="color: #3F9194">+/-</p>
                                                    {{pcBrandProfile.clickThroughMatchprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="pcBrandProfile?.clickThroughMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who went to your website matched to your categories.
                                            </p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-purple">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Recommenders Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">
                                            <div class="card-data">
                                                <h1>{{pcBrandProfile.recommendations | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="pcBrandProfile?.recommendationsprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : pcBrandProfile.recommendationsprev >= 0,
                                    'negative' : pcBrandProfile.recommendationsprev  < 0}" class="percent">
                                                    <p *ngIf="pcBrandProfile?.recommendationsprev===0" style="color: #3F9194">+/-</p>
                                                    {{pcBrandProfile.recommendationsprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="pcBrandProfile?.recommendationsprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who recommended your brand matches your categories.
                                            </p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /table------------------------------------------------ -->
                        <!-- Main row -->
                        <div *ngIf="currentBrandInfo.product_categories.length > 0" class="row table-desktop">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table mb-0" style="max-height: 502px">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th class="tab-heading">Product Category Breakdown</th>
                                                    <th class="text-center" style="border-right: 1px solid #dee2e6;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <ng-container *ngFor="let productCategory of pcTableData">
                                                    <tr *ngIf="productCategory.per && productCategory.prosel" style="border-top: 1px solid #dee2e6; !important;">

                                                        <th class="toplist d-flex flex-row align-items-center" scope="row">
                                                            <img alt=" {{productCategory.title}}" class="stories-image img-fluid" src="{{productCategory.image}}">
                                                            <div class="top-list">
                                                                <div class="top-name">
                                                                    <h2 class="title">{{productCategory.title}}</h2>
                                                                    <p [ngClass]="{'positive' : productCategory.allocation >= 0,
                                                           'negative' : productCategory.allocation < 0}" class="sub-title">
                                                                        {{productCategory.allocation}}% Allocation</p>
                                                                </div>
                                                            </div>
                                                        </th>

                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productCategory.avgAge | number : '0.0-0'}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{productCategory.followers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productCategory.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{productCategory.viewers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productCategory.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                --
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                --
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="currentBrandInfo.product_categories.length > 0" class="row table-mobile">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <div class="tab-heading">Product Category Breakdown</div>


                                    <div class="table-responsive-lg">
                                        <table class="table" style="margin-bottom: 0rem !important;">
                                            <colgroup>
                                                <col class=""/>
                                                <col class="grey"/>
                                                <col class=""/>
                                                <col class="grey"/>
                                                <col class=""/>
                                                <col class="grey"/>
                                                <col class=""/>
                                                <col class="grey"/>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th style="position: sticky;left: 0; background-color: #ffffff; border-top: none !important;"></th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <th *ngIf="data.per && data.prosel" style="background-color: #ffffff; border-top: none !important;">

                                                        <div class="main-block">
                                                            <label>
                                                                <img alt="{{data.title}}"
                                                                     class="pro-image"
                                                                     src="{{data.image}}">
                                                                {{data.title}}

                                                            </label>
                                                            <span class="allocation">
                                                                {{data.allocation}}% Allocation
                                                            </span>
                                                        </div>

                                                    </th>
                                                </ng-container>

                                            </tr>

                                            </thead>

                                            <tbody>

                                            <tr>
                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="avg-color">Avg Age</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">
                                                            {{data.avgAge | number : '0.0-0'}}</div>
                                                    </td>
                                                </ng-container>

                                            </tr>

                                            <tr>

                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="Head-color"># of<br> Followers</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">{{data.followers}}</div>
                                                    </td>
                                                </ng-container>
                                            </tr>

                                            <tr>

                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="Head-color">% of Total<br> Followers</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">
                                                            {{data.perOfFollwers | number : '0.0-0'}}%
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </tr>

                                            <tr>

                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="Head-color"># of<br> Viewers</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">{{data.viewers}}</div>
                                                    </td>
                                                </ng-container>


                                            </tr>

                                            <tr>

                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="Head-color">% of Total<br> Viewers</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">
                                                            {{data.perOfViewers | number : '0.0-0'}}%
                                                        </div>
                                                    </td>
                                                </ng-container>

                                            </tr>

                                            <tr>

                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="Head-color"># of Users<br> Agree</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">
                                                            &#45;&#45;
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr>

                                                <th scope="row" style="position: sticky;left: 0;background: #FFFFFF;">
                                                    <div class="Head-color"># of Users<br> Disagree</div>
                                                </th>
                                                <ng-container *ngFor="let data of pcTableData">
                                                    <td *ngIf="data.per && data.prosel" align="center">
                                                        <div class="tad">
                                                            &#45;&#45;
                                                        </div>
                                                    </td>
                                                </ng-container>

                                            </tr>

                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!------------------------------------ TAB (Product Vibes) ------------------------------------>

                    <div #pop2 [ngClass]="{'fade show active' : selectedTab === 'vb'}" aria-labelledby="pop2-tab" class="tab-pane fade bs-tab-content active" id="pop2"
                         role="tabpanel">
                        <div [ngStyle]="currentBrandInfo.vibes.length > 0 ? {'border-bottom': '1px solid #0000001A'} : {}" class="product-heading position-relative">
                            <div class="form-inline" id="vbCntr">
                                 <span class="arrow-horizontal-right" id="vbRtBtn">
                                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                                </span>
                                <span class="arrow-horizontal-left" id="vbLtBtn">
                                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                                </span>
                                <div class="col-lg-4 mobile-view pl-0 pr-0" style="margin-bottom: 32px !important;">
                                    <div class="form-group">
                                        <ng-select [(ngModel)]="vibeSelected" [clearable]="false" class="w-100">
                                            <div *ngFor="let vibe of allVibes">
                                                <div *ngIf="vibe.is_predefined && vibe.selected">
                                                    <ng-option [value]="vibe.title"><img alt="{{vibe.title}}" src="{{vibe.image}}" style="width: 29px; padding-right: 10px"><label
                                                            class="proTitle">{{vibe.title}}</label><label class="PerLabels">{{vibe.percentage}}%</label></ng-option>
                                                </div>
                                            </div>
                                        </ng-select>
                                    </div>
                                </div>
                                <ng-container *ngFor="let vibe of allVibes" class="desktop">
                                    <div *ngIf="vibe.is_predefined && vibe.selected" class="text-center">
                                        <div class="select-box" fragment="vb" routerLink="/edit-brand-profile">
                                            <input [checked]="vibe.selected" disabled hidden id="pc-{{vibe.id}}" type="checkbox"/>
                                            <label for="pc-{{vibe.id}}">
                                                <img alt="{{vibe.title}}" src="{{vibe.image}}">
                                                {{vibe.title}}
                                            </label>
                                        </div>
                                        <label class="productPerLabels">{{vibe.percentage}}%</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="date-period">
                                    <div class="fc-center">
                                        <h5 *ngIf="vibcurrday">
                                            {{(startDate || currentDate) | date: 'EEEE, MMMM d, yyyy'}}</h5>
                                        <h5 *ngIf="vibcurrweek">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="vibcurrmonth">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="vibcurryear">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label (click)="setActive('vibedayBtn', 'vfbtn');  vibShowDay()" class="btn vfbtn active" id="vibedayBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       id="daypicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput
                                                       name="options"
                                                       ngxDaterangepickerMd singleDatePicker="true"
                                                       type="radio"> Day
                                            </label>
                                            <label (click)="setActive('vibeweekBtn', 'vfbtn');  vibShoWeek()" class="btn vfbtn" id="vibeweekBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       dateLimit="6"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="weekpicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       type="radio">
                                                Week
                                            </label>
                                            <label (click)="setActive('vibmonthBtn', 'vfbtn');  vibShowMonth()" class="btn vfbtn" id="vibmonthBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers"
                                                       applyLabel="Okay"
                                                       autocomplete="off" dateLimit="30"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day"
                                                       matInput
                                                       name="options" ngxDaterangepickerMd
                                                       type="radio">
                                                Month
                                            </label>
                                            <label (click)="setActive('vibyearBtn', 'vfbtn');  vibShowYear();myVibe.open()" class="btn vfbtn" id="vibyearBtn"
                                                   style="line-height: 28px">
                                                <mat-select #myVibe style="visibility:hidden;width: 0">
                                                    <mat-option (click)="selectYear(year,'vib')"
                                                                *ngFor="let year of years"
                                                                [value]="year">{{ year }}
                                                    </mat-option>
                                                </mat-select>
                                                Year
                                            </label>
                                            <label (click)="setActive('vibCustomBtn', 'vfbtn');ctShowCustom()" class="ngx-daterangepicker-action btn vfbtn" id="vibCustomBtn"
                                                   style="width:60px">
                                                <input #customDate (datesUpdated)="selectedChange($event)"
                                                       [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="false"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="customDate"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       opens="right"
                                                       type="radio"
                                                />
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Cardds------------------------------------------------ -->
                        <div class="row no-gutters metric-containers">
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Follower Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{vibBrandProfile.followerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="vibBrandProfile?.followerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : vibBrandProfile.followerMatchprev >= 0,
                                    'negative' : vibBrandProfile.followerMatchprev  < 0}" class="percent">
                                                <p *ngIf="vibBrandProfile?.followerMatchprev===0" style="color: #3F9194">+/-</p>
                                                {{vibBrandProfile.followerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="vibBrandProfile?.followerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely your followers match your vibes.</p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Profile Viewer Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{vibBrandProfile.profileViewerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="vibBrandProfile?.profileViewerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : vibBrandProfile.profileViewerMatchprev >= 0,
                                    'negative' : vibBrandProfile.profileViewerMatchprev  < 0}" class="percent">
                                                <p *ngIf="vibBrandProfile?.profileViewerMatchprev===0" style="color: #3F9194">+/-</p>
                                                {{vibBrandProfile.profileViewerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="vibBrandProfile?.profileViewerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely a user who clicks on your profile matches your vibe. </p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-blue">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Click-through Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">

                                            <div class="card-data">
                                                <h1>{{vibBrandProfile.clickThroughMatch | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="vibBrandProfile?.clickThroughMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : vibBrandProfile.clickThroughMatchprev >= 0,
                                    'negative' : vibBrandProfile.clickThroughMatchprev  < 0}" class="percent">
                                                    <p *ngIf="vibBrandProfile?.clickThroughMatchprev===0" style="color: #3F9194">+/-</p>
                                                    {{vibBrandProfile.clickThroughMatchprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="vibBrandProfile?.clickThroughMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who went to your website matched to your vibe.</p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-purple">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Recommenders Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">
                                            <div class="card-data">
                                                <h1>{{vibBrandProfile.recommendations | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="vibBrandProfile?.recommendationsprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : vibBrandProfile.recommendationsprev >= 0,
                                    'negative' : vibBrandProfile.recommendationsprev  < 0}" class="percent">
                                                    <p *ngIf="vibBrandProfile?.recommendationsprev===0" style="color: #3F9194">+/-</p>
                                                    {{vibBrandProfile.recommendationsprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="vibBrandProfile?.recommendationsprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who recommended your brand matches your vibes.</p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /table------------------------------------------------ -->
                        <!-- Main row -->
                        <div *ngIf="currentBrandInfo.vibes.length > 0" class="row table-desktop">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table  mb-0" style="max-height: 502px;">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th class="tab-heading">Vibes Breakdown</th>
                                                    <th class="text-center" style="border-right: 1px solid #dee2e6;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <ng-container *ngFor="let productVibes of vibTableData">
                                                    <tr *ngIf="productVibes.vibper && productVibes.vibesel" style="border-top: 1px solid #dee2e6; !important;">
                                                        <th class="toplist d-flex flex-row align-items-center" scope="row">
                                                            <img alt=" {{productVibes.title}}" class="stories-image img-fluid" src="{{productVibes.image}}">
                                                            <div class="top-list">
                                                                <div class="top-name">
                                                                    <h2 class="title">{{productVibes.title}}</h2>
                                                                    <p [ngClass]="{'positive' : productVibes.allocation >= 0,
                                                           'negative' : productVibes.allocation < 0}" class="sub-title">
                                                                        {{productVibes.allocation}}% Allocation</p>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <td align="center">
                                                            <div class="tad">{{productVibes.avgAge | number : '0.0-0'}}
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{productVibes.followers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productVibes.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{productVibes.viewers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productVibes.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">--</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                --
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="currentBrandInfo.vibes.length > 0" class="row table-mobile">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <div class="tab-heading">Vibes Breakdown</div>

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table" style="margin-bottom: 0rem !important;">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th style="position: sticky;left: 0; background-color: #ffffff; border-top: none !important;"></th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <th *ngIf="vibeData.vibper && vibeData.vibesel" style="background-color: #ffffff; border-top: none !important;">

                                                            <div class="main-block">
                                                                <label>
                                                                    <img alt="{{vibeData.title}}"
                                                                         class="pro-image"
                                                                         src="{{vibeData.image}}">
                                                                    {{vibeData.title}}

                                                                </label>
                                                                <span class="allocation">
                                                                {{vibeData.allocation}}% Allocation
                                                            </span>
                                                            </div>

                                                        </th>
                                                    </ng-container>

                                                </tr>

                                                </thead>

                                                <tbody>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">
                                                                {{vibeData.avgAge | number : '0.0-0'}}</div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">{{vibeData.followers}}</div>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">
                                                                {{vibeData.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">{{vibeData.viewers}}</div>
                                                        </td>
                                                    </ng-container>


                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">
                                                                {{vibeData.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">
                                                                &#45;&#45;
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                    <ng-container *ngFor="let vibeData of vibTableData">
                                                        <td *ngIf="vibeData.vibper && vibeData.vibesel" align="center">
                                                            <div class="tad">
                                                                &#45;&#45;
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!------------------------------------ TAB (Brand Values) ------------------------------------>

                    <div #pop3 [ngClass]="{'fade show active' : selectedTab === 'vl'}" aria-labelledby="pop3-tab" class="tab-pane fade bs-tab-content active" id="pop3"
                         role="tabpanel">
                        <div [ngStyle]="currentBrandInfo.values.length > 0 ? {'border-bottom': '1px solid #0000001A'} : {}" class="product-heading position-relative">
                            <div class="form-inline" id="vlCntr">
                                <span class="arrow-horizontal-right" id="vlRtBtn">
                                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                                </span>
                                <span class="arrow-horizontal-left" id="vlLtBtn">
                                    <img alt="arrow-down" src="http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png">
                                </span>
                                <div class="col-lg-4 mobile-view pl-0 pr-0" style="margin-bottom: 32px !important;">
                                    <div class="form-group">
                                        <ng-select [(ngModel)]="valueSelected" [clearable]="false" class="w-100">
                                            <div *ngFor="let value of allValues">
                                                <div *ngIf="value.is_predefined && value.selected">
                                                    <ng-option [value]="value.title"><img alt="{{value.title}}" src="{{value.image}}"
                                                                                          style="width: 29px; padding-right: 10px"><label
                                                            class="proTitle">{{value.title}}</label><label class="PerLabels">{{value.percentage}}%</label></ng-option>
                                                </div>
                                            </div>
                                        </ng-select>
                                    </div>
                                </div>
                                <ng-container *ngFor="let value of allValues" class="desktop">
                                    <div *ngIf="value.is_predefined && value.selected" class="text-center">
                                        <div class="select-box" fragment="vl" routerLink="/edit-brand-profile">
                                            <input [checked]="value.selected" disabled hidden id="pc-{{value.id}}" type="checkbox"/>
                                            <label for="pc-{{value.id}}">
                                                <img alt="{{value.title}}" src="{{value.image}}">
                                                {{value.title}}
                                            </label>
                                        </div>
                                        <label class="productPerLabels">{{value.percentage}}%</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="date-period">
                                    <div class="fc-center">
                                        <h5 *ngIf="valcurrday">
                                            {{(startDate || currentDate) | date: 'EEEE, MMMM d, yyyy'}}</h5>
                                        <h5 *ngIf="valcurrweek">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="valcurrmonth">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="valcurryear">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label (click)="setActive('valuedayBtn', 'vafbtn'); valShowDay()" class="btn vafbtn active" id="valuedayBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers" [showWeekNumbers]="options.showWeekNumbers"
                                                       applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       id="daypicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput
                                                       name="options"
                                                       ngxDaterangepickerMd singleDatePicker="true"
                                                       type="radio"> Day
                                            </label>
                                            <label (click)="setActive('valueweekBtn', 'vafbtn'); valShoWeek()" class="btn vafbtn" id="valueweekBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       dateLimit="6"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="weekpicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       type="radio">
                                                Week
                                            </label>
                                            <label (click)="setActive('valuemonthBtn', 'vafbtn'); valShowMonth()" class="btn vafbtn" id="valuemonthBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers"
                                                       applyLabel="Okay"
                                                       autocomplete="off" dateLimit="30"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day"
                                                       matInput
                                                       name="options" ngxDaterangepickerMd
                                                       type="radio">
                                                Month
                                            </label>
                                            <label (click)="setActive('valueyearBtn', 'vafbtn'); valShowYear(); myValue.open()" class="btn vafbtn" id="valueyearBtn"
                                                   style="line-height: 28px">
                                                <mat-select #myValue style="visibility:hidden;width: 0">
                                                    <mat-option (click)="selectYear(year,'val')"
                                                                *ngFor="let year of years"
                                                                [value]="year">{{ year }}
                                                    </mat-option>
                                                </mat-select>
                                                Year
                                            </label>
                                            <label (click)="setActive('valCustomBtn', 'vafbtn');ctShowCustom();" class="ngx-daterangepicker-action btn vafbtn" id="valCustomBtn"
                                                   style="width:60px">
                                                <input #customDate (datesUpdated)="selectedChange($event)"
                                                       [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="false"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="customDate"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       opens="right"
                                                       type="radio"
                                                />
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Cardds------------------------------------------------ -->
                        <div class="row no-gutters metric-containers">
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Follower Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{valBrandProfile.followerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="valBrandProfile?.followerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : valBrandProfile.followerMatchprev >= 0,
                                    'negative' : valBrandProfile.followerMatchprev  < 0}" class="percent">
                                                <p *ngIf="valBrandProfile?.followerMatchprev===0" style="color: #3F9194">+/-</p>
                                                {{valBrandProfile.followerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="valBrandProfile?.followerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely your followers match your values.</p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Profile Viewer Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{valBrandProfile.profileViewerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="valBrandProfile?.profileViewerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : valBrandProfile.profileViewerMatchprev >= 0,
                                    'negative' : valBrandProfile.profileViewerMatchprev  < 0}" class="percent">
                                                <p *ngIf="valBrandProfile?.profileViewerMatchprev===0" style="color: #3F9194">+/-</p>
                                                {{valBrandProfile.profileViewerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="valBrandProfile?.profileViewerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely a user who clicks on your profile matches your values.</p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-blue">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Click-through Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">

                                            <div class="card-data">
                                                <h1>{{valBrandProfile.clickThroughMatch | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="valBrandProfile?.clickThroughMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : valBrandProfile.clickThroughMatchprev >= 0,
                                    'negative' : valBrandProfile.clickThroughMatchprev  < 0}" class="percent">
                                                    <p *ngIf="valBrandProfile?.clickThroughMatchprev===0" style="color: #3F9194">+/-</p>
                                                    {{valBrandProfile.clickThroughMatchprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="valBrandProfile?.clickThroughMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who went to your website matched to your values.</p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-purple">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Recommenders Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">
                                            <div class="card-data">
                                                <h1>{{valBrandProfile.recommendations | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="valBrandProfile?.recommendationsprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : valBrandProfile.recommendationsprev >= 0,
                                    'negative' : valBrandProfile.recommendationsprev  < 0}" class="percent">
                                                    <p *ngIf="valBrandProfile?.recommendationsprev===0" style="color: #3F9194">+/-</p>
                                                    {{valBrandProfile.recommendationsprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="valBrandProfile?.recommendationsprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who recommended your brand matches your values.</p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /table ------------------------------------------------ -->
                        <!-- Main row -->
                        <div *ngIf="currentBrandInfo.values.length > 0" class="row table-desktop">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table  mb-0" style="max-height: 502px;">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th class="tab-heading">Values Breakdown</th>
                                                    <th class="text-center" style="border-right: 1px solid #dee2e6;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <ng-container *ngFor="let productValues of valTableData">
                                                    <tr *ngIf="productValues.valper && productValues.valuesel" style="border-top: 1px solid #dee2e6; !important;">
                                                        <th class="toplist d-flex flex-row align-items-center" scope="row">
                                                            <img alt=" {{productValues.title}}" class="stories-image img-fluid" src="{{productValues.image}}">
                                                            <div class="top-list">
                                                                <div class="top-name">
                                                                    <h2 class="title">{{productValues.title}}</h2>
                                                                    <p [ngClass]="{'positive' : productValues.allocation >= 0,
                                                           'negative' : productValues.allocation < 0}" class="sub-title">
                                                                        {{productValues.allocation}}% Allocation</p>
                                                                </div>
                                                            </div>
                                                        </th>

                                                        <td align="center">
                                                            <div class="tad">{{productValues.avgAge | number : '0.0-0'}}
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{productValues.followers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productValues.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{productValues.viewers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{productValues.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">--</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                --
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="currentBrandInfo.values.length > 0" class="row table-mobile">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <div class="tab-heading">Values Breakdown</div>

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table" style="margin-bottom: 0rem !important;">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th style="position: sticky;left: 0; background-color: #ffffff;border-top: none !important;"></th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <th *ngIf="valueData.valper && valueData.valuesel" style="background-color: #ffffff; border-top: none !important;">

                                                            <div class="main-block">
                                                                <label>
                                                                    <img alt="{{valueData.title}}"
                                                                         class="pro-image"
                                                                         src="{{valueData.image}}">
                                                                    {{valueData.title}}

                                                                </label>
                                                                <span class="allocation">
                                                                {{valueData.allocation}}% Allocation
                                                            </span>
                                                            </div>

                                                        </th>
                                                    </ng-container>

                                                </tr>

                                                </thead>

                                                <tbody>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">
                                                                {{valueData.avgAge | number : '0.0-0'}}</div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">{{valueData.followers}}</div>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">
                                                                {{valueData.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">{{valueData.viewers}}</div>
                                                        </td>
                                                    </ng-container>


                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">
                                                                {{valueData.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">
                                                                &#45;&#45;
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                    <ng-container *ngFor="let valueData of valTableData">
                                                        <td *ngIf="valueData.valper && valueData.valuesel" align="center">
                                                            <div class="tad">
                                                                &#45;&#45;
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!------------------------------------ TAB (Brand Social Causes) ------------------------------------>

                    <div #pop4 [ngClass]="{'fade show active' : selectedTab === 'sc'}" aria-labelledby="pop4-tab" class="tab-pane fade bs-tab-content active" id="pop4"
                         role="tabpanel">
                        <div [ngStyle]="currentBrandInfo.social_causes.length > 0 ? {'border-bottom': '1px solid #0000001A'} : {}" class="product-heading">
                            <div class="form-inline">
                                <div *ngIf="socialCausesCount > 0" class="col-lg-4 mobile-view pl-0 pr-0" style="margin-bottom: 32px !important;">
                                    <div class="form-group">
                                        <ng-select [(ngModel)]="socailSelected" [clearable]="false" class="w-100">
                                            <div *ngFor="let socialCause of allSocialCauses">
                                                <div *ngIf="socialCause.is_predefined && socialCause.selected">
                                                    <ng-option [value]="socialCause.title"><img alt="{{socialCause.title}}" src="{{socialCause.image}}"
                                                                                                style="width: 29px; padding-right: 10px"><label
                                                            class="proTitle">{{socialCause.title}}</label>
                                                    </ng-option>
                                                </div>
                                            </div>
                                        </ng-select>
                                    </div>
                                </div>
                                <div *ngIf="socialCausesCount == 0" class="col-lg-4 mobile-view pl-0 pr-0" style="margin-bottom: 32px !important;">
                                    <div class="form-group">
                                        <button class="social-btn" fragment="sc" routerLink="/edit-brand-profile" type="button"><img alt="plus-img" class="plus"
                                                                                                                                     src="assets/images/Path@2x.png">&nbsp;Add
                                            Social Cause
                                        </button>
                                    </div>
                                </div>
                                <div class="desktop">
                                    <div class="row">
                                        <ng-container *ngFor="let socialCause of allSocialCauses">
                                            <div *ngIf="socialCause.is_predefined && socialCause.selected" class="col-6 col-md-6  mb-4">
                                                <label class="social-chkbox" fragment="sc" routerLink="/edit-brand-profile">{{socialCause.title}}
                                                    <input [checked]="socialCause.selected" disabled type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- Other Social Cause Input -->
                                <ng-container *ngFor="let socialCause of allSocialCauses" class="desktop">
                                    <div *ngIf="!socialCause.is_predefined && socialCause.is_other && socialCause.selected" class="col-6 col-md-3 p-0 mb-4">
                                        <label class="social-chkbox">{{socialCause.title}}
                                            <input [checked]="socialCause.selected" type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="date-period">
                                    <div class="fc-center">
                                        <h5 *ngIf="sccurrday">
                                            {{(startDate || currentDate) | date: 'EEEE, MMMM d, yyyy'}}</h5>
                                        <h5 *ngIf="sccurrweek">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="sccurrmonth">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <h5 *ngIf="sccurryear">
                                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                                        </h5>
                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label (click)="setActive('scdayBtn', 'dcfbtn'); scShowDay()" class="btn dcfbtn active" id="scdayBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       id="daypicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput
                                                       name="options"
                                                       ngxDaterangepickerMd singleDatePicker="true"
                                                       type="radio"> Day
                                            </label>
                                            <label (click)="setActive('scweekBtn', 'dcfbtn'); scShowWeek()" class="btn dcfbtn" id="scweekBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       dateLimit="6"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="weekpicker"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       type="radio"> Week
                                            </label>
                                            <label (click)="setActive('scmonthBtn', 'dcfbtn'); scShowMonth()" class="btn dcfbtn" id="scmonthBtn">
                                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="options.linkedCalendars"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers"
                                                       applyLabel="Okay"
                                                       autocomplete="off" dateLimit="30"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day"
                                                       matInput
                                                       name="options" ngxDaterangepickerMd
                                                       type="radio">
                                                Month
                                            </label>
                                            <label (click)="setActive('scyearBtn', 'dcfbtn'); scShowYear();mySocial.open()" class="btn dcfbtn" id="scyearBtn"
                                                   style="line-height: 28px">
                                                <mat-select #mySocial style="visibility:hidden;width: 0">
                                                    <mat-option (click)="selectYear(year,'sc')"
                                                                *ngFor="let year of years"
                                                                [value]="year">{{ year }}
                                                    </mat-option>
                                                </mat-select>
                                                Year
                                            </label>
                                            <label (click)="setActive('scCustomBtn', 'dcfbtn');ctShowCustom();" class="ngx-daterangepicker-action btn pcfbtn" id="scCustomBtn"
                                                   style="width:60px">
                                                <input #customDate (datesUpdated)="selectedChange($event)"
                                                       [(ngModel)]="selected"
                                                       [(ngModel)]="selected"
                                                       [autoApply]="options.autoApply"
                                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                                       [customRangeDirection]="options.customRangeDirection"
                                                       [linkedCalendars]="false"
                                                       [lockStartDate]="options.lockStartDate"
                                                       [maxDate]="maxDate"
                                                       [showCancel]="options.showCancel"
                                                       [showClearButton]="options.showClearButton"
                                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                                       autocomplete="off"
                                                       emptyWeekRowClass="empty-week"
                                                       firstDayOfNextMonthClass="first-next-day"
                                                       firstMonthDayClass="first-day" id="customDate"
                                                       lastDayOfPreviousMonthClass="last-previous-day"
                                                       lastMonthDayClass="last-day" matInput name="options"
                                                       ngxDaterangepickerMd
                                                       opens="right"
                                                       type="radio"
                                                />
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- /Cards------------------------------------------------ -->
                        <div class="row no-gutters metric-containers">
                            <div class="ol-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Follower Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{scBrandProfile.followerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="scBrandProfile?.followerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : scBrandProfile.followerMatchprev >= 0,
                                    'negative' : scBrandProfile.followerMatchprev  < 0}" class="percent">
                                                <p *ngIf="scBrandProfile?.followerMatchprev===0" style="color: #3F9194">+/-</p>
                                                {{scBrandProfile.followerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="scBrandProfile?.followerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely your followers match your social causes.</p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="ol-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="card mb-5">
                                    <div class="card-head">
                                        <h3 class="card-title">Profile Viewer Match</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="cd-body">
                                        <div class="card-data">
                                            <h1>{{scBrandProfile.profileViewerMatch | number : '0.0-0'}}%</h1>
                                            <h5 *ngIf="scBrandProfile?.profileViewerMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : scBrandProfile.profileViewerMatchprev >= 0,
                                    'negative' : scBrandProfile.profileViewerMatchprev  < 0}" class="percent">
                                                <p *ngIf="scBrandProfile?.profileViewerMatchprev===0" style="color: #3F9194">+/-</p>
                                                {{scBrandProfile.profileViewerMatchprev | number : '0.0-0'}}%
                                            </h5>
                                            <h5 *ngIf="scBrandProfile?.profileViewerMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                        </div>
                                        <p>How closely a user who clicks on your profile matches your social causes.
                                        </p>
                                    </div>
                                    <!-- /.card-body -->
                                </div>
                            </div>
                            <div class="ol-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-blue">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Click-through Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">

                                            <div class="card-data">
                                                <h1>{{scBrandProfile.clickThroughMatch | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="scBrandProfile?.clickThroughMatchprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : scBrandProfile.clickThroughMatchprev >= 0,
                                    'negative' : scBrandProfile.clickThroughMatchprev  < 0}" class="percent">
                                                    <p *ngIf="scBrandProfile?.clickThroughMatchprev === 0" style="color: #3F9194">+/-</p>
                                                    {{scBrandProfile.clickThroughMatchprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="scBrandProfile?.clickThroughMatchprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who went to your website matched to your social causes.
                                            </p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                            <div class="ol-sm-6 col-md-6 col-lg-4 col-xl card-wrap">
                                <div class="panel panel-purple">
                                    <div class="card mb-5">
                                        <div class="card-head">
                                            <h3 class="card-title">Recommenders Match</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="cd-body">
                                            <div class="card-data">
                                                <h1>{{scBrandProfile.recommendations | number : '0.0-0'}}%</h1>
                                                <h5 *ngIf="scBrandProfile?.recommendationsprev !== 0 && currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : scBrandProfile.recommendationsprev >= 0,
                                    'negative' : scBrandProfile.recommendationsprev  < 0}" class="percent">
                                                    <p *ngIf="scBrandProfile?.recommendationsprev===0" style="color: #3F9194">+/-</p>
                                                    {{scBrandProfile.recommendationsprev | number : '0.0-0'}}%
                                                </h5>
                                                <h5 *ngIf="scBrandProfile?.recommendationsprev === 0 && currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                                            </div>
                                            <p>How closely a user who recommended your brand matches your social causes.
                                            </p>
                                        </div>
                                        <!-- /.card-body -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /table------------------------------------------------ -->
                        <!-- Main row -->
                        <div *ngIf="currentBrandInfo.social_causes.length > 0" class="row table-desktop">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table  mb-0" style="max-height: 502px;">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th class="tab-heading">Social Causes Breakdown</th>
                                                    <th class="text-center" style="border-right: 1px solid #dee2e6;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <th class="text-center" scope="col" style="border-right: 1px solid #dee2e6;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <th class="text-center" scope="col">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <ng-container *ngFor="let scdata of scTableData">
                                                    <tr *ngIf="scdata.scper && scdata.scsel" style="border-top: 1px solid #dee2e6; !important;">
                                                        <th class="toplist d-flex flex-row align-items-center" scope="row">
                                                            <div class="top-list">
                                                                <div class="top-name" style="padding-bottom: 20px;padding-top: 10px;">
                                                                    <h2 class="title">{{scdata.title}}</h2>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <td align="center">
                                                            <div class="tad">{{scdata.avgAge | number : '0.0-0'}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{scdata.followers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">
                                                                {{scdata.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{scdata.viewers}}</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">{{scdata.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">--</div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="tad">--
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="currentBrandInfo.social_causes.length > 0" class="row table-mobile">
                            <div class="col-lg-12 mb-4">
                                <div class="cad">

                                    <div class="tab-heading">Social Causes Breakdown</div>

                                    <ngx-simplebar style="overflow-x: hidden">
                                        <div class="table-responsive-lg">
                                            <table class="table" style="margin-bottom: 0rem !important;">
                                                <colgroup>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                    <col class=""/>
                                                    <col class="grey"/>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th style="position: sticky;left: 0; background-color: #ffffff; border-top: none !important;"></th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <th *ngIf="scData.scper && scData.scsel" style="background-color: #ffffff; border-top: none !important;">

                                                            <div class="main-block">
                                                                <label>
                                                                    {{scData.title}}

                                                                </label>
                                                            </div>

                                                        </th>
                                                    </ng-container>

                                                </tr>

                                                </thead>

                                                <tbody>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="avg-color">Avg Age</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">
                                                                {{scData.avgAge | number : '0.0-0'}}</div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of<br> Followers</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">{{scData.followers}}</div>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color">% of Total<br> Followers</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">
                                                                {{scData.perOfFollwers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of<br> Viewers</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">{{scData.viewers}}</div>
                                                        </td>
                                                    </ng-container>


                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color">% of Total<br> Viewers</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">
                                                                {{scData.perOfViewers | number : '0.0-0'}}%
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of Users<br> Agree</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">
                                                                &#45;&#45;
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                <tr>

                                                    <th scope="row" style="position: sticky;left: 0; background-color: #ffffff;">
                                                        <div class="Head-color"># of Users<br> Disagree</div>
                                                    </th>
                                                    <ng-container *ngFor="let scData of scTableData">
                                                        <td *ngIf="scData.scper && scData.scsel" align="center">
                                                            <div class="tad">
                                                                &#45;&#45;
                                                            </div>
                                                        </td>
                                                    </ng-container>

                                                </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                    </ngx-simplebar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
