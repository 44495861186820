import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactUsService} from '../shared/services/ContactUs/contact-us.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import {Title} from '@angular/platform-browser';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {AuthService} from '../shared/services/auth/auth.service';
import {PreviousRouteService} from '../shared/services/previous-route.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import Timer = NodeJS.Timer;

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent implements OnInit {

    constructor(public fb: FormBuilder,
                private contactUsService: ContactUsService,
                private toastr: ToastrService,
                private titleService: Title,
                private authService: AuthService,
                private previousRouteService: PreviousRouteService,
                private router: Router,
                private locationService: Location
    ) {

        this.authService.currentUser.subscribe((x) => (this.currentUser = x));

        this.contactUsForm = this.fb.group({
            email: new FormControl('', {
                validators: [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
                updateOn: 'blur'
            }),
            fullName: ['', [Validators.required, Validators.minLength(3)]],
            msg: ['', [Validators.required]]
        });
    }

    get cuf() {
        return this.contactUsForm.controls;
    }
    maxChars = 250;
    role = '';
    chars = 0;
    contactUsForm: FormGroup;
    contactUsFormSubmitted: boolean;
    currentUser: AuthenticatedUser;
    cdnUrl = environment.webStaticResources;
    previousUrl = '';
    mailSent = false;
    sending = false;
    showAlertMessage = false;
    showAlertErrorMassage = false;
    alertMessage = '';
    alertTimeout: Timer;
    alertErrorTimeout: Timer;

    ngOnInit() {
        this.previousUrl = this.previousRouteService.getPreviousUrl();
    }

    validateAllFormFields(formGroup: FormGroup) {         // {1}
        Object.keys(formGroup.controls).forEach(field => {  // {2}
            const control = formGroup.get(field);             // {3}
            if (control instanceof FormControl) {             // {4}
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {        // {5}
                this.validateAllFormFields(control);            // {6}
            }
        });
    }

    contactUs() {
        this.sending = true;
        if (this.contactUsFormSubmitted) {
            return false;
        } else {
            this.contactUsFormSubmitted = true;
            if (this.contactUsForm.valid) {
                const fullName = document.getElementById(`fullName`) as HTMLInputElement;
                const email = document.getElementById(`email`) as HTMLInputElement;
                // const type = document.getElementById(`type`) as HTMLInputElement;
                const message = document.getElementById(`msg`) as HTMLInputElement;

                const contactUs = {
                    full_name: fullName.value,
                    email: email.value,
                    type: 'brand',
                    message: message.value,
                };

                this.contactUsService.ContactSubmissions(contactUs).subscribe(res => {
                    this.mailSent = true;
                    this.sending = false;
                    (document.getElementById(`fullName`) as HTMLInputElement).value = '';
                    (document.getElementById(`email`) as HTMLInputElement).value = '';
                    // (document.getElementById(`type`) as HTMLInputElement).value = '';
                    (document.getElementById(`msg`) as HTMLInputElement).value = '';
                    // window.location.reload();
                    // return false;
                    setTimeout(() => {
                        if (this.previousUrl === '/contact-us') {
                            this.locationService.back();
                        } else {
                            this.router.navigateByUrl(this.previousUrl);
                        }
                    }, 600);

                }, err => {
                    this.showAlertError('Something went wrong! Please try again later.');
                    this.mailSent = false;
                    this.sending = false;
                });


            } else {
                this.contactUsFormSubmitted = false;
                this.validateAllFormFields(this.contactUsForm);
                this.mailSent = false;
                this.sending = false;
                return false;
            }
        }
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    openDropdown() {
        document.getElementById('topNav').classList.toggle('show');
    }
    showAlert(message: string) {
        this.showAlertErrorMassage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertMessage = true;
        this.alertMessage = message;
        this.alertTimeout = setTimeout(() => {
            this.showAlertMessage = false;
            this.alertMessage = '';
        }, 5000);
    }

    showAlertError(message: string) {
        this.showAlertMessage = false;
        clearTimeout(this.alertTimeout);
        clearTimeout(this.alertErrorTimeout);
        this.showAlertErrorMassage = true;
        this.alertMessage = message;
        this.alertErrorTimeout = setTimeout(() => {
            this.showAlertErrorMassage = false;
            this.alertMessage = '';
        }, 5000);
    }

    hideAlert() {
        this.showAlertMessage = false;
        this.showAlertErrorMassage = false;
        this.alertMessage = '';
    }

}
