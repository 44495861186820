export class UsStatesModel {

    private usStates = [
        {value: '', label: ''},
        {value: 'AL', label: 'Alabama'},
        {value: 'AK', label: 'Kaunas'},
        {value: 'AZ', label: 'Arizona'},
        {value: 'AR', label: 'Arkansas'},
        {value: 'CA', label: 'California'},
        {value: 'CO', label: 'Colorado'},
        {value: 'CT', label: 'Connecticut'},
        {value: 'DE', label: 'Delaware'},
        {value: 'DC', label: 'District Of Columbia'},
        {value: 'FL', label: 'Florida'},
        {value: 'GA', label: 'Georgia'},
        {value: 'HI', label: 'Hawaii'},
        {value: 'ID', label: 'Idaho'},
        {value: 'IL', label: 'Illinois'},
        {value: 'IN', label: 'Indiana'},
        {value: 'IA', label: 'Iowa'},
        {value: 'KS', label: 'Kansas'},
        {value: 'KY', label: 'Kentucky'},
        {value: 'LA', label: 'Louisiana'},
        {value: 'ME', label: 'Maine'},
        {value: 'MD', label: 'Maryland'},
        {value: 'MA', label: 'Massachusetts'},
        {value: 'MI', label: 'Michigan'},
        {value: 'MN', label: 'Minnesota'},
        {value: 'MS', label: 'Mississippi'},
        {value: 'MO', label: 'Missouri'},
        {value: 'MT', label: 'Montana'},
        {value: 'NE', label: 'Nebraska'},
        {value: 'NV', label: 'Nevada'},
        {value: 'NH', label: 'New Hampshire'},
        {value: 'NJ', label: 'New Jersey'},
        {value: 'NM', label: 'New Mexico'},
        {value: 'NY', label: 'New York'},
        {value: 'NC', label: 'North Carolina'},
        {value: 'ND', label: 'North Dakota'},
        {value: 'OH', label: 'Ohio'},
        {value: 'OK', label: 'Oklahoma'},
        {value: 'OR', label: 'Oregon'},
        {value: 'PA', label: 'Pennsylvania'},
        {value: 'RI', label: 'Rhode Island'},
        {value: 'SC', label: 'South Carolina'},
        {value: 'SD', label: 'South Dakota'},
        {value: 'TN', label: 'Tennessee'},
        {value: 'TX', label: 'Texas'},
        {value: 'UT', label: 'Utah'},
        {value: 'VT', label: 'Vermont'},
        {value: 'VA', label: 'Virginia'},
        {value: 'WA', label: 'Washington'},
        {value: 'WV', label: 'West Virginia'},
        {value: 'WI', label: 'Wisconsin'},
        {value: 'WY', label: 'Wyoming'},
        {value: 'others', label: 'Others'},
    ];

    private countries = [
        {label: 'Australia', value: 'AU'},
        {label: 'Austria', value: 'AT'},
        {label: 'Belgium', value: 'BE'},
        {label: 'Bulgaria', value: 'BG'},
        {label: 'Brazil ', value: 'BR'},
        {label: 'Canada', value: 'CA'},
        {label: 'Cyprus', value: 'CY'},
        {label: 'Czech Republic', value: 'CZ'},
        {label: 'Denmark', value: 'DK'},
        {label: 'Estonia', value: 'EE'},
        {label: 'Finland', value: 'FI'},
        {label: 'France', value: 'FR'},
        {label: 'Germany', value: 'DE'},
        {label: 'Greece', value: 'GR'},
        {label: 'Hong Kong', value: 'HK'},
        {label: 'India', value: 'IN'},
        {label: 'Ireland', value: 'IE'},
        {label: 'Italy', value: 'IT'},
        {label: 'Japan', value: 'JP'},
        {label: 'Latvia', value: 'LV'},
        {label: 'Lithuania', value: 'LT'},
        {label: 'Luxembourg', value: 'LU'},
        {label: 'Malaysia', value: 'MY'},
        {label: 'Malta', value: 'MT'},
        {label: 'Mexico ', value: 'MX'},
        {label: 'Netherlands', value: 'NL'},
        {label: 'New Zealand', value: 'NZ'},
        {label: 'Norway', value: 'NO'},
        {label: 'Poland', value: 'PL'},
        {label: 'Portugal', value: 'PT'},
        {label: 'Romania', value: 'RO'},
        {label: 'Singapore', value: 'SG'},
        {label: 'Slovakia', value: 'SK'},
        {label: 'Slovenia', value: 'SI'},
        {label: 'Spain', value: 'ES'},
        {label: 'Sweden', value: 'SE'},
        {label: 'Switzerland', value: 'CH'},
        {label: 'United Kingdom', value: 'GB'},
        {label: 'United States', value: 'US'}
    ];

    getUsStates() {
        return this.usStates.slice();
    }

    getCountries() {
        return this.countries.slice();
    }
}
