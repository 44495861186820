import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {ToggleSidebarService} from '../shared/services/toggle-sidebar.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-dash-side-menu',
    templateUrl: './dash-side-menu.component.html',
    styleUrls: ['./dash-side-menu.component.scss']
})
export class DashSideMenuComponent implements OnInit {
    cdnUrl = environment.webStaticResources;

    menuItems: any[];
    show = -1;
    hide = -1;
    isOpen = false;
    menus = [];
    public innerWidth: any;
    scrollBarOptions = {
        autoHide: false, scrollbarMinSize: 100
    };
    visible = false;

    // tslint:disable-next-line:max-line-length
    constructor(private sideMenuService: ToggleSidebarService, private el: ElementRef, private router: Router, private route: ActivatedRoute) {
        this.sideMenuService.toggleStatus.subscribe(status => {
            this.isOpen = status;
        });

        // ========= ./START/ Show hidden pages if passed visible=true in parameter =========
        // by passing visible=false these can can be hidden again

        if (route.snapshot.queryParams.visible) {
            this.setLinksVisibility(route.snapshot.queryParams.visible);
        } else if (router.url.includes('visible=true')) {
            this.setLinksVisibility('true');
        } else if (router.url.includes('visible=false')) {
            this.setLinksVisibility('false');
        } else if (localStorage.getItem('visible')) {
            this.setLinksVisibility(localStorage.getItem('visible'));
        }

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (route.snapshot.queryParams.visible) {
                    this.setLinksVisibility(route.snapshot.queryParams.visible);
                }
            }
        });

        // ========= ./END/ Show hidden pages if passed visible=true in query parameter =========

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
        if (this.innerWidth > 991) {
            this.isOpen = false;
        }
    }

    // Close all dropdowns when user clicks anywhere else on document
    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    handleOutsideClick(event) {
        if (!this.el.nativeElement.contains(event.target)) {
            document.getElementById('submenu-1').classList.remove('show');
            document.getElementById('submenu-2').classList.remove('show');
            const parent = this.el.nativeElement; // parent element
            // parent.classList.remove('show');
            const child = [].filter.call(this.el.nativeElement.children, (ele) => {
                return ele.classList.contains('sub-menu');
            });
            // Identify the child element on dropdown clicked- dropdown menu
            //
            // if (child.length) {
            //     child[0].classList.remove('show');
            // }
        }
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
    }

    toggleSidebar() {
        // document.getElementById('sidebar').classList.toggle('active');
        this.isOpen = false;
    }

    openSubmenu1() {
        document.getElementById('submenu-1').classList.toggle('show');
        document.getElementById('submenu-2').classList.remove('show');
        document.getElementsByClassName('customCaret')[0].classList.toggle('rotateCaret');
    }

    // hideMenu1() {
    //     document.getElementById('submenu-1').classList.toggle('hide');
    // }
    openSubmenu2() {
        document.getElementById('submenu-2').classList.toggle('show');
        document.getElementById('submenu-1').classList.remove('show');
        document.getElementsByClassName('customCaret')[1].classList.toggle('rotateCaret');
    }

    closePanels() {
        document.getElementById('submenu-1').classList.remove('show');
        document.getElementById('submenu-2').classList.remove('show');
        this.toggleSidebar();
    }

    hardNavigate(route: string) {
        window.location.href = `community-profile/${route}`;
    }

    /*
     Private method to set Links visibility based on passed parameter
     */
    private setLinksVisibility(item: any) {
        switch (item) {
            case 'true':
                localStorage.setItem('visible', 'true');
                this.visible = true;
                break;
            case 'false':
                localStorage.setItem('visible', 'false');
                this.visible = false;
                break;
            default:
                break;
        }

    }
}

