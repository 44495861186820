<div class="content-wrapper">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap">
            <img class="side-img w-auto" src="{{cdnUrl}}/assets/images/brand-account-images/image6-fliped.png" style="margin-left: 20px">
        </div>
        <div class="sub-head pr-2">
            <h1>Performance</h1>
            <p>Hello, {{currentUser.user.branduserprofile.brand.business_name}}. Let's see how your content is performing.
            </p>
        </div>

    </div>
    <div class="content main-section">
        <div class="row">
            <div class="col-lg-12">
                <div class="date-period">
                    <div class="fc-center">
                        <h5 *ngIf="currday">{{startDate || currentDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="currweek">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="currmonth">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <h5 *ngIf="curryear">
                            {{startDate | date: 'EEEE, MMMM d, yyyy'}}{{' - '}}{{endDate | date: 'EEEE, MMMM d, yyyy'}}
                        </h5>
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">

                            <label (click)="setActive('dayBtn', 'pcfbtn'); ctShowDay()" class="btn pcfbtn active" id="dayBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply" [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars" [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate" [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers" [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay" autocomplete="off"
                                       emptyWeekRowClass="empty-week" firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day" id="daypicker"
                                       lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day" matInput
                                       name="options" ngxDaterangepickerMd opens="left" singleDatePicker="true"
                                       type="radio"> Day
                            </label>
                            <label (click)="setActive('weekBtn', 'pcfbtn'); ctShoWeek()" class="btn pcfbtn" id="weekBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply" [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars" [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate" [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers" [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay" autocomplete="off"
                                       dateLimit="6" emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day" firstMonthDayClass="first-day"
                                       id="weekpicker" lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day" matInput name="options" ngxDaterangepickerMd
                                       opens="left" type="radio">
                                Week
                            </label>
                            <label (click)="setActive('monthBtn', 'pcfbtn'); ctShowMonth()" class="btn pcfbtn" id="monthBtn">
                                <input (datesUpdated)="selectedChange($event)" [(ngModel)]="selected"
                                       [autoApply]="options.autoApply" [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="options.linkedCalendars" [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate" [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers" [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay" autocomplete="off"
                                       dateLimit="30" emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day" firstMonthDayClass="first-day"
                                       lastDayOfPreviousMonthClass="last-previous-day" lastMonthDayClass="last-day"
                                       matInput name="options" ngxDaterangepickerMd opens="center" type="radio">
                                Month
                            </label>
                            <label (click)="setActive('yearBtn', 'pcfbtn'); myProduct.open()" class="btn pcfbtn" id="yearBtn" style="line-height: 28px">
                                <mat-select #myProduct [(ngModel)]="selected" style="visibility:hidden;width: 0">
                                    <mat-option (click)="selectYear(year,'pc')"
                                                *ngFor="let year of years"
                                                [value]="year">{{ year }}
                                    </mat-option>
                                </mat-select>
                                Year
                            </label>
                            <label (click)="setActive('customBtn', 'pcfbtn'); ctCustomDate()" class="ngx-daterangepicker-action btn pcfbtn" id="customBtn" style="width:60px">
                                <input #customDate (datesUpdated)="selectedChange($event)"
                                       [(ngModel)]="selected"
                                       [autoApply]="options.autoApply"
                                       [closeOnAutoApply]="options.closeOnAutoApply"
                                       [customRangeDirection]="options.customRangeDirection"
                                       [linkedCalendars]="false"
                                       [lockStartDate]="options.lockStartDate"
                                       [maxDate]="maxDate"
                                       [showCancel]="options.showCancel"
                                       [showClearButton]="options.showClearButton"
                                       [showISOWeekNumbers]="options.showISOWeekNumbers"
                                       [showWeekNumbers]="options.showWeekNumbers" applyLabel="Okay"
                                       autocomplete="off"
                                       emptyWeekRowClass="empty-week"
                                       firstDayOfNextMonthClass="first-next-day"
                                       firstMonthDayClass="first-day" id="customDate"
                                       lastDayOfPreviousMonthClass="last-previous-day"
                                       lastMonthDayClass="last-day" matInput name="options"
                                       ngxDaterangepickerMd
                                       opens="right"
                                       type="radio"
                                />
                                Custom
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cards -->
        <div class="row no-gutters metric-containers">
            <div class="col-sm-6 col-md-6 col-xl-3 card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Journey Videos </h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="cd-body">
                        <div class="card-data">
                            <h1>{{ performanceJourneyData?.post_views }}</h1>
                            <h5 *ngIf="performanceJourneyData?.percentage !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : performanceJourneyData?.percentage>=0,
                                    'negative' : performanceJourneyData?.percentage< 0}" class="percent">
                                <p *ngIf="performanceJourneyData?.percentage===0" style="color: #3F9194;">+/-</p>

                                {{ performanceJourneyData?.percentage }}%
                            </h5>
                            <h5 *ngIf="performanceJourneyData?.percentage === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                        </div>
                        <p>Total # of times your journey videos have been viewed.</p>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-3 card-wrap">
                <div class="card mb-5">
                    <div class="card-head">
                        <h3 class="card-title">Moments</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="cd-body">
                        <div class="card-data">
                            <h1>{{ performanceMomentData?.post_views }}</h1>
                            <h5 *ngIf="performanceMomentData?.percentage !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : performanceMomentData?.percentage>=0,
                                    'negative' : performanceMomentData?.percentage< 0}" class="percent">
                                <p *ngIf="performanceMomentData?.percentage===0" style="color: #3F9194;">+/-</p>


                                {{ performanceMomentData?.percentage}}%
                            </h5>

                            <h5 *ngIf="performanceMomentData?.percentage === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                        </div>
                        <p>Total # of times users viewed your moments.</p>
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-3 card-wrap">
                <div class="panel panel-blue">
                    <div class="card mb-5">
                        <div class="card-head">
                            <h3 class="card-title">Replies</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="cd-body">

                            <div class="card-data">
                                <h1>{{ performanceRepliesData?.replies_count }}</h1>
                                <h5 *ngIf="performanceRepliesData?.percentage !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : performanceRepliesData?.percentage>=0,
                                        'negative' : performanceRepliesData?.percentage< 0}" class="percent">
                                    <p *ngIf="performanceRepliesData?.percentage===0" style="color: #3F9194;">+/-</p>

                                    {{ performanceRepliesData?.percentage }}%
                                </h5>

                                <h5 *ngIf="performanceRepliesData?.percentage === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                            </div>
                            <p>Total # of replies on your content.
                            </p>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-3 card-wrap">
                <div class="panel panel-purple">
                    <div class="card mb-5">
                        <div class="card-head">
                            <h3 class="card-title">Likes</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="cd-body">
                            <div class="card-data">
                                <h1>{{ performancelikesData?.likes_count }}</h1>
                                <h5 *ngIf="performancelikesData?.percentage !== 0 && this.currentFilter !== 'CUSTOM'" [ngClass]="{'positive' : performancelikesData?.percentage>=0,
                                        'negative' : performancelikesData?.percentage< 0}" class="percent">
                                    <p *ngIf="performancelikesData?.percentage===0" style="color: #3F9194;">+/-</p>

                                    {{ performancelikesData?.percentage}}%
                                </h5>
                                <h5 *ngIf="performancelikesData?.percentage === 0 && this.currentFilter !== 'CUSTOM'" class="count positive">No Change</h5>
                            </div>
                            <p>Total # of users who have liked your content.
                            </p>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
        <!-- cards -->

        <!-- moments -->
        <div class="col-xl-12">
            <div class="row cards">
                <div class="card-head">
                    <div class="card-heads responsive-head">
                        Moments
                    </div>
                    <ng-container *ngIf="momentData">
                        <ng-container *ngIf="momentData?.length > 0">
                            <div class="mobile d-inline-block text">
                            <span appDropdown class="dropdown" style="margin-top: 16px;">
                                    <a aria-expanded="false" aria-haspopup="true" class="nav-tags"
                                       data-toggle="dropdown" id="DropdownMenuLink" role="button"
                                       style="font-size: 15px"><div class="ellipse-first">{{btnName | titlecase}}</div>
                                        <img alt="caret" class="caret" src="assets/images/arrow-up.svg"
                                             style="width: 25px; color: #1E537E;">
                                     </a>
                                    <div aria-labelledby="DropdownMenuLink" class="dropdown-menu dropdown-menu-side">
                                         <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='best performing moments'}"
                                            class="dropdown-item" name="best performing moments">Best Performing Moments
                                         </a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="tabFilter($event)" [ngClass]="{'active': btnName==='all moments'}"
                                           class="dropdown-item" name="all moments">
                                            All Moments
                                        </a>
                                    </div>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <ng-container *ngIf="!isMomentsLoading; else momentsLoading">
                    <div [ngClass]="{scrollMoment:bestPerformingMoment}" class="img-section">
                        <ng-container *ngIf="momentData">
                            <ng-container *ngFor="let item of momentData | paginate: { id:'foo',itemsPerPage: 5, currentPage: p }">

                                <div (click)="openPostMetrics(item)" class="img-card">
                                    <div class="img-part">
                                        <div *ngIf="item?.post_content_type==='text_post'" [style.backgroundImage]="'url('+ item?.text +')'" class="blurred-bg"></div>
                                        <div *ngIf="item?.post_content_type==='video_post'" [style.backgroundImage]="'url('+ item?.video_thumbnail +')'" class="blurred-bg"></div>
                                        <div *ngIf="item?.post_content_type==='image_post'" [style.backgroundImage]="'url('+ item?.image +')'" class="blurred-bg"></div>
                                        <img *ngIf="item?.post_content_type==='text_post'" [src]="item?.text">
                                        <img *ngIf="item?.post_content_type==='video_post'" [src]="item?.video_thumbnail">
                                        <img *ngIf="item?.post_content_type==='image_post'" [src]="item?.image">
                                    </div>
                                    <div class="content-sections">
                                        <div [title]="item?.post_type.post_type" class="recommendation">{{ item?.post_type.post_type}}</div>
                                        <div class="inner-content">
                                            <div>{{ item?.likes_count | numberConversion:1}} likes</div>
                                            <div>{{ item?.views_count | numberConversion:1}} views</div>
                                            <!--                                        <div>&#45;&#45; click through</div>-->
                                            <div>Posted on: {{ item?.created_at |date:'MM/dd/yy @ h:mm a' | lowercase }}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="momentData && momentData?.length<1" class="no-data">
                            <img [src]="cdnUrl+'/assets/images/camera.svg'" alt="icon">
                            <h2>
                                Share a Moment With Your Community!
                            </h2>
                            <p style="max-width: 100%">
                                Upload a Moment post in the app.
                            </p>
                        </div>
                    </div>
                </ng-container>
                <ng-template #momentsLoading>
                    <div class="p-5 mx-auto">
                        <div class="tiny-loader"></div>
                    </div>
                </ng-template>
                <pagination-controls (pageChange)="momentpageChanged(p = $event)"
                                     *ngIf="momentData?.length>0 && !bestPerformingMoment && !isMomentsLoading"
                                     autoHide="true"
                                     directionLinks="true" id="foo"
                                     maxSize="5"
                                     nextLabel=""
                                     previousLabel=""
                                     responsive="true"
                                     screenReaderCurrentLabel="You're on page"
                                     screenReaderPageLabel="page"
                                     screenReaderPaginationLabel="Pagination"
                                     style="margin-left: auto; margin-right: auto; margin-top: 60px">
                </pagination-controls>
            </div>
        </div>
        <!-- moments -->

        <!-- recommendation -->
        <div class="col-xl-12">
            <div class="row cards" >
                <div class="card-head">
                    <div class="card-heads responsive-head">
                        Recommendations
                    </div>
                    <ng-container *ngIf="recommendData">
                        <ng-container *ngIf="recommendData?.length > 0">
                            <div class="mobile d-inline-block text">
                            <span appDropdown class="dropdown" style="margin-top: 16px;">
                                    <a aria-expanded="false" aria-haspopup="true" class="nav-tags"
                                       data-toggle="dropdown" id="DropdownMenuLink"
                                       role="button" style="font-size: 15px"> <div>{{recommendBtnName | titlecase}}</div>
                                        <img alt="caret" class="caret" src="assets/images/arrow-up.svg"
                                             style="width: 25px; color: #1E537E">
                                </a>
                                    <div aria-labelledby="DropdownMenuLink" class="dropdown-menu dropdown-menu-side">
                                        <a (click)="recommendtabFilter($event)" [ngClass]="{'active': recommendBtnName==='Best Performing User Recommendations'}"
                                           class="dropdown-item" name="Best Performing User Recommendations">Best Performing User Recommendations</a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="recommendtabFilter($event)" [ngClass]="{'active': recommendBtnName==='all user recommendations'}"
                                           class="dropdown-item" name="all user recommendations">All User Recommendations</a>

                                    </div>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <ng-container *ngIf="!isRecommendationsLoading; else recommendationsLoading">
                    <div [ngClass]="{scrollMoment:bestRecommendations}" class="img-section">
                        <ng-container *ngIf="recommendData">
                            <ng-container *ngFor="let item of recommendData | paginate: {  id:'recommend',itemsPerPage: 10, currentPage: m }">
                                <div (click)="openPostMetrics(item)" class="img-card">
                                    <div class="img-part">
                                        <div *ngIf="item?.post_content_type==='text_post'" [style.backgroundImage]="'url('+ item?.text +')'" class="blurred-bg"></div>
                                        <div *ngIf="item?.post_content_type==='video_post'" [style.backgroundImage]="'url('+ item?.video_thumbnail +')'" class="blurred-bg"></div>
                                        <div *ngIf="item?.post_content_type==='image_post'" [style.backgroundImage]="'url('+ item?.image +')'" class="blurred-bg"></div>
                                        <img *ngIf="item?.post_content_type==='text_post'" [src]="item?.text">
                                        <img *ngIf="item?.post_content_type==='video_post'" [src]="item?.video_thumbnail">
                                        <img *ngIf="item?.post_content_type==='image_post'" [src]="item?.image">
                                    </div>
                                    <div class="content-sections">
                                        <div [title]="item?.description" class="recommendation">{{ item?.description }}
                                        </div>
                                        <div class="inner-content">
                                            <div>{{ item?.likes_count | numberConversion:1}} likes</div>
                                            <div>{{ item?.views_count | numberConversion:1}} views</div>
                                            <!--                                        <div>&#45;&#45; click through</div>-->
                                            <div>Posted on: {{ item?.created_at |date:'MM/dd/yy @ h:mm a' | lowercase }}</div>
                                        </div>
                                        <!--                                <div class="recommendation">Link to your website</div>-->
                                        <!--                                <div class="inner-content">Add</div>-->

                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="recommendData?.length<1" class="no-data">
                            <img [src]="cdnUrl+'/assets/images/recommendation.svg'" alt="icon">
                            <h2>
                                Authentic Recommendations Are Coming Your Way!
                            </h2>
                            <p class="show-on-desktop">
                                In the meantime, share more about your story by creating a Moment post in the app or upload a Journey Video.
                            </p>
                            <p class="show-on-mobile">
                                In the meantime, create a Moment post in the app or upload a Journey video.
                            </p>
                        </div>
                    </div>
                </ng-container>
                <ng-template #recommendationsLoading>
                    <div class="p-5 mx-auto">
                        <div class="tiny-loader"></div>
                    </div>
                </ng-template>
                <pagination-controls (pageChange)="recommendpageChanged(m = $event)"
                                     *ngIf="recommendData?.length>0 && !bestRecommendations && !isRecommendationsLoading"
                                     autoHide="true"
                                     directionLinks="true" id="recommend"
                                     maxSize="5"
                                     nextLabel=""
                                     previousLabel=""
                                     responsive="true"
                                     screenReaderCurrentLabel="You're on page"
                                     screenReaderPageLabel="page"
                                     screenReaderPaginationLabel="Pagination"
                                     style="margin-left: auto; margin-right: auto; margin-top: 60px">
                </pagination-controls>
            </div>
        </div>
        <!-- recommendation -->

        <!-- journey-video -->
        <div class="col-xl-12">
            <div class="row cards">
                <div class="card-head">
                    <div class="card-heads responsive-head">
                        Journey Videos
                    </div>
                    <ng-container *ngIf="journeyData">
                        <ng-container *ngIf="journeyData?.length > 0">
                            <div class="mobile d-inline-block text">
                            <span appDropdown class="dropdown" style="margin-top: 16px;">
                                    <a aria-expanded="false" aria-haspopup="true" class="nav-tags"
                                       data-toggle="dropdown" id="DropdownMenuLink"
                                       role="button" style="font-size: 15px"> <div class="ellipse">{{journeyBtnName | titlecase}}</div>
                                        <img alt="caret" class="caret" src="assets/images/arrow-up.svg"
                                             style="width: 25px; color: #1E537E">
                                </a>
                                    <div aria-labelledby="DropdownMenuLink" class="dropdown-menu dropdown-menu-side">
                                         <a (click)="journeytabFilter($event)" [ngClass]="{'active': journeyBtnName==='Best Performing Journey Videos'}"
                                            class="dropdown-item" name="Best Performing Journey Videos">Best Performing Journey Videos</a>
                                        <div class="dropdown-divider"></div>
                                        <a (click)="journeytabFilter($event)" [ngClass]="{'active': journeyBtnName==='all journey videos'}"
                                           class="dropdown-item" name="all journey videos">All Journey Videos</a>
                                    </div>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <ng-container *ngIf="!isJourneyLoading; else journeyLoading">
                    <div [ngClass]="{scrollMoment:bestJourneyVideos}" class="img-section">
                        <ng-container *ngIf="journeyData">
                            <ng-container *ngFor="let item of journeyData | paginate: {  id:'journey',itemsPerPage: 5, currentPage: n }">
                                <div (click)="openPostMetrics(item)" class="img-card">
                                    <div class="img-part">
                                        <div [style.backgroundImage]="'url('+ item?.video_thumbnail +')'" class="blurred-bg"></div>
                                        <img [src]="item?.video_thumbnail">
                                    </div>
                                    <div class="content-sections">
                                        <div [title]="item?.post_type?.post_type" class="recommendation">
                                            {{ item?.post_type?.post_type}}
                                        </div>
                                        <div class="inner-content">
                                            <div>{{ item?.likes_count | numberConversion:1}} likes</div>
                                            <div>{{ item?.views_count | numberConversion:1}} views</div>
                                            <!--                                        <div>&#45;&#45; click through</div>-->
                                            <div>Posted on: {{ item?.created_at |date:'MM/dd/yy @ h:mm a' | lowercase }}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="journeyData?.length==0" style="margin-left: 20px;padding-bottom: 15px">No Data Found</div>
                    </div>
                </ng-container>
                <ng-template #journeyLoading>
                    <div class="p-5 mx-auto">
                        <div class="tiny-loader"></div>
                    </div>
                </ng-template>
                <pagination-controls (pageChange)="journeypageChanged(n = $event)"
                                     *ngIf="journeyData?.length>0 && !bestJourneyVideos && !isJourneyLoading"
                                     autoHide="true"
                                     directionLinks="true" id="journey"
                                     maxSize="5"
                                     nextLabel=""
                                     previousLabel=""
                                     responsive="true"
                                     screenReaderCurrentLabel="You're on page"
                                     screenReaderPageLabel="page"
                                     screenReaderPaginationLabel="Pagination"
                                     style="margin-left: auto; margin-right: auto; margin-top: 60px">
                </pagination-controls>
            </div>
        </div>
        <!-- journey-video -->

    </div>
</div>
