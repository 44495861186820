<main class="contactUs">

    <app-header-homepage></app-header-homepage>

    <div class="contactUsForm">
        <div class="vector-img">
            <img alt="" src="{{cdnUrl}}/assets/images/contact-us-vector.png">
        </div>
        <div class="container-fluid">
            <div class="row main-brand-form justify-content-center align-items-center">
                <div class="col-md-8 pb-5">
                    <div class="form-wrapper">

                        <h4>Contact Us!</h4>

                        <!-- FORM 1 -->
                        <form [formGroup]="contactUsForm">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input [ngClass]="{ 'is-invalid': (cuf.fullName.dirty || contactUsFormSubmitted) && cuf.fullName.errors }"
                                           class="form-control" formControlName="fullName" id="fullName"
                                           placeholder="Full Name*"
                                           required
                                           type="text">
                                    <div *ngIf="(cuf.fullName.dirty || contactUsFormSubmitted) && cuf.fullName.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="cuf.fullName.errors.required">Full Name is required</div>
                                        <div *ngIf="cuf.fullName.errors.full_name">Full Name is invalid!</div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <input [ngClass]="{ 'is-invalid': (cuf.email.dirty || contactUsFormSubmitted) && cuf.email.errors }"
                                           aria-describedby="button-addon2" aria-label="user email" aria-required="true"
                                           class="form-control" formControlName="email"
                                           id="email" placeholder="Email*"
                                           required
                                           type="email">
                                    <div *ngIf="(cuf.email.dirty || contactUsFormSubmitted) && cuf.email.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="cuf.email.errors.required">Email is required</div>
                                        <div *ngIf="cuf.email.errors.email">Email is invalid!</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group position-relative">
                                <p class="ca-label" for="msg">Message*</p>
                                <textarea
                                        [(ngModel)]="role"
                                        [maxlength]="maxChars"
                                        [ngClass]="{ 'is-invalid': (cuf.msg.dirty || contactUsFormSubmitted) && cuf.msg.errors }"
                                        class="form-control" formControlName="msg"
                                        id="msg"
                                        name="msg"
                                        onkeyup="countChar(this)"
                                        style="-webkit-line-clamp: 3;">
                                   ></textarea>
                                <label [ngClass]="{'adjust-counter': cuf.msg.dirty && cuf.msg.invalid}" class="wordCount">
                                    {{role.length}}/{{maxChars}} Characters
                                </label>
                                <div *ngIf="(cuf.msg.dirty || contactUsFormSubmitted) && cuf.msg.errors"
                                     class="invalid-feedback">
                                    <div *ngIf="cuf.msg.errors.required">Message is required</div>
                                    <div *ngIf="cuf.msg.errors.email">Message is invalid!</div>
                                </div>
                                <label class="info">*Mandatory information</label>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-md-4">
                                    <button (click)="contactUs()" class="btn-block" type="button">
                                    <span *ngIf="mailSent" class="mail-check">
                                        <img src="assets/images/icons/check.svg">
                                    </span>
                                        <span *ngIf="sending" class="mail-check">
                                        <img src="assets/images/icons/loader.svg">
                                    </span>
                                        <span *ngIf="!mailSent && !sending">
                                        Submit
                                    </span>
                                    </button>
                                    <label class="info-mob">*Mandatory information</label>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

</main>

<app-footer-homepage></app-footer-homepage>


