import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {ProductCategories, ProductCategory} from './product-category';
import {Brand} from '../brand/brand';

@Injectable({
    providedIn: 'root',
})
export class ProductCategoryService extends ApiService {
    selected = {
        startDate: null,
        endDate: null,
    };
    currentFilter: String = 'WEEK';

    constructor(private http: HttpClient) {
        super();
    }

    CreateProductCategory(data): Observable<ProductCategory> {
        const newData = {
            title: data.title
        };
        return this.http
            .post<ProductCategory>(`${this.apiUrl}/product_categories/`, JSON.stringify(newData), this.httpOptions)
            .pipe();
    }

    GetAllProductCategories(): Observable<ProductCategories> {
        return this.http.get<ProductCategories>(`${this.apiUrl}/product_categories/`).pipe();
    }

    FetchFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/category_follower/?date_from=${startDate}&date_to=${endDate}&category_id=${id}`)
            .pipe();
    }

    FetchRecommendersData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/category_recommendation/?date_from=${startDate}&date_to=${endDate}&category_id=${id}`)
            .pipe();
    }

    FetchJourneyData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/category_viewer/?date_from=${startDate}&date_to=${endDate}&category_id=${id}`)
            .pipe();
    }

    FetchProductCategoryQuestion(id, type, count_type) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/daily_questions/${id}/?type=${type}&count_type=${count_type}`)
            .pipe();
    }
}
