import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ValueService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    CreateValue(data) {
        const newData = {
            title: data.title
        };
        return this.http.post(`${this.apiUrl}/values/`, JSON.stringify(newData), this.httpOptions).pipe();
    }
    GetAllValues() {
        return this.http.get(`${this.apiUrl}/values/`).pipe();
    }
    FetchFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/value_follower/?date_from=${startDate}&date_to=${endDate}&value_id=${id}`)
            .pipe();
    }
    FetchRecommendersData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/value_recommendation/?date_from=${startDate}&date_to=${endDate}&value_id=${id}`)
            .pipe();
    }
    FetchJourneyData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/value_viewer/?date_from=${startDate}&date_to=${endDate}&value_id=${id}`)
            .pipe();
    }
}
ValueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValueService_Factory() { return new ValueService(i0.ɵɵinject(i1.HttpClient)); }, token: ValueService, providedIn: "root" });
