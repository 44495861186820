import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {BrandProfile} from './brand-profile';

@Injectable({
    providedIn: 'root'
})
export class BrandProfileService extends ApiService {
    selected = {
        startDate: null,
        endDate: null,
    };
    currentFilter = 'CUSTOM';

    constructor(
        private http: HttpClient
    ) {
        super();
    }

// <--------------------------------------------------ProductCategory stats Apis-------------------------------------------------->
    GetBrandProductCategoryFollowerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandProductCategoryProfileViewerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandProductCategoryClickThroughMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/Click_through_view_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandProductCategoryRecommendationsMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/recommendation_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandProductCategoryBreakdownMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/product_categories_breakdown/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

// <--------------------------------------------------vibes Causes stats Apis-------------------------------------------------->
    GetBrandVibeFollowerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/vibe_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandVibeProfileViewerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/vibe_profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandVibeClickThroughMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/vibe_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandVibeRecommendationsMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/vibe_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandVibeBreakdownMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/vibes_breakdown_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

// <--------------------------------------------------values stats Apis-------------------------------------------------->

    GetBrandValueFollowerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/value_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandValueProfileViewerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/value_profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandValueClickThroughMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/value_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandValueRecommendationsMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/value_recommendation_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandValueBreakdownMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/value_breakdown_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    // <--------------------------------------------------Scoial Causes stats Apis-------------------------------------------------->
    GetBrandSocialCausesFollowerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/social_cause_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandSocialCausesProfileViewerMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/social_cause_profile_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandSocialCausesClickThroughMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/social_cause_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandSocialCausesRecommendationsMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/social_cause_recommendation_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandSocialCausesBreakdownMatch(startDate, endDate): Observable<BrandProfile> {
        return this.http.get<BrandProfile>(`${this.apiUrl}/brands/reports/social_cause_breakdown_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
}
