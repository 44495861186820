<header class="mainHeader">
    <nav class="navbar navbar-expand-md navbar-light fixed-top">
        <div class="container">
            <a href="https://tailorie.com/brands-home" class="navbar-brand">
                tailorie <span>Brands</span>
            </a>
            <button class="navbar-toggler" data-target="#topNav" data-toggle="collapse" aria-controls="topNav"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div id="topNav" class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto text-center">
                    <li class="nav-item">
                        <a class="nav-link px-2" href="https://tailorie.com">Contact Us</a>
                    </li>
                    <li class="nav-item" *ngIf="!currentUser">
                        <a class="nav-link" href="#" routerLink="/login">Login</a>
                    </li>
                    <li class="nav-item" *ngIf="!currentUser">
                        <a class="nav-link join-btn" href="#" routerLink="/brandaccount">Join Now</a>
                    </li>
                    <li class="nav-item ml-4" *ngIf="currentUser">
                        <a class="nav-link join-btn" href="/dashboard">Go to Dashboard</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<main>
    <!-- Hero Area -->
    <section class="wrap-404">
        <div class="container">
            <h1>
                Whoops!
            </h1>
            <p>
                We can’t seem to find the page you’re looking for.
            </p>
            <div class="d-block" style="text-align: center;">
                <a class="nav-link join-btn" href="#"  routerLink="/company-page">Home</a>
            </div>
            <h5>Error code: 404</h5>
        </div>
    </section>
<!--    <div class="bottom-image">-->
<!--        <img class="error-image" src="assets/images/Group 1.png">-->
<!--    </div>-->

</main>
