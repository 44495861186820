<div class="cards">
    <div class="header">
        <div class="head-inner">
            Post Metrics
        </div>
        <div (click)="popUpMetricsClose()" class="icon">
            <img src="../../../../assets/images/close-icon.png">
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-7 col-lg-6 pr-0 pb-lg-4">
            <div class="left-part">
                <div class="img-section">
                    <img *ngIf="data?.post_content_type==='text_post'" [src]="data?.text">
                    <img *ngIf="data?.post_content_type==='video_post'" [src]="data?.video_thumbnail">
                    <img *ngIf="data?.post_content_type==='video'" [src]="data?.video_thumbnail">
                    <img *ngIf="data?.post_content_type==='image_post'" [src]="data?.image">
                </div>
                <div class="contents">
                    <div class="content-sections">
                        <div class="content-head">
                            {{data?.post_content_type == 'text_post' ? data?.description : data?.post_type?.post_type}}
                        </div>
                        <div class="semi-content">
                            <div>{{ data?.likes_count| numberConversion:1}} likes</div>
                            <div>{{ data?.views_count}} views</div>
                            <!--                            <div>940 click through</div>-->
                        </div>
                    </div>
                    <div class="content-sections">
                        <div class="semi-content">
                            <div>Posted on:</div>
                            <div>{{ data?.created_at | date:'MM/dd/yy @ h:mm a' | lowercase }}</div>
                        </div>
                    </div>
                    <!--                    <div class="content-sections">-->
                    <!--                        <div class="semi-content">-->
                    <!--                            <div>Link to your website:</div>-->
                    <!--                            <div>{{websiteUrl}}</div>-->
                    <!--                            <button>This feature is coming soon!</button>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-5 pl-0 col-lg-6">
            <div class="right-part">
                <div class="table-header">
                    <div class="left">
                        Top Symbols
                    </div>
                    <div class="right">
                        <div class="mobile d-inline-block text">
                                <span appDropdown class="dropdown" style="margin-top: 16px;">
                                        <a aria-expanded="false" aria-haspopup="true"
                                           class="nav-tags" data-toggle="dropdown"
                                           id="DropdownMenuLink" role="button"> <div>{{ btnName | titlecase}}</div>
                                            <img alt="caret" class="caret" src="assets/images/arrow-up.svg"
                                                 style="width: 25px; color: #1E537E">
                                    </a>
                                        <div aria-labelledby="DropdownMenuLink" class="dropdown-menu dropdown-menu-side">
                                            <a (click)="recommendtabFilter($event)" [ngClass]="{'active': btnName==='followers'}"
                                               class="dropdown-item" name="followers">Followers</a>
                                            <div class="dropdown-divider"></div>
                                            <a (click)="recommendtabFilter($event)" [ngClass]="{'active': btnName==='viewers'}"
                                               class="dropdown-item" name="viewers"
                                               style="margin-top: 10px;">Viewers</a>
                                            <div class="dropdown-divider"></div>
                                            <a (click)="recommendtabFilter($event)" [ngClass]="{'active': btnName==='recommenders'}"
                                               class="dropdown-item" name="recommenders"
                                               style="margin-top: 10px;">Recommenders</a>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                    </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="isLoading" class="center">
                    <mat-progress-spinner class="custom-spinner"
                                          diameter="40"
                                          mode="indeterminate">
                    </mat-progress-spinner>
                </div>
                <div class="sct-scroll">
                    <ng-container *ngIf="topSymbols?.length>0">
                        <div *ngFor="let item of topSymbols" class="item-sections">
                            <div class="left-item">
                                <div class="item-img">
                                    <img [src]="item?.symbol">
                                </div>
                                <div class="item-content">
                                    <div class="label-name">{{item?.name}} </div>
                                    <div class="label-value">{{ item?.type.toString().replace("Category", "Product Category") }}</div>
                                </div>
                            </div>
                            <div class="right-item">
                                <div class="rupee">
                                    {{item?.count | numberConversion:1}}
                                </div>
                                <div class="rupee-value">
                                    {{ btnName | titlecase }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <p *ngIf="topSymbols?.length==0" class="no-data">No Data Found</p>
                </div>
            </div>
        </div>
    </div>
</div>
