<div class="content-wrapper">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap align-items-center">
            <img class="side-img w-auto" src="{{cdnUrl}}/assets/images/CuratedIntroductions.png"
                 style="margin-left: 20px">
        </div>
        <div>
            <h1>Account Info</h1>
            <p>Update key contact information and access payment information.</p>
        </div>
    </div>
    <!------------------------------------ TABS ------------------------------------>
    <div class="row mx-md-5 position-relative">
        <section class="col-lg-12 py-0 pr-0">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a #link1 (click)="showTabContent(pop1,link1)" aria-controls="pop1" aria-selected="true"
                       class="nav-item nav-link bs-tab-link active" data-toggle="tab" href="javascript:void(0)"
                       id="pop1-tab" role="tab">Account</a>
                    <a #link2 (click)="showTabContent(pop2,link2)" *ngIf="visible" aria-controls="pop2" aria-selected="true"
                       class="nav-item nav-link bs-tab-link" data-toggle="tab" href="javascript:void(0)" id="pop2-tab"
                       role="tab">Billing Info</a>

                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">

                <!------------------------------------ TAB (Account) ------------------------------------>
                <div #pop1 aria-labelledby="pop1-tab" class="tab-pane bs-tab-content fade show active" id="pop1"
                     role="tabpanel">
                    <div class="create-account-tab">

                        <h4>Brand Contact</h4>

                        <div class="row main-brand-form">

                            <div class="col-md-5">

                                <p class="sub-head">Contact Details</p>

                                <div class="form-group">
                                    <p class="ca-label">Contact Name</p>
                                    <input [(ngModel)]="contactDetails.contact_name" class="form-control" type="text">
                                </div>

                                <div class="form-group">
                                    <p class="ca-label">Title</p>
                                    <input [(ngModel)]="contactDetails.title" class="form-control" type="text">
                                </div>

                                <div class="form-group">
                                    <p class="ca-label">Phone</p>
                                    <input [(ngModel)]="contactDetails.phone" appNumbersOnly class="form-control"
                                           type="text">
                                </div>

                            </div>

                            <div class="col-md-5 ml-md-5">
                                <p class="sub-head">Contact Details</p>
                                <p>
                                    Please note, this email address and password will also be used to login to
                                    the Tailorie app.
                                </p>

                                <div class="form-group">
                                    <p class="ca-label">Email Address</p>
                                    <p>{{currentUser.user.email}}</p>
                                </div>
                                <form (keydown.enter)="onResetFormSubmit()">
                                    <div class="form-group">
                                        <!--                                    <p class="ca-label">Password</p>-->
                                        <!--                                    <input class="form-control" required type="password">-->
                                        <div class="input-group mb-3 mx-auto">
                                            <input [value]="currentUser.user.email" aria-label="user email"
                                                   aria-required="true" class="form-control" hidden id="reset-email"
                                                   name="reset-email" placeholder="myemail@email.com" required
                                                   type="email">
                                            <div class="input-group-append">
                                                <button (click)="onResetFormSubmit()"
                                                        class="button-text d-inline-block mt-2" id="button-addon2"
                                                        type="submit">
                                                    Reset Password
                                                </button>
                                            </div>
                                        </div>
                                        <!--                                    <a (click)="onResetFormSubmit()" class="ca-label d-inline-block mt-2"-->
                                        <!--                                       href="javascript:void(0)">Reset Password</a>-->
                                        <!--                                    <a (click)="openModal('resetPasswordModal')" class="ca-label d-inline-block mt-2"-->
                                        <!--                                       href="javascript:void(0)">Reset Password</a>-->
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div class="row main-brand-form">

                            <div class="col-md-5">
                                <p class="sub-head">Brand Billing Address</p>

                                <div class="form-group">
                                    <p class="ca-label">Address Line 1</p>
                                    <input [(ngModel)]="billingAddress.address_line_1" class="form-control" required
                                           type="text">
                                </div>
                                <div class="form-group">
                                    <p class="ca-label">Address Line 2</p>
                                    <input [(ngModel)]="billingAddress.address_line_2" class="form-control" required
                                           type="text">
                                </div>
                                <div class="form-group">
                                    <p class="ca-label">City</p>
                                    <input [(ngModel)]="billingAddress.city" class="form-control" required type="text">
                                </div>
                                <div class="row">

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <p class="ca-label">Country</p>
                                            <ng-select #country
                                                       (change)="loadStates(selectedCountry.label); toggleSaveBar()"
                                                       [(ngModel)]="selectedCountry" [clearable]="false"
                                                       [items]="defaultCountryBindingList">
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <p class="ca-label">State</p>
                                            <ng-select (change)="toggleSaveBar()" [(ngModel)]="selectedState"
                                                       [clearable]="false" [items]="defaultStateBindingsList">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <p class="ca-label">Zip Code</p>
                                            <input [(ngModel)]="billingAddress.zip_code" class="form-control"
                                                   mask="00000-0000">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class="saveBar">-->
                        <!--                            <button (click)="submitData()" class="btn btn-primary" type="submit">save</button>-->
                        <!--                        </div>-->
                    </div>
                    <div *ngIf="showSaveBar" [@slideInOut] class="saveBar">
                        <div class="d-flex align-items-center">
                            <p class="msg">
                                <span *ngIf="showAlertErrorMassage" class="alertErrorMessage">
                                    <img alt="error" src="assets/images/ErrorAlert.png">{{alertMessage}}
                                </span>
                                <span *ngIf="showAlertMessage" class="alertMessage">
                                    <img alt="success" src="assets/images/checkMark.png"> {{alertMessage}}
                                </span>
                            </p>
                            <button (click)="submitData()" class="btn btnsave" type="submit">save</button>
                        </div>
                    </div>
                </div>

                <!------------------------------------ TAB (Billing Info) ------------------------------------>
                <div #pop2 aria-labelledby="pop2-tab" class="tab-pane bs-tab-content fade" id="pop2" role="tabpanel">

                    <div class="create-account-tab bi-tab">

                        <div class="row" style="margin-top: 38px;">
                            <div class="col-md-5">
                                <div class="bi-card">
                                    <div class="bi-card-header notice">
                                        <h6>
                                            Your invite-only, 3-month trial expires
                                        </h6>
                                    </div>
                                    <div class="bi-card-body">
                                        <h1>
                                            December 20, 2020
                                        </h1>
                                        <p>
                                            Following your trial, opt-in to our monthly <br> subscription platform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-5">
                                <div class="bi-card">
                                    <div class="bi-card-header">
                                        <h6>
                                            Monthly Subscription
                                        </h6>
                                    </div>
                                    <div class="bi-card-body">
                                        <h1 class="amount">
                                            $150 <span>USD</span>
                                        </h1>
                                        <p class="per-month">
                                            Per month
                                        </p>
                                        <button class="w-100">
                                            upgrade now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-5">
                                <div class="bi-card">
                                    <div class="bi-card-header">
                                        <h6>
                                            Invoices
                                        </h6>
                                    </div>
                                    <div class="bi-card-body">
                                        <p class="my-0">
                                            you have no invoices to view
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FAQ Accordians -->
                        <div class="row mt-5">
                            <div class="col-lg-12 pr-5">
                                <header class="faq-header">
                                    Frequently Asked Questions
                                </header>

                                <!-- Panel One -->

                                <div class="panel">

                                    <div (click)="togglePanel('panel-body-1')" class="panel-head">
                                        <label class="panel-label">
                                            What is the cost?
                                        </label>
                                    </div>

                                    <div class="panel-body" id="panel-body-1">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </div>

                                </div>

                                <!-- Panel Two -->

                                <div class="panel">

                                    <div (click)="togglePanel('panel-body-2')" class="panel-head">
                                        <label class="panel-label">
                                            When will I be charged?
                                        </label>
                                        <!-- Panel title or Label here -->
                                    </div>

                                    <div class="panel-body" id="panel-body-2">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        <br>
                                        <button>
                                            Learn More
                                        </button>
                                    </div>

                                </div>

                                <!-- Panel Three -->

                                <div class="panel">

                                    <div (click)="togglePanel('panel-body-3')" class="panel-head">
                                        <label class="panel-label">
                                            Is there a discount for referring other brands to Tailorie?
                                        </label>
                                        <!-- Panel title or Label here -->
                                    </div>
                                    <div class="panel-body" id="panel-body-3">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        <br>
                                        <button>
                                            Learn More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </section>
    </div>
</div>

<!-- Reset PASSWORD MODAL -->
<div class="modal text-center open" id="resetPasswordModal">

    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">

            <div class="modal-body">

                <span (click)="closeModal('resetPasswordModal')" data-dismiss="modal"
                      style="display: block; text-align: right">
                    <img alt="cancel" src="assets/images/cancel.png" style="cursor: pointer; width: 16px;">
                </span>
                <div class="contact-us-img">
                    <img alt="contact-us" src="assets/images/contact-us.svg" style="width: 190px;margin-bottom: 18px;">
                </div>
                <h3 class="mb-2">Check Your Email</h3>
                <p>Next steps have been sent to the email<br> address associated with this account</p>

                <form (keydown.enter)="onResetFormSubmit()">
                    <div class="input-group mx-auto">
                        <input [value]="currentUser.user.email" aria-label="user email" aria-required="true"
                               class="form-control" hidden id="reset-email" name="reset-email"
                               placeholder="myemail@email.com" required type="email">
                        <div class="input-group-append">
                            <a (click)="onResetFormSubmit()" class="send-label d-inline-block" id="button-addon2"
                               type="submit">
                                Send me a new email.
                            </a>
                        </div>
                    </div>
                    <!--                    <div class="row">-->
                    <!--                        <div class="offset-3 col-6">-->
                    <!--                            <div *ngIf="forgotPasswordSuccessMessage" class="alert alert-success">-->
                    <!--                                {{forgotPasswordSuccessMessageText}}-->
                    <!--                            </div>-->
                    <!--                            <div *ngIf="forgotPasswordErrorMessage" class="alert alert-danger">-->
                    <!--                                {{forgotPasswordErrorMessageText}}-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </form>

            </div>
        </div>
    </div>

</div>
