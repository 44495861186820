import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {OverviewService} from '../../shared/services/overview.service';
import {DatePipe} from '@angular/common';
import {AuthService} from '../../shared/services/auth/auth.service';
import {AuthenticatedUser} from '../../shared/services/auth/authenticateduser';
import {BrandProfileService} from '../../shared/services/Brand Profile/brand-profile.service';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
    currentUser: AuthenticatedUser;
    currentFilter = 'CUSTOM';
    startDate: any;
    endDate: any;
    brandId = this.authService.currentUserValue.user.branduserprofile.brand.id;
    public selected = {startDate: null, endDate: null};
    currday = true;
    currweek = false;
    currmonth = false;
    curryear = false;
    isLoading = true;
    options: any = {
        autoApply: false,
        alwaysShowCalendars: false,
        showCancel: false,
        showClearButton: false,
        linkedCalendars: true,
        singleDatePicker: true,
        showWeekNumbers: false,
        showISOWeekNumbers: false,
        customRangeDirection: false,
        lockStartDate: false,
        closeOnAutoApply: true,
    };
    maxDate = moment().max(moment().add(1, 'd'));
    minDate = moment().startOf('year');
    years = [];
    selectedYear: any;
    selectedTab = null;
    btnName = 'all';
    filter = 'all';
    public topFollowerCategory: any;
    public topRecommenderCategory: any;
    public topViewerCategory: any;
    public brandImportantData: any;
    public today = new Date();
    public yearLimit: any;

    constructor(private datepipe: DatePipe,
                private overviewService: OverviewService,
                private brandProfileService: BrandProfileService,
                private authService: AuthService) {
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }

    ngOnInit() {
        this.yearLimit = this.today.getFullYear();
        this.generateYears();
        this.dateSelector();
    }

    generateYears() {
        const count = this.yearLimit - 2020;
        for (let i = 0; i <= count; i++) {
            this.years.push(2020 + i);
        }
    }

    dateSelector() {
        if (
            this.brandProfileService.selected.startDate === null &&
            this.brandProfileService.currentFilter === 'CUSTOM'
        ) {
            this.selected = {startDate: null, endDate: null};
            this.currday = false;
            this.currweek = true;
            this.currmonth = false;
            this.curryear = false;
            const weekdate = new Date();
            const weekend = new Date(weekdate);
            weekend.setDate(weekdate.getDate());
            const weekstart = new Date(weekdate);
            weekstart.setDate(weekdate.getDate() - 6);
            const createdDate = this.datepipe.transform(this.currentUser.user.branduserprofile.brand.created_at, 'yyyy-MM-dd');
            this.startDate = this.datepipe.transform(createdDate, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.fetchData();
        } else {
            this.startDate = this.brandProfileService.selected.startDate;
            this.endDate = this.brandProfileService.selected.endDate;
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.fetchData();
        }

        switch (this.brandProfileService.currentFilter) {
            case 'WEEK':
                this.ctShoWeek();
                this.setActive('weekBtn', 'pcfbtn');
                break;
            case 'DAY':
                this.ctShowDay();
                this.setActive('dayBtn', 'pcfbtn');
                break;
            case 'MONTH':
                this.ctShowMonth();
                this.setActive('monthBtn', 'pcfbtn');
                break;
            case 'CUSTOM':
                this.ctCustomDate();
                this.setActive('customBtn', 'pcfbtn');
                break;
            default:
                break;
        }
    }

    tabFilter(e) {
        this.btnName = e.target.name;
        this.filter = e.target.name;
        this.overviewService.FetchImportantData(this.brandId, this.filter, this.startDate, this.endDate).subscribe(res => {
            this.brandImportantData = [];
            this.brandImportantData = res[0].topSymbols;
        });
        // this.initStats();
    }

    fetchData() {
        this.overviewService.FetchImportantData(this.brandId, this.filter, this.startDate, this.endDate).subscribe(res => {
            this.brandImportantData = [];
            this.brandImportantData = res[0].topSymbols;
            this.isLoading = false;
        });
        this.overviewService.FetchTopFollowerData(this.brandId, this.startDate, this.endDate).subscribe(res => {
            this.topFollowerCategory = res;
        });
        this.overviewService.FetchTopRecommenderData(this.brandId, this.startDate, this.endDate).subscribe(res => {
            this.topRecommenderCategory = res;
        });
        this.overviewService.FetchTopViewerData(this.brandId, this.startDate, this.endDate).subscribe(res => {
            this.topViewerCategory = res;
        });
    }

    selectYear(year, category) {
        this.startDate = year + '-01-01';
        this.endDate = year + '-12-31';
        this.fetchData();
    }

    selectedChange(event) {
        if (event.startDate === null) {
            return;
        }
        if (this.currentFilter === 'WEEK') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.fetchData();
        }
        if (this.currentFilter === 'DAY') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.fetchData();
        }
        if (this.currentFilter === 'MONTH') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.fetchData();
        }
        if (this.currentFilter === 'CUSTOM') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.fetchData();
        }
    }

    // <-------------------------------------------------- calender stats End----------------------->
    showTabContent(
        pop: HTMLDivElement,
        link: HTMLAnchorElement,
        content: string
    ) {
        const links = document.getElementsByClassName('bs-tab-link');
        const tabPanes = document.getElementsByClassName('bs-tab-content');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show', 'active');
        }
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < links.length; j++) {
            links[j].classList.remove('active');
        }

        pop.classList.add('show', 'active');
        link.classList.add('active');
        this.selected = {startDate: null, endDate: null};
    }

    setActive(selBtn: string, allBtnsClass: string) {
        const selectedBtn = document.getElementById(selBtn);
        const allBtns = document.querySelectorAll('.' + allBtnsClass);

        allBtns.forEach((elem, index) => {
            elem.classList.remove('active');
        });

        selectedBtn.classList.add('active');
    }

    ctShowDay() {
        this.currday = true;
        this.currweek = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';

            if (this.brandProfileService.currentFilter !== 'DAY') {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(
                    daydate.setDate(daystart),
                    'yyyy-MM-dd'
                );
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandProfileService.currentFilter = this.currentFilter;
            } else {
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandProfileService.currentFilter = this.currentFilter;
            }
        }
    }

    ctShoWeek() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;
        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandProfileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandProfileService.currentFilter = this.currentFilter;
            } else {
                this.dateSelector();
                this.brandProfileService.currentFilter = this.currentFilter;
            }

            this.fetchData();
        }
    }

    ctShowMonth() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = true;
        this.curryear = false;
        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandProfileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(
                    monthdate.getFullYear(),
                    monthdate.getMonth(),
                    1
                );
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(
                    monthdate.setDate(monthend),
                    'yyyy-MM-dd'
                );
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandProfileService.currentFilter = this.currentFilter;
                this.fetchData();
            } else {
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandProfileService.currentFilter = this.currentFilter;
            }
        }
    }

    ctShowYear() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = false;
        this.curryear = true;
        if (this.currentFilter !== 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.fetchData();
        }
    }

    ctCustomDate() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;
        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandProfileService.currentFilter !== 'CUSTOM') {
                // const monthdate = new Date();
                // const monthstart = new Date(
                //     monthdate.getFullYear(),
                //     monthdate.getMonth(),
                //     1
                // );
                // const monthend = monthdate.getDate() - 1;
                // this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                // this.endDate = this.datepipe.transform(
                //     monthdate.setDate(monthend),
                //     'yyyy-MM-dd'
                // );
                // this.selected = {startDate: null, endDate: null};
                // this.selected.startDate = moment(this.startDate);
                // this.selected.endDate = moment(this.endDate);
                // this.brandProfileService.currentFilter = this.currentFilter;
                // this.fetchData();
            } else {
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.fetchData();
                this.brandProfileService.currentFilter = this.currentFilter;
            }
        }
    }


}
