import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../api.service';
import {Vibe, Vibes} from './vibe';
import {ProductCategory} from '../product_category/product-category';

@Injectable({
    providedIn: 'root',
})
export class VibeService extends ApiService {
    selected = {
        startDate: null,
        endDate: null,
    };
    currentFilter: String = 'WEEK';
    constructor(private http: HttpClient) {
        super();
    }

    CreateVibes(data): Observable<Vibe> {
        const newData = {
            title: data.title
        };
        return this.http.post<Vibe>(`${this.apiUrl}/vibes/`, JSON.stringify(newData), this.httpOptions).pipe();
    }

    GetAllVibes(): Observable<Vibes> {
        return this.http.get<Vibes>(`${this.apiUrl}/vibes/`).pipe();
    }

    FetchFollowerData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/vibe_follower/?date_from=${startDate}&date_to=${endDate}&vibe_id=${id}`)
        .pipe();
    }

    FetchRecommendersData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/vibe_recommenders/?date_from=${startDate}&date_to=${endDate}&vibe_id=${id}`)
        .pipe();
    }
    FetchJourneyData(id,startDate, endDate){
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/vibe_viewers/?date_from=${startDate}&date_to=${endDate}&vibe_id=${id}`)
        .pipe();
    }
}
