import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

// tslint:disable-next-line:ban-types
declare let fbq: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Tailorie';

    constructor(private router: Router) {
        router.events.subscribe((y: NavigationEnd) => {
            if (y instanceof NavigationEnd) {
                fbq('track', 'PageView');
            }
        });
    }
}
