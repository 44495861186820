import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class VideoProcessingService {
    constructor(document) {
        this.document = document;
    }
    promptForVideo() {
        return new Promise((resolve, reject) => {
            // make file input element in memory
            const fileInput = this.document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'video/*, video/mp4, video/3gpp, ' +
                // 'video/x-flv, video/x-msvideo,' +
                ' video/m4v, application/x-mpegURL, ' +
                'video/MP2T, video/quicktime, video/x-m4v, video/wmv,video/mov';
            // fileInput.setAttribute('capture', 'camera');
            // fileInput['capture'] = 'camera';
            fileInput.addEventListener('error', event => {
                reject(event.error);
            });
            fileInput.addEventListener('change', event => {
                resolve(fileInput.files[0]);
            });
            // prompt for video file
            fileInput.click();
        });
    }
    generateThumbnail(videoFile) {
        console.log('In Video Thumbnail');
        const video = this.document.createElement('video');
        const canvas = this.document.createElement('canvas');
        const context = canvas.getContext('2d');
        return new Promise((resolve, reject) => {
            canvas.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('error', event => {
                reject(event.error);
            });
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                resolve(canvas.toDataURL());
            });
            if (videoFile.type) {
                video.setAttribute('type', videoFile.type);
            }
            video.preload = 'auto';
            video.src = window.URL.createObjectURL(videoFile);
            video.load();
        });
    }
    getDuration(e) {
        const duration = e.target.duration;
        this.videoSizeError = duration > 30;
    }
}
VideoProcessingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoProcessingService_Factory() { return new VideoProcessingService(i0.ɵɵinject(i1.DOCUMENT)); }, token: VideoProcessingService, providedIn: "root" });
