import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MomentsService} from '../../services/performance/moments/moments.service';
import {BrandProfileService} from '../../services/Brand Profile/brand-profile.service';
import {AuthenticatedUser} from '../../services/auth/authenticateduser';

@Component({
    selector: 'app-post-metrics-popup',
    templateUrl: './post-metrics-popup.component.html',
    styleUrls: ['./post-metrics-popup.component.scss']
})
export class PostMetricsPopupComponent implements OnInit {
    public topSymbols: any;
    public btnName = 'viewers';
    public websiteUrl: any;
    isLoading = true;
    currentUser: AuthenticatedUser;

    constructor(
        private momentService: MomentsService,
        private dialogRef: MatDialogRef<PostMetricsPopupComponent>,
        private brandProfileService: BrandProfileService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit() {
        this.getTopSymbolsData();
        this.websiteLink();
    }

    popUpMetricsClose() {
        this.dialogRef.close();
    }


    getTopSymbolsData() {
        // this.spinnerService.show();
        this.momentService.getViewersPostMetrics(this.data.id, this.brandProfileService.selected.startDate,
            this.brandProfileService.selected.endDate).subscribe(res => {
            this.isLoading = false;
            this.topSymbols = res;
        });
    }

    recommendtabFilter(event) {
        this.btnName = event.target.name;
    }

    websiteLink() {
        const website = JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
        this.websiteUrl = website.user.branduserprofile.brand.website;
    }
}
