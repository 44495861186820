import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SubScribeService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    // POST
    addSubscribers(data) {
        return this.http.post(`${this.apiUrl}/subscribers/`, data).pipe();
    }
}
SubScribeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubScribeService_Factory() { return new SubScribeService(i0.ɵɵinject(i1.HttpClient)); }, token: SubScribeService, providedIn: "root" });
