<ng-container *ngIf="tokenAvailable">

    <div class="container">
        <div class="row no-gutters justify-content-center">
            <div class="col-md-7 col-lg-5 col-xl-4 mt-5">
                <div class="login-box mb-5">
                    <div class="login-logo">
                        <a href="https://tailorie.com/brands-home">
                            <img alt="" src="https://webcdn.tailorie.com/assets/images/Tailorie-logo_dark.svg">
                        </a>
                    </div>
                    <h4 class="reset-paswd mt-3">Reset Password
                        <img alt="" src="https://webcdn.tailorie.com/assets/images/sunglasses-2.png">
                    </h4>
                    <!-- /.login-logo -->

                    <ng-container *ngIf="tokenValid">
                        <div class="card-body login-card-body">
                            <form [formGroup]="resetPasswordForm">
                                <div class="row">
                                    <div class="col-12 px-0">
                                        <div *ngIf="forgotPasswordSuccessMessage"
                                             [innerHTML]="forgotPasswordSuccessMessageText"
                                             class="alert alert-success">
                                        </div>
                                        <div *ngIf="forgotPasswordErrorMessage"
                                             [innerHTML]="forgotPasswordErrorMessageText"
                                             class="alert alert-danger">
                                        </div>
                                    </div>
                                </div>
                                <p class="mb-5">Please choose your new password and confirm it in
                                    the form below.</p>
                                <div class="form-group mb-5">
                                    <input [ngClass]="{ 'is-invalid': (rpf.password.dirty || resetPasswordFormSubmitted) && rpf.password.errors }"
                                           class="inp" formControlName="password" placeholder="New Password"
                                           required
                                           type="password"/>
                                    <div *ngIf="(rpf.password.dirty || resetPasswordFormSubmitted) && rpf.password.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="rpf.password.errors.required">Password is required</div>
                                        <div *ngIf="rpf.password.errors.minlength">Password should not be less than 8
                                            characters
                                        </div>
                                        <div *ngIf="rpf.password.errors.mustMatch">Password is invalid!</div>
                                    </div>
                                </div>
                                <div class="form-group mb-5">
                                    <input [ngClass]="{ 'is-invalid': (rpf.confirm_password.dirty || resetPasswordFormSubmitted) && rpf.confirm_password.errors }"
                                           class="inp" formControlName="confirm_password" placeholder="Confirm Password"
                                           required
                                           type="password"/>
                                    <div *ngIf="(rpf.confirm_password.dirty || resetPasswordFormSubmitted) && rpf.confirm_password.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="rpf.confirm_password.errors.required">Confirm Password is required
                                        </div>
                                        <div *ngIf="rpf.confirm_password.errors.mustMatch">Confirm Password should match
                                            the
                                            Password
                                        </div>
                                    </div>
                                </div>
                                <p class="mb-5 mt-3" style="font-size: 12px; color: #aaa">8 Characters minimum</p>
                                <div class="social-auth-links text-center mb-5">
                                    <button (click)="passwordResetFormOnSubmit()" *ngIf="!resetDone && !loading"
                                            [disabled]="resetPasswordForm.invalid"
                                            class="btn btn-block btn-primary create-account"
                                            type="button">
                                        Reset Password
                                    </button>
                                    <button *ngIf="loading && !resetDone"
                                            class="btn btn-block btn-primary create-account"
                                            type="button"><i class="fa fa-spinner fa-spin"></i>
                                    </button>
                                    <button *ngIf="resetDone && !loading"
                                            class="btn btn-block btn-primary create-account"
                                            type="button">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </button>
                                </div>
                            </form>

                        </div>
                        <!-- /.login-card-body -->
                    </ng-container>

                    <ng-container *ngIf="!tokenValid">
                        <div class="card-body login-card-body">
                            <div class="alert alert-warning">
                                Password Reset Token is invalid! <a routerLink="/login">Click here</a> to go to login
                                page.
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</ng-container>
