import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AllTopSymbols, BrandAdminDashboard, BrandProfileVsCustomerProfile, CommunityDemographics, FollowerList} from './brand-admin-dashboard';

@Injectable({
    providedIn: 'root'
})
export class BrandAdminDashboardService extends ApiService {
    selected = {
        startDate: null,
        endDate: null,
    };
    currentFilter: 'WEEK';

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    GetBrandFollowerCount(startDate, endDate): Observable<BrandAdminDashboard> {
        return this.http.get<BrandAdminDashboard>(`${this.apiUrl}/brands/dashboard_stats/follower_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandProfileCount(startDate, endDate): Observable<BrandAdminDashboard> {
        return this.http.get<BrandAdminDashboard>(`${this.apiUrl}/brands/dashboard_stats/brand_viewer_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandClickThroughCount(startDate, endDate): Observable<BrandAdminDashboard> {
        return this.http.get<BrandAdminDashboard>(`${this.apiUrl}/brands/dashboard_stats/brand_click_through_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandCollectionCount(startDate, endDate): Observable<BrandAdminDashboard> {
        return this.http.get<BrandAdminDashboard>(`${this.apiUrl}/brands/dashboard_stats/brand_collection_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandRecommendationsCount(startDate, endDate): Observable<BrandAdminDashboard> {
        return this.http.get<BrandAdminDashboard>(`${this.apiUrl}/brands/dashboard_stats/brand_recommendation_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandFollowerMatch(startDate, endDate): Observable<BrandProfileVsCustomerProfile> {
        return this.http.get<BrandProfileVsCustomerProfile>(`${this.apiUrl}/brands/dashboard_stats/brand_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandProfileMatch(startDate, endDate): Observable<BrandProfileVsCustomerProfile> {
        return this.http.get<BrandProfileVsCustomerProfile>(`${this.apiUrl}/brands/dashboard_stats/brand_profile_viewer_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetBrandClickThroughMatch(startDate, endDate): Observable<BrandProfileVsCustomerProfile> {
        return this.http.get<BrandProfileVsCustomerProfile>(`${this.apiUrl}/brands/dashboard_stats/brand_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetMaleToFemaleRatio(startDate, endDate): Observable<CommunityDemographics> {
        return this.http.get<CommunityDemographics>(`${this.apiUrl}/brands/dashboard_stats/follower_gender_ratio_view/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetFollowerAvgAge(startDate, endDate): Observable<CommunityDemographics> {
        return this.http.get<CommunityDemographics>(`${this.apiUrl}/brands/dashboard_stats/follower_avg_age_view/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetProfileViewerAvgAge(startDate, endDate): Observable<CommunityDemographics> {
        return this.http.get<CommunityDemographics>(`${this.apiUrl}/brands/dashboard_stats/profile_avg_age_view/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetFollowerAlsoFollow(filter: string, startDate, endDate): Observable<FollowerList> {
        return this.http.get<FollowerList>(`${this.apiUrl}/brands/dashboard_stats/follower_also_following_view/?filter_interval=${filter}&date_from=${startDate}&date_to=${endDate}`)
            .pipe();

    }

    GetAllTopSymbols(filter: string, startDate, endDate): Observable<AllTopSymbols> {
        return this.http.get<AllTopSymbols>(`${this.apiUrl}/brands/dashboard_stats/brand_follower_symbol/?filter_interval=${filter}&date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }

    GetChartData(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/brands/dashboard_stats/chart_data_view/`)
            .pipe();
    }

    GetSingleChartData(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/brands/dashboard_stats/single_chart_view/`)
            .pipe();
    }

    GetDashboardChartData(selection: string, dateFrom?: string, dateTo?: string, date?: string, year?: string): Observable<any> {
        let endPoint = `?selection=${selection}`;

        if (selection === 'day' && date) {
            endPoint = endPoint + `&date=${date}`;
        } else if (selection === 'year' && year) {
            endPoint = endPoint + `&year=${year}`;
        } else if (selection === 'week' || selection === 'month') {
            endPoint = endPoint + `&date_from=${dateFrom}&date_to=${dateTo}`;
        }

        return this.http.get<any>(`${this.apiUrl}/brands/dashboard_stats/chart_data_view/${endPoint}`).pipe();
    }

    // GetBrandAdminDashboardStats(filter: string): Observable<BrandAdminDashboard> {
    //   return this.http.get<BrandAdminDashboard>(`${this.apiUrl}/get_brand_admin_dashboard_stats/?stats_interval=${filter}`)
    //       .pipe();
    // }

}
