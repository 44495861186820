<div class="content-wrapper">
    <!------------------------------------ Main Banner------------------------------------>
    <div class="dashboard-main-banner d-flex flex-row">
        <div class="side-img-wrap">
            <img alt="brand-profile-banner-image" class="side-img" src="../../assets/images/Bitmap-community.png">
        </div>
        <div class="pr-2">
            <h1>Community Profile</h1>
            <p>Hello, {{currentUser.user.branduserprofile.brand.business_name}}! Let’s get to know your community.</p>
        </div>

    </div>
    <div class="row position-relative">

        <section class="col-lg-12 py-0 pr-0">
            <nav class="desktop" mat-align-tabs="left" mat-tab-nav-bar style="margin-left: 50px;margin-top: 20px; padding-right: 65px">
                <a #rla="routerLinkActive" *ngFor="let link of navLinks" [active]="rla.isActive" [routerLinkActiveOptions]="{exact: true}"
                   [routerLink]="link?.path" mat-tab-link disableRipple routerLinkActive>
                    <span>{{link?.label}} </span>
                </a>
            </nav>
            <div class="mobile-view pl-0 pr-0" style="margin-left: 24px;margin-right: 37px; margin-top: 30px;">
                <div class="form-group">
                    <ng-select (change)="navigateTo($event)" [(ngModel)]="selectedMenu" [clearable]="false" [searchable]="false">
                        <a *ngFor="let link of navLinks" [routerLink]="link?.path" routerLinkActive>
                            <ng-option [value]="link?.path">
                                <label>{{link?.label}}</label></ng-option>
                        </a>
                    </ng-select>
                </div>
            </div>
            <router-outlet></router-outlet>
        </section>
    </div>
</div>
