import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {AuthService} from '../shared/services/auth/auth.service';
import {BrandTopUsersService} from '../shared/services/brand_top_users/brand-top-users.service';
import {environment} from '../../environments/environment';
import {Brand} from '../shared/services/brand/brand';
import {MatDialog} from '@angular/material';
import {PostMetricsPopupComponent} from '../shared/components/post-metrics-popup/post-metrics-popup.component';
import {MomentsService} from '../shared/services/performance/moments/moments.service';
import * as moment from 'moment';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {DatePipe} from '@angular/common';
import {BrandProfileService} from '../shared/services/Brand Profile/brand-profile.service';

@Component({
    selector: 'app-performance',
    templateUrl: './performance.component.html',
    styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent implements OnInit, OnDestroy {
    currentUser: AuthenticatedUser;
    cdnUrl = environment.webStaticResources;
    collection = [1, 2, 3, 4, 5];
    brandInfo: Brand;
    brandId = this.authService.currentUserValue.user.branduserprofile.brand.id;
    journeyData: any;
    recommendData: any;
    momentData: any;
    isMomentsLoading = true;
    isRecommendationsLoading = true;
    isJourneyLoading = true;
    performanceJourneyData: any;
    performanceMomentData;
    performanceRepliesData;
    performancelikesData;
    currday = true;
    currweek = false;
    currmonth = false;
    curryear = false;
    m = 1;
    n = 1;
    p = 1;
    pageSize = 10;
    dateToday: Date = new Date();
    currentFilter = 'CUSTOM';
    currentDate = this.datepipe.transform(this.dateToday, 'yyyy-MM-dd');
    startDate: any;
    endDate: any;
    dateLimit = '7';
    selectedDay: { startDate; endDate };
    selected = {startDate: null, endDate: null};
    options: any = {
        autoApply: false,
        alwaysShowCalendars: false,
        showCancel: false,
        showClearButton: false,
        linkedCalendars: true,
        singleDatePicker: true,
        showWeekNumbers: false,
        showISOWeekNumbers: false,
        customRangeDirection: false,
        lockStartDate: false,
        closeOnAutoApply: true,
    };
    maxDate = moment().max(moment().add(1, 'd'));
    minDate = moment().startOf('year');
    years = [];
    number2;
    number1;
    selectedYear: any;
    @ViewChild(DaterangepickerDirective, {static: false})
    pickerDirective: DaterangepickerDirective;
    bestPerformingMoment = false;
    bestRecommendations = false;
    bestJourneyVideos = false;
    btnName = 'all moments';
    recommendBtnName = 'all user recommendations';
    journeyBtnName = 'all journey videos';
    public today = new Date();
    public yearLimit: any;

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private momentsService: MomentsService,
        private brandProfileService: BrandProfileService,
        private brandTopUsersService: BrandTopUsersService,
        public datepipe: DatePipe
    ) {
        this.momentData = null;
        this.recommendData = null;
        this.journeyData = null;
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    }


    ngOnInit() {

        this.yearLimit = this.today.getFullYear();
        this.datePunk();
        this.momentApiCalling();
        this.journeyApiCalling();
        this.recommendationApiCalling();
        if (window.screen.availWidth <= 411) {
            this.pageSize = 6;
        }
        this.generateYears();
    }

    ngOnDestroy(): void {
        this.dialog.closeAll();
    }

    datePunk() {
        if (
            this.brandProfileService.selected.startDate === null &&
            this.brandProfileService.currentFilter === 'CUSTOM'
        ) {
            this.selected = {startDate: null, endDate: null};
            const weekdate = new Date();
            const weekend = new Date(weekdate);
            weekend.setDate(weekdate.getDate());
            const weekstart = new Date(weekdate);
            weekstart.setDate(weekdate.getDate() - 6);

            const createdDate = this.datepipe.transform(this.currentUser.user.branduserprofile.brand.created_at, 'yyyy-MM-dd');

            this.startDate = this.datepipe.transform(createdDate, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.viewsCount();
        } else {
            this.startDate = this.brandProfileService.selected.startDate;
            this.endDate = this.brandProfileService.selected.endDate;
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.viewsCount();
        }
        switch (this.brandProfileService.currentFilter) {
            case 'WEEK':
                this.ctShoWeek();
                this.setActive('weekBtn', 'pcfbtn');
                break;

            case 'DAY':
                this.ctShowDay();
                this.setActive('dayBtn', 'pcfbtn');
                break;

            case 'MONTH':
                this.ctShowMonth();
                this.setActive('monthBtn', 'pcfbtn');
                break;

            case 'CUSTOM':
                this.ctCustomDate();
                this.setActive('customBtn', 'pcfbtn');
                break;

            default:
                break;
        }
    }

    selectedChange(event) {
        if (event.startDate === null) {
            return;
        }
        if (this.currentFilter === 'WEEK') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;

        }
        if (this.currentFilter === 'DAY') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
        }
        if (this.currentFilter === 'MONTH') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
        }
        if (this.currentFilter === 'CUSTOM') {
            this.brandProfileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(
                event.startDate._d,
                'yyyy-MM-dd'
            );
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
        }
    }

    selectYear(year, category) {
        this.startDate = year + '-01-01';
        this.endDate = year + '-12-31';
        this.viewsCount();
    }

    setActive(selBtn: string, allBtnsClass: string) {
        const selectedBtn = document.getElementById(selBtn);
        const allBtns = document.querySelectorAll('.' + allBtnsClass);

        allBtns.forEach((elem, index) => {
            elem.classList.remove('active');
        });

        selectedBtn.classList.add('active');
    }

    ctShowDay() {
        this.currday = true;
        this.currweek = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';

            if (this.brandProfileService.currentFilter !== 'DAY') {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(
                    daydate.setDate(daystart),
                    'yyyy-MM-dd'
                );
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandProfileService.currentFilter = this.currentFilter;
                this.viewsCount();
            } else {
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.viewsCount();
                this.brandProfileService.currentFilter = this.currentFilter;
            }
        }
    }

    generateYears() {
        const count = this.yearLimit - 2020;
        for (let i = 0; i <= count; i++) {
            this.years.push(2020 + i);
        }
    }

    dateSelector() {
        if (
            this.brandProfileService.selected.startDate === null &&
            this.brandProfileService.currentFilter === 'WEEK'
        ) {
            this.selected = {startDate: null, endDate: null};
            const weekdate = new Date();
            const weekend = new Date(weekdate);
            weekend.setDate(weekdate.getDate());
            const weekstart = new Date(weekdate);
            weekstart.setDate(weekdate.getDate() - 6);
            this.startDate = this.datepipe.transform(weekstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.brandProfileService.selected.startDate = this.startDate;
            this.brandProfileService.selected.endDate = this.endDate;
            this.viewsCount();
        } else {
            this.startDate = this.brandProfileService.selected.startDate;
            this.endDate = this.brandProfileService.selected.endDate;
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.viewsCount();
        }
    }

    ctShoWeek() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;
        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandProfileService.selected.startDate) {
                const weekdate = new Date();

                const weekend = new Date(weekdate); // End date or last date of calendar range selector
                weekend.setDate(weekdate.getDate());

                const weekstart = new Date(weekdate); // Start date or First date of calendar range selector
                weekstart.setDate(weekdate.getDate() - 6);

                this.startDate = this.datepipe.transform(weekstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');

                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);

                this.brandProfileService.selected.startDate = this.startDate;
                this.brandProfileService.selected.endDate = this.endDate;
                this.brandProfileService.currentFilter = this.currentFilter;
            } else {
                this.datePunk();
                this.brandProfileService.currentFilter = this.currentFilter;
            }

            this.viewsCount();
        }
    }

    ctShowMonth() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = true;
        this.curryear = false;
        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandProfileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(
                    monthdate.getFullYear(),
                    monthdate.getMonth(),
                    1
                );
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(
                    monthdate.setDate(monthend),
                    'yyyy-MM-dd'
                );
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandProfileService.currentFilter = this.currentFilter;
                this.viewsCount();
            } else {
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.viewsCount();
                this.brandProfileService.currentFilter = this.currentFilter;
            }
        }
    }

    ctShowYear() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = false;
        this.curryear = true;
        if (this.currentFilter !== 'YEAR') {
            this.currentFilter = 'YEAR';
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.selected = {startDate: null, endDate: null};
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.selected.startDate = this.startDate;
            this.selected.endDate = this.endDate;
            this.brandProfileService.currentFilter = this.currentFilter;
            this.viewsCount();
        }
    }

    ctCustomDate() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandProfileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandProfileService.selected.startDate;
                this.endDate = this.brandProfileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandProfileService.currentFilter = this.currentFilter;
            } else {
                this.datePunk();
                this.brandProfileService.currentFilter = this.currentFilter;
            }

            setTimeout(() => {
                this.viewsCount();
            }, 1000);
        }
    }

    viewsCount() {
        this.momentsService
            .getJourneyCounts(this.startDate, this.endDate)
            .subscribe((res) => {
                this.performanceJourneyData = res;
            });
        this.momentsService
            .getMomentCounts(this.startDate, this.endDate)
            .subscribe((res) => {
                this.performanceMomentData = res;
            });
        this.momentsService
            .getRepliesCount(this.startDate, this.endDate)
            .subscribe((res) => {
                this.performanceRepliesData = res;
            });
        this.momentsService
            .getLikesCount(this.startDate, this.endDate)
            .subscribe((res) => {
                this.performancelikesData = res;
            });
        this.getRandomNumber();
    }

    getRandomNumber() {
        this.number1 = Math.floor(Math.random() * 10 + 1);
        this.number2 = Math.floor(Math.random() * 10 + 1);
    }

    momentApiCalling() {
        this.momentsService.getMoments(this.brandId).subscribe((res) => {
            this.momentData = [];
            this.momentData = res[`posts`];
            this.isMomentsLoading = false;
        });
    }

    journeyApiCalling() {
        this.momentsService.getJourneys(this.brandId).subscribe((res) => {
            this.journeyData = [];
            this.journeyData = res[`posts`];
            this.isJourneyLoading = false;
        });
    }

    recommendationApiCalling() {
        this.momentsService.getRecommendations(this.brandId).subscribe((res) => {
            this.recommendData = [];
            this.recommendData = res[`posts`];
            this.isRecommendationsLoading = false;
        });
    }

    openPostMetrics(data) {
        this.dialog.open(PostMetricsPopupComponent, {
            minWidth: '600px',
            panelClass: 'dialog-responsive',
            data,
        });
    }

    journeypageChanged(event) {
        // console.log(event, 'jou');
    }

    momentpageChanged(event) {
        // console.log(event, 'mom');
    }

    recommendpageChanged(event) {
        // console.log(event);
    }

    tabFilter(e) {
        this.btnName = e.target.name;
        switch (e.target.name) {
            case 'best performing moments':
                if (window.screen.availWidth <= 411) {
                    this.bestPerformingMoment = true;
                } else {
                    this.bestPerformingMoment = false;
                }

        }
    }

    recommendtabFilter(e) {
        this.recommendBtnName = e.target.name;
        switch (e.target.name) {
            case 'Best Performing User Recommendations':
                if (window.screen.availWidth <= 411) {
                    this.bestRecommendations = true;
                } else {
                    this.bestRecommendations = false;
                }
                break;

            case 'all user recommendations':
                this.bestRecommendations = false;
                break;
            default:
                this.bestRecommendations = false;
        }

    }

    journeytabFilter(e) {
        this.journeyBtnName = e.target.name;
        switch (e.target.name) {
            case 'Best Performing Journey Videos':
                if (window.screen.availWidth <= 411) {
                    this.bestJourneyVideos = true;
                } else {
                    this.bestJourneyVideos = false;
                }
                break;

            case 'all user recommendations':
                this.bestJourneyVideos = false;
                break;
            default:
                this.bestJourneyVideos = false;
        }

    }

    private daysCount(): number {

        // Calculating days between start and end date to set chart 'week' or 'month' filter.

        const firstDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        const timeDiff = (endDate.getTime() - firstDate.getTime());

        return timeDiff / (1000 * 3600 * 24);
    }

}
