import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticatedUser} from '../shared/services/auth/authenticateduser';
import {AuthService} from '../shared/services/auth/auth.service';
import {single} from '../data';
import {Brand} from '../shared/services/brand/brand';
import {BrandService} from '../shared/services/brand/brand.service';
import {BrandAdminDashboardService} from '../shared/services/brand_admin_dashboard/brand--admin-dashboard.service';
import {
    BrandAdminDashboard,
    BrandFollowers,
    BrandProfileVsCustomerProfile,
    CommunityDemographics,
    FollowerList,
    MatchPercentage
} from '../shared/services/brand_admin_dashboard/brand-admin-dashboard';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {DatePipe} from '@angular/common';
import {BrandProfileService} from '../shared/services/Brand Profile/brand-profile.service';
import {ProductCategoryService} from '../shared/services/product_category/product-category.service';
import {VibeService} from '../shared/services/vibe/vibe.service';
import {ValueService} from '../shared/services/value/value.service';
import {SocialCauseService} from '../shared/services/social_cause/social-cause.service';
import {ProductCategory} from '../shared/services/product_category/product-category';
import {Vibe} from '../shared/services/vibe/vibe';
import {Value} from '../shared/services/value/value';
import {SocialCause} from '../shared/services/social_cause/social_cause';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    isLoading = false;
    dateToday: Date = new Date();
    currentFilter = 'CUSTOM';
    currentDate = this.datepipe.transform(this.dateToday, 'yyyy-MM-dd');
    startDate: any;
    endDate: any;
    dateLimit = '7';
    selectedDay: { startDate; endDate };
    selected = {startDate: null, endDate: null};
    options: any = {
        autoApply: false,
        alwaysShowCalendars: false,
        showCancel: false,
        showClearButton: false,
        linkedCalendars: true,
        singleDatePicker: true,
        showWeekNumbers: false,
        showISOWeekNumbers: false,
        customRangeDirection: false,
        lockStartDate: false,
        closeOnAutoApply: true,
    };
    maxDate = moment().max(moment().add(1, 'd'));
    minDate = moment().startOf('year');
    years = [];
    selectedYear: any;
    @ViewChild(DaterangepickerDirective, {static: false})
    pickerDirective: DaterangepickerDirective;
    pcTableData: Array<any> = [];
    topsymbolsData: Array<any> = [];
    cdnUrl = environment.webStaticResources;
    isSignupSuccessModalShown = false;
    bottomBorder = false;
    selection: string[];
    single: any[];
    multi: any[];
    data = [];
    singleData = [];
    showTab = 1;
    showStats = 1;
    currentUser: AuthenticatedUser;
    statsBrandDashboard: BrandAdminDashboard;
    brandprofilevscustomer: BrandProfileVsCustomerProfile;
    communityDemographic: CommunityDemographics;
    followerList: FollowerList;
    brandFollowers: BrandFollowers[];
    matchGenderRatio: MatchPercentage[];
    gerderRatio: {
        title: string,
        ratio: number
    };
    gerderRatioPrev: {
        ratio: number
    };
    matchGenderRatioPrev: MatchPercentage[];
    brandTopSymbols: Array<any>[];
    brandSymbolsAll: any;
    symbolsData: Array<any>;
    allProductCategories: Array<ProductCategory>;
    allVibes: Array<Vibe>;
    allValues: Array<Value>;
    allSocialCauses: Array<SocialCause>;
    // Required Variables to use and hold dynamic values
    dropName: string;
    selectedTab: { filter };
    btnName = 'all';
    filter = 'all';
    view: any[] = [1400, 400];
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Month';
    showYAxisLabel = true;
    yAxisLabel = 'Count';
    timeline = true;
    currday = true;
    currweek = false;
    currmonth = false;
    curryear = false;
    colorScheme = {
        domain: ['#3f9194', '#e1c920', '#1e537e', '#e17400', '#c78891'],
    };
    state = 'default';
    // line, area
    autoScale = true;
    public today = new Date();
    public yearLimit: any;
    // <-------------------------------------------------- calender stats End----------------------->
    countWord = 15;
    private currentBrandInfo: Brand;

    constructor(
        private authService: AuthService,
        private brandService: BrandService,
        private BrandDashboardService: BrandAdminDashboardService,
        private brandprofileService: BrandProfileService,
        private productCategoryService: ProductCategoryService,
        private vibeService: VibeService,
        private valueService: ValueService,
        public datepipe: DatePipe,
        private socialCauseService: SocialCauseService,
    ) {
        this.brandTopSymbols = [];
        this.brandSymbolsAll = [];
        this.authService.currentUser.subscribe((x) => (this.currentUser = x));
        Object.assign(this, {single});
        this.view = [innerWidth / 1.4, 400];
        this.gerderRatio = {
            title: '',
            ratio: 0,
        };
        this.gerderRatioPrev = {
            ratio: 0,
        };
    }

    onSelect(event) {
        // console.log(event);
    }

    followerFilter(e) {
        this.dropName = e.target.name;
        this.filter = e.target.name;

        this.BrandDashboardService.GetFollowerAlsoFollow(this.filter, this.startDate, this.endDate)
        .subscribe((flf) => {
            
            this.brandFollowers = flf['result'] as Array<BrandFollowers>;
            this.pcTableData = [];
            if (this.brandFollowers.length > 0) {
                this.brandFollowers.forEach((pc) => {
                    const newObj = {
                        count: pc.count,
                        brand_username: pc.brand.business_name,
                        match_percentage: pc.match_percentage,
                        logo_url: pc.brand.logo_url
                    };
                    this.pcTableData.push(newObj);

                });
            }

        });


    }

    tabFilter(e) {
        this.btnName = e.target.name;
        this.filter = e.target.name;
        this.BrandDashboardService.GetAllTopSymbols(this.filter, this.startDate, this.endDate)
            .subscribe((response) => {
                this.brandSymbolsAll = [];
                this.brandSymbolsAll = response[0].topSymbols;
            });
    }

    tabToggle(index) {
        this.showTab = index;
    }

    onResize(event) {
        this.view = [event.target.innerWidth / 1.6, 400];
    }

    initObjects() {
        this.brandFollowers = [];
        this.matchGenderRatio = [];
        this.matchGenderRatioPrev = [];
        this.brandSymbolsAll = [];
        this.statsBrandDashboard = {
            followerCount: 0,
            followerCountPrev: 0,
            clickThroughCount: 0,
            clickThroughCountPre: 0,
            collectionCount: 0,
            collectionCountPre: 0,
            recommendationCount: 0,
            recommendationCountPre: 0,
            profileViewerCount: 0,
            profileViewerCountPre: 0,
        };
        this.brandprofilevscustomer = {
            followerMatch: 0,
            followerMatchprev: 0,
            profileViewerMatch: 0,
            profileViewerMatchprev: 0,
            clickThroughMatch: 0,
            clickThroughMatchprev: 0,
        };
        this.communityDemographic = {
            followerAvgAge: 0,
            followerAvgAgePrev: 0,
            profileAvgAge: 0,
            profileAvgAgePrev: 0,
        };
        this.topsymbolsData = [];
    }

    initBrandInfo() {
        this.productCategoryService.GetAllProductCategories().subscribe(
            (productCategories) => {
                this.allProductCategories = productCategories.results;
                this.allProductCategories.forEach((productCategory, index) => {
                    productCategory.selected = false;
                    this.bottomBorder = true;
                    this.currentBrandInfo.product_categories.forEach((brandProductCategory, index2) => {
                        if (brandProductCategory.id === productCategory.id) {
                            productCategory.selected = true;
                            productCategory.min_price = brandProductCategory.min_price;
                            productCategory.max_price = brandProductCategory.max_price;
                            productCategory.percentage = brandProductCategory.percentage;
                        }
                    });
                });

                this.vibeService.GetAllVibes().subscribe((vibes) => {
                    this.allVibes = vibes.results;
                    this.allVibes.forEach((vibe, index) => {
                        vibe.selected = false;
                        this.currentBrandInfo.vibes.forEach((brandVibe, index2) => {
                            if (brandVibe.id === vibe.id) {
                                vibe.selected = true;
                                vibe.percentage = brandVibe.percentage;
                            }
                        });
                    });
                    this.valueService.GetAllValues().subscribe((values) => {
                        this.allValues = values.results;
                        this.allValues.forEach((value, index) => {
                            value.selected = false;
                            this.currentBrandInfo.values.forEach((brandValue, index2) => {
                                if (brandValue.id === value.id) {
                                    value.selected = true;
                                    value.percentage = brandValue.percentage;
                                }
                            });
                        });

                        this.socialCauseService.GetAllSocialCauses().subscribe((socialCauses) => {
                            this.allSocialCauses = socialCauses.results;
                            this.allSocialCauses.forEach((socialCause, index) => {
                                socialCause.selected = false;
                                this.currentBrandInfo.social_causes.forEach((brandSocialCause, index2) => {
                                    if (brandSocialCause.id === socialCause.id) {
                                        socialCause.selected = true;
                                    }
                                });
                            });
                        });
                    });
                });
            },
            (err) => {
            }
        );
    }

    ngOnInit() {
        this.yearLimit = this.today.getFullYear();
        this.generateYears();
        this.datePunk();
        this.initObjects();
        this.initBrandInfo();
        this.isLoading = true;

        const currentBrand = this.authService.currentUserValue.user.branduserprofile.brand;
        this.currentBrandInfo = new Brand(
            currentBrand.id,
            currentBrand.business_name,
            currentBrand.description,
            currentBrand.tags,
            currentBrand.brandsignupchecklist,
            currentBrand.brandcustomerprofile,
            currentBrand.product_categories,
            currentBrand.vibes,
            currentBrand.values,
            currentBrand.social_causes,
            currentBrand.sales_channel,
            currentBrand.website,
            currentBrand.headquarter_location,
            currentBrand.tax_id,
            currentBrand.years_in_business,
            currentBrand.year_founded,
            currentBrand.number_of_locations,
            currentBrand.business_percent_online,
            currentBrand.business_percent_instore,
        );
        this.currentBrandInfo.initBrandInfo();
        const brandCheckList = this.currentBrandInfo.brandsignupchecklist;
        if (
            !brandCheckList.provided_business_details ||
            !brandCheckList.selected_product_categories ||
            !brandCheckList.provided_category_details ||
            !brandCheckList.selected_brand_vibes ||
            !brandCheckList.selected_brand_values ||
            !brandCheckList.selected_social_values ||
            !brandCheckList.provided_brand_description ||
            !brandCheckList.shared_customer_profile
        ) {
            window.location.href = '/brandaccount';
        }
        // else if (!this.currentBrandInfo.brandsignupchecklist.greeted_on_dashboard) {
        //     this.spinnerService.hide();
        //     this.showSignupSuccessModal();
        //
        //     this.currentBrandInfo.brandsignupchecklist.greeted_on_dashboard = true;
        //     // const brandDetails = {
        //     //     brandsignupchecklist: {
        //     //         greeted_on_dashboard: true,
        //     //     },
        //     // };
        //     // this.brandService.UpdateBrandById(this.currentBrandInfo.id, brandDetails).subscribe(
        //     //     (brand) => {
        //     //         this.authService.updateCurrentUserBrand(brand);
        //     //     },
        //     //     (err) => {
        //     //     }
        //     // );
        // }
        this.initStats();
        this.initChartStats();
        this.initSingleChartStats();
        const tDays = +this.daysCount();

        if (tDays < 60) {
            this.getChartStats('week');
        } else if (tDays < 300 && tDays > 59) {
            this.getChartStats('month');
        } else {
            this.getChartStats('year');
        }
    }

    generateYears() {
        const count = this.yearLimit - 2020;
        for (let i = 0; i <= count; i++) {
            this.years.push(2020 + i);
        }
    }

    datePunk() {
        if (this.brandprofileService.selected.startDate === null && this.brandprofileService.currentFilter === 'CUSTOM') {
            this.selected = {startDate: null, endDate: null};

            const weekdate = new Date();

            const weekend = new Date(weekdate); // End date or last date of calendar range selector
            weekend.setDate(weekdate.getDate());


            const weekstart = new Date(weekdate); // Start date or First date of calendar range selector
            weekstart.setDate(weekdate.getDate() - 6);

            const createdDate = this.datepipe.transform(this.currentUser.user.branduserprofile.brand.created_at, 'yyyy-MM-dd');

            this.startDate = this.datepipe.transform(createdDate, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');

            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);

            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;

            this.initDashbaordStats();

        } else {
            this.startDate = this.brandprofileService.selected.startDate;
            this.endDate = this.brandprofileService.selected.endDate;
            this.selected.startDate = moment(this.startDate);
            this.selected.endDate = moment(this.endDate);
            this.initDashbaordStats();
        }
        switch (this.brandprofileService.currentFilter) {
            case 'WEEK':
                this.ctShoWeek();
                this.setActive('weekBtn', 'pcfbtn');
                break;

            case 'DAY':
                this.ctShowDay();
                this.setActive('dayBtn', 'pcfbtn');
                break;

            case 'MONTH':
                this.ctShowMonth();
                this.setActive('monthBtn', 'pcfbtn');
                break;
            case 'YEAR':
                this.ctShowYear();
                this.setActive('yearBtn', 'pcfbtn');
                break;
            case 'CUSTOM':
                this.ctShowCustom();
                this.setActive('customBtn', 'pcfbtn');
                break;

            default:
                break;
        }

    }

    initDashbaordStats() {
        this.BrandDashboardService
            .GetBrandFollowerCount(this.startDate, this.endDate)
            .subscribe((res) => {
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.followerCount = +res['follower_count'];
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.followerCountPrev = +res['follower_count_previous'];

            });
        this.BrandDashboardService
            .GetBrandProfileCount(this.startDate, this.endDate)
            .subscribe((vm) => {
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.profileViewerCount = +vm['profile_viewer_count'];
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.profileViewerCountPre = +vm['profile_viewer_count_previous'];

            });
        this.BrandDashboardService
            .GetBrandClickThroughCount(this.startDate, this.endDate)
            .subscribe((ctm) => {
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.clickThroughCount = +ctm['click_through_count'];
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.clickThroughCountPre = +ctm['click_through_count_previous'];


            });

        this.BrandDashboardService.GetBrandCollectionCount(this.startDate, this.endDate)
            .subscribe((col) => {
                this.statsBrandDashboard.collectionCount = +col[`collection_count`];
                this.statsBrandDashboard.collectionCountPre = +col[`collection_count_previous`];

            });

        this.BrandDashboardService
            .GetBrandRecommendationsCount(this.startDate, this.endDate)
            .subscribe((rm) => {
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.recommendationCount = +rm['recommendation_count'];
                // tslint:disable-next-line:no-string-literal
                this.statsBrandDashboard.recommendationCountPre = +rm['recommendation_count_previous'];

            });

        this.BrandDashboardService.GetBrandFollowerMatch(this.startDate, this.endDate)
            .subscribe((fm) => {
                this.brandprofilevscustomer.followerMatch = +fm[`match_percentage`];
                this.brandprofilevscustomer.followerMatchprev = +fm[`match_percentage_previous`];

            });

        this.BrandDashboardService.GetBrandProfileMatch(this.startDate, this.endDate)
            .subscribe((pm) => {
                this.brandprofilevscustomer.profileViewerMatch = +pm[`match_percentage`];
                this.brandprofilevscustomer.profileViewerMatchprev = +pm[`match_percentage_previous`];


            });

        this.BrandDashboardService.GetBrandClickThroughMatch(this.startDate, this.endDate)
            .subscribe((cm) => {
                this.brandprofilevscustomer.clickThroughMatch = +cm[`match_percentage`];
                this.brandprofilevscustomer.clickThroughMatchprev = +cm[`match_percentage_previous`];


            });

        this.BrandDashboardService.GetMaleToFemaleRatio(this.startDate, this.endDate)
            .subscribe((mf) => {
                this.matchGenderRatio = mf[`match_percentage`] as Array<MatchPercentage>;
                if (this.matchGenderRatio[0].male_percentage > this.matchGenderRatio[0].female_percentage) {
                    this.gerderRatio.title = 'Female';
                    this.gerderRatio.ratio = this.matchGenderRatio[0].female_percentage;
                } else if (this.matchGenderRatio[0].female_percentage > this.matchGenderRatio[0].male_percentage) {
                    this.gerderRatio.title = 'Male';
                    this.gerderRatio.ratio = this.matchGenderRatio[0].male_percentage;
                } else {
                    this.gerderRatio.title = 'Neutral';
                    this.gerderRatio.ratio = 0;
                }
                this.matchGenderRatioPrev = mf[`match_percentage_previous`] as Array<MatchPercentage>;
                if (this.matchGenderRatioPrev[0].male_percentage >= this.matchGenderRatioPrev[0].female_percentage) {
                    this.gerderRatioPrev.ratio = this.matchGenderRatioPrev[0].male_percentage;
                } else if (this.matchGenderRatioPrev[0].female_percentage > this.matchGenderRatioPrev[0].male_percentage) {
                    this.gerderRatioPrev.ratio = this.matchGenderRatioPrev[0].female_percentage;
                }

            });

        this.BrandDashboardService.GetFollowerAvgAge(this.startDate, this.endDate)
            .subscribe((fa) => {
                this.communityDemographic.followerAvgAge = +fa[`match_count`];
                this.communityDemographic.followerAvgAgePrev = +fa[`match_count_previous`];

            });

        this.BrandDashboardService.GetProfileViewerAvgAge(this.startDate, this.endDate)
            .subscribe((pa) => {
                this.communityDemographic.profileAvgAge = +pa[`match_count`];
                this.communityDemographic.profileAvgAgePrev = +pa[`match_count_previous`];

            });

        this.BrandDashboardService.GetFollowerAlsoFollow(this.filter, this.startDate, this.endDate)
            .subscribe((flf) => {
                // tslint:disable-next-line:no-string-literal
                this.brandFollowers = flf['result'] as Array<BrandFollowers>;
                this.pcTableData = [];
                if (this.brandFollowers.length > 0) {
                    this.brandFollowers.forEach((pc) => {
                        const newObj = {
                            count: pc.count,
                            brand_username: pc.brand.business_name,
                            logo_url: pc.brand.logo_url
                        };
                        this.pcTableData.push(newObj);

                    });
                }

            });

        this.BrandDashboardService.GetAllTopSymbols(this.filter, this.startDate, this.endDate)
            .subscribe((response) => {
                this.brandSymbolsAll = [];
                this.brandSymbolsAll = response[0].topSymbols;
            });
    }

    initStats() {
        this.initObjects();
        this.initDashbaordStats();
    }

    initChartStats() {
        this.BrandDashboardService.GetChartData().subscribe((chart) => {
            this.data = chart;
        });
    }

    initSingleChartStats() {
        this.BrandDashboardService.GetSingleChartData().subscribe((dSingle) => {
            this.singleData = dSingle;
        });
    }

    selectedChange(event) {
        if (event.startDate === null) {
            return;
        }
        this.isLoading = true;
        if (this.currentFilter === 'WEEK') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.initDashbaordStats();
            this.getChartStats('week');
        }
        if (this.currentFilter === 'DAY') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.initDashbaordStats();
            this.getChartStats('day');
        }
        if (this.currentFilter === 'MONTH') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.initDashbaordStats();
            this.getChartStats('month');
        }
        if (this.currentFilter === 'CUSTOM') {
            this.brandprofileService.currentFilter = this.currentFilter;
            this.startDate = this.datepipe.transform(event.startDate._d, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(event.endDate._d, 'yyyy-MM-dd');
            this.brandprofileService.selected.startDate = this.startDate;
            this.brandprofileService.selected.endDate = this.endDate;
            this.initDashbaordStats();

            const tDays = +this.daysCount();

            if (tDays < 60) {
                this.getChartStats('week');
            } else if (tDays < 300 && tDays > 59) {
                this.getChartStats('month');
            } else {
                this.getChartStats('year');
            }

        }

    }

    selectYear(year, category) {
        this.selectedYear = year;
        this.currentFilter = 'YEAR';
        this.startDate = year + '-01-01';
        this.endDate = year + '-12-31';
        this.initDashbaordStats();
        this.getChartStats('year');
    }

    // <-------------------------------------------------- calender stats End----------------------->

    setActive(selBtn: string, allBtnsClass: string
    ) {
        const selectedBtn = document.getElementById(selBtn);
        const allBtns = document.querySelectorAll('.' + allBtnsClass);

        allBtns.forEach((elem, index) => {
            elem.classList.remove('active');
        });

        selectedBtn.classList.add('active');
    }

    ctShowDay() {
        this.currday = true;
        this.currweek = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'DAY') {
            this.currentFilter = 'DAY';

            if (this.brandprofileService.currentFilter !== 'DAY') {
                const daydate = new Date();
                const daystart = daydate.getDate();
                this.startDate = this.datepipe.transform(daydate.setDate(daystart), 'yyyy-MM-dd');
                this.endDate = this.startDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.initDashbaordStats();
                this.brandprofileService.currentFilter = this.currentFilter;

            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.initDashbaordStats();
                this.brandprofileService.currentFilter = this.currentFilter;


            }
        }
    }

    ctShoWeek() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'WEEK') {
            this.currentFilter = 'WEEK';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};

                const weekdate = new Date();

                const weekend = new Date(weekdate); // End date or last date of calendar range selector
                weekend.setDate(weekdate.getDate());

                const weekstart = new Date(weekdate); // Start date or First date of calendar range selector
                weekstart.setDate(weekdate.getDate() - 6);

                this.startDate = this.datepipe.transform(weekstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(weekend, 'yyyy-MM-dd');

                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);

                this.brandprofileService.selected.startDate = this.startDate;
                this.brandprofileService.selected.endDate = this.endDate;
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.datePunk();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.initDashbaordStats();
        }
    }

    ctShowMonth() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = true;
        this.curryear = false;

        if (this.currentFilter !== 'MONTH') {
            this.currentFilter = 'MONTH';
            if (this.brandprofileService.currentFilter !== 'MONTH') {
                const monthdate = new Date();
                const monthstart = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
                const monthend = monthdate.getDate();
                this.startDate = this.datepipe.transform(monthstart, 'yyyy-MM-dd');
                this.endDate = this.datepipe.transform(monthdate.setDate(monthend), 'yyyy-MM-dd');
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
                this.initDashbaordStats();
            } else {
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected = {startDate: null, endDate: null};
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.initDashbaordStats();
                this.brandprofileService.currentFilter = this.currentFilter;
            }
        }
    }

    ctShowYear() {
        this.currweek = false;
        this.currday = false;
        this.currmonth = false;
        this.curryear = true;

        if (this.currentFilter !== 'YEAR') {
            this.currentFilter = 'YEAR';
            this.selected = {startDate: null, endDate: null};
            const yeardate = new Date();
            const yearstart = new Date(yeardate.getFullYear(), 0, 1);
            const yearend = new Date(yeardate.getFullYear(), 11, 31);
            this.startDate = this.datepipe.transform(yearstart, 'yyyy-MM-dd');
            this.endDate = this.datepipe.transform(yearend, 'yyyy-MM-dd');
            this.brandprofileService.currentFilter = this.currentFilter;
            this.initDashbaordStats();

        }
    }

    ctShowCustom() {
        this.currweek = true;
        this.currday = false;
        this.currmonth = false;
        this.curryear = false;

        if (this.currentFilter !== 'CUSTOM') {
            this.currentFilter = 'CUSTOM';
            if (this.brandprofileService.selected.startDate) {
                this.selected = {startDate: null, endDate: null};
                this.startDate = this.brandprofileService.selected.startDate;
                this.endDate = this.brandprofileService.selected.endDate;
                this.selected.startDate = moment(this.startDate);
                this.selected.endDate = moment(this.endDate);
                this.brandprofileService.currentFilter = this.currentFilter;
            } else {
                this.datePunk();
                this.brandprofileService.currentFilter = this.currentFilter;
            }

            this.initDashbaordStats();
        }
    }

    showSignupSuccessModal(): void {
        this.isSignupSuccessModalShown = true;
    }

    // Fetch Charts Data

    chartsBreakdown(selection: string) {
        this.isLoading = true;
        // console.log('This selec: ' + selection);
        // console.log('Main selec: ' + this.currentFilter);
        if (selection === 'days') {
            // console.log(this.startDate);
            // console.log(this.endDate);
            this.getChartStats('week');
        } else if (selection === 'weeks') {
            // console.log(this.startDate);
            // console.log(this.endDate);
            this.getChartStats('month');
        } else if (selection === 'months') {
            // console.log(this.startDate);
            // console.log(this.endDate);
            this.getChartStats('year');
        }
    }

    private getChartStats(selection: 'day' | 'week' | 'month' | 'year'): void {

        const tempObj = {
            singleDate: this.startDate,
            dateTo: this.endDate,
            dateFrom: this.startDate,
            year: this.selectedYear
        };

        if (selection === 'day') {
            this.BrandDashboardService.GetDashboardChartData(selection, '', '', tempObj.singleDate).subscribe(data => {
                this.data = data;
                this.isLoading = false;
            });
        } else if (selection === 'year') {
            this.BrandDashboardService.GetDashboardChartData(selection, '', '', '', tempObj.year).subscribe(data => {
                this.data = data;
                this.isLoading = false;
            });
        } else if (selection === 'week' || selection === 'month') {
            this.BrandDashboardService.GetDashboardChartData(selection, tempObj.dateFrom, tempObj.dateTo).subscribe(data => {
                this.data = data;
                this.isLoading = false;
            });
        }


    }

    private daysCount(): number {

        // Calculating days between start and end date to set chart 'week' or 'month' filter.

        const firstDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        const timeDiff = (endDate.getTime() - firstDate.getTime());

        return timeDiff / (1000 * 3600 * 24);
    }

}
