import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../api.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthenticatedUser} from './authenticateduser';
import {BrandService} from '../brand/brand.service';
import {Brand} from '../brand/brand';
import {error} from 'util';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends ApiService {
    public currentUser: Observable<AuthenticatedUser>;
    private currentUserSubject: BehaviorSubject<AuthenticatedUser>;

    constructor(private http: HttpClient) {
        super();

        this.currentUserSubject = new BehaviorSubject<AuthenticatedUser>(
            JSON.parse(localStorage.getItem('currentUser'))
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): AuthenticatedUser {
        return this.currentUserSubject.value;
    }

    public resetCurrentUserValue(): void {
        this.currentUserSubject.next(null);
    }

    public localUserObject() {
        return JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
    }

    public updateUser(user) {
        localStorage.setItem('authToken', user.token);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    public updateCurrentUserBrand(updatedBrand: Brand) {
        const currentUser: AuthenticatedUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.user.branduserprofile.brand = updatedBrand;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.updateUser(currentUser);
    }

    GetAuthToken(email: string, password: string): Observable<AuthenticatedUser> {
        return this.http
            .post<AuthenticatedUser>(
                `${environment.apiBaseUrl}/api-token-auth/`,
                {
                    email,
                    password,
                },
                this.httpOptions
            )
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes

                    localStorage.setItem('authToken', user.token);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    isAuthenticated() {
        return new Promise<AuthenticatedUser>((resolve, reject) => {
            const user = this.currentUserValue;
            if (!user) {
                reject(user);
            } else {
                this.refreshAuthToken().subscribe(
                    // tslint:disable-next-line:no-shadowed-variable
                    (user) => {
                        resolve(this.currentUserValue);
                    }, err => {
                        console.log(err);
                        this.logout();
                    }
                );
            }
        });
    }

    refreshAuthToken() {
        const currentUser = this.currentUserValue;

        return this.http
            .post<AuthenticatedUser>(
                `${environment.apiBaseUrl}/api-token-refresh/`,
                {
                    token: currentUser.token,
                },
                this.httpOptions
            )
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if (user.user.user_type !== 'brand') {
                        this.logout();
                    } else {
                        this.updateUser(user);
                        return user;
                    }
                })
            );
    }

    validateAuthToken() {
        const currentUser = this.currentUserValue;

        return this.http
            .post<AuthenticatedUser>(
                `${environment.apiBaseUrl}/api-token-verify/`,
                {
                    token: currentUser.token,
                },
                this.httpOptions
            )
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if (user.user.user_type !== 'brand') {
                        this.logout();
                        return user;
                    } else {
                        this.refreshAuthToken().subscribe(
                            (updatedUser) => {
                                localStorage.setItem('authToken', updatedUser.token);
                                localStorage.setItem('currentUser', JSON.stringify(updatedUser));
                                this.currentUserSubject.next(updatedUser);
                                return updatedUser;
                            },
                            (err) => {
                                console.log(err);
                                this.logout();
                                return user;
                            }
                        );
                    }
                })
            );
    }

    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('authToken');
        // localStorage.removeItem('currentUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
        window.location.href = '/login';
    }

    RequestPasswordReset(email: string): Observable<object> {
        return this.http
            .post<object>(
                `${environment.apiBaseUrl}/api/password_reset/`,
                {
                    email,
                },
                this.httpOptions
            )
            .pipe();
    }

    ResetPasswordConfirm(token: string, password: string): Observable<object> {
        return this.http
            .post<object>(
                `${environment.apiBaseUrl}/api/password_reset/confirm/`,
                {
                    token,
                    password,
                },
                this.httpOptions
            )
            .pipe();
    }

    ResetPasswordValidateToken(token: string): Observable<object> {
        return this.http
            .post<object>(
                `${environment.apiBaseUrl}/api/password_reset/validate_token/`,
                {
                    token,
                },
                this.httpOptions
            )
            .pipe();
    }
}
