import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BrandAdminDashboardService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
    }
    GetBrandFollowerCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/follower_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandProfileCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_viewer_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandClickThroughCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_click_through_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandCollectionCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_collection_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandRecommendationsCount(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_recommendation_count/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandFollowerMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_follower_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandProfileMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_profile_viewer_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetBrandClickThroughMatch(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_click_through_match/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetMaleToFemaleRatio(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/follower_gender_ratio_view/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetFollowerAvgAge(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/follower_avg_age_view/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetProfileViewerAvgAge(startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/profile_avg_age_view/?date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetFollowerAlsoFollow(filter, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/follower_also_following_view/?filter_interval=${filter}&date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetAllTopSymbols(filter, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/brand_follower_symbol/?filter_interval=${filter}&date_from=${startDate}&date_to=${endDate}`)
            .pipe();
    }
    GetChartData() {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/chart_data_view/`)
            .pipe();
    }
    GetSingleChartData() {
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/single_chart_view/`)
            .pipe();
    }
    GetDashboardChartData(selection, dateFrom, dateTo, date, year) {
        let endPoint = `?selection=${selection}`;
        if (selection === 'day' && date) {
            endPoint = endPoint + `&date=${date}`;
        }
        else if (selection === 'year' && year) {
            endPoint = endPoint + `&year=${year}`;
        }
        else if (selection === 'week' || selection === 'month') {
            endPoint = endPoint + `&date_from=${dateFrom}&date_to=${dateTo}`;
        }
        return this.http.get(`${this.apiUrl}/brands/dashboard_stats/chart_data_view/${endPoint}`).pipe();
    }
}
BrandAdminDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandAdminDashboardService_Factory() { return new BrandAdminDashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: BrandAdminDashboardService, providedIn: "root" });
