<!--  HEADER-->
<header class="mainHeader">
    <nav class="navbar navbar-expand-md navbar-light fixed-top">
        <div class="container px-4 px-md-1">
            <a class="navbar-brand" href="https://tailorie.com/brands-home/">
                <img alt="" src="{{cdnUrl}}/assets/images/Tailorie-logo_dark.svg">
                <span *ngIf="!hideText">Brands</span>
            </a>
            <button (click)="openDropdown()" aria-controls="topNav" aria-expanded="false"
                    aria-label="Toggle navigation" class="navbar-toggler">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="topNav">
                <ul class="navbar-nav ml-auto text-center">
                    <li class="nav-item">
                        <a class="nav-link px-2" href="https://tailorie.com/contact-us">Contact Us</a>
                    </li>
                    <li *ngIf="!currentUser" class="nav-item">
                        <a class="nav-link" href="#" routerLink="/login">Log In</a>
                    </li>
                    <li *ngIf="!currentUser" class="nav-item">
                        <a class="nav-link join-btn" href="#" routerLink="/signup">Join Now</a>
                    </li>
                    <li *ngIf="currentUser" class="nav-item ml-4">
                        <!-- TODO: replace below href link to dashboard once production screens got approved -->
                        <a class="nav-link join-btn" href="/brand-journey">Go to Dashboard</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
