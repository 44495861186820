import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProductCategoryService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
        this.selected = {
            startDate: null,
            endDate: null,
        };
        this.currentFilter = 'WEEK';
    }
    CreateProductCategory(data) {
        const newData = {
            title: data.title
        };
        return this.http
            .post(`${this.apiUrl}/product_categories/`, JSON.stringify(newData), this.httpOptions)
            .pipe();
    }
    GetAllProductCategories() {
        return this.http.get(`${this.apiUrl}/product_categories/`).pipe();
    }
    FetchFollowerData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/category_follower/?date_from=${startDate}&date_to=${endDate}&category_id=${id}`)
            .pipe();
    }
    FetchRecommendersData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/category_recommendation/?date_from=${startDate}&date_to=${endDate}&category_id=${id}`)
            .pipe();
    }
    FetchJourneyData(id, startDate, endDate) {
        return this.http.get(`${this.apiUrl}/brands/${id}/community_profile_stats/category_viewer/?date_from=${startDate}&date_to=${endDate}&category_id=${id}`)
            .pipe();
    }
    FetchProductCategoryQuestion(id, type, count_type) {
        return this.http.get(`${this.apiUrl}/brands/performance_stats/daily_questions/${id}/?type=${type}&count_type=${count_type}`)
            .pipe();
    }
}
ProductCategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductCategoryService_Factory() { return new ProductCategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductCategoryService, providedIn: "root" });
