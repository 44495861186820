import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {CreateCanvasCrateForm} from './Canvas_crate_data';
import * as url from "url";


@Injectable({
  providedIn: 'root'
})

export class CanvasCrateService extends ApiService {

  private listCrates: BehaviorSubject<boolean> = new BehaviorSubject(false);
  listcrate = this.listCrates.asObservable();


  constructor(private http: HttpClient) {
    super();
  }

 // Function to reset canvas crate list in observable
 setlistCrates(value: boolean) {
  this.listCrates.next(value);
}


  // POST TO CREATE CANVAS CRATE
  CreateCanvasCrate(data): Observable<CreateCanvasCrateForm> {
    return this.http.post<CreateCanvasCrateForm>(`${this.apiUrl}/brand_canvas_crate/`, data).pipe();
  }

  // GET CANVAS CRATES
  ListCanvasCrates(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/brand_canvas_crate/`).pipe();
  }

  // GET CANVAS CRATES
  GetCanvasCrate(crateId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/brand_canvas_crate/?crate_id=${crateId}`).pipe();
  }

  // GET CANVAS CRATES
  UpdateCanvasCrate(crateId, data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/brand_canvas_crate/?crate_id=${crateId}`, data).pipe();
  }

  // Upload Canvas Crate Media
  UploadCanvasCrateMedia(crateId, data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/canvas_crate/media/${crateId}/`, data).pipe();
  }

  // Delete Canvas Crate Media
  DeleteCanvasCrateMedia(crateId, mediaId): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/canvas_crate/media/${crateId}/?media_id=${mediaId}`).pipe();
  }

}

